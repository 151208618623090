import React, { useMemo, useState } from 'react';
import AlertModal from '../../../../Components/AlertModal/AlertModal';
import utilitySvg from '../../../../Components/Assets/utility.svg';
import DataSourceTag from '../../../../Components/DataSourceTag/DataSourceTag';
import applicationSettings from '../../../../Core/Settings/ApplicationSettings';
import {
    Currency,
    DataSeries,
    DemeterDataFrequency,
    DemeterFeatureType,
    DemeterRegion,
    DemeterTableDefinitionType,
    UnitOfMeasure,
} from '../../../../Generated/Raven-Demeter';
import useSeasonalApi, { SeasonalMonthlyRowModel } from '../../../Apis/Hooks/useSeasonalApiHook';
import tableCsvDownloadService from '../../../Services/Download/DownloadService';
import featureFlagsService from '../../../Services/FeatureFlags/FeatureFlagsService';
import useFeatureFlag from '../../../Services/FeatureFlags/useFeatureFlagHook';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../Services/Logging/LoggingService';
import LinkButton, { LinkButtonType } from '../../Form/Buttons/LinkButton';
import Switch from '../../Form/Buttons/Switch';
import IconMenuDropdown from '../../Form/Inputs/IconMenuDropdown';
import ComponentHeader from '../../Headers/ComponentHeader';
import EmailLink from '../../Navigation/EmailLink/EmailLink';
import { IRegionCommoditySelection } from '../../Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Navigation/Hooks/useTableDefinitionHook';
import { SeasonalTableReferenceType } from './SeasonalTableDefinitions';
import SeasonalTableRaw from './SeasonalTableRaw';
import styles from './SeasonalTables.module.scss';

interface ISeasonalTablesProps {
    title: string;
    tableDefinitionType: DemeterTableDefinitionType;
    regionCommoditySelection: IRegionCommoditySelection;
    aggregateRegions?: DemeterRegion[];
    unitOfMeasure?: UnitOfMeasure;
    currency?: Currency;
    showPercentTable?: boolean;
    testId?: string;
}

const numberOfYearsToAdd = 5;
const minimumNumberOfYears = 2;

const SeasonalTables: React.FC<ISeasonalTablesProps> = (props: ISeasonalTablesProps) => {
    // States.
    const [years, setYears] = useState<number[]>([]);
    const [displayQuarterlyAndYearlyValues, setDisplayQuarterlyAndYearlyValues] = useState<boolean>(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [, tableDefinitionCommodity] = useTableDefinition(props.tableDefinitionType, props.regionCommoditySelection);

    // Custom hooks.
    const [translations] = useLanguage();
    const isExportDataOn = useFeatureFlag(featureFlagsService.getFeatureType(DemeterFeatureType.DownloadsGeneral, props.tableDefinitionType));
    const seasonalData = useSeasonalApi(
        props.tableDefinitionType,
        props.regionCommoditySelection,
        props.aggregateRegions,
        DemeterDataFrequency.Monthly,
        props.unitOfMeasure,
        props.currency,
        years,
        true,
    );

    const seasonalMonthlyData = useMemo(() => seasonalData?.rows?.map((row) => row as SeasonalMonthlyRowModel).filter((row) => row) ?? [], [seasonalData]);

    // Page constants.
    const currencyText = useMemo(() => translations.currency[seasonalData?.currency!], [seasonalData]);
    const unitOfMeasureText = useMemo(() => translations.unitOfMeasure[seasonalData?.unitOfMeasure!], [seasonalData]);

    // Memoized values.
    const exportOptions = useMemo(() => {
        const options: { label: string; value: SeasonalTableReferenceType }[] = [
            {
                value: 'value',
                label: translations.words.values,
            },
        ];

        if (props.showPercentTable) {
            options.push({
                value: 'percent',
                label: translations.text.percentChange,
            });
        }
        return options;
    }, []);
    const pageSubtitle = useMemo(() => `${currencyText || ''} ${currencyText && unitOfMeasureText ? '/' : ''} ${unitOfMeasureText || ''}`, [seasonalData]);

    const handleAddYears = () => {
        const currentYears = seasonalData?.rows?.map((x) => x.year!) ?? [];
        const oldestYear = Math.min(...currentYears);
        for (let i = 1; i <= numberOfYearsToAdd; i += 1) {
            currentYears.unshift(oldestYear - i);
        }

        setYears(currentYears);

        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ButtonClick,
            EventCategoriesEnum.AddedYearsToSeasonalDataTables,
            '',
            EventDataTargetsEnum.SeasonalDataTables,
        );
    };

    const handleRemoveYears = () => {
        const filteredYears = years.filter((_year, index) => index >= numberOfYearsToAdd);
        setYears(filteredYears?.length > minimumNumberOfYears ? filteredYears : years);

        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ButtonClick,
            EventCategoriesEnum.RemovedYearsFromSeasonalDataTables,
            '',
            EventDataTargetsEnum.SeasonalDataTables,
        );
    };

    const buildSeasonalCsv = (type: SeasonalTableReferenceType): DataSeries[] | undefined => {
        if (seasonalMonthlyData.length === 0) {
            return [];
        }

        const newData = seasonalMonthlyData.map((row) => {
            if (!row.monthlyValues) {
                return {};
            }

            const averageOrTotalsData = displayQuarterlyAndYearlyValues
                ? [
                      ...row.quarterlyValues!.map((x) => ({
                          value: type === 'value' ? `${x.valueAverage}` : `${x.monthOverMonthChangePercentAverage}`,
                          rawDate: x.quarter!,
                          date: `${translations.words.quarter} ${x.quarter} ${translations.words.average}`,
                          isActualValue: x.isActualValue,
                      })),
                      {
                          value: type === 'value' ? `${row.yearlyValues?.valueAverage}` : `${row.yearlyValues?.monthOverMonthChangePercentAverage}`,
                          date: translations.text.yearlyAverage,
                          rawDate: row.yearlyValues?.year!,
                          isActualValue: row.yearlyValues?.isActualValue,
                      },
                  ]
                : [];

            return {
                label: `${row.year}`,
                data: [
                    ...row.monthlyValues!.map((x) => ({
                        value: type === 'value' ? `${x.value}` : `${x.monthOverMonthChangePercent}`,
                        date: formattingService.toMonth(x.month),
                        rawDate: new Date(x.asOfDate)!,
                        isActualValue: x.isActualValue,
                    })),
                    ...averageOrTotalsData,
                ],
            };
        });

        return newData;
    };

    const handleExportButtonClick = (referenceType: SeasonalTableReferenceType) => {
        if (isExportDataOn) {
            const downloadData = buildSeasonalCsv(referenceType);
            tableCsvDownloadService.downloadCsv(downloadData!, props.title);
        } else {
            setShowExportModal(true);
        }
    };

    const handleDisplayAveragesToggle = () => {
        setDisplayQuarterlyAndYearlyValues(!displayQuarterlyAndYearlyValues);

        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ToggleClicked,
            EventCategoriesEnum.HideOrDisplayData,
            '',
            EventDataTargetsEnum.SeasonalDataTables,
        );
    };

    return (
        <div data-testid={props.testId} className={styles.seasonal_table_wrapper_container}>
            {seasonalData && seasonalData.rows && seasonalData.rows.length > 0 && (
                <>
                    <div className={styles.seasonal_table_title_container}>
                        <ComponentHeader title={props.title} subtitle={pageSubtitle} />
                        <div className={styles.seasonal_table_button_container}>
                            <Switch handleChange={handleDisplayAveragesToggle} />
                            <p className={styles.seasonal_table_quarterly_and_yearly_text}>{translations.text.quarterlyAndYearly}</p>
                            <div className={styles.seasonal_table_add_and_remove_years}>
                                <LinkButton onClick={handleAddYears} title={translations.actions.addFiveYears} type={LinkButtonType.White} />
                            </div>
                            <div className={styles.seasonal_table_add_and_remove_years}>
                                <LinkButton
                                    onClick={handleRemoveYears}
                                    title={translations.actions.removeFiveYears}
                                    type={LinkButtonType.White}
                                    disabled={seasonalData.rows.length <= 5}
                                />
                            </div>
                            <IconMenuDropdown
                                iconButton={<img className={styles.seasonal_table_dropdown_image} src={utilitySvg} alt="#" />}
                                options={exportOptions}
                                handleSelection={handleExportButtonClick}
                                tooltip={translations.actions.clickToDownload}
                            />
                        </div>
                    </div>
                    <SeasonalTableRaw
                        seasonalData={seasonalData}
                        isPercent={false}
                        displayQuarterlyAndYearlyValues={displayQuarterlyAndYearlyValues}
                        displayDecimalPlacesMinimum={tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0}
                        displayDecimalPlacesMaximum={tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 0}
                    />
                    {props.showPercentTable && (
                        <SeasonalTableRaw
                            seasonalData={seasonalData}
                            isPercent
                            displayQuarterlyAndYearlyValues={displayQuarterlyAndYearlyValues}
                            displayDecimalPlacesMinimum={tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0}
                            displayDecimalPlacesMaximum={tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 0}
                        />
                    )}
                    <DataSourceTag value={seasonalData?.dataSourceTag} />
                    {showExportModal && (
                        <AlertModal
                            setShowModal={setShowExportModal}
                            message={
                                <h3>
                                    {translations.errors.downloadSubscriptionError}{' '}
                                    <EmailLink linkText={translations.text.brokerSupport} email={applicationSettings.emails.supportEmail} />
                                </h3>
                            }
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default SeasonalTables;
