import React, { useEffect, useMemo, useState } from 'react';
import { demeterApi } from '../../../../Apis/Apis';
import { Currency, DemeterDataFrequency, DemeterFilterTimeSpan, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import useMultipleApis from '../../../Apis/Hooks/useMultipleApisHook';
import { IOtcPriceSelection } from '../../../Apis/Hooks/useOtcPricesApiHook';
import { ChartDisplayType, IChartDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import FilterTimeSpans from '../../../Components/Charts/FilterTimeSpans/FilterTimeSpans';
import PriceChartRaw from '../../../Components/Charts/Price/PriceChartRaw';
import { ChartStudyType } from '../../../Components/Charts/Studies/StudiesDefinitions';
import StudiesDropdown from '../../../Components/Charts/Studies/StudiesDropdown';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';

export interface IOtcPricesChartProps {
    // Right now we only allow a single commodity, might have to re-visit the title when multiple are allowed.
    title: string;
    otcPricesSelections: IOtcPriceSelection[];
    currency: Currency;
    unitOfMeasure: UnitOfMeasure;
    displayDecimalPlacesMinimum: number;
    displayDecimalPlacesMaximum: number;
}

const availableFilterTimeSpans = [
    DemeterFilterTimeSpan.OneMonth,
    DemeterFilterTimeSpan.SixMonths,
    DemeterFilterTimeSpan.OneYear,
    DemeterFilterTimeSpan.TwoYears,
];

const defaultFilterTimeSpan = DemeterFilterTimeSpan.OneYear;

const defaultDisplayTypes = [ChartDisplayType.Line];
const singleLineDisplayTypes = [...defaultDisplayTypes, ChartDisplayType.Area, ChartDisplayType.Column, ChartDisplayType.Spline];

const OtcPricesChart: React.FC<IOtcPricesChartProps> = (props: IOtcPricesChartProps) => {
    // Text hooks.
    const [translations] = useLanguage();

    // Api hooks.
    const [filterTimeSpan, setFilterTimeSpan] = useState<DemeterFilterTimeSpan>(defaultFilterTimeSpan);
    const [otcPricesLoading, refreshApi, otcPricesDatas] = useMultipleApis(
        () => {
            if (props.otcPricesSelections.length === 0 || !props.otcPricesSelections[0].contractMonth) {
                return null;
            }

            return props.otcPricesSelections.map((x) =>
                demeterApi.listCommodityOtcPrices(
                    x.region,
                    x.commodity,
                    x.contractYear,
                    x.contractMonth,
                    props.currency,
                    props.unitOfMeasure,
                    DemeterFilterTimeSpan.TwoYears,
                ),
            );
        },
        { stopAutoExecute: true },
    );

    // Data hooks.
    const [linesSeries, setLinesSeries] = useState<IChartDataSeries[]>([]);
    const [displayType, setDisplayType] = useState<ChartDisplayType>(ChartDisplayType.Line);
    const [studies, setStudies] = useState<ChartStudyType[]>([]);

    useEffect(() => {
        if (props.otcPricesSelections.length > 0 && props.otcPricesSelections[0].contractMonth > 0) {
            refreshApi();
        }
        if (props.otcPricesSelections.length > 1) {
            setStudies([]);
        }
    }, [props.otcPricesSelections, filterTimeSpan]);

    useEffect(() => {
        if (!otcPricesDatas || otcPricesDatas.length === 0) {
            setLinesSeries([]);
            return;
        }

        const newLinesSeries = otcPricesDatas.map((data, index) => {
            const selection = props.otcPricesSelections[index];
            let label = props.title;

            if (selection && selection.contractYear) {
                label = `${label} ${formattingService.toMonthYear(new Date(selection.contractYear, selection.contractMonth - 1, 1))}`;
            }

            return {
                label,
                forecastLabel: `${label} ${translations.words.forecast}`,
                data: data!.rows!.map((row) => ({
                    value: row.bidAskAveragePrice,
                    asOfDate: new Date(row.releaseTime),
                    releastDate: row.releaseTime,
                    isActualValue: true,
                })),
            };
        });

        setLinesSeries(newLinesSeries);
    }, [otcPricesDatas]);

    const dataSourceTag = useMemo((): string => (otcPricesDatas && otcPricesDatas[0] && otcPricesDatas[0].dataSourceTag) ?? '', [otcPricesDatas]);
    const currency = useMemo(
        (): Currency => props.currency ?? (otcPricesDatas && otcPricesDatas[0] && otcPricesDatas[0].currency),
        [otcPricesDatas, props.currency],
    );
    const unitOfMeasure = useMemo(
        (): UnitOfMeasure => props.unitOfMeasure ?? (otcPricesDatas && otcPricesDatas[0] && otcPricesDatas[0].unitOfMeasure),
        [otcPricesDatas, props.unitOfMeasure],
    );
    const oldestAsOfDate = useMemo((): Date | undefined => {
        if (!otcPricesDatas || !otcPricesDatas[0] || !otcPricesDatas[0].oldestAsOfDate) {
            return undefined;
        }

        const date = otcPricesDatas
            .map((x) => x.oldestAsOfDate)
            .filter((x) => !!x)
            .sort()
            .reverse()[0] as string;

        return new Date(date);
    }, [otcPricesDatas]);

    return (
        <ChartWrapper
            name="OtcPricesChart"
            title={linesSeries.length === 1 && linesSeries[0].label ? linesSeries[0].label : props.title}
            dataSourceTag={dataSourceTag}
            isLoading={otcPricesLoading && linesSeries.length === 0}
            headerOptions={{
                isCsvDownloadAvailable: true,
                chartDisplayTypes: linesSeries.length <= 1 ? singleLineDisplayTypes : defaultDisplayTypes,
                handleChartDisplayTypeChange: (newDisplayType: ChartDisplayType) => {
                    setDisplayType(newDisplayType);
                },
            }}
            header={
                <StudiesDropdown disabled={linesSeries.length > 1} studies={studies} handleStudiesSelected={(selectedStudies) => setStudies(selectedStudies)} />
            }
            footer={
                <FilterTimeSpans
                    name="OtcPricesChart"
                    filterTimeSpanOptions={availableFilterTimeSpans}
                    filterTimeSpan={filterTimeSpan}
                    oldestAsOfDate={oldestAsOfDate}
                    handleTimeSpanSelected={(timeSpan) => setFilterTimeSpan(timeSpan)}
                />
            }
        >
            <PriceChartRaw
                displayType={displayType}
                linesSeries={linesSeries}
                dataFrequency={DemeterDataFrequency.Daily}
                currency={currency}
                unitOfMeasure={unitOfMeasure}
                filterTimeSpan={filterTimeSpan}
                navigatorHasFullData
                displayDecimalPlacesMinimum={props.displayDecimalPlacesMinimum}
                displayDecimalPlacesMaximum={props.displayDecimalPlacesMaximum}
                studies={studies}
            />
        </ChartWrapper>
    );
};

export default OtcPricesChart;
