import { Options, SeriesOptionsType, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import scssVariables from '../../../../Config.module.scss';
import { Currency, DemeterDataFrequency, DemeterFilterTimeSpan, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../Services/Logging/LoggingService';
import {
    chartColors,
    ChartContext,
    ChartDisplayType,
    ChartOptionsDefinitions,
    defaultChartOptions,
    defaultZoneAxis,
    dotDashStyle,
    HighchartsPlot,
    IChartPriceDataSeries,
    IChartProps,
    lineChartType,
    longDashStyle,
    shortDashStyle,
} from '../ChartDefinitions';
import chartService from '../ChartService';
import { getTimestampMap } from '../FilterTimeSpans/FilterTimeSpans';
import { ChartStudyType, StudyToSeriesMap as studyToSeriesMap } from '../Studies/StudiesDefinitions';

export interface IPriceChartRawProps extends IChartProps {
    displayType?: ChartDisplayType;
    linesSeries: IChartPriceDataSeries[];
    dataFrequency: DemeterDataFrequency;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    hidePriceNavigator?: boolean;
    filterTimeSpan?: DemeterFilterTimeSpan;
    lineSeriesScreenPercent?: number;
    // This is true when chart navigator has the entire data set and the timespans from the props will move it
    // around instead of the parent filtering the data by time spans. Otherwise every time the time span changes,
    // the navigator will only show the filtered data and be fully highlighted.
    navigatorHasFullData?: boolean;
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
    studies?: ChartStudyType[];
}

type YAxisOptionExtended = YAxisOptions & { currency?: Currency; unitOfMeasure?: UnitOfMeasure };

const lineSeriesBufferPercent = 5;
const seriesIdPrefix = 'priceChartRaw';

const PriceChartRaw: React.FC<IPriceChartRawProps> = (props: IPriceChartRawProps) => {
    // Chart hooks.
    const chartReference = useRef<HighchartsReact.RefObject>();
    const [forecastDataOn, setForecastDataOn] = useState<boolean>(true);

    const [highchartOptions, setHighchartOptions] = useState<Options>(() => ({
        ...defaultChartOptions,
        xAxis: {
            ...defaultChartOptions.xAxis,
            crosshair: {
                snap: true,
                dashStyle: longDashStyle,
            },
        },
        yAxis: [],
        series: [],
        navigator: {
            ...defaultChartOptions.navigator,
            enabled: !props.hidePriceNavigator,
            maskFill: scssVariables.stonexSkyBlueTranslucent,
            series: {
                dataGrouping: {
                    enabled: false,
                },
            },
        },
    }));

    const getDataSeriesDefinitions = useCallback(() => {
        const linesDataSeries = props.linesSeries.flatMap((lineSeries, index) => {
            const color = chartColors.lineChartColors[index % chartColors.lineChartColors.length];
            return [
                {
                    ...chartService.getDataSeriesBase(lineSeries, lineChartType, color),
                    id: `${seriesIdPrefix}${index}`,
                    yAxis: 0,
                    events: {
                        legendItemClick() {
                            handleLegendItemClicked(this as unknown as ChartContext);
                        },
                    },
                    zoneAxis: defaultZoneAxis,
                    zones: [
                        {
                            value: 0,
                        },
                        {
                            dashStyle: longDashStyle,
                        },
                    ],
                    showInNavigator: true,
                    turboThreshold: 0,
                },
                // These forecast x-axis registrations are there just for the legend.
                chartService.getForecastDataSeriesBase(lineSeries, color, handleForecastClicked),
                {
                    name: lineSeries.forwardCurveLabel,
                    yAxis: 0,
                    marker: {
                        enabled: false,
                    },
                    events: {
                        legendItemClick() {
                            handleLegendItemClicked(this as unknown as ChartContext);
                        },
                    },
                    data: [] as HighchartsPlot[],
                    zoneAxis: defaultZoneAxis,
                    color,
                    dashStyle: dotDashStyle,
                    showInLegend: true,
                    showInNavigator: true,
                },
            ];
        });

        let studiesSeries: SeriesOptionsType[] = [];
        if (props.linesSeries.length === 1 && props.studies && props.studies.length > 0) {
            let colorIndex = linesDataSeries.length / 3 - 1;
            studiesSeries = props.studies.flatMap((study) => {
                const seriesDefinitions = studyToSeriesMap[study];
                if (!seriesDefinitions) {
                    return [];
                }

                return seriesDefinitions.map((x) => {
                    colorIndex += 1;
                    const color = chartColors.lineChartColors[colorIndex % chartColors.lineChartColors.length];
                    const name = x.name ?? (x.nameValueGetter && x.nameValueGetter());

                    return {
                        ...x,
                        name,
                        linkedTo: `${seriesIdPrefix}0`, // Always maps to the first line series.
                        color,
                    };
                });
            });
        }

        return [...linesDataSeries, ...(studiesSeries as typeof linesDataSeries)];
    }, [props.linesSeries, props.hidePriceNavigator, forecastDataOn, props.studies]);

    const getYAxisOptions = useCallback(() => {
        let yAxisOptions: YAxisOptionExtended[] = [
            {
                title: {
                    text: '',
                    style: {
                        color: scssVariables.chartYLabel,
                    },
                },
                labels: {
                    format: '{value:{point.y: , .0f}',
                },
                gridLineWidth: 1,
                startOnTick: false,
                endOnTick: false,
                crosshair: {
                    snap: true,
                    dashStyle: shortDashStyle,
                },
                currency: props.currency,
                unitOfMeasure: props.unitOfMeasure,
            },
        ];

        if (!props.linesSeries.some((x) => x.currency || x.unitOfMeasure)) {
            return yAxisOptions;
        }

        const currentAndUnitOfMeasuresMap: { [key: string]: { currency?: Currency; unitOfMeasure?: UnitOfMeasure; order: number } } = {};
        let order = 0;
        props.linesSeries.forEach((x) => {
            order += 1;

            currentAndUnitOfMeasuresMap[`${x.currency}/${x.unitOfMeasure}`] = {
                currency: x.currency,
                unitOfMeasure: x.unitOfMeasure,
                order,
            };
        });

        yAxisOptions = Object.values(currentAndUnitOfMeasuresMap)
            .sort((x) => x.order)
            .map((x, index) => ({
                ...yAxisOptions[0],
                title: { ...yAxisOptions[0].title },
                labels: { ...yAxisOptions[0].labels },
                crosshair: { ...(yAxisOptions[0].crosshair as HighStock.AxisCrosshairOptions) },
                currency: x.currency,
                unitOfMeasure: x.unitOfMeasure,
                opposite: index === 1,
            }));

        return yAxisOptions;
    }, [props.linesSeries]);

    // Main useEffect to update chart when props or data changes.
    useEffect(() => {
        const dataSeries = getDataSeriesDefinitions();
        const yAxisOptions = getYAxisOptions();
        const newYAxis = [...yAxisOptions];
        const today = new Date();
        const maximumValues = Array(newYAxis.length).fill(0);

        const downloadData = chartService.getDownloadData(props.linesSeries, props.dataFrequency);

        [...props.linesSeries].forEach((series, index) => {
            const lastActualValueDataIndex = series.data.findLastIndex((x) => x.isActualValue);
            const hasOnlyForecastData = series.data.length > 0 && lastActualValueDataIndex === -1;
            const currentDataSeriesIndex = index * 3;
            let firstForecastDate = today;

            if (series.data.length > 0 && hasOnlyForecastData) {
                firstForecastDate = series.data[0] && series.data[0].asOfDate;
            } else if (series.data.length > lastActualValueDataIndex + 1) {
                firstForecastDate = series.data[lastActualValueDataIndex]?.asOfDate;
            }
            const endIndex = forecastDataOn ? series.data.length : lastActualValueDataIndex + 1;
            const currentDataSeriesItem = dataSeries[currentDataSeriesIndex] as ChartOptionsDefinitions;
            currentDataSeriesItem.type = (props.displayType ?? ChartDisplayType.Line).toLowerCase();
            currentDataSeriesItem.data = series.data
                .slice(0, endIndex)
                .map((item) => ({ x: item?.asOfDate.getTime(), y: item.value, isActualValue: item.isActualValue }));

            if (series.forwardCurveData && series.forwardCurveData.length > 0) {
                firstForecastDate = series.forwardCurveData[0]?.asOfDate;
                (dataSeries[currentDataSeriesIndex + 2] as ChartOptionsDefinitions).data = series.forwardCurveData?.map((item) => ({
                    x: item?.asOfDate.getTime(),
                    y: item.value,
                    isActualValue: item.isActualValue,
                }));

                if (series.forwardCurveLineStyle) {
                    dataSeries[currentDataSeriesIndex + 2].dashStyle = series.forwardCurveLineStyle;
                }
            }

            let yAxisIndex = 0;
            if (newYAxis.length > 1 && (series.currency || series.unitOfMeasure)) {
                yAxisIndex = newYAxis.findIndex((x) => x.currency === series.currency && x.unitOfMeasure === series.unitOfMeasure);
                dataSeries[currentDataSeriesIndex].yAxis = yAxisIndex;
                dataSeries[currentDataSeriesIndex + 2].yAxis = yAxisIndex;
            } else {
                currentDataSeriesItem.yAxis = 0;
                dataSeries[currentDataSeriesIndex + 2].yAxis = 0;
            }

            maximumValues[yAxisIndex] = Math.max(maximumValues[yAxisIndex], ...currentDataSeriesItem.data!.map((x) => Math.abs(x.y ?? 0)));

            newYAxis[yAxisIndex].maxPadding = lineSeriesBufferPercent / 100.0;
            newYAxis[yAxisIndex].minPadding = lineSeriesBufferPercent / 100.0;

            // Push the point forward so it doesn't think the last actual is a forecast.
            // TODO: Do different math based on monthly/weekly data.
            currentDataSeriesItem.zones![0].value = (firstForecastDate?.getTime() ?? 0) + 1000;
            currentDataSeriesItem.dashStyle = hasOnlyForecastData ? longDashStyle : undefined;
            currentDataSeriesItem.showInLegend = series.data.length > 0;
            dataSeries[currentDataSeriesIndex + 1].showInLegend = series.data.length > 0 && !hasOnlyForecastData && firstForecastDate !== today;
            dataSeries[currentDataSeriesIndex + 2].showInLegend = (dataSeries[currentDataSeriesIndex + 2] as ChartOptionsDefinitions).data!.length > 0;
        });

        const newOptions = {
            ...highchartOptions,
            ...{ series: dataSeries },
            ...{ yAxis: newYAxis },
            ...{
                navigator: {
                    ...highchartOptions.navigator,
                    ...{
                        enabled: !props.hidePriceNavigator,
                    },
                },
            },
            ...{
                tooltip: {
                    formatter() {
                        const context = this as unknown as ChartContext;

                        return chartService.getTooltipText(context, {
                            nameOverride: !props.linesSeries.some((lineSeries) => lineSeries.forwardCurveLabel === newOptions.series[context.series.index].name)
                                ? newOptions.series![context.series.index + 1]?.name
                                : '',
                            dataFrequency: props.dataFrequency,
                            displayDecimalPlacesMinimum: props.displayDecimalPlacesMinimum ?? 0,
                            displayDecimalPlacesMaximum: props.displayDecimalPlacesMaximum ?? 4,
                        });
                    },
                },
            },
            downloadData,
        };

        newOptions.yAxis.forEach((axis, index) => {
            axis.labels!.format = `{value:{point.y: , .${formattingService.getDisplayDecimalPlacesMinimumForCharts(maximumValues[index] ?? 0)}f}`;
            axis!.title!.text = chartService.getCurrencyAndUnitOfMeasureText(axis.unitOfMeasure, axis.currency);
        });

        setHighchartOptions(newOptions as Options);
        handleFilterTimeSpanChange();
    }, [forecastDataOn, props.displayType, props.linesSeries, props.lineSeriesScreenPercent, getDataSeriesDefinitions]);

    useEffect(() => {
        handleFilterTimeSpanChange();
    }, [props.filterTimeSpan]);

    // Unique price chart functions.
    const handleFilterTimeSpanChange = () => {
        if (!props.filterTimeSpan || props.linesSeries.length === 0 || props.linesSeries[0].data.length === 0) {
            return;
        }

        const oldestDate = Math.min(...props.linesSeries.map((x) => x.data[0].asOfDate.getTime()));
        const latestDataDate = Math.max(...props.linesSeries.map((x) => x.data[x.data.length - 1].asOfDate.getTime()));

        if (props.navigatorHasFullData) {
            const minimumDateForTimeSpan = getTimestampMap(new Date(latestDataDate))[props.filterTimeSpan as DemeterFilterTimeSpan];
            chartReference.current?.chart.xAxis[0].setExtremes(Math.max(minimumDateForTimeSpan, oldestDate), latestDataDate);
        } else {
            const latestForwardCurveDate = Math.max(
                ...props.linesSeries.map((x) => {
                    if (x.forwardCurveData && x.forwardCurveData.length > 0) {
                        return x.forwardCurveData![x.forwardCurveData!.length - 1].asOfDate.getTime();
                    }

                    return 0;
                }),
            );
            chartReference.current?.chart.xAxis[0].setExtremes(oldestDate, Math.max(latestDataDate, latestForwardCurveDate));
        }
    };

    const handleLegendItemClicked = (context: ChartContext) => {
        const seriesClicked = context.chart.series[context.index];

        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ChartInteraction,
            EventCategoriesEnum.LegendItemClicked,
            seriesClicked.name,
            EventDataTargetsEnum.PriceGraph,
        );

        // If we clicked on a line series, also make the forward curve go away.
        if (context.index % 3 === 0) {
            if (seriesClicked.visible) {
                context.chart.series[context.index + 1].hide();
                context.chart.series[context.index + 2].hide();
            } else {
                if (forecastDataOn) {
                    context.chart.series[context.index + 1].show();
                }
                context.chart.series[context.index + 2].show();
            }
        } else if (!seriesClicked.visible && !context.chart.series[context.index - 2].visible) {
            // If we click on the forward curve while the lines series is gone, bring it back as well.
            context.chart.series[context.index - 2].show();
            if (forecastDataOn) {
                context.chart.series[context.index - 1].show();
            }
        }
    };

    const handleForecastClicked = (context: ChartContext) => {
        const seriesClicked = context.chart.series[context.index];

        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ChartInteraction,
            EventCategoriesEnum.LegendItemClicked,
            seriesClicked.name,
            EventDataTargetsEnum.PriceGraph,
        );
        context.chart.series.forEach((item, index) => {
            if (index % 3 !== 1 || index === context.index) {
                return;
            }

            if (forecastDataOn) {
                item.hide();
            } else {
                item.show();
            }
        });

        setForecastDataOn(!forecastDataOn);
    };

    return (
        <HighchartsReact
            ref={(newChartReference: HighchartsReact.RefObject) => {
                if (chartReference.current || !newChartReference) {
                    return;
                }

                chartReference.current = newChartReference;
                if (props.chartReference) {
                    props.chartReference(newChartReference);
                }
            }}
            highcharts={HighStock}
            options={highchartOptions}
            containerProps={{ style: { height: '100%' } }}
        />
    );
};

export default memo(PriceChartRaw);
