import { Chart, Options, Point, SeriesOptions, SeriesPieDataLabelsOptionsObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useMemo } from 'react';
import StoneXLogo from '../../../../Components/Assets/StoneX_Dark_Logo.png';
import utilitySvg from '../../../../Components/Assets/utility.svg';
import scssVariables from '../../../../Config.module.scss';
import { DataSeries } from '../../../../Generated/Raven-Demeter';
import downloadService from '../../../Services/Download/DownloadService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import IconMenuDropdown from '../../Form/Inputs/IconMenuDropdown';
import { defaultChartOptions, OptionsWithDownloads } from '../ChartDefinitions';
import styles from './ChartWrapper.module.scss';

interface IChartDownloadProps {
    chartReference?: HighchartsReact.RefObject;
    name: string; // Internal name of the component.
    title?: string; // Title of the chart.
    subtitle?: string;
    dataSourceTag?: string | string[];
    isCsvDownloadAvailable?: boolean;
}

type DownloadType = 'jpeg' | 'svg' | 'pdf' | 'csv';
type LinkedSeries = (SeriesOptions & { linkedTo: string })[];

const maxDataInSeriesForDisplay = 3000;

const ChartDownload: React.FC<IChartDownloadProps> = (props: IChartDownloadProps) => {
    const [translations] = useLanguage();

    const downloadOptions = useMemo(() => {
        const options: { label: string; value: DownloadType }[] = [
            {
                label: translations.charts.downloads.jpeg,
                value: 'jpeg',
            },
            {
                label: translations.charts.downloads.svg,
                value: 'svg',
            },
            {
                label: translations.charts.downloads.pdf,
                value: 'pdf',
            },
        ];

        if (props.isCsvDownloadAvailable) {
            options.push({
                label: translations.charts.downloads.csv,
                value: 'csv',
            });
        }
        return options;
    }, [props.isCsvDownloadAvailable]);

    const handleExport = (type: DownloadType) => {
        if (props.chartReference?.chart) {
            const { chart } = props.chartReference;

            const options: Options = {
                title: {
                    text: props.title,
                    align: 'left',
                    style: {
                        color: scssVariables.mainTextColor,
                        fontSize: '20px',
                        fontWeight: '700',
                    },
                },
                subtitle: {
                    text: props.subtitle,
                    align: 'left',
                    style: {
                        color: scssVariables.stonexMediumGray,
                        fontSize: '15px',
                        fontWeight: '500',
                    },
                },
                chart: {
                    ...defaultChartOptions.chart,
                    spacingTop: 20,
                    spacingBottom: 70,
                    spacingLeft: 20,
                    spacingRight: 20,
                    events: {
                        load: (a) => {
                            const eventChart = a.target as unknown as Chart;

                            eventChart.renderer
                                .image(StoneXLogo, eventChart.chartWidth - 92.5, eventChart.chartHeight - 40, 76.5, 24)
                                .addClass('credits')
                                .add();

                            const dataSource = Array.isArray(props.dataSourceTag)
                                ? props.dataSourceTag.filter((value, index, array) => array.indexOf(value) === index).join(', ')
                                : props.dataSourceTag ?? ' ';

                            const sourceLabel = eventChart.renderer.label(dataSource, 0).css({ color: scssVariables.stonexMediumGray, fontSize: '9px' }).add();
                            sourceLabel.align(
                                {
                                    ...sourceLabel.getBBox(),
                                    align: 'center',
                                    x: 0,
                                    verticalAlign: 'bottom',
                                    y: 27,
                                },
                                undefined,
                                'spacingBox',
                            );

                            const disclaimer = translations.modalChartFooterDisclaimer;
                            const disclaimerLabel = eventChart.renderer
                                .label(disclaimer, 0)
                                .css({ color: scssVariables.stonexMediumGray, fontSize: '8px' })
                                .add();

                            disclaimerLabel.align(
                                {
                                    ...disclaimerLabel.getBBox(),
                                    align: 'center',
                                    x: 0,
                                    verticalAlign: 'bottom',
                                    y: 57,
                                },
                                undefined,
                                'spacingBox',
                            );
                        },
                    },
                },
                navigator: {
                    enabled: false,
                },
            };

            const currentNavigatorMax = chart.xAxis[0].max!;
            const currentNavigatorMin = chart.xAxis[0].min!;
            const numberOfIndicators = (chart.userOptions.series as LinkedSeries).filter((x) => x.linkedTo).length;

            const navigatorSeriesLength = (chart.userOptions.series as Options[])
                .filter((x) => x.data)
                .flatMap((x) => (x.data as Point[]).filter((y) => y?.x > currentNavigatorMin && y.x < currentNavigatorMax)).length;

            const totalNumberOfDataPoints = navigatorSeriesLength * (numberOfIndicators + 1);

            if (totalNumberOfDataPoints > maxDataInSeriesForDisplay) {
                options.plotOptions = {
                    series: {
                        dataGrouping: {
                            enabled: true,
                        },
                    },
                };
            }

            // Do this async so the dropdown closes.
            const defaultExportOptions = { filename: props.name, sourceWidth: 1280, sourceHeight: 720 };
            setTimeout(() => {
                const userOptions = chart?.userOptions as OptionsWithDownloads;
                const isDonutChart = userOptions?.chart?.type === 'pie';
                const isDonutSliceLabelsEnabled = (chart?.options?.plotOptions?.pie?.dataLabels as SeriesPieDataLabelsOptionsObject)?.enabled;

                // Donut chart is unique in download. If we have a donut type, we will enable the labels for download
                // regardless of whether they are currently showing or not (same in popout mode). After
                // export is complete, we change it back.
                if (isDonutChart) {
                    chart?.update({ plotOptions: { pie: { dataLabels: { enabled: true } } } });
                }

                switch (type) {
                    case 'jpeg':
                        chart.exportChart({ type: 'image/jpeg', ...defaultExportOptions }, options);
                        break;

                    case 'svg':
                        chart.exportChart({ type: 'image/svg+xml', ...defaultExportOptions }, options);
                        break;

                    case 'pdf':
                        chart.exportChart({ type: 'application/pdf', ...defaultExportOptions }, options);
                        break;

                    case 'csv':
                        downloadService.downloadCsv(userOptions.downloadData as unknown as DataSeries[], props.name);
                        break;

                    default:
                        break;
                }

                if (isDonutChart) {
                    chart?.update({ plotOptions: { pie: { dataLabels: { enabled: isDonutSliceLabelsEnabled } } } });
                }
            }, 0);
        }
    };

    return (
        <IconMenuDropdown
            iconButton={<img className={styles.chart_wrapper_image} src={utilitySvg} alt="#" />}
            options={downloadOptions}
            handleSelection={(value: DownloadType) => handleExport(value)}
            buttonClassName={styles.chart_wrapper_image_button}
            tooltip={translations.actions.clickToDownload}
        />
    );
};

export default ChartDownload;
