import { useEffect, useMemo, useState } from 'react';
import chartDisplayOptionsSvg from '../../../Assets/Icons/chartDisplayOptions.svg';
import areaSvg from '../../../Assets/Icons/ChartTypeIcons/areaChartTypeIcon.svg';
import columnSvg from '../../../Assets/Icons/ChartTypeIcons/columnChartTypeIcon.svg';
import lineSvg from '../../../Assets/Icons/ChartTypeIcons/lineChartTypeIcon.svg';
import splineSvg from '../../../Assets/Icons/ChartTypeIcons/splineChartTypeIcon.svg';
import useLanguage from '../../../Services/Language/useLanguageHook';
import IconMenuDropdown from '../../Form/Inputs/IconMenuDropdown';
import { ChartDisplayType } from '../ChartDefinitions';
import styles from './ChartWrapper.module.scss';

interface IChartDisplayOptionsProps {
    chartDisplayTypes: ChartDisplayType[];
    handleChartDisplayTypeChange: (displayType: ChartDisplayType) => void;
}

const ChartDisplayOptions: React.FC<IChartDisplayOptionsProps> = (props: IChartDisplayOptionsProps) => {
    const [translations] = useLanguage();

    const [displayType, setDisplayType] = useState<ChartDisplayType>(props.chartDisplayTypes[0]);

    const getChartLabel = (chartType: ChartDisplayType) => {
        let displayImage = lineSvg;
        switch (chartType) {
            case ChartDisplayType.Column:
                displayImage = columnSvg;
                break;
            case ChartDisplayType.Spline:
                displayImage = splineSvg;
                break;
            case ChartDisplayType.Area:
                displayImage = areaSvg;
                break;
            default:
                displayImage = lineSvg;
                break;
        }
        return (
            <div className={styles.chart_wrapper_chart_type_item}>
                <img src={displayImage} alt="#" /> {translations.charts.displayType[chartType]}
            </div>
        );
    };

    const displayOptions = useMemo<{ label: JSX.Element; value: ChartDisplayType }[]>(
        () =>
            props.chartDisplayTypes.map((x: ChartDisplayType) => ({
                label: getChartLabel(x),
                value: x,
            })),
        [props.chartDisplayTypes],
    );

    useEffect(() => {
        const newDisplayType = props.chartDisplayTypes[0] ?? ChartDisplayType.Line;

        if (newDisplayType !== displayType) {
            setDisplayType(newDisplayType);
            props.handleChartDisplayTypeChange(newDisplayType);
        }
    }, [props.chartDisplayTypes]);

    const handleDisplayChange = (newDisplayType: ChartDisplayType) => {
        setDisplayType(newDisplayType);
        props.handleChartDisplayTypeChange(newDisplayType);
    };

    return (
        <IconMenuDropdown
            iconButton={<img className={styles.chart_wrapper_image} src={chartDisplayOptionsSvg} alt="#" />}
            options={displayOptions}
            handleSelection={(value: ChartDisplayType) => handleDisplayChange(value)}
            buttonClassName={styles.chart_wrapper_image_button}
            tooltip={translations.charts.buttons.changeType}
        />
    );
};

export default ChartDisplayOptions;
