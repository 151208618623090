import React from 'react';
import TreeItem, { ITreeNode } from './TreeItem';

interface ITreeViewProps {
    data: ITreeNode[];
    searchTerm: string;
    selectedNode: ITreeNode | null | undefined;
    setSelectedNode: (node: ITreeNode) => void;
    disabledNodes?: (ITreeNode | null | undefined)[];
    noData?: React.ReactNode;
}

const TreeView: React.FC<ITreeViewProps> = (props: ITreeViewProps) => {
    const isNodeDisabled = (node: ITreeNode) => {
        if (props.disabledNodes) {
            return props.disabledNodes.some((disabledNode) => disabledNode && disabledNode.id === node.id);
        }
        return false;
    };

    const expandPathToSelectedNode = (nodes: ITreeNode[], selectedNode: ITreeNode | null | undefined): ITreeNode[] =>
        nodes.map((node) => {
            const isInPath = selectedNode && (node.id === selectedNode.id || node.children?.some((child) => isNodeInPath(child, selectedNode)));
            return {
                ...node,
                expanded: isInPath,
                children: node.children ? expandPathToSelectedNode(node.children, selectedNode) : [],
            };
        });

    const expandNodesMatchingSearch = (nodes: ITreeNode[], searchTerm: string): ITreeNode[] =>
        nodes.reduce<ITreeNode[]>((acc, node) => {
            const matchesSearch = node.name.toLowerCase().includes(searchTerm.toLowerCase());
            const children = node.children ? expandNodesMatchingSearch(node.children, searchTerm) : [];

            if (matchesSearch || children.length > 0) {
                acc.push({
                    ...node,
                    expanded: true,
                    children,
                });
            }

            return acc;
        }, []);

    const isNodeInPath = (node: ITreeNode, selectedNode: ITreeNode | null | undefined): boolean => {
        if (!selectedNode) {
            return false;
        }
        if (node.id === selectedNode.id) {
            return true;
        }
        return node.children ? node.children.some((child) => isNodeInPath(child, selectedNode)) : false;
    };

    const preserveInitialExpansion = (nodes: ITreeNode[]): ITreeNode[] =>
        nodes.map((node) => ({
            ...node,
            expanded: node.expanded,
            children: node.children ? preserveInitialExpansion(node.children) : [],
        }));

    const getFilteredData = () => {
        if (props.searchTerm) {
            return expandNodesMatchingSearch(props.data, props.searchTerm);
        }
        if (props.selectedNode) {
            return expandPathToSelectedNode(props.data, props.selectedNode);
        }
        return preserveInitialExpansion(props.data);
    };

    return (
        <div>
            {getFilteredData().length > 0 &&
                getFilteredData().map((node) => (
                    <TreeItem
                        key={node.id}
                        node={node}
                        searchTerm={props.searchTerm}
                        selectedNode={props.selectedNode}
                        setSelectedNode={props.setSelectedNode}
                        disabled={isNodeDisabled(node)}
                        disabledNodes={props.disabledNodes}
                        expanded={node.expanded || false}
                        level={0}
                    />
                ))}
            {getFilteredData().length === 0 && props.noData}
        </div>
    );
};

export default TreeView;
