/* tslint:disable */
/* eslint-disable */
/**
 * Raven Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountDetailsModel
 */
export interface AccountDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsModel
     */
    'ourCode': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsModel
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsModel
     */
    'baseCurrency': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsModel
     */
    'tradeCurrency': string;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'cashPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'openTradeEquity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'netMarketValueOfOptions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'netLiquidationValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'changeMarketVariance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'initialMargin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'totalMarginCall'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'marginExcessDeficit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'marginValueCollateral'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'marginTotalAccountValueRatio'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'currencyConversionRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'convertedAccountValueAtMarket'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'marginAge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'totalCommissionAndFees'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'endOfDayNetLiquidationValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'endOfDayNetOptionValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'endOfDayOpenTradeEquity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsModel
     */
    'intradayAsOfDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'intradayNetLiquidationValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'intradayNetOptionValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountDetailsModel
     */
    'intradayOpenTradeEquity'?: number | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof AccountDetailsModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof AccountDetailsModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface AccountModel
 */
export interface AccountModel {
    /**
     * 
     * @type {SourceSystem}
     * @memberof AccountModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    'accountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    'masterAccountName': string;
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    'netLiquidationValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    'endOfDayNetLiquidationValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    'intraDayNetLiquidationValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    'changeMarketVariance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    'totalMarginCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    'baseCurrency': string;
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    'marginAge'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    'groupAccountNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    'numberOfSubAccounts': number;
    /**
     * 
     * @type {Array<AccountModel>}
     * @memberof AccountModel
     */
    'subAccounts': Array<AccountModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccountType = {
    Customer: 'Customer',
    CustomerRelated: 'CustomerRelated',
    GiveUp: 'GiveUp',
    House: 'House'
} as const;

export type AccountType = typeof AccountType[keyof typeof AccountType];


/**
 * 
 * @export
 * @interface AccountTypeModel
 */
export interface AccountTypeModel {
    /**
     * 
     * @type {string}
     * @memberof AccountTypeModel
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof AccountTypeModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof AccountTypeModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AchWireSetting = {
    N: 'N',
    A: 'A',
    W: 'W'
} as const;

export type AchWireSetting = typeof AchWireSetting[keyof typeof AchWireSetting];


/**
 * 
 * @export
 * @interface AddClientServiceCenterApplicationRequest
 */
export interface AddClientServiceCenterApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'applicationTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'applicationSourceGuid'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'delegates'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'companyType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'companyWebsite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'ownerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'taxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'tradingPlatformGuid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'inTradeDeskManager'?: boolean | null;
    /**
     * 
     * @type {Array<ClientServiceCenterApplicationAccount>}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'accounts'?: Array<ClientServiceCenterApplicationAccount> | null;
    /**
     * 
     * @type {ClientServiceCenterApplicationAdditionalInformation}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'additionalInformation'?: ClientServiceCenterApplicationAdditionalInformation;
    /**
     * 
     * @type {Array<ClientServiceCenterApplicationAddress>}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'addresses'?: Array<ClientServiceCenterApplicationAddress> | null;
    /**
     * 
     * @type {Array<ClientServiceCenterApplicationContact>}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'contacts'?: Array<ClientServiceCenterApplicationContact> | null;
    /**
     * 
     * @type {ClientServiceCenterApplicationCustomer}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'customer'?: ClientServiceCenterApplicationCustomer;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddClientServiceCenterApplicationRequest
     */
    'applicationAgreements'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AddCropBudgetExpenseRequest
 */
export interface AddCropBudgetExpenseRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCropBudgetExpenseRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AddCropBudgetExpenseRequest
     */
    'cropBudgetGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AddCropBudgetExpenseRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AddCropBudgetExpenseRequest
     */
    'pricePerAcre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddCropBudgetExpenseRequest
     */
    'totalPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface AddCropBudgetExpenseResponse
 */
export interface AddCropBudgetExpenseResponse {
    /**
     * 
     * @type {string}
     * @memberof AddCropBudgetExpenseResponse
     */
    'cropBudgetExpenseGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddCropBudgetRequest
 */
export interface AddCropBudgetRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCropBudgetRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {number}
     * @memberof AddCropBudgetRequest
     */
    'year': number;
    /**
     * 
     * @type {CropType}
     * @memberof AddCropBudgetRequest
     */
    'cropType': CropType;
    /**
     * 
     * @type {string}
     * @memberof AddCropBudgetRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AddCropBudgetRequest
     */
    'marketingYield'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddCropBudgetRequest
     */
    'projectedProduct'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddCropBudgetRequest
     */
    'projectedPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddCropBudgetRequest
     */
    'cropPriceGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface AddCropBudgetResponse
 */
export interface AddCropBudgetResponse {
    /**
     * 
     * @type {string}
     * @memberof AddCropBudgetResponse
     */
    'cropBudgetGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddCropFieldRequest
 */
export interface AddCropFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCropFieldRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AddCropFieldRequest
     */
    'name': string;
    /**
     * 
     * @type {State}
     * @memberof AddCropFieldRequest
     */
    'state': State;
    /**
     * 
     * @type {Division}
     * @memberof AddCropFieldRequest
     */
    'division'?: Division;
    /**
     * 
     * @type {number}
     * @memberof AddCropFieldRequest
     */
    'section'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddCropFieldRequest
     */
    'township'?: string | null;
    /**
     * 
     * @type {TownshipCardinalDirection}
     * @memberof AddCropFieldRequest
     */
    'townshipCardinalDirection'?: TownshipCardinalDirection;
    /**
     * 
     * @type {string}
     * @memberof AddCropFieldRequest
     */
    'range'?: string | null;
    /**
     * 
     * @type {RangeCardinalDirection}
     * @memberof AddCropFieldRequest
     */
    'rangeCardinalDirection'?: RangeCardinalDirection;
    /**
     * 
     * @type {Meridian}
     * @memberof AddCropFieldRequest
     */
    'meridian'?: Meridian;
    /**
     * 
     * @type {number}
     * @memberof AddCropFieldRequest
     */
    'farmableAcres'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddCropFieldRequest
     */
    'seededAcres'?: number | null;
}
/**
 * 
 * @export
 * @interface AddCropFieldResponse
 */
export interface AddCropFieldResponse {
    /**
     * 
     * @type {string}
     * @memberof AddCropFieldResponse
     */
    'cropFieldGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddCropPlanRequest
 */
export interface AddCropPlanRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCropPlanRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AddCropPlanRequest
     */
    'cropFieldGuid': string;
    /**
     * 
     * @type {number}
     * @memberof AddCropPlanRequest
     */
    'year': number;
    /**
     * 
     * @type {CropType}
     * @memberof AddCropPlanRequest
     */
    'cropType': CropType;
    /**
     * 
     * @type {string}
     * @memberof AddCropPlanRequest
     */
    'variety'?: string | null;
    /**
     * 
     * @type {CropTolerance}
     * @memberof AddCropPlanRequest
     */
    'tolerance'?: CropTolerance;
    /**
     * 
     * @type {number}
     * @memberof AddCropPlanRequest
     */
    'farmableAcres'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddCropPlanRequest
     */
    'seededAcres'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddCropPlanRequest
     */
    'seededDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddCropPlanRequest
     */
    'agronomicYield'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddCropPlanRequest
     */
    'cropBudgetGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface AddCropPlanResponse
 */
export interface AddCropPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof AddCropPlanResponse
     */
    'cropPlanGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddFixedExpenseRequest
 */
export interface AddFixedExpenseRequest {
    /**
     * 
     * @type {string}
     * @memberof AddFixedExpenseRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AddFixedExpenseRequest
     */
    'fixedExpenseTypeGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddFixedExpenseRequest
     */
    'customName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddFixedExpenseRequest
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof AddFixedExpenseRequest
     */
    'budgetPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddFixedExpenseRequest
     */
    'budgetPricePerAcre'?: number | null;
}
/**
 * 
 * @export
 * @interface AddFixedExpenseResponse
 */
export interface AddFixedExpenseResponse {
    /**
     * 
     * @type {string}
     * @memberof AddFixedExpenseResponse
     */
    'fixedExpenseGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddFuturesOnboardingApplicationRequest
 */
export interface AddFuturesOnboardingApplicationRequest {
    /**
     * 
     * @type {AccountType}
     * @memberof AddFuturesOnboardingApplicationRequest
     */
    'accountType': AccountType;
    /**
     * 
     * @type {CompanyType}
     * @memberof AddFuturesOnboardingApplicationRequest
     */
    'companyType': CompanyType;
}
/**
 * 
 * @export
 * @interface AddFuturesOnboardingApplicationResponse
 */
export interface AddFuturesOnboardingApplicationResponse {
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingApplicationResponse
     */
    'futuresOnboardingApplicationGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddFuturesOnboardingContactAddressRequest
 */
export interface AddFuturesOnboardingContactAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactAddressRequest
     */
    'futuresOnboardingApplicationGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactAddressRequest
     */
    'address1': string;
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactAddressRequest
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactAddressRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactAddressRequest
     */
    'region': string;
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactAddressRequest
     */
    'postalCode': string;
    /**
     * 
     * @type {AddressType}
     * @memberof AddFuturesOnboardingContactAddressRequest
     */
    'addressType': AddressType;
}
/**
 * 
 * @export
 * @interface AddFuturesOnboardingContactRequest
 */
export interface AddFuturesOnboardingContactRequest {
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactRequest
     */
    'futuresOnboardingApplicationGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingContactRequest
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface AddFuturesOnboardingFinancialsRequest
 */
export interface AddFuturesOnboardingFinancialsRequest {
    /**
     * 
     * @type {string}
     * @memberof AddFuturesOnboardingFinancialsRequest
     */
    'futuresOnboardingApplicationGuid': string;
    /**
     * 
     * @type {FundSource}
     * @memberof AddFuturesOnboardingFinancialsRequest
     */
    'fundSource': FundSource;
    /**
     * 
     * @type {IntendedInitialDeposit}
     * @memberof AddFuturesOnboardingFinancialsRequest
     */
    'intendedInitialDeposit': IntendedInitialDeposit;
    /**
     * 
     * @type {ExpectedMonthlyTradingActivity}
     * @memberof AddFuturesOnboardingFinancialsRequest
     */
    'expectedMonthlyTradingActivity': ExpectedMonthlyTradingActivity;
    /**
     * 
     * @type {AnnualRevenue}
     * @memberof AddFuturesOnboardingFinancialsRequest
     */
    'annualRevenue': AnnualRevenue;
    /**
     * 
     * @type {TotalNetWorth}
     * @memberof AddFuturesOnboardingFinancialsRequest
     */
    'totalNetWorth': TotalNetWorth;
    /**
     * 
     * @type {AvailableWorkingCapital}
     * @memberof AddFuturesOnboardingFinancialsRequest
     */
    'availableWorkingCapital': AvailableWorkingCapital;
}
/**
 * 
 * @export
 * @interface AddIncomeCalculatorRequest
 */
export interface AddIncomeCalculatorRequest {
    /**
     * 
     * @type {CropType}
     * @memberof AddIncomeCalculatorRequest
     */
    'cropType': CropType;
    /**
     * 
     * @type {number}
     * @memberof AddIncomeCalculatorRequest
     */
    'seededAcres': number;
    /**
     * 
     * @type {number}
     * @memberof AddIncomeCalculatorRequest
     */
    'marketPrice': number;
    /**
     * 
     * @type {number}
     * @memberof AddIncomeCalculatorRequest
     */
    'yield'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddIncomeCalculatorRequest
     */
    'agronomicYield'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddIncomeCalculatorRequest
     */
    'fixedCost': number;
    /**
     * 
     * @type {number}
     * @memberof AddIncomeCalculatorRequest
     */
    'variableCost': number;
}
/**
 * 
 * @export
 * @interface AddIncomeCalculatorResponse
 */
export interface AddIncomeCalculatorResponse {
    /**
     * 
     * @type {string}
     * @memberof AddIncomeCalculatorResponse
     */
    'incomeCalculatorGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddKnowRiskAccountRequest
 */
export interface AddKnowRiskAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AddKnowRiskAccountRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AddKnowRiskAccountResponse
 */
export interface AddKnowRiskAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof AddKnowRiskAccountResponse
     */
    'knowRiskAccountGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddKnowRiskFavoriteRequest
 */
export interface AddKnowRiskFavoriteRequest {
    /**
     * 
     * @type {FavoriteType}
     * @memberof AddKnowRiskFavoriteRequest
     */
    'favoriteType': FavoriteType;
    /**
     * 
     * @type {string}
     * @memberof AddKnowRiskFavoriteRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface AddPushNotificationRequest
 */
export interface AddPushNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPushNotificationRequest
     */
    'platformId': string;
    /**
     * 
     * @type {string}
     * @memberof AddPushNotificationRequest
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof AddPushNotificationRequest
     */
    'registrationToken': string;
    /**
     * 
     * @type {string}
     * @memberof AddPushNotificationRequest
     */
    'languageCode'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddPushNotificationRequest
     */
    'subscribedMessageTypes': Array<string>;
}
/**
 * 
 * @export
 * @interface AddSubscriptionRequest
 */
export interface AddSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof AddSubscriptionRequest
     */
    'userGuid': string;
    /**
     * 
     * @type {string}
     * @memberof AddSubscriptionRequest
     */
    'planId': string;
    /**
     * 
     * @type {string}
     * @memberof AddSubscriptionRequest
     */
    'subscriptionStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddSubscriptionRequest
     */
    'subscriptionEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddSubscriptionRequest
     */
    'trialStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddSubscriptionRequest
     */
    'trialEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddSubscriptionRequest
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof AddSubscriptionRequest
     */
    'displaySource': string;
}
/**
 * 
 * @export
 * @interface AddSupportTicketRequest
 */
export interface AddSupportTicketRequest {
    /**
     * 
     * @type {SupportType}
     * @memberof AddSupportTicketRequest
     */
    'supportType': SupportType;
    /**
     * 
     * @type {string}
     * @memberof AddSupportTicketRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddSupportTicketRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddSupportTicketRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddSupportTicketRequest
     */
    'description': string;
    /**
     * 
     * @type {Application}
     * @memberof AddSupportTicketRequest
     */
    'application'?: Application;
    /**
     * 
     * @type {MobileApplicationCountry}
     * @memberof AddSupportTicketRequest
     */
    'mobileApplicationCountry'?: MobileApplicationCountry;
}
/**
 * 
 * @export
 * @interface AddTermsAndConditionsRequest
 */
export interface AddTermsAndConditionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AddTermsAndConditionsRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AddTermsAndConditionsRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AddTermsAndConditionsRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof AddTermsAndConditionsRequest
     */
    'companyName': string;
    /**
     * 
     * @type {number}
     * @memberof AddTermsAndConditionsRequest
     */
    'version'?: number | null;
}
/**
 * 
 * @export
 * @interface AddTermsAndConditionsResponse
 */
export interface AddTermsAndConditionsResponse {
    /**
     * 
     * @type {string}
     * @memberof AddTermsAndConditionsResponse
     */
    'termsAndConditionsGuid'?: string;
}
/**
 * 
 * @export
 * @interface AddUserAuthentiationAuditRequest
 */
export interface AddUserAuthentiationAuditRequest {
    /**
     * 
     * @type {Application}
     * @memberof AddUserAuthentiationAuditRequest
     */
    'application': Application;
    /**
     * 
     * @type {string}
     * @memberof AddUserAuthentiationAuditRequest
     */
    'subApplication': string;
    /**
     * 
     * @type {string}
     * @memberof AddUserAuthentiationAuditRequest
     */
    'buildNumber': string;
}
/**
 * 
 * @export
 * @interface AddWatchListRequest
 */
export interface AddWatchListRequest {
    /**
     * 
     * @type {string}
     * @memberof AddWatchListRequest
     */
    'contentConfigurationId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AddressType = {
    Invoicing: 'Invoicing',
    Payment: 'Payment',
    Contract: 'Contract',
    Shipping: 'Shipping',
    Primary: 'Primary',
    Legal: 'Legal',
    Statement: 'Statement',
    Confirmation: 'Confirmation',
    Mailing: 'Mailing',
    Physical: 'Physical'
} as const;

export type AddressType = typeof AddressType[keyof typeof AddressType];


/**
 * 
 * @export
 * @interface AgreeToTermsAndConditionsRequest
 */
export interface AgreeToTermsAndConditionsRequest {
    /**
     * 
     * @type {Application}
     * @memberof AgreeToTermsAndConditionsRequest
     */
    'application': Application;
    /**
     * 
     * @type {string}
     * @memberof AgreeToTermsAndConditionsRequest
     */
    'subApplication'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgreeToTermsAndConditionsRequest
     */
    'agree': boolean;
    /**
     * 
     * @type {number}
     * @memberof AgreeToTermsAndConditionsRequest
     */
    'version'?: number | null;
}
/**
 * 
 * @export
 * @interface AgreeToUserAgreementRequest
 */
export interface AgreeToUserAgreementRequest {
    /**
     * 
     * @type {Application}
     * @memberof AgreeToUserAgreementRequest
     */
    'application': Application;
    /**
     * 
     * @type {string}
     * @memberof AgreeToUserAgreementRequest
     */
    'subApplication'?: string | null;
    /**
     * 
     * @type {UserAgreementType}
     * @memberof AgreeToUserAgreementRequest
     */
    'userAgreementType': UserAgreementType;
    /**
     * 
     * @type {boolean}
     * @memberof AgreeToUserAgreementRequest
     */
    'agree': boolean;
    /**
     * 
     * @type {number}
     * @memberof AgreeToUserAgreementRequest
     */
    'version'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AnnualRevenue = {
    LessThanTwentyFiveThousand: 'LessThanTwentyFiveThousand',
    TwentyFiveThousandToFiftyThousand: 'TwentyFiveThousandToFiftyThousand',
    FiftyThousandToOneHundredThousand: 'FiftyThousandToOneHundredThousand',
    OneHundredThousandToTwoHundredFiftyThousand: 'OneHundredThousandToTwoHundredFiftyThousand',
    TwoHundredFiftyThousandToFiveHundredThousand: 'TwoHundredFiftyThousandToFiveHundredThousand',
    FiveHundredThousandToOneMillion: 'FiveHundredThousandToOneMillion',
    OneMillionToFiveMillion: 'OneMillionToFiveMillion',
    GreaterThanFiveMillion: 'GreaterThanFiveMillion'
} as const;

export type AnnualRevenue = typeof AnnualRevenue[keyof typeof AnnualRevenue];


/**
 * 
 * @export
 * @enum {string}
 */

export const Application = {
    Demeter: 'Demeter',
    FarmAdvantage: 'FarmAdvantage',
    MyStoneXMobile: 'MyStoneXMobile',
    CommodityManagement: 'CommodityManagement'
} as const;

export type Application = typeof Application[keyof typeof Application];


/**
 * 
 * @export
 * @interface ArticleModel
 */
export interface ArticleModel {
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'articleId': string;
    /**
     * 
     * @type {AuthorModel}
     * @memberof ArticleModel
     */
    'author': AuthorModel;
    /**
     * 
     * @type {ArticleType}
     * @memberof ArticleModel
     */
    'articleType'?: ArticleType;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleModel
     */
    'isFeatured'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'languageCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'heroImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'abstract'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'publishedDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleModel
     */
    'isLocked': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticleModel
     */
    'commodityIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticleModel
     */
    'secondaryCommodityIds': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ArticleModel
     */
    'marketId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'marketName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'articleImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'marketIconUrl'?: string | null;
    /**
     * 
     * @type {Array<SecondaryMarketModel>}
     * @memberof ArticleModel
     */
    'secondaryMarkets': Array<SecondaryMarketModel>;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'contentConfigurationId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleModel
     */
    'watched'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleModel
     */
    'isFavorite'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleModel
     */
    'isSubscribed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'attachmentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'disclaimer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleModel
     */
    'allowPrinting'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleModel
     */
    'isPlatformPremium'?: boolean | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleType = {
    Free: 'Free',
    Premium: 'Premium'
} as const;

export type ArticleType = typeof ArticleType[keyof typeof ArticleType];


/**
 * 
 * @export
 * @interface ArticleViewCountModel
 */
export interface ArticleViewCountModel {
    /**
     * 
     * @type {string}
     * @memberof ArticleViewCountModel
     */
    'articleContentId': string;
    /**
     * 
     * @type {number}
     * @memberof ArticleViewCountModel
     */
    'viewCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleViewCountModel
     */
    'hasExceededMaximumViewCount': boolean;
}
/**
 * 
 * @export
 * @interface AuthenticateUserRequest
 */
export interface AuthenticateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateUserRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateUserRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AuthenticateUserResponse
 */
export interface AuthenticateUserResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateUserResponse
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateUserResponse
     */
    'identityToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateUserResponse
     */
    'openIdAccessToken'?: string | null;
    /**
     * 
     * @type {UserModel}
     * @memberof AuthenticateUserResponse
     */
    'user'?: UserModel;
}
/**
 * 
 * @export
 * @interface AuthorModel
 */
export interface AuthorModel {
    /**
     * 
     * @type {string}
     * @memberof AuthorModel
     */
    'imageUrl'?: string | null;
    /**
     * 
     * @type {ImageModel}
     * @memberof AuthorModel
     */
    'images': ImageModel;
    /**
     * 
     * @type {string}
     * @memberof AuthorModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorModel
     */
    'isDisabled': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof AuthorModel
     */
    'parentIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AuthorModel
     */
    'childIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof AuthorModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AuthorModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AvailableWorkingCapital = {
    LessThanTwentyFiveThousand: 'LessThanTwentyFiveThousand',
    TwentyFiveThousandToOneHundredThousand: 'TwentyFiveThousandToOneHundredThousand',
    OneHundredThousandToTwoHundredFiftyThousand: 'OneHundredThousandToTwoHundredFiftyThousand',
    TwoHundredFiftyThousandToFiveHundredThousand: 'TwoHundredFiftyThousandToFiveHundredThousand',
    FiveHundredThousandToOneMillion: 'FiveHundredThousandToOneMillion',
    OneMillionToFiveMillion: 'OneMillionToFiveMillion',
    GreaterThanFiveMillion: 'GreaterThanFiveMillion'
} as const;

export type AvailableWorkingCapital = typeof AvailableWorkingCapital[keyof typeof AvailableWorkingCapital];


/**
 * 
 * @export
 * @enum {string}
 */

export const CalculationType = {
    Empty: 'Empty',
    Calculated: 'Calculated',
    ManualEntry: 'ManualEntry'
} as const;

export type CalculationType = typeof CalculationType[keyof typeof CalculationType];


/**
 * 
 * @export
 * @interface CashActivityModel
 */
export interface CashActivityModel {
    /**
     * 
     * @type {string}
     * @memberof CashActivityModel
     */
    'tradeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashActivityModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashActivityModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashActivityModel
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CashActivityModel
     */
    'cash'?: number | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof CashActivityModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof CashActivityModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface CashBidCanadaModel
 */
export interface CashBidCanadaModel {
    /**
     * 
     * @type {string}
     * @memberof CashBidCanadaModel
     */
    'displayName': string;
    /**
     * 
     * @type {CropType}
     * @memberof CashBidCanadaModel
     */
    'cropType': CropType;
    /**
     * 
     * @type {CashBidPricingType}
     * @memberof CashBidCanadaModel
     */
    'pricingType': CashBidPricingType;
    /**
     * 
     * @type {Currency}
     * @memberof CashBidCanadaModel
     */
    'currency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof CashBidCanadaModel
     */
    'unitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof CashBidCanadaModel
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof CashBidCanadaModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidCanadaModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CashBidCanadaModel
     */
    'isFavorite': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CashBidCommodity = {
    ChicagoWheat: 'ChicagoWheat',
    Corn: 'Corn',
    Cotton: 'Cotton',
    KcWheat: 'KcWheat',
    MinneapolisWheat: 'MinneapolisWheat',
    Soybean: 'Soybean'
} as const;

export type CashBidCommodity = typeof CashBidCommodity[keyof typeof CashBidCommodity];


/**
 * 
 * @export
 * @enum {string}
 */

export const CashBidDeliveryMonth = {
    Spot: 'Spot',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December'
} as const;

export type CashBidDeliveryMonth = typeof CashBidDeliveryMonth[keyof typeof CashBidDeliveryMonth];


/**
 * 
 * @export
 * @interface CashBidFavoriteModel
 */
export interface CashBidFavoriteModel {
    /**
     * 
     * @type {CashBidCommodity}
     * @memberof CashBidFavoriteModel
     */
    'commodity'?: CashBidCommodity;
    /**
     * 
     * @type {string}
     * @memberof CashBidFavoriteModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidFavoriteModel
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidFavoriteModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidFavoriteModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidFavoriteModel
     */
    'zip'?: string | null;
}
/**
 * 
 * @export
 * @interface CashBidModel
 */
export interface CashBidModel {
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {CashBidCommodity}
     * @memberof CashBidModel
     */
    'commodity'?: CashBidCommodity;
    /**
     * 
     * @type {CashBidDeliveryMonth}
     * @memberof CashBidModel
     */
    'deliveryMonth'?: CashBidDeliveryMonth;
    /**
     * 
     * @type {number}
     * @memberof CashBidModel
     */
    'contactMonth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CashBidModel
     */
    'contactYear'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'delivery'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'queryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'securityDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'refinitivInstrumentCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'refinitivInstrumentCodePrefix'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CashBidModel
     */
    'price'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CashBidModel
     */
    'basis'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CashBidModel
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CashBidModel
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CashBidModel
     */
    'distanceToLocation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'zip'?: string | null;
    /**
     * 
     * @type {Array<CashBidPriceModel>}
     * @memberof CashBidModel
     */
    'cashBidPrices': Array<CashBidPriceModel>;
    /**
     * 
     * @type {boolean}
     * @memberof CashBidModel
     */
    'isFavorite'?: boolean | null;
    /**
     * 
     * @type {CashBidFavoriteModel}
     * @memberof CashBidModel
     */
    'cashBidFavorite': CashBidFavoriteModel;
    /**
     * 
     * @type {string}
     * @memberof CashBidModel
     */
    'cashBidFavoriteKey'?: string | null;
}
/**
 * 
 * @export
 * @interface CashBidPriceModel
 */
export interface CashBidPriceModel {
    /**
     * 
     * @type {number}
     * @memberof CashBidPriceModel
     */
    'contactYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CashBidPriceModel
     */
    'contactMonth'?: number | null;
    /**
     * 
     * @type {CashBidDeliveryMonth}
     * @memberof CashBidPriceModel
     */
    'deliveryMonth'?: CashBidDeliveryMonth;
    /**
     * 
     * @type {string}
     * @memberof CashBidPriceModel
     */
    'delivery'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidPriceModel
     */
    'refinitivInstrumentCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CashBidPriceModel
     */
    'price'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CashBidPriceModel
     */
    'basis'?: number | null;
    /**
     * 
     * @type {CashBidCommodity}
     * @memberof CashBidPriceModel
     */
    'commodity'?: CashBidCommodity;
    /**
     * 
     * @type {string}
     * @memberof CashBidPriceModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidPriceModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidPriceModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashBidPriceModel
     */
    'zip'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CashBidPricingType = {
    Spot: 'Spot',
    NewCrop: 'NewCrop',
    OldCrop: 'OldCrop'
} as const;

export type CashBidPricingType = typeof CashBidPricingType[keyof typeof CashBidPricingType];


/**
 * 
 * @export
 * @interface Check
 */
export interface Check {
    /**
     * 
     * @type {number}
     * @memberof Check
     */
    'checkId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    'checkDate': string;
    /**
     * 
     * @type {number}
     * @memberof Check
     */
    'totalPrice': number;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    'checkUrl': string;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationAccount
 */
export interface ClientServiceCenterApplicationAccount {
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAccount
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAccount
     */
    'parentAccountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAccount
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAccount
     */
    'brokerCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationAccount
     */
    'isManagedAccount'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAccount
     */
    'subSubsegmentCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAccount
     */
    'currency'?: string | null;
    /**
     * 
     * @type {ClientServiceCenterApplicationAdditionalAccountInformation}
     * @memberof ClientServiceCenterApplicationAccount
     */
    'additionalInformation'?: ClientServiceCenterApplicationAdditionalAccountInformation;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationAdditionalAccountInformation
 */
export interface ClientServiceCenterApplicationAdditionalAccountInformation {
    /**
     * 
     * @type {DiscretionaryTypeCode}
     * @memberof ClientServiceCenterApplicationAdditionalAccountInformation
     */
    'discretionaryTypeCode'?: DiscretionaryTypeCode;
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationAdditionalAccountInformation
     */
    'cftcReportingNumber'?: boolean | null;
    /**
     * 
     * @type {HedgeCode}
     * @memberof ClientServiceCenterApplicationAdditionalAccountInformation
     */
    'hedgeCode'?: HedgeCode;
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationAdditionalAccountInformation
     */
    'isAccountIdToPostInterestTo'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationAdditionalInformation
 */
export interface ClientServiceCenterApplicationAdditionalInformation {
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAdditionalInformation
     */
    'assetSource'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationAdditionalInformation
     */
    'hasRelatedAccounts'?: boolean | null;
    /**
     * 
     * @type {Array<ClientServiceCenterApplicationApplicantDetail>}
     * @memberof ClientServiceCenterApplicationAdditionalInformation
     */
    'applicantDetails'?: Array<ClientServiceCenterApplicationApplicantDetail> | null;
    /**
     * 
     * @type {Array<ClientServiceCenterApplicationApplicantIncomeDetail>}
     * @memberof ClientServiceCenterApplicationAdditionalInformation
     */
    'applicantIncomeDetails'?: Array<ClientServiceCenterApplicationApplicantIncomeDetail> | null;
    /**
     * 
     * @type {Array<ClientServiceCenterApplicationInvestmentKnowledge>}
     * @memberof ClientServiceCenterApplicationAdditionalInformation
     */
    'investmentKnowledge'?: Array<ClientServiceCenterApplicationInvestmentKnowledge> | null;
    /**
     * 
     * @type {Array<ClientServiceCenterApplicationRelatedEmployee>}
     * @memberof ClientServiceCenterApplicationAdditionalInformation
     */
    'relatedEmployees'?: Array<ClientServiceCenterApplicationRelatedEmployee> | null;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationAddress
 */
export interface ClientServiceCenterApplicationAddress {
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAddress
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAddress
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAddress
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAddress
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAddress
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationAddress
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {Array<AddressType>}
     * @memberof ClientServiceCenterApplicationAddress
     */
    'addressTypes'?: Array<AddressType> | null;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationApplicantDetail
 */
export interface ClientServiceCenterApplicationApplicantDetail {
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationApplicantDetail
     */
    'hasCommodityExchangeActExemption'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationApplicantDetail
     */
    'hasEmployeeRelations'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationApplicantDetail
     */
    'isPoliticallyExposed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationApplicantDetail
     */
    'isRegisteredWithRegulator'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationApplicantIncomeDetail
 */
export interface ClientServiceCenterApplicationApplicantIncomeDetail {
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationApplicantIncomeDetail
     */
    'beenThroughBankruptcy'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationApplicantIncomeDetail
     */
    'hadLegalDisputes'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationApplicantIncomeDetail
     */
    'hasClosedWithUnsatisfiedDebitBalance'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ClientServiceCenterApplicationApplicantIncomeDetail
     */
    'expectedMonthlyTradingAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientServiceCenterApplicationApplicantIncomeDetail
     */
    'initialDepositAmount'?: number | null;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationContact
 */
export interface ClientServiceCenterApplicationContact {
    /**
     * 
     * @type {Array<ContactType>}
     * @memberof ClientServiceCenterApplicationContact
     */
    'contactTypes'?: Array<ContactType> | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'countryOfCitizenship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'employmentStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'employerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'fax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'governmentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientServiceCenterApplicationContact
     */
    'isProfessionalTrader'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationContact
     */
    'phone'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientServiceCenterApplicationContact
     */
    'yearsOfEmployment'?: number | null;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationCustomer
 */
export interface ClientServiceCenterApplicationCustomer {
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationCustomer
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationCustomer
     */
    'legalName'?: string | null;
    /**
     * 
     * @type {ClientServiceCenterApplicationCustomerFinancials}
     * @memberof ClientServiceCenterApplicationCustomer
     */
    'financials'?: ClientServiceCenterApplicationCustomerFinancials;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationCustomerFinancials
 */
export interface ClientServiceCenterApplicationCustomerFinancials {
    /**
     * 
     * @type {number}
     * @memberof ClientServiceCenterApplicationCustomerFinancials
     */
    'netWorthInMillions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientServiceCenterApplicationCustomerFinancials
     */
    'assetsInMillions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientServiceCenterApplicationCustomerFinancials
     */
    'annualRevenueInMillions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationCustomerFinancials
     */
    'profit'?: string | null;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationInvestmentKnowledge
 */
export interface ClientServiceCenterApplicationInvestmentKnowledge {
    /**
     * 
     * @type {number}
     * @memberof ClientServiceCenterApplicationInvestmentKnowledge
     */
    'securitiesYears'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientServiceCenterApplicationInvestmentKnowledge
     */
    'commodityYears'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientServiceCenterApplicationInvestmentKnowledge
     */
    'otcYears'?: number | null;
}
/**
 * 
 * @export
 * @interface ClientServiceCenterApplicationRelatedEmployee
 */
export interface ClientServiceCenterApplicationRelatedEmployee {
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationRelatedEmployee
     */
    'employeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationRelatedEmployee
     */
    'employeeRelationshipType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientServiceCenterApplicationRelatedEmployee
     */
    'ownerName'?: string | null;
}
/**
 * 
 * @export
 * @interface CodexAccountModel
 */
export interface CodexAccountModel {
    /**
     * 
     * @type {string}
     * @memberof CodexAccountModel
     */
    'accountName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodexAccountModel
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CodexAccountModel
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodexAccountModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof CodexAccountModel
     */
    'backOfficeSystem'?: SourceSystem;
    /**
     * 
     * @type {SourceSystem}
     * @memberof CodexAccountModel
     */
    'sourceSystem'?: SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof CodexAccountModel
     */
    'brokerCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodexAccountModel
     */
    'firm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodexAccountModel
     */
    'groupAccountNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CodexAccountModel
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CodexAccountModel
     */
    'isErrorAccount': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CodexAccountModel
     */
    'isGroupAccount': boolean;
    /**
     * 
     * @type {string}
     * @memberof CodexAccountModel
     */
    'office'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CodexAccountModel
     */
    'isQueryMatch': boolean;
    /**
     * 
     * @type {Array<CodexAccountModel>}
     * @memberof CodexAccountModel
     */
    'subAccounts': Array<CodexAccountModel>;
}
/**
 * 
 * @export
 * @interface CommissionAndFeesModel
 */
export interface CommissionAndFeesModel {
    /**
     * 
     * @type {string}
     * @memberof CommissionAndFeesModel
     */
    'ourCode': string;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'clearingFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'commissionAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'nfaFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'brokerageFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'carryingBrokerFloorFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'efsFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'exchangeFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'executionFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'giveUpInFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'gstFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'regulatoryFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'symexFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'transactionFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'twentyFourHourDeskFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionAndFeesModel
     */
    'totalCommissionAndFees'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CompanyType = {
    Individual: 'Individual',
    Joint: 'Joint'
} as const;

export type CompanyType = typeof CompanyType[keyof typeof CompanyType];


/**
 * 
 * @export
 * @interface ContactAddress
 */
export interface ContactAddress {
    /**
     * 
     * @type {string}
     * @memberof ContactAddress
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactAddress
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactAddress
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactAddress
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactAddress
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactAddress
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {AddressType}
     * @memberof ContactAddress
     */
    'addressType'?: AddressType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContactType = {
    PowerOfAttorneyCommodityTradingAdvisorCta: 'PowerOfAttorneyCommodityTradingAdvisorCta',
    PowerOfAttorneyFloorBrokerFb: 'PowerOfAttorneyFloorBrokerFb',
    PowerOfAttorneyAssociatedPersonAp: 'PowerOfAttorneyAssociatedPersonAp',
    PowerOfAttorneyThirdPartyProviderTpp: 'PowerOfAttorneyThirdPartyProviderTpp',
    AuthorizedSignatory: 'AuthorizedSignatory',
    AuthorizedTrader: 'AuthorizedTrader',
    StatementRecipient: 'StatementRecipient',
    Officer: 'Officer',
    Director: 'Director',
    Shareholder: 'Shareholder',
    AgentOrServiceProvider: 'AgentOrServiceProvider',
    InterestedParty: 'InterestedParty',
    Primary: 'Primary',
    Secondary: 'Secondary',
    EmpoweredIndividuals: 'EmpoweredIndividuals',
    BeneficialOwner: 'BeneficialOwner',
    GeneralPartner: 'GeneralPartner',
    Trustee: 'Trustee',
    FinancialsProvider: 'FinancialsProvider',
    Exempt: 'Exempt',
    SelfDirected: 'SelfDirected',
    KycRefreshEmpoweredIndividual: 'KycRefreshEmpoweredIndividual'
} as const;

export type ContactType = typeof ContactType[keyof typeof ContactType];


/**
 * 
 * @export
 * @interface ContractModel
 */
export interface ContractModel {
    /**
     * 
     * @type {string}
     * @memberof ContractModel
     */
    'contractYearMonthDay'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof ContractModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof ContractModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Country = {
    Us: 'US',
    Ca: 'CA',
    Br: 'BR',
    Gb: 'GB',
    Py: 'PY'
} as const;

export type Country = typeof Country[keyof typeof Country];


/**
 * 
 * @export
 * @interface CropBudgetExpenseModel
 */
export interface CropBudgetExpenseModel {
    /**
     * 
     * @type {string}
     * @memberof CropBudgetExpenseModel
     */
    'cropBudgetExpenseGuid': string;
    /**
     * 
     * @type {string}
     * @memberof CropBudgetExpenseModel
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof CropBudgetExpenseModel
     */
    'cropBudgetGuid': string;
    /**
     * 
     * @type {string}
     * @memberof CropBudgetExpenseModel
     */
    'name': string;
    /**
     * 
     * @type {CalculationType}
     * @memberof CropBudgetExpenseModel
     */
    'pricePerAcreCalculationType'?: CalculationType;
    /**
     * 
     * @type {number}
     * @memberof CropBudgetExpenseModel
     */
    'pricePerAcre'?: number | null;
    /**
     * 
     * @type {CalculationType}
     * @memberof CropBudgetExpenseModel
     */
    'totalPriceCalculationType'?: CalculationType;
    /**
     * 
     * @type {number}
     * @memberof CropBudgetExpenseModel
     */
    'totalPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface CropBudgetModel
 */
export interface CropBudgetModel {
    /**
     * 
     * @type {string}
     * @memberof CropBudgetModel
     */
    'cropBudgetGuid': string;
    /**
     * 
     * @type {string}
     * @memberof CropBudgetModel
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {number}
     * @memberof CropBudgetModel
     */
    'year': number;
    /**
     * 
     * @type {CropType}
     * @memberof CropBudgetModel
     */
    'cropType'?: CropType;
    /**
     * 
     * @type {string}
     * @memberof CropBudgetModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CropBudgetModel
     */
    'marketingYield'?: number | null;
    /**
     * 
     * @type {CalculationType}
     * @memberof CropBudgetModel
     */
    'marketingYieldCalculationType'?: CalculationType;
    /**
     * 
     * @type {number}
     * @memberof CropBudgetModel
     */
    'projectedProduct'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CropBudgetModel
     */
    'projectedPrice'?: number | null;
    /**
     * 
     * @type {CalculationType}
     * @memberof CropBudgetModel
     */
    'projectedProductCalculationType'?: CalculationType;
    /**
     * 
     * @type {string}
     * @memberof CropBudgetModel
     */
    'cropPriceGuid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CropBudgetModel
     */
    'seededAcres'?: number | null;
}
/**
 * 
 * @export
 * @interface CropFieldModel
 */
export interface CropFieldModel {
    /**
     * 
     * @type {string}
     * @memberof CropFieldModel
     */
    'cropFieldGuid': string;
    /**
     * 
     * @type {string}
     * @memberof CropFieldModel
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof CropFieldModel
     */
    'name': string;
    /**
     * 
     * @type {State}
     * @memberof CropFieldModel
     */
    'state': State;
    /**
     * 
     * @type {Division}
     * @memberof CropFieldModel
     */
    'division'?: Division;
    /**
     * 
     * @type {number}
     * @memberof CropFieldModel
     */
    'section'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CropFieldModel
     */
    'township'?: string | null;
    /**
     * 
     * @type {TownshipCardinalDirection}
     * @memberof CropFieldModel
     */
    'townshipCardinalDirection'?: TownshipCardinalDirection;
    /**
     * 
     * @type {string}
     * @memberof CropFieldModel
     */
    'range'?: string | null;
    /**
     * 
     * @type {RangeCardinalDirection}
     * @memberof CropFieldModel
     */
    'rangeCardinalDirection'?: RangeCardinalDirection;
    /**
     * 
     * @type {Meridian}
     * @memberof CropFieldModel
     */
    'meridian'?: Meridian;
    /**
     * 
     * @type {number}
     * @memberof CropFieldModel
     */
    'farmableAcres'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CropFieldModel
     */
    'seededAcres'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CropFieldModel
     */
    'isRetired': boolean;
    /**
     * 
     * @type {number}
     * @memberof CropFieldModel
     */
    'startYear': number;
    /**
     * 
     * @type {number}
     * @memberof CropFieldModel
     */
    'endYear'?: number | null;
}
/**
 * 
 * @export
 * @interface CropPlanModel
 */
export interface CropPlanModel {
    /**
     * 
     * @type {string}
     * @memberof CropPlanModel
     */
    'cropPlanGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CropPlanModel
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof CropPlanModel
     */
    'cropFieldGuid': string;
    /**
     * 
     * @type {string}
     * @memberof CropPlanModel
     */
    'cropFieldName': string;
    /**
     * 
     * @type {number}
     * @memberof CropPlanModel
     */
    'year': number;
    /**
     * 
     * @type {CropType}
     * @memberof CropPlanModel
     */
    'cropType'?: CropType;
    /**
     * 
     * @type {string}
     * @memberof CropPlanModel
     */
    'variety'?: string | null;
    /**
     * 
     * @type {CropTolerance}
     * @memberof CropPlanModel
     */
    'tolerance'?: CropTolerance;
    /**
     * 
     * @type {number}
     * @memberof CropPlanModel
     */
    'farmableAcres'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CropPlanModel
     */
    'seededAcres'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CropPlanModel
     */
    'seededDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CropPlanModel
     */
    'agronomicYield'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CropPlanModel
     */
    'cropBudgetGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CropPlanModel
     */
    'cropBudgetName'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CropTolerance = {
    Clearfield: 'Clearfield',
    LibertyLink: 'LibertyLink',
    RoundupReady: 'RoundupReady',
    Organic: 'Organic',
    Sulfonylurea: 'Sulfonylurea'
} as const;

export type CropTolerance = typeof CropTolerance[keyof typeof CropTolerance];


/**
 * 
 * @export
 * @enum {string}
 */

export const CropType = {
    ChicagoWheat: 'ChicagoWheat',
    Corn: 'Corn',
    Cotton: 'Cotton',
    KcWheat: 'KcWheat',
    MinneapolisWheat: 'MinneapolisWheat',
    Soybean: 'Soybean',
    FeedBarley: 'FeedBarley',
    CanarySeed: 'CanarySeed',
    Canola: 'Canola',
    DurumWheat: 'DurumWheat',
    FallRye: 'FallRye',
    Flax: 'Flax',
    LentilsGreenLarge: 'LentilsGreenLarge',
    LentilsRedSmall: 'LentilsRedSmall',
    MaltBarley: 'MaltBarley',
    Oats: 'Oats',
    PeasGreen: 'PeasGreen',
    PeasYellow: 'PeasYellow'
} as const;

export type CropType = typeof CropType[keyof typeof CropType];


/**
 * 
 * @export
 * @interface CropVarietiesModel
 */
export interface CropVarietiesModel {
    /**
     * 
     * @type {CropType}
     * @memberof CropVarietiesModel
     */
    'cropType'?: CropType;
    /**
     * 
     * @type {string}
     * @memberof CropVarietiesModel
     */
    'variety'?: string | null;
}
/**
 * 
 * @export
 * @interface CurrenciesModel
 */
export interface CurrenciesModel {
    /**
     * 
     * @type {string}
     * @memberof CurrenciesModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof CurrenciesModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Currency = {
    Aud: 'AUD',
    Brl: 'BRL',
    Cad: 'CAD',
    Cny: 'CNY',
    Eur: 'EUR',
    Gbp: 'GBP',
    Jpy: 'JPY',
    Krw: 'KRW',
    Mxn: 'MXN',
    Myr: 'MYR',
    Nzd: 'NZD',
    Thb: 'THB',
    Usc: 'USC',
    Usd: 'USD'
} as const;

export type Currency = typeof Currency[keyof typeof Currency];


/**
 * 
 * @export
 * @interface DailyForecastModel
 */
export interface DailyForecastModel {
    /**
     * 
     * @type {string}
     * @memberof DailyForecastModel
     */
    'forecastDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DailyForecastModel
     */
    'forecastDisplayDate': string;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'maximumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'minimumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'thunderstormProbabilityMorning'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'thunderstormProbabilityEvening'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'snowAccumulation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'relativeHumidity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'cloudCoverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'iceAccumulation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'precipitationProbabilityEvening'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'rainfallAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'minimumRainfallAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'maximumRainfallAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'windGusts'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'windSpeed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'windDirection'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyForecastModel
     */
    'precipitationProbabilityMorning'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DailyForecastModel
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface DailyWeatherForecastModel
 */
export interface DailyWeatherForecastModel {
    /**
     * 
     * @type {string}
     * @memberof DailyWeatherForecastModel
     */
    'forecastDate': string;
    /**
     * 
     * @type {string}
     * @memberof DailyWeatherForecastModel
     */
    'forecastDisplayDate': string;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'maximumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'minimumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'soilTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'thunderstormProbability'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'snowAccumulation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'precipitationAccumulation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'relativeHumidity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'cloudCoverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'iceAccumulation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'precipitationProbabilityEvening'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'windGusts'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'windSpeed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'windDirection'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DailyWeatherForecastModel
     */
    'precipitationProbabilityMorning'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DailyWeatherForecastModel
     */
    'icon'?: string | null;
}
/**
 * 
 * @export
 * @interface DeleteCropBudgetExpenseRequest
 */
export interface DeleteCropBudgetExpenseRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteCropBudgetExpenseRequest
     */
    'cropBudgetExpenseGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteCropBudgetExpenseRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteCropBudgetExpenseRequest
     */
    'cropBudgetGuid': string;
}
/**
 * 
 * @export
 * @interface DeleteCropBudgetRequest
 */
export interface DeleteCropBudgetRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteCropBudgetRequest
     */
    'cropBudgetGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteCropBudgetRequest
     */
    'knowRiskAccountGuid': string;
}
/**
 * 
 * @export
 * @interface DeletePushNotificationRequest
 */
export interface DeletePushNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof DeletePushNotificationRequest
     */
    'platformId': string;
    /**
     * 
     * @type {string}
     * @memberof DeletePushNotificationRequest
     */
    'deviceId': string;
}
/**
 * 
 * @export
 * @interface DeleteSubscriptionRequest
 */
export interface DeleteSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteSubscriptionRequest
     */
    'userGuid': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteSubscriptionRequest
     */
    'planId': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteSubscriptionRequest
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteSubscriptionRequest
     */
    'displaySource': string;
}
/**
 * 
 * @export
 * @interface DeleteZohoSubscriptionRequest
 */
export interface DeleteZohoSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteZohoSubscriptionRequest
     */
    'subscriptionId': string;
}
/**
 * 
 * @export
 * @interface DeleteZohoSubscriptionResponse
 */
export interface DeleteZohoSubscriptionResponse {
    /**
     * 
     * @type {ZohoSubscriptionModel}
     * @memberof DeleteZohoSubscriptionResponse
     */
    'subscription'?: ZohoSubscriptionModel;
}
/**
 * 
 * @export
 * @interface DescriptionsModel
 */
export interface DescriptionsModel {
    /**
     * 
     * @type {string}
     * @memberof DescriptionsModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof DescriptionsModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof DescriptionsModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DiscretionaryTypeCode = {
    LetterOfDirection: 'LetterOfDirection',
    DiscretionaryWithAssociatedPersonDesignation: 'DiscretionaryWithAssociatedPersonDesignation',
    DiscretionaryWithoutAssociatedPersonDesignation: 'DiscretionaryWithoutAssociatedPersonDesignation',
    CommodityTradingAdvisor: 'CommodityTradingAdvisor'
} as const;

export type DiscretionaryTypeCode = typeof DiscretionaryTypeCode[keyof typeof DiscretionaryTypeCode];


/**
 * 
 * @export
 * @enum {string}
 */

export const Division = {
    Sc: 'SC',
    Ne: 'NE',
    Nw: 'NW',
    Se: 'SE',
    Sw: 'SW'
} as const;

export type Division = typeof Division[keyof typeof Division];


/**
 * 
 * @export
 * @enum {string}
 */

export const EmployeeRelationshipType = {
    Employee: 'Employee',
    Family: 'Family'
} as const;

export type EmployeeRelationshipType = typeof EmployeeRelationshipType[keyof typeof EmployeeRelationshipType];


/**
 * 
 * @export
 * @enum {string}
 */

export const EmploymentStatus = {
    Employed: 'Employed',
    SelfEmployed: 'SelfEmployed',
    Other: 'Other',
    Retired: 'Retired'
} as const;

export type EmploymentStatus = typeof EmploymentStatus[keyof typeof EmploymentStatus];


/**
 * 
 * @export
 * @interface EnumAndDescriptionModel
 */
export interface EnumAndDescriptionModel {
    /**
     * 
     * @type {string}
     * @memberof EnumAndDescriptionModel
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnumAndDescriptionModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ExchangeModel
 */
export interface ExchangeModel {
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'exchangeName'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof ExchangeModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof ExchangeModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ExpectedMonthlyTradingActivity = {
    LessThanOneThousand: 'LessThanOneThousand',
    OneThousandOneToTenThousand: 'OneThousandOneToTenThousand',
    TenThousandOneToFiftyThousand: 'TenThousandOneToFiftyThousand',
    FiftyThousandOneToOneHundredThousand: 'FiftyThousandOneToOneHundredThousand',
    OneHundredThousandOneToTwoHundredFiftyThousand: 'OneHundredThousandOneToTwoHundredFiftyThousand',
    TwoHundredFiftyThousandOneToFiveHundredThousand: 'TwoHundredFiftyThousandOneToFiveHundredThousand',
    FiveHundredThousandOneToOneMillion: 'FiveHundredThousandOneToOneMillion',
    OneMillionOneToFiveMillion: 'OneMillionOneToFiveMillion',
    GreaterThanFiveMillion: 'GreaterThanFiveMillion'
} as const;

export type ExpectedMonthlyTradingActivity = typeof ExpectedMonthlyTradingActivity[keyof typeof ExpectedMonthlyTradingActivity];


/**
 * 
 * @export
 * @enum {string}
 */

export const FavoriteType = {
    MarketIntelligence: 'MarketIntelligence',
    CashBids: 'CashBids',
    CashBidsCanada: 'CashBidsCanada',
    Symbol: 'Symbol',
    SymbolGainFutures: 'SymbolGainFutures'
} as const;

export type FavoriteType = typeof FavoriteType[keyof typeof FavoriteType];


/**
 * 
 * @export
 * @enum {string}
 */

export const FeatureApplicationLevel = {
    User: 'User',
    System: 'System',
    Override: 'Override'
} as const;

export type FeatureApplicationLevel = typeof FeatureApplicationLevel[keyof typeof FeatureApplicationLevel];


/**
 * 
 * @export
 * @enum {string}
 */

export const FeatureType = {
    UserRegistration: 'UserRegistration',
    MyStoneXLogin: 'MyStoneXLogin',
    KnowRiskApplication: 'KnowRiskApplication',
    MinimumApplicationVersion: 'MinimumApplicationVersion',
    MobileApplicationLanguageSelection: 'MobileApplicationLanguageSelection',
    Crops: 'Crops',
    CashBids: 'CashBids',
    Trading: 'Trading',
    TradingWeeklyAndShortDatedOptionFilter: 'TradingWeeklyAndShortDatedOptionFilter',
    PhysicalPositions: 'PhysicalPositions',
    TradingDom: 'TradingDom',
    Weather: 'Weather',
    Accounts: 'Accounts',
    Markets: 'Markets',
    Insurance: 'Insurance',
    InsuranceNotifications: 'InsuranceNotifications',
    ProfitCalculator: 'ProfitCalculator',
    IntegratedRiskManagementProgram: 'IntegratedRiskManagementProgram',
    StructuredProductOnlineCalculator: 'StructuredProductOnlineCalculator',
    MarketIntelligence: 'MarketIntelligence',
    MarketIntelligenceMarket: 'MarketIntelligenceMarket',
    MarketIntelligenceArticles: 'MarketIntelligenceArticles',
    MarketIntelligenceWatchlist: 'MarketIntelligenceWatchlist',
    MarketFeaturesSelection: 'MarketFeaturesSelection',
    MarketQuotesGainFuturesData: 'MarketQuotesGainFuturesData',
    MarketGrains: 'MarketGrains',
    MarketLivestock: 'MarketLivestock',
    MarketEnergy: 'MarketEnergy',
    MarketIndices: 'MarketIndices',
    MarketCurrency: 'MarketCurrency',
    MarketDairy: 'MarketDairy',
    MarketFertilizer: 'MarketFertilizer',
    MarketSofts: 'MarketSofts',
    SupportGeneralInquiry: 'SupportGeneralInquiry',
    SupportTechnicalInquiry: 'SupportTechnicalInquiry',
    SupportAccessTradingAccount: 'SupportAccessTradingAccount',
    SupportDairyAndLivestockInsuranceTools: 'SupportDairyAndLivestockInsuranceTools',
    SupportMarketsAndOpenTradingAccount: 'SupportMarketsAndOpenTradingAccount',
    SupportMarketIntelligenceSubscription: 'SupportMarketIntelligenceSubscription',
    SupportHelpPlacingTrade: 'SupportHelpPlacingTrade',
    OptionsStrategies: 'OptionsStrategies',
    UserMarketGrainsAndOilseeds: 'UserMarketGrainsAndOilseeds',
    UserMarketMeatsAndLivestock: 'UserMarketMeatsAndLivestock',
    UserMarketDairy: 'UserMarketDairy'
} as const;

export type FeatureType = typeof FeatureType[keyof typeof FeatureType];


/**
 * 
 * @export
 * @interface FertilizerPriceModel
 */
export interface FertilizerPriceModel {
    /**
     * 
     * @type {string}
     * @memberof FertilizerPriceModel
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FertilizerPriceModel
     */
    'contractYearMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FertilizerPriceModel
     */
    'fullDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FertilizerPriceModel
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FertilizerPriceModel
     */
    'netChange'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FertilizerPriceModel
     */
    'openInterest'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FertilizerPriceModel
     */
    'reutersInstrumentCode': string;
    /**
     * 
     * @type {number}
     * @memberof FertilizerPriceModel
     */
    'settlementPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FertilizerPriceModel
     */
    'shortDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FertilizerPriceModel
     */
    'volume'?: number | null;
}
/**
 * 
 * @export
 * @interface FixedExpenseModel
 */
export interface FixedExpenseModel {
    /**
     * 
     * @type {string}
     * @memberof FixedExpenseModel
     */
    'fixedExpenseGuid': string;
    /**
     * 
     * @type {string}
     * @memberof FixedExpenseModel
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof FixedExpenseModel
     */
    'fixedExpenseTypeGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FixedExpenseModel
     */
    'fixedExpenseTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FixedExpenseModel
     */
    'customName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FixedExpenseModel
     */
    'displayName': string;
    /**
     * 
     * @type {number}
     * @memberof FixedExpenseModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof FixedExpenseModel
     */
    'budgetPrice'?: number | null;
    /**
     * 
     * @type {CalculationType}
     * @memberof FixedExpenseModel
     */
    'budgetPriceCalculationType'?: CalculationType;
    /**
     * 
     * @type {number}
     * @memberof FixedExpenseModel
     */
    'budgetPricePerAcre'?: number | null;
    /**
     * 
     * @type {CalculationType}
     * @memberof FixedExpenseModel
     */
    'budgetPricePerAcreCalculationType'?: CalculationType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FundSource = {
    Broker: 'Broker',
    Gift: 'Gift',
    Inheritance: 'Inheritance',
    Insurance: 'Insurance',
    LegalSettlement: 'LegalSettlement',
    Pension: 'Pension',
    Retirement: 'Retirement',
    Sale: 'Sale',
    Wages: 'Wages'
} as const;

export type FundSource = typeof FundSource[keyof typeof FundSource];


/**
 * 
 * @export
 * @interface FuturesBalanceModel
 */
export interface FuturesBalanceModel {
    /**
     * 
     * @type {string}
     * @memberof FuturesBalanceModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FuturesBalanceModel
     */
    'buyingPower': number;
}
/**
 * 
 * @export
 * @interface FuturesBaseContractModel
 */
export interface FuturesBaseContractModel {
    /**
     * 
     * @type {string}
     * @memberof FuturesBaseContractModel
     */
    'gainFuturesCodePrefix': string;
}
/**
 * 
 * @export
 * @interface FuturesContractModel
 */
export interface FuturesContractModel {
    /**
     * 
     * @type {string}
     * @memberof FuturesContractModel
     */
    'gainFuturesCode': string;
    /**
     * 
     * @type {number}
     * @memberof FuturesContractModel
     */
    'contractYear': number;
    /**
     * 
     * @type {number}
     * @memberof FuturesContractModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {string}
     * @memberof FuturesContractModel
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof FuturesContractModel
     */
    'expirationDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof FuturesContractModel
     */
    'expirationType'?: string | null;
    /**
     * 
     * @type {OptionType}
     * @memberof FuturesContractModel
     */
    'optionType': OptionType;
    /**
     * 
     * @type {boolean}
     * @memberof FuturesContractModel
     */
    'isTraded': boolean;
    /**
     * 
     * @type {number}
     * @memberof FuturesContractModel
     */
    'strikePrice'?: number | null;
    /**
     * 
     * @type {FuturesBaseContractModel}
     * @memberof FuturesContractModel
     */
    'baseContract': FuturesBaseContractModel;
}
/**
 * 
 * @export
 * @interface FuturesHistoricalPriceModel
 */
export interface FuturesHistoricalPriceModel {
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'reutersInstrumentCode': string;
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'fullDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'shortDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'contractYearMonth'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FuturesHistoricalPriceModel
     */
    'openPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FuturesHistoricalPriceModel
     */
    'openInterest'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FuturesHistoricalPriceModel
     */
    'closePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FuturesHistoricalPriceModel
     */
    'settlementPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FuturesHistoricalPriceModel
     */
    'highPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FuturesHistoricalPriceModel
     */
    'lowPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FuturesHistoricalPriceModel
     */
    'volume'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'securityType': string;
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'masterInstrumentExchangeSymbol': string;
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'masterMic': string;
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'exchangeSymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesHistoricalPriceModel
     */
    'tradeDate': string;
}
/**
 * 
 * @export
 * @interface FuturesOnboardingAffiliationDetails
 */
export interface FuturesOnboardingAffiliationDetails {
    /**
     * 
     * @type {boolean}
     * @memberof FuturesOnboardingAffiliationDetails
     */
    'isPoliticallyExposed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FuturesOnboardingAffiliationDetails
     */
    'hasEmployeeRelations'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FuturesOnboardingAffiliationDetails
     */
    'hasRelatedAccounts'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingAffiliationDetails
     */
    'employeeName'?: string | null;
    /**
     * 
     * @type {EmployeeRelationshipType}
     * @memberof FuturesOnboardingAffiliationDetails
     */
    'employeeRelationshipType'?: EmployeeRelationshipType;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingAffiliationDetails
     */
    'employeeOwnerName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FuturesOnboardingAffiliationDetails
     */
    'isRegisteredWithRegulator'?: boolean | null;
}
/**
 * 
 * @export
 * @interface FuturesOnboardingApplicationAccountDetails
 */
export interface FuturesOnboardingApplicationAccountDetails {
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationAccountDetails
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationAccountDetails
     */
    'parentAccountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationAccountDetails
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationAccountDetails
     */
    'brokerCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationAccountDetails
     */
    'currencyEligibilityCode'?: string | null;
}
/**
 * 
 * @export
 * @interface FuturesOnboardingApplicationContactDetails
 */
export interface FuturesOnboardingApplicationContactDetails {
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationContactDetails
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationContactDetails
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationContactDetails
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationContactDetails
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationContactDetails
     */
    'phone'?: string | null;
    /**
     * 
     * @type {ContactAddress}
     * @memberof FuturesOnboardingApplicationContactDetails
     */
    'address'?: ContactAddress;
}
/**
 * 
 * @export
 * @interface FuturesOnboardingApplicationCustomerDetails
 */
export interface FuturesOnboardingApplicationCustomerDetails {
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationCustomerDetails
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationCustomerDetails
     */
    'legalName'?: string | null;
}
/**
 * 
 * @export
 * @interface FuturesOnboardingApplicationPersonalDetails
 */
export interface FuturesOnboardingApplicationPersonalDetails {
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationPersonalDetails
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationPersonalDetails
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingApplicationPersonalDetails
     */
    'countryOfCitizenship'?: string | null;
}
/**
 * 
 * @export
 * @interface FuturesOnboardingEmploymentDetails
 */
export interface FuturesOnboardingEmploymentDetails {
    /**
     * 
     * @type {EmploymentStatus}
     * @memberof FuturesOnboardingEmploymentDetails
     */
    'employmentStatus'?: EmploymentStatus;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingEmploymentDetails
     */
    'employerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FuturesOnboardingEmploymentDetails
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FuturesOnboardingEmploymentDetails
     */
    'yearsOfEmployment'?: number | null;
}
/**
 * 
 * @export
 * @interface FuturesOnboardingFinancialDetails
 */
export interface FuturesOnboardingFinancialDetails {
    /**
     * 
     * @type {FundSource}
     * @memberof FuturesOnboardingFinancialDetails
     */
    'fundSource'?: FundSource;
    /**
     * 
     * @type {IntendedInitialDeposit}
     * @memberof FuturesOnboardingFinancialDetails
     */
    'intendedInitialDeposit'?: IntendedInitialDeposit;
    /**
     * 
     * @type {ExpectedMonthlyTradingActivity}
     * @memberof FuturesOnboardingFinancialDetails
     */
    'expectedMonthlyTradingActivity'?: ExpectedMonthlyTradingActivity;
    /**
     * 
     * @type {AnnualRevenue}
     * @memberof FuturesOnboardingFinancialDetails
     */
    'annualRevenue'?: AnnualRevenue;
    /**
     * 
     * @type {TotalNetWorth}
     * @memberof FuturesOnboardingFinancialDetails
     */
    'totalNetWorth'?: TotalNetWorth;
    /**
     * 
     * @type {AvailableWorkingCapital}
     * @memberof FuturesOnboardingFinancialDetails
     */
    'availableWorkingCapital'?: AvailableWorkingCapital;
}
/**
 * 
 * @export
 * @interface FuturesOnboardingRegulatoryDetails
 */
export interface FuturesOnboardingRegulatoryDetails {
    /**
     * 
     * @type {boolean}
     * @memberof FuturesOnboardingRegulatoryDetails
     */
    'hasCommodityExchangeActExemption'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FuturesOnboardingRegulatoryDetails
     */
    'beenThroughBankruptcy'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FuturesOnboardingRegulatoryDetails
     */
    'hadLegalDisputes'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FuturesOnboardingRegulatoryDetails
     */
    'hasClosedWithUnsatisfiedDebitBalance'?: boolean | null;
}
/**
 * 
 * @export
 * @interface FuturesOnboardingTradingExperienceDetails
 */
export interface FuturesOnboardingTradingExperienceDetails {
    /**
     * 
     * @type {YearsOfTradingExperience}
     * @memberof FuturesOnboardingTradingExperienceDetails
     */
    'yearsOfExperienceTradingSecurites'?: YearsOfTradingExperience;
    /**
     * 
     * @type {YearsOfTradingExperience}
     * @memberof FuturesOnboardingTradingExperienceDetails
     */
    'yearsOfExperienceTradingCommodities'?: YearsOfTradingExperience;
    /**
     * 
     * @type {YearsOfTradingExperience}
     * @memberof FuturesOnboardingTradingExperienceDetails
     */
    'yearsOfExperienceTradingOtcContracts'?: YearsOfTradingExperience;
    /**
     * 
     * @type {boolean}
     * @memberof FuturesOnboardingTradingExperienceDetails
     */
    'isProfessionalTrader'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetArticleResponse
 */
export interface GetArticleResponse {
    /**
     * 
     * @type {ArticleModel}
     * @memberof GetArticleResponse
     */
    'article'?: ArticleModel;
}
/**
 * 
 * @export
 * @interface GetClarityWeatherCurrentWeatherConditionsResponse
 */
export interface GetClarityWeatherCurrentWeatherConditionsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'airPressure'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'feelsLikeTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'dewPoint'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'icon'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'minimumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'maximumTemperature'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'weatherConditionPhrase'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'precipitationType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'precipitationProbabilityEvening'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'precipitationProbabilityMorning'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'recentRainfallAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'relativeHumidity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'sprayIcon'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'temperature'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'thunderstorm'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'forecastDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'visibility'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'windChill'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'windDirection'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'windGust'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetClarityWeatherCurrentWeatherConditionsResponse
     */
    'windSpeed'?: number | null;
}
/**
 * 
 * @export
 * @interface GetClarityWeatherLocalVideoResponse
 */
export interface GetClarityWeatherLocalVideoResponse {
    /**
     * 
     * @type {LocalVideoModel}
     * @memberof GetClarityWeatherLocalVideoResponse
     */
    'video'?: LocalVideoModel;
}
/**
 * 
 * @export
 * @interface GetClarityWeatherLongRangeVideoResponse
 */
export interface GetClarityWeatherLongRangeVideoResponse {
    /**
     * 
     * @type {LongRangeVideoModel}
     * @memberof GetClarityWeatherLongRangeVideoResponse
     */
    'video'?: LongRangeVideoModel;
}
/**
 * 
 * @export
 * @interface GetClientServicesCenterOnboardingApplicationResponse
 */
export interface GetClientServicesCenterOnboardingApplicationResponse {
    /**
     * 
     * @type {string}
     * @memberof GetClientServicesCenterOnboardingApplicationResponse
     */
    'futuresOnboardingApplicationGuid'?: string;
    /**
     * 
     * @type {AddClientServiceCenterApplicationRequest}
     * @memberof GetClientServicesCenterOnboardingApplicationResponse
     */
    'futuresOnboardingApplication'?: AddClientServiceCenterApplicationRequest;
}
/**
 * 
 * @export
 * @interface GetCropBudgetResponse
 */
export interface GetCropBudgetResponse {
    /**
     * 
     * @type {CropBudgetModel}
     * @memberof GetCropBudgetResponse
     */
    'cropBudget'?: CropBudgetModel;
    /**
     * 
     * @type {Array<CropBudgetExpenseModel>}
     * @memberof GetCropBudgetResponse
     */
    'cropBudgetExpenses'?: Array<CropBudgetExpenseModel> | null;
    /**
     * 
     * @type {Array<CropPlanModel>}
     * @memberof GetCropBudgetResponse
     */
    'cropPlans'?: Array<CropPlanModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetCropBudgetResponse
     */
    'farmableAcres'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCropBudgetResponse
     */
    'seededAcres'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCropBudgetResponse
     */
    'averageAgronomicYield'?: number | null;
}
/**
 * 
 * @export
 * @interface GetCropFieldResponse
 */
export interface GetCropFieldResponse {
    /**
     * 
     * @type {CropFieldModel}
     * @memberof GetCropFieldResponse
     */
    'cropField'?: CropFieldModel;
}
/**
 * 
 * @export
 * @interface GetCropPlanResponse
 */
export interface GetCropPlanResponse {
    /**
     * 
     * @type {CropPlanModel}
     * @memberof GetCropPlanResponse
     */
    'cropPlan'?: CropPlanModel;
}
/**
 * 
 * @export
 * @interface GetCurrentFuturesOnboardingApplicationGuidResponse
 */
export interface GetCurrentFuturesOnboardingApplicationGuidResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCurrentFuturesOnboardingApplicationGuidResponse
     */
    'futuresOnboardingApplicationGuid'?: string;
}
/**
 * 
 * @export
 * @interface GetCurrentWeatherConditionsResponse
 */
export interface GetCurrentWeatherConditionsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'forecastDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'pressure'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'temperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'dewPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'windDirection'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'windSpeed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'windGusts'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'visibility'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'maximumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'minimumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'precipitationProbability'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'relativeHumidity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'lastDayPrecipitation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'sprayIcon'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetCurrentWeatherConditionsResponse
     */
    'feelsLikeTemperature'?: number | null;
}
/**
 * 
 * @export
 * @interface GetFuturesOnboardingApplicationSummaryResponse
 */
export interface GetFuturesOnboardingApplicationSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'futuresOnboardingApplicationGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'applicationTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'ownerId'?: string | null;
    /**
     * 
     * @type {CompanyType}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'companyType'?: CompanyType;
    /**
     * 
     * @type {AccountType}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'accountType'?: AccountType;
    /**
     * 
     * @type {FuturesOnboardingApplicationContactDetails}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'contactDetails'?: FuturesOnboardingApplicationContactDetails;
    /**
     * 
     * @type {FuturesOnboardingApplicationAccountDetails}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'accountDetails'?: FuturesOnboardingApplicationAccountDetails;
    /**
     * 
     * @type {FuturesOnboardingApplicationCustomerDetails}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'customerDetails'?: FuturesOnboardingApplicationCustomerDetails;
    /**
     * 
     * @type {FuturesOnboardingApplicationPersonalDetails}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'personalDetails'?: FuturesOnboardingApplicationPersonalDetails;
    /**
     * 
     * @type {FuturesOnboardingFinancialDetails}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'financialDetails'?: FuturesOnboardingFinancialDetails;
    /**
     * 
     * @type {FuturesOnboardingEmploymentDetails}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'employmentDetails'?: FuturesOnboardingEmploymentDetails;
    /**
     * 
     * @type {FuturesOnboardingTradingExperienceDetails}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'tradingExperienceDetails'?: FuturesOnboardingTradingExperienceDetails;
    /**
     * 
     * @type {FuturesOnboardingAffiliationDetails}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'affiliationDetails'?: FuturesOnboardingAffiliationDetails;
    /**
     * 
     * @type {FuturesOnboardingRegulatoryDetails}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'regulatoryDetails'?: FuturesOnboardingRegulatoryDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetFuturesOnboardingApplicationSummaryResponse
     */
    'applicationAgreements'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GetMarketIntelligenceUserResponse
 */
export interface GetMarketIntelligenceUserResponse {
    /**
     * 
     * @type {MarketIntelligenceUserModel}
     * @memberof GetMarketIntelligenceUserResponse
     */
    'marketIntelligenceUser'?: MarketIntelligenceUserModel;
}
/**
 * 
 * @export
 * @interface GetMarketPriceVolatilityResponse
 */
export interface GetMarketPriceVolatilityResponse {
    /**
     * 
     * @type {MarketPriceVolatilityModel}
     * @memberof GetMarketPriceVolatilityResponse
     */
    'marketPriceVolatility'?: MarketPriceVolatilityModel;
}
/**
 * 
 * @export
 * @interface GetRadarTokenResponse
 */
export interface GetRadarTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof GetRadarTokenResponse
     */
    'radarToken'?: string | null;
}
/**
 * 
 * @export
 * @interface GetSubscriptionStatusResponse
 */
export interface GetSubscriptionStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetSubscriptionStatusResponse
     */
    'isSubscribed'?: boolean;
}
/**
 * 
 * @export
 * @interface GetTermsAndConditionsResponse
 */
export interface GetTermsAndConditionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetTermsAndConditionsResponse
     */
    'agreed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTermsAndConditionsResponse
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetTermsAndConditionsResponse
     */
    'version'?: number | null;
}
/**
 * 
 * @export
 * @interface GetTicketResponse
 */
export interface GetTicketResponse {
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'ticketId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'ticketVersionNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponse
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {TicketType}
     * @memberof GetTicketResponse
     */
    'ticketType'?: TicketType;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponse
     */
    'ticketUnloadDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'commodityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'unitId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'originCustomerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'destinationCustomerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'elevatorDomainId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'officeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'grossPlusTarePounds'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponse
     */
    'ticketDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'netDollars'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'grossUnits'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'netUnits'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponse
     */
    'settleDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponse
     */
    'firstSettledDate'?: string | null;
    /**
     * 
     * @type {Array<TicketApplication>}
     * @memberof GetTicketResponse
     */
    'ticketApplications'?: Array<TicketApplication> | null;
    /**
     * 
     * @type {Array<TicketGrade>}
     * @memberof GetTicketResponse
     */
    'ticketGrades'?: Array<TicketGrade> | null;
}
/**
 * 
 * @export
 * @interface GetUserAgreementResponse
 */
export interface GetUserAgreementResponse {
    /**
     * 
     * @type {UserAgreementType}
     * @memberof GetUserAgreementResponse
     */
    'userAgreementType'?: UserAgreementType;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserAgreementResponse
     */
    'agreed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserAgreementResponse
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserAgreementResponse
     */
    'version'?: number | null;
}
/**
 * 
 * @export
 * @interface GetUserResponse
 */
export interface GetUserResponse {
    /**
     * 
     * @type {UserModel}
     * @memberof GetUserResponse
     */
    'user'?: UserModel;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HedgeCode = {
    Hedge: 'Hedge',
    Speculation: 'Speculation'
} as const;

export type HedgeCode = typeof HedgeCode[keyof typeof HedgeCode];


/**
 * 
 * @export
 * @interface HistoricalWeatherConditionModel
 */
export interface HistoricalWeatherConditionModel {
    /**
     * 
     * @type {string}
     * @memberof HistoricalWeatherConditionModel
     */
    'forecastDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HistoricalWeatherConditionModel
     */
    'forecastDisplayDate': string;
    /**
     * 
     * @type {number}
     * @memberof HistoricalWeatherConditionModel
     */
    'minimumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HistoricalWeatherConditionModel
     */
    'maximumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HistoricalWeatherConditionModel
     */
    'rainAccumulation'?: number | null;
}
/**
 * 
 * @export
 * @interface HistoricalWeatherModel
 */
export interface HistoricalWeatherModel {
    /**
     * 
     * @type {string}
     * @memberof HistoricalWeatherModel
     */
    'forecastDate': string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalWeatherModel
     */
    'forecastDisplayDate': string;
    /**
     * 
     * @type {number}
     * @memberof HistoricalWeatherModel
     */
    'precipitationAccumulation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HistoricalWeatherModel
     */
    'maximumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HistoricalWeatherModel
     */
    'minimumTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HistoricalWeatherModel
     */
    'windSpeed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HistoricalWeatherModel
     */
    'windDirection'?: number | null;
}
/**
 * 
 * @export
 * @interface HourlyForecastModel
 */
export interface HourlyForecastModel {
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'cloudCoverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'dewPointAt2Meters'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'feelsLikeTemperature'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HourlyForecastModel
     */
    'forecastDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HourlyForecastModel
     */
    'forecastDisplayDate': string;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'iceAccumulation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HourlyForecastModel
     */
    'icon'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'precipitationProbability'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'rainAccumulation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'relativeHumidityAt2Meters'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'snowAccumulation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'snowProbability'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HourlyForecastModel
     */
    'sprayIcon'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'surfacePressure'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'temperatureAt2Meters'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'thunderstormProbability'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'visibility'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'wetBulbTemperatureGreaterThan2Meters'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'windDirection'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'windGusts'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyForecastModel
     */
    'windSpeed'?: number | null;
}
/**
 * 
 * @export
 * @interface HourlyWeatherForecastModel
 */
export interface HourlyWeatherForecastModel {
    /**
     * 
     * @type {string}
     * @memberof HourlyWeatherForecastModel
     */
    'forecastDate': string;
    /**
     * 
     * @type {string}
     * @memberof HourlyWeatherForecastModel
     */
    'forecastDisplayDate': string;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'temperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'windGusts'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'dewPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'windDirection'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'windSpeed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'visibility'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'cloudCoverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'precipitationAccumulation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'snowAccumulation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'thunderstormProbability'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'precipitationProbability'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'feelsLikeTemperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'relativeHumidity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HourlyWeatherForecastModel
     */
    'iceAccumulation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HourlyWeatherForecastModel
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HourlyWeatherForecastModel
     */
    'sprayIcon'?: string | null;
}
/**
 * 
 * @export
 * @interface ImageModel
 */
export interface ImageModel {
    /**
     * 
     * @type {string}
     * @memberof ImageModel
     */
    'small'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageModel
     */
    'medium'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageModel
     */
    'large'?: string | null;
}
/**
 * 
 * @export
 * @interface IncomeCalculatorDefinitionModel
 */
export interface IncomeCalculatorDefinitionModel {
    /**
     * 
     * @type {string}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'incomeCalculatorDefinitionGuid': string;
    /**
     * 
     * @type {CropType}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'cropType': CropType;
    /**
     * 
     * @type {boolean}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'isExchangeTraded': boolean;
    /**
     * 
     * @type {string}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'reutersInstrumentCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'gainFuturesCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'cropPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'cropPriceAsOfDate'?: string | null;
    /**
     * 
     * @type {Currency}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'cropPriceCurrency': Currency;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'cropPriceUnitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'yieldUnitOfMeasure': UnitOfMeasure;
    /**
     * 
     * @type {number}
     * @memberof IncomeCalculatorDefinitionModel
     */
    'cropPriceToYieldUnitOfMeasureConversion': number;
}
/**
 * 
 * @export
 * @interface IncomeCalculatorModel
 */
export interface IncomeCalculatorModel {
    /**
     * 
     * @type {string}
     * @memberof IncomeCalculatorModel
     */
    'incomeCalculatorGuid': string;
    /**
     * 
     * @type {CropType}
     * @memberof IncomeCalculatorModel
     */
    'cropType': CropType;
    /**
     * 
     * @type {number}
     * @memberof IncomeCalculatorModel
     */
    'seededAcres': number;
    /**
     * 
     * @type {number}
     * @memberof IncomeCalculatorModel
     */
    'marketPrice': number;
    /**
     * 
     * @type {number}
     * @memberof IncomeCalculatorModel
     */
    'yield': number;
    /**
     * 
     * @type {number}
     * @memberof IncomeCalculatorModel
     */
    'agronomicYield': number;
    /**
     * 
     * @type {number}
     * @memberof IncomeCalculatorModel
     */
    'fixedCost': number;
    /**
     * 
     * @type {number}
     * @memberof IncomeCalculatorModel
     */
    'variableCost': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IntendedInitialDeposit = {
    LessThanOneThousand: 'LessThanOneThousand',
    OneThousandOneToTenThousand: 'OneThousandOneToTenThousand',
    TenThousandOneToFiftyThousand: 'TenThousandOneToFiftyThousand',
    FiftyThousandOneToOneHundredThousand: 'FiftyThousandOneToOneHundredThousand',
    OneHundredThousandOneToTwoHundredFiftyThousand: 'OneHundredThousandOneToTwoHundredFiftyThousand',
    TwoHundredFiftyThousandOneToFiveHundredThousand: 'TwoHundredFiftyThousandOneToFiveHundredThousand',
    FiveHundredThousandOneToOneMillion: 'FiveHundredThousandOneToOneMillion',
    OneMillionOneToFiveMillion: 'OneMillionOneToFiveMillion',
    GreaterThanFiveMillion: 'GreaterThanFiveMillion'
} as const;

export type IntendedInitialDeposit = typeof IntendedInitialDeposit[keyof typeof IntendedInitialDeposit];


/**
 * 
 * @export
 * @interface KnowRiskAccountModel
 */
export interface KnowRiskAccountModel {
    /**
     * 
     * @type {string}
     * @memberof KnowRiskAccountModel
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskAccountModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface KnowRiskFeatureModel
 */
export interface KnowRiskFeatureModel {
    /**
     * 
     * @type {FeatureType}
     * @memberof KnowRiskFeatureModel
     */
    'featureType': FeatureType;
    /**
     * 
     * @type {boolean}
     * @memberof KnowRiskFeatureModel
     */
    'isOn': boolean;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskFeatureModel
     */
    'minimumBuildNumber': number;
}
/**
 * 
 * @export
 * @interface KnowRiskSymbolContractModel
 */
export interface KnowRiskSymbolContractModel {
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolContractModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolContractModel
     */
    'monthYearName': string;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolContractModel
     */
    'reutersInstrumentCode': string;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolContractModel
     */
    'tradingViewCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolContractModel
     */
    'gainFuturesCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolContractModel
     */
    'priceMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolContractModel
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolContractModel
     */
    'month': number;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolContractModel
     */
    'iconName': string;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolContractModel
     */
    'optionsReutersInstrumentCodeCallSuffix': string;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolContractModel
     */
    'optionsReutersInstrumentCodePutSuffix': string;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolContractModel
     */
    'optionsReutersInstrumentCodePrefix'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolContractModel
     */
    'optionsMultiplier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolContractModel
     */
    'optionsIncrementSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolContractModel
     */
    'optionsExpirationDate'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnowRiskSymbolContractModel
     */
    'weeklyOptionsExpirationDates': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof KnowRiskSymbolContractModel
     */
    'isFavorite'?: boolean | null;
}
/**
 * 
 * @export
 * @interface KnowRiskSymbolModel
 */
export interface KnowRiskSymbolModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof KnowRiskSymbolModel
     */
    'contractMonths'?: Array<number> | null;
    /**
     * 
     * @type {SymbolCategory}
     * @memberof KnowRiskSymbolModel
     */
    'symbolCategory': SymbolCategory;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolModel
     */
    'reutersInstrumentCodePrefix': string;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolModel
     */
    'tradingViewPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolModel
     */
    'gainFuturesPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolModel
     */
    'marketOpenTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolModel
     */
    'marketCloseTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolModel
     */
    'priceMultiplier': number;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolModel
     */
    'visibleContracts': number;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolModel
     */
    'contractSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolModel
     */
    'iconName': string;
    /**
     * 
     * @type {Array<KnowRiskSymbolContractModel>}
     * @memberof KnowRiskSymbolModel
     */
    'symbolContracts': Array<KnowRiskSymbolContractModel>;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskSymbolModel
     */
    'optionsTickerPrefix'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolModel
     */
    'optionsTickerMultiplier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolModel
     */
    'optionsIncrementSize'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolModel
     */
    'totalContracts': number;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolModel
     */
    'startYearForTwoDigitFutureYearSuffix': number;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolModel
     */
    'startYearForTwoDigitOptionYearSuffix': number;
    /**
     * 
     * @type {number}
     * @memberof KnowRiskSymbolModel
     */
    'consecutiveContractsAfterFrontMonth'?: number | null;
}
/**
 * 
 * @export
 * @interface KnowRiskUserFavoriteModel
 */
export interface KnowRiskUserFavoriteModel {
    /**
     * 
     * @type {string}
     * @memberof KnowRiskUserFavoriteModel
     */
    'knowRiskUserFavoriteGuid': string;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskUserFavoriteModel
     */
    'userGuid': string;
    /**
     * 
     * @type {FavoriteType}
     * @memberof KnowRiskUserFavoriteModel
     */
    'favoriteType': FavoriteType;
    /**
     * 
     * @type {string}
     * @memberof KnowRiskUserFavoriteModel
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ListAccountDetailsResponse
 */
export interface ListAccountDetailsResponse {
    /**
     * 
     * @type {Array<AccountDetailsModel>}
     * @memberof ListAccountDetailsResponse
     */
    'rows'?: Array<AccountDetailsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListAccountDetailsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListAccountDetailsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListAccountDetailsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListAccountDetailsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListAccountDetailsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListAccountTypesResponse
 */
export interface ListAccountTypesResponse {
    /**
     * 
     * @type {Array<AccountTypeModel>}
     * @memberof ListAccountTypesResponse
     */
    'rows'?: Array<AccountTypeModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListAccountTypesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListAccountTypesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListAccountTypesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListAccountTypesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListAccountTypesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListAccountsResponse
 */
export interface ListAccountsResponse {
    /**
     * 
     * @type {Array<AccountModel>}
     * @memberof ListAccountsResponse
     */
    'rows'?: Array<AccountModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListAccountsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListAccountsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListAccountsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListAccountsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListAccountsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListArticleViewCountsResponse
 */
export interface ListArticleViewCountsResponse {
    /**
     * 
     * @type {Array<ArticleViewCountModel>}
     * @memberof ListArticleViewCountsResponse
     */
    'rows'?: Array<ArticleViewCountModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListArticleViewCountsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListArticleViewCountsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListArticleViewCountsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListArticleViewCountsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListArticleViewCountsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListArticlesResponse
 */
export interface ListArticlesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ListArticlesResponse
     */
    'hasMoreResults'?: boolean;
    /**
     * 
     * @type {Array<ArticleModel>}
     * @memberof ListArticlesResponse
     */
    'rows'?: Array<ArticleModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListArticlesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListArticlesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListArticlesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListArticlesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListArticlesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCashActivityResponse
 */
export interface ListCashActivityResponse {
    /**
     * 
     * @type {Array<CashActivityModel>}
     * @memberof ListCashActivityResponse
     */
    'rows'?: Array<CashActivityModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCashActivityResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCashActivityResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCashActivityResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCashActivityResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCashActivityResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCashBidsCanadaResponse
 */
export interface ListCashBidsCanadaResponse {
    /**
     * 
     * @type {Array<CashBidCanadaModel>}
     * @memberof ListCashBidsCanadaResponse
     */
    'rows'?: Array<CashBidCanadaModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCashBidsCanadaResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCashBidsCanadaResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCashBidsCanadaResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCashBidsCanadaResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCashBidsCanadaResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCashBidsResponse
 */
export interface ListCashBidsResponse {
    /**
     * 
     * @type {Array<CashBidModel>}
     * @memberof ListCashBidsResponse
     */
    'rows'?: Array<CashBidModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCashBidsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCashBidsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCashBidsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCashBidsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCashBidsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListChecksResponse
 */
export interface ListChecksResponse {
    /**
     * 
     * @type {Array<Check>}
     * @memberof ListChecksResponse
     */
    'rows'?: Array<Check> | null;
    /**
     * 
     * @type {number}
     * @memberof ListChecksResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListChecksResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListChecksResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListChecksResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListChecksResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListClarityWeatherDailyWeatherForecastResponse
 */
export interface ListClarityWeatherDailyWeatherForecastResponse {
    /**
     * 
     * @type {Array<DailyForecastModel>}
     * @memberof ListClarityWeatherDailyWeatherForecastResponse
     */
    'rows'?: Array<DailyForecastModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListClarityWeatherDailyWeatherForecastResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListClarityWeatherDailyWeatherForecastResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListClarityWeatherDailyWeatherForecastResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListClarityWeatherDailyWeatherForecastResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListClarityWeatherDailyWeatherForecastResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListClarityWeatherHistoricalWeatherConditionsResponse
 */
export interface ListClarityWeatherHistoricalWeatherConditionsResponse {
    /**
     * 
     * @type {Array<HistoricalWeatherConditionModel>}
     * @memberof ListClarityWeatherHistoricalWeatherConditionsResponse
     */
    'rows'?: Array<HistoricalWeatherConditionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListClarityWeatherHistoricalWeatherConditionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListClarityWeatherHistoricalWeatherConditionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListClarityWeatherHistoricalWeatherConditionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListClarityWeatherHistoricalWeatherConditionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListClarityWeatherHistoricalWeatherConditionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListClarityWeatherHourlyWeatherForecastResponse
 */
export interface ListClarityWeatherHourlyWeatherForecastResponse {
    /**
     * 
     * @type {Array<HourlyForecastModel>}
     * @memberof ListClarityWeatherHourlyWeatherForecastResponse
     */
    'rows'?: Array<HourlyForecastModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListClarityWeatherHourlyWeatherForecastResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListClarityWeatherHourlyWeatherForecastResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListClarityWeatherHourlyWeatherForecastResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListClarityWeatherHourlyWeatherForecastResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListClarityWeatherHourlyWeatherForecastResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCodexAccountsResponse
 */
export interface ListCodexAccountsResponse {
    /**
     * 
     * @type {Array<CodexAccountModel>}
     * @memberof ListCodexAccountsResponse
     */
    'value'?: Array<CodexAccountModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCodexAccountsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCodexAccountsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCodexAccountsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCodexAccountsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCodexAccountsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCommissionAndFeesResponse
 */
export interface ListCommissionAndFeesResponse {
    /**
     * 
     * @type {Array<CommissionAndFeesModel>}
     * @memberof ListCommissionAndFeesResponse
     */
    'rows'?: Array<CommissionAndFeesModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommissionAndFeesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommissionAndFeesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCommissionAndFeesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCommissionAndFeesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCommissionAndFeesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListContractsResponse
 */
export interface ListContractsResponse {
    /**
     * 
     * @type {Array<ContractModel>}
     * @memberof ListContractsResponse
     */
    'rows'?: Array<ContractModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListContractsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListContractsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListContractsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListContractsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListContractsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCropBudgetExpensesResponse
 */
export interface ListCropBudgetExpensesResponse {
    /**
     * 
     * @type {Array<CropBudgetExpenseModel>}
     * @memberof ListCropBudgetExpensesResponse
     */
    'rows'?: Array<CropBudgetExpenseModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCropBudgetExpensesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCropBudgetExpensesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCropBudgetExpensesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCropBudgetExpensesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCropBudgetExpensesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCropBudgetsResponse
 */
export interface ListCropBudgetsResponse {
    /**
     * 
     * @type {Array<CropBudgetModel>}
     * @memberof ListCropBudgetsResponse
     */
    'rows'?: Array<CropBudgetModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCropBudgetsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCropBudgetsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCropBudgetsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCropBudgetsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCropBudgetsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCropFieldsResponse
 */
export interface ListCropFieldsResponse {
    /**
     * 
     * @type {Array<CropFieldModel>}
     * @memberof ListCropFieldsResponse
     */
    'rows'?: Array<CropFieldModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCropFieldsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCropFieldsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCropFieldsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCropFieldsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCropFieldsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCropPlansResponse
 */
export interface ListCropPlansResponse {
    /**
     * 
     * @type {number}
     * @memberof ListCropPlansResponse
     */
    'totalFarmableAcres'?: number;
    /**
     * 
     * @type {Array<CropPlanModel>}
     * @memberof ListCropPlansResponse
     */
    'rows'?: Array<CropPlanModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCropPlansResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCropPlansResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCropPlansResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCropPlansResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCropPlansResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCropVarietiesResponse
 */
export interface ListCropVarietiesResponse {
    /**
     * 
     * @type {Array<CropVarietiesModel>}
     * @memberof ListCropVarietiesResponse
     */
    'rows'?: Array<CropVarietiesModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCropVarietiesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCropVarietiesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCropVarietiesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCropVarietiesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCropVarietiesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListCurrenciesResponse
 */
export interface ListCurrenciesResponse {
    /**
     * 
     * @type {Array<CurrenciesModel>}
     * @memberof ListCurrenciesResponse
     */
    'rows'?: Array<CurrenciesModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListCurrenciesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCurrenciesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListCurrenciesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListCurrenciesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListCurrenciesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDailyWeatherForecastResponse
 */
export interface ListDailyWeatherForecastResponse {
    /**
     * 
     * @type {Array<DailyWeatherForecastModel>}
     * @memberof ListDailyWeatherForecastResponse
     */
    'rows'?: Array<DailyWeatherForecastModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDailyWeatherForecastResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDailyWeatherForecastResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDailyWeatherForecastResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDailyWeatherForecastResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDailyWeatherForecastResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListDescriptionsResponse
 */
export interface ListDescriptionsResponse {
    /**
     * 
     * @type {Array<DescriptionsModel>}
     * @memberof ListDescriptionsResponse
     */
    'rows'?: Array<DescriptionsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListDescriptionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDescriptionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListDescriptionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListDescriptionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDescriptionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListEndOfDayFertilizerPricesResponse
 */
export interface ListEndOfDayFertilizerPricesResponse {
    /**
     * 
     * @type {Array<FertilizerPriceModel>}
     * @memberof ListEndOfDayFertilizerPricesResponse
     */
    'rows'?: Array<FertilizerPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListEndOfDayFertilizerPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListEndOfDayFertilizerPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListEndOfDayFertilizerPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListEndOfDayFertilizerPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListEndOfDayFertilizerPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListEntitiesResponse
 */
export interface ListEntitiesResponse {
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof ListEntitiesResponse
     */
    'rows'?: Array<{ [key: string]: string; }> | null;
    /**
     * 
     * @type {number}
     * @memberof ListEntitiesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListEntitiesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListEntitiesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListEntitiesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListEntitiesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListExchangesResponse
 */
export interface ListExchangesResponse {
    /**
     * 
     * @type {Array<ExchangeModel>}
     * @memberof ListExchangesResponse
     */
    'rows'?: Array<ExchangeModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListExchangesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListExchangesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListExchangesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListExchangesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListExchangesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListFixedExpensesResponse
 */
export interface ListFixedExpensesResponse {
    /**
     * 
     * @type {number}
     * @memberof ListFixedExpensesResponse
     */
    'totalFarmableAcres'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListFixedExpensesResponse
     */
    'totalBudgetPricePerAcre'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListFixedExpensesResponse
     */
    'totalBudgetPrice'?: number;
    /**
     * 
     * @type {Array<FixedExpenseModel>}
     * @memberof ListFixedExpensesResponse
     */
    'rows'?: Array<FixedExpenseModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListFixedExpensesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListFixedExpensesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListFixedExpensesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListFixedExpensesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListFixedExpensesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListFuturesAccountBuyingPowersResponse
 */
export interface ListFuturesAccountBuyingPowersResponse {
    /**
     * 
     * @type {Array<FuturesBalanceModel>}
     * @memberof ListFuturesAccountBuyingPowersResponse
     */
    'rows'?: Array<FuturesBalanceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListFuturesAccountBuyingPowersResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListFuturesAccountBuyingPowersResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListFuturesAccountBuyingPowersResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListFuturesAccountBuyingPowersResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListFuturesAccountBuyingPowersResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListFuturesContractsResponse
 */
export interface ListFuturesContractsResponse {
    /**
     * 
     * @type {Array<FuturesContractModel>}
     * @memberof ListFuturesContractsResponse
     */
    'rows'?: Array<FuturesContractModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListFuturesContractsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListFuturesContractsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListFuturesContractsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListFuturesContractsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListFuturesContractsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListFuturesHistoricalPricesResponse
 */
export interface ListFuturesHistoricalPricesResponse {
    /**
     * 
     * @type {Array<FuturesHistoricalPriceModel>}
     * @memberof ListFuturesHistoricalPricesResponse
     */
    'rows'?: Array<FuturesHistoricalPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListFuturesHistoricalPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListFuturesHistoricalPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListFuturesHistoricalPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListFuturesHistoricalPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListFuturesHistoricalPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListHistoricalWeatherResponse
 */
export interface ListHistoricalWeatherResponse {
    /**
     * 
     * @type {Array<HistoricalWeatherModel>}
     * @memberof ListHistoricalWeatherResponse
     */
    'rows'?: Array<HistoricalWeatherModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListHistoricalWeatherResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListHistoricalWeatherResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListHistoricalWeatherResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListHistoricalWeatherResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListHistoricalWeatherResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListHourlyWeatherForecastResponse
 */
export interface ListHourlyWeatherForecastResponse {
    /**
     * 
     * @type {Array<HourlyWeatherForecastModel>}
     * @memberof ListHourlyWeatherForecastResponse
     */
    'rows'?: Array<HourlyWeatherForecastModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListHourlyWeatherForecastResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListHourlyWeatherForecastResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListHourlyWeatherForecastResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListHourlyWeatherForecastResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListHourlyWeatherForecastResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListIncomeCalculatorDefinitionsResponse
 */
export interface ListIncomeCalculatorDefinitionsResponse {
    /**
     * 
     * @type {Array<IncomeCalculatorDefinitionModel>}
     * @memberof ListIncomeCalculatorDefinitionsResponse
     */
    'rows'?: Array<IncomeCalculatorDefinitionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListIncomeCalculatorDefinitionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListIncomeCalculatorDefinitionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListIncomeCalculatorDefinitionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListIncomeCalculatorDefinitionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListIncomeCalculatorDefinitionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListIncomeCalculatorsResponse
 */
export interface ListIncomeCalculatorsResponse {
    /**
     * 
     * @type {Array<IncomeCalculatorModel>}
     * @memberof ListIncomeCalculatorsResponse
     */
    'rows'?: Array<IncomeCalculatorModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListIncomeCalculatorsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListIncomeCalculatorsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListIncomeCalculatorsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListIncomeCalculatorsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListIncomeCalculatorsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListKnowRiskAccountsResponse
 */
export interface ListKnowRiskAccountsResponse {
    /**
     * 
     * @type {Array<KnowRiskAccountModel>}
     * @memberof ListKnowRiskAccountsResponse
     */
    'rows'?: Array<KnowRiskAccountModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskAccountsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListKnowRiskAccountsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListKnowRiskAccountsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskAccountsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskAccountsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListKnowRiskFavoritesResponse
 */
export interface ListKnowRiskFavoritesResponse {
    /**
     * 
     * @type {Array<KnowRiskUserFavoriteModel>}
     * @memberof ListKnowRiskFavoritesResponse
     */
    'rows'?: Array<KnowRiskUserFavoriteModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskFavoritesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListKnowRiskFavoritesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListKnowRiskFavoritesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskFavoritesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskFavoritesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListKnowRiskFeaturesResponse
 */
export interface ListKnowRiskFeaturesResponse {
    /**
     * 
     * @type {Array<KnowRiskFeatureModel>}
     * @memberof ListKnowRiskFeaturesResponse
     */
    'rows'?: Array<KnowRiskFeatureModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskFeaturesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListKnowRiskFeaturesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListKnowRiskFeaturesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskFeaturesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskFeaturesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListKnowRiskSymbolsResponse
 */
export interface ListKnowRiskSymbolsResponse {
    /**
     * 
     * @type {Array<KnowRiskSymbolModel>}
     * @memberof ListKnowRiskSymbolsResponse
     */
    'rows'?: Array<KnowRiskSymbolModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskSymbolsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListKnowRiskSymbolsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListKnowRiskSymbolsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskSymbolsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListKnowRiskSymbolsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketIndexValuesResponse
 */
export interface ListMarketIndexValuesResponse {
    /**
     * 
     * @type {Array<MarketIndexValueModel>}
     * @memberof ListMarketIndexValuesResponse
     */
    'rows'?: Array<MarketIndexValueModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndexValuesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketIndexValuesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketIndexValuesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndexValuesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketIndexValuesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPricePositionsResponse
 */
export interface ListMarketPricePositionsResponse {
    /**
     * 
     * @type {Array<MarketPricePositionModel>}
     * @memberof ListMarketPricePositionsResponse
     */
    'rows'?: Array<MarketPricePositionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricePositionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricePositionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPricePositionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricePositionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricePositionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPriceVolatilitiesResponse
 */
export interface ListMarketPriceVolatilitiesResponse {
    /**
     * 
     * @type {Array<MarketPriceVolatilityModel>}
     * @memberof ListMarketPriceVolatilitiesResponse
     */
    'rows'?: Array<MarketPriceVolatilityModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPriceVolatilitiesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPriceVolatilitiesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPriceVolatilitiesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPriceVolatilitiesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPriceVolatilitiesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPricesComparisonChartResponse
 */
export interface ListMarketPricesComparisonChartResponse {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ListMarketPricesComparisonChartResponse
     */
    'reutersInstrumentCodePrefixMap'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<MarketPriceComparisonChartModel>}
     * @memberof ListMarketPricesComparisonChartResponse
     */
    'rows'?: Array<MarketPriceComparisonChartModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesComparisonChartResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesComparisonChartResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPricesComparisonChartResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesComparisonChartResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesComparisonChartResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPricesComparisonResponse
 */
export interface ListMarketPricesComparisonResponse {
    /**
     * 
     * @type {Array<MarketPriceComparisonModel>}
     * @memberof ListMarketPricesComparisonResponse
     */
    'rows'?: Array<MarketPriceComparisonModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesComparisonResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesComparisonResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPricesComparisonResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesComparisonResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesComparisonResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPricesForTradingViewResponse
 */
export interface ListMarketPricesForTradingViewResponse {
    /**
     * 
     * @type {Array<MarketPriceTradingViewModel>}
     * @memberof ListMarketPricesForTradingViewResponse
     */
    'rows'?: Array<MarketPriceTradingViewModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesForTradingViewResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesForTradingViewResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPricesForTradingViewResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesForTradingViewResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesForTradingViewResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPricesForwardCurveResponse
 */
export interface ListMarketPricesForwardCurveResponse {
    /**
     * 
     * @type {Array<MarketPriceModel>}
     * @memberof ListMarketPricesForwardCurveResponse
     */
    'currentMarketPrices'?: Array<MarketPriceModel> | null;
    /**
     * 
     * @type {Array<MarketPriceModel>}
     * @memberof ListMarketPricesForwardCurveResponse
     */
    'asOfDateMarketPrices'?: Array<MarketPriceModel> | null;
    /**
     * 
     * @type {Array<MarketPriceModel>}
     * @memberof ListMarketPricesForwardCurveResponse
     */
    'rows'?: Array<MarketPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesForwardCurveResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesForwardCurveResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPricesForwardCurveResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesForwardCurveResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesForwardCurveResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketPricesResponse
 */
export interface ListMarketPricesResponse {
    /**
     * 
     * @type {Array<MarketPriceModel>}
     * @memberof ListMarketPricesResponse
     */
    'rows'?: Array<MarketPriceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListMarketPricesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketPricesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketPricesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListMarketsResponse
 */
export interface ListMarketsResponse {
    /**
     * 
     * @type {Array<MarketModel>}
     * @memberof ListMarketsResponse
     */
    'rows'?: Array<MarketModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListMarketsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {Array<RegionModel>}
     * @memberof ListMarketsResponse
     */
    'regions'?: Array<RegionModel> | null;
    /**
     * 
     * @type {Array<MarketIntelligenceModel>}
     * @memberof ListMarketsResponse
     */
    'periods'?: Array<MarketIntelligenceModel> | null;
    /**
     * 
     * @type {string}
     * @memberof ListMarketsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListMarketsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListOptionsChainResponse
 */
export interface ListOptionsChainResponse {
    /**
     * 
     * @type {Array<OptionChainModel>}
     * @memberof ListOptionsChainResponse
     */
    'rows'?: Array<OptionChainModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListOptionsChainResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListOptionsChainResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListOptionsChainResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListOptionsChainResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListOptionsChainResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListOptionsExpirationsResponse
 */
export interface ListOptionsExpirationsResponse {
    /**
     * 
     * @type {Array<OptionExpirationsModel>}
     * @memberof ListOptionsExpirationsResponse
     */
    'rows'?: Array<OptionExpirationsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListOptionsExpirationsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListOptionsExpirationsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListOptionsExpirationsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListOptionsExpirationsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListOptionsExpirationsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListPositionDetailsResponse
 */
export interface ListPositionDetailsResponse {
    /**
     * 
     * @type {Array<PositionDetailsModel>}
     * @memberof ListPositionDetailsResponse
     */
    'rows'?: Array<PositionDetailsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListPositionDetailsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListPositionDetailsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListPositionDetailsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListPositionDetailsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListPositionDetailsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListPositionsResponse
 */
export interface ListPositionsResponse {
    /**
     * 
     * @type {Array<PositionsModel>}
     * @memberof ListPositionsResponse
     */
    'rows'?: Array<PositionsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListPositionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListPositionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListPositionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListPositionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListPositionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListPremiumArticlesResponse
 */
export interface ListPremiumArticlesResponse {
    /**
     * 
     * @type {Array<PremiumArticleModel>}
     * @memberof ListPremiumArticlesResponse
     */
    'rows'?: Array<PremiumArticleModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListPremiumArticlesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListPremiumArticlesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListPremiumArticlesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListPremiumArticlesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListPremiumArticlesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListPriorDaysActivityDetailsResponse
 */
export interface ListPriorDaysActivityDetailsResponse {
    /**
     * 
     * @type {Array<PriorDaysActivityDetailsModel>}
     * @memberof ListPriorDaysActivityDetailsResponse
     */
    'rows'?: Array<PriorDaysActivityDetailsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListPriorDaysActivityDetailsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListPriorDaysActivityDetailsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListPriorDaysActivityDetailsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListPriorDaysActivityDetailsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListPriorDaysActivityDetailsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListPriorDaysActivityResponse
 */
export interface ListPriorDaysActivityResponse {
    /**
     * 
     * @type {Array<PriorDaysActivityModel>}
     * @memberof ListPriorDaysActivityResponse
     */
    'rows'?: Array<PriorDaysActivityModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListPriorDaysActivityResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListPriorDaysActivityResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListPriorDaysActivityResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListPriorDaysActivityResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListPriorDaysActivityResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListProductsResponse
 */
export interface ListProductsResponse {
    /**
     * 
     * @type {Array<ProductModel>}
     * @memberof ListProductsResponse
     */
    'rows'?: Array<ProductModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListProductsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListProductsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListProductsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListProductsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListProductsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListRadarIntervalVersionsResponse
 */
export interface ListRadarIntervalVersionsResponse {
    /**
     * 
     * @type {ZoomMetadataModel}
     * @memberof ListRadarIntervalVersionsResponse
     */
    'zoomMetadata'?: ZoomMetadataModel;
    /**
     * 
     * @type {Array<VersionModel>}
     * @memberof ListRadarIntervalVersionsResponse
     */
    'versions'?: Array<VersionModel> | null;
}
/**
 * 
 * @export
 * @interface ListRadarTimesResponse
 */
export interface ListRadarTimesResponse {
    /**
     * 
     * @type {Array<RadarTimeModel>}
     * @memberof ListRadarTimesResponse
     */
    'rows'?: Array<RadarTimeModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListRadarTimesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListRadarTimesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListRadarTimesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListRadarTimesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListRadarTimesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListRealizedGainsAndLossesDetailsResponse
 */
export interface ListRealizedGainsAndLossesDetailsResponse {
    /**
     * 
     * @type {Array<RealizedGainsAndLossesDetailsModel>}
     * @memberof ListRealizedGainsAndLossesDetailsResponse
     */
    'rows'?: Array<RealizedGainsAndLossesDetailsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListRealizedGainsAndLossesDetailsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListRealizedGainsAndLossesDetailsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListRealizedGainsAndLossesDetailsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListRealizedGainsAndLossesDetailsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListRealizedGainsAndLossesDetailsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListRealizedGainsAndLossesResponse
 */
export interface ListRealizedGainsAndLossesResponse {
    /**
     * 
     * @type {Array<RealizedGainsAndLossesModel>}
     * @memberof ListRealizedGainsAndLossesResponse
     */
    'rows'?: Array<RealizedGainsAndLossesModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListRealizedGainsAndLossesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListRealizedGainsAndLossesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListRealizedGainsAndLossesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListRealizedGainsAndLossesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListRealizedGainsAndLossesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListReutersInstrumentCodesForAccountResponse
 */
export interface ListReutersInstrumentCodesForAccountResponse {
    /**
     * 
     * @type {Array<ReutersInstrumentCodeForAccountModel>}
     * @memberof ListReutersInstrumentCodesForAccountResponse
     */
    'rows'?: Array<ReutersInstrumentCodeForAccountModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListReutersInstrumentCodesForAccountResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListReutersInstrumentCodesForAccountResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListReutersInstrumentCodesForAccountResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListReutersInstrumentCodesForAccountResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListReutersInstrumentCodesForAccountResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListReutersInstrumentCodesResponse
 */
export interface ListReutersInstrumentCodesResponse {
    /**
     * 
     * @type {Array<ReutersInstrumentCodeModel>}
     * @memberof ListReutersInstrumentCodesResponse
     */
    'rows'?: Array<ReutersInstrumentCodeModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListReutersInstrumentCodesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListReutersInstrumentCodesResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListReutersInstrumentCodesResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListReutersInstrumentCodesResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListReutersInstrumentCodesResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListSearchAccountsResponse
 */
export interface ListSearchAccountsResponse {
    /**
     * 
     * @type {Array<SearchAccountModel>}
     * @memberof ListSearchAccountsResponse
     */
    'rows'?: Array<SearchAccountModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListSearchAccountsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListSearchAccountsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListSearchAccountsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListSearchAccountsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListSearchAccountsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListStoneXCommoditySolutionsContractPricingsResponse
 */
export interface ListStoneXCommoditySolutionsContractPricingsResponse {
    /**
     * 
     * @type {Array<StoneXCommoditySolutionsContractPricingsModel>}
     * @memberof ListStoneXCommoditySolutionsContractPricingsResponse
     */
    'rows'?: Array<StoneXCommoditySolutionsContractPricingsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsContractPricingsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListStoneXCommoditySolutionsContractPricingsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {string}
     * @memberof ListStoneXCommoditySolutionsContractPricingsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsContractPricingsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsContractPricingsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListStoneXCommoditySolutionsContractsResponse
 */
export interface ListStoneXCommoditySolutionsContractsResponse {
    /**
     * 
     * @type {Array<StoneXCommoditySolutionsContractModel>}
     * @memberof ListStoneXCommoditySolutionsContractsResponse
     */
    'rows'?: Array<StoneXCommoditySolutionsContractModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsContractsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsContractsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsContractsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListStoneXCommoditySolutionsCustomersResponse
 */
export interface ListStoneXCommoditySolutionsCustomersResponse {
    /**
     * 
     * @type {Array<StoneXCommoditySolutionsCustomerModel>}
     * @memberof ListStoneXCommoditySolutionsCustomersResponse
     */
    'rows'?: Array<StoneXCommoditySolutionsCustomerModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsCustomersResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListStoneXCommoditySolutionsCustomersResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {string}
     * @memberof ListStoneXCommoditySolutionsCustomersResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsCustomersResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsCustomersResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListStoneXCommoditySolutionsUnderliersResponse
 */
export interface ListStoneXCommoditySolutionsUnderliersResponse {
    /**
     * 
     * @type {Array<StoneXCommoditySolutionsUnderlierModel>}
     * @memberof ListStoneXCommoditySolutionsUnderliersResponse
     */
    'rows'?: Array<StoneXCommoditySolutionsUnderlierModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsUnderliersResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListStoneXCommoditySolutionsUnderliersResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListStoneXCommoditySolutionsUnderliersResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsUnderliersResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListStoneXCommoditySolutionsUnderliersResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListSubscriptionsResponse
 */
export interface ListSubscriptionsResponse {
    /**
     * 
     * @type {Array<SubscriptionModel>}
     * @memberof ListSubscriptionsResponse
     */
    'rows'?: Array<SubscriptionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ListSubscriptionsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListSubscriptionsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListSubscriptionsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListSubscriptionsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListSubscriptionsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListTicketsResponse
 */
export interface ListTicketsResponse {
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof ListTicketsResponse
     */
    'rows'?: Array<Ticket> | null;
    /**
     * 
     * @type {number}
     * @memberof ListTicketsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListTicketsResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListTicketsResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListTicketsResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListTicketsResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListWatchListResponse
 */
export interface ListWatchListResponse {
    /**
     * 
     * @type {Array<WatchListModel>}
     * @memberof ListWatchListResponse
     */
    'rows'?: Array<WatchListModel> | null;
    /**
     * 
     * @type {string}
     * @memberof ListWatchListResponse
     */
    'sortColumn'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof ListWatchListResponse
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {number}
     * @memberof ListWatchListResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListWatchListResponse
     */
    'take'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListWatchListResponse
     */
    'skip'?: number;
}
/**
 * 
 * @export
 * @interface ListWeatherVideoResponse
 */
export interface ListWeatherVideoResponse {
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'longRangeWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'inWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'ilWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'ohWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'iaWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'neWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'moWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'sdWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'ndWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'mnWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'miWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'ksWeatherVideo'?: WeatherVideoModel;
    /**
     * 
     * @type {WeatherVideoModel}
     * @memberof ListWeatherVideoResponse
     */
    'wiWeatherVideo'?: WeatherVideoModel;
}
/**
 * 
 * @export
 * @interface LocalVideoModel
 */
export interface LocalVideoModel {
    /**
     * 
     * @type {string}
     * @memberof LocalVideoModel
     */
    'markdown'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocalVideoModel
     */
    'headLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocalVideoModel
     */
    'videoUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface LongRangeVideoModel
 */
export interface LongRangeVideoModel {
    /**
     * 
     * @type {string}
     * @memberof LongRangeVideoModel
     */
    'videoUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongRangeVideoModel
     */
    'lastUpdatedDate'?: string | null;
}
/**
 * 
 * @export
 * @interface MarketIndexValueModel
 */
export interface MarketIndexValueModel {
    /**
     * 
     * @type {string}
     * @memberof MarketIndexValueModel
     */
    'reutersInstrumentCode': string;
    /**
     * 
     * @type {number}
     * @memberof MarketIndexValueModel
     */
    'value'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIndexValueModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface MarketIntelligenceModel
 */
export interface MarketIntelligenceModel {
    /**
     * 
     * @type {number}
     * @memberof MarketIntelligenceModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MarketIntelligenceModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketIntelligenceSubscriptionCountry = {
    Brazil: 'Brazil',
    Canada: 'Canada',
    Europe: 'Europe',
    Paraguay: 'Paraguay',
    UnitedStates: 'UnitedStates'
} as const;

export type MarketIntelligenceSubscriptionCountry = typeof MarketIntelligenceSubscriptionCountry[keyof typeof MarketIntelligenceSubscriptionCountry];


/**
 * 
 * @export
 * @interface MarketIntelligenceUserModel
 */
export interface MarketIntelligenceUserModel {
    /**
     * 
     * @type {string}
     * @memberof MarketIntelligenceUserModel
     */
    'marketIntelligenceUserId': string;
    /**
     * 
     * @type {string}
     * @memberof MarketIntelligenceUserModel
     */
    'userGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIntelligenceUserModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof MarketIntelligenceUserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketIntelligenceUserModel
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface MarketModel
 */
export interface MarketModel {
    /**
     * 
     * @type {string}
     * @memberof MarketModel
     */
    'iconUrl'?: string | null;
    /**
     * 
     * @type {Array<MarketIntelligenceModel>}
     * @memberof MarketModel
     */
    'commodities': Array<MarketIntelligenceModel>;
    /**
     * 
     * @type {number}
     * @memberof MarketModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MarketModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MarketPriceComparisonChartModel
 */
export interface MarketPriceComparisonChartModel {
    /**
     * 
     * @type {string}
     * @memberof MarketPriceComparisonChartModel
     */
    'reutersInstrumentCodePrefix1': string;
    /**
     * 
     * @type {string}
     * @memberof MarketPriceComparisonChartModel
     */
    'reutersInstrumentCodePrefix2': string;
}
/**
 * 
 * @export
 * @interface MarketPriceComparisonModel
 */
export interface MarketPriceComparisonModel {
    /**
     * 
     * @type {string}
     * @memberof MarketPriceComparisonModel
     */
    'reutersInstrumentCode1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketPriceComparisonModel
     */
    'reutersInstrumentCode2'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceComparisonModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceComparisonModel
     */
    'contractYear': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceComparisonModel
     */
    'settlementPrice1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceComparisonModel
     */
    'settlementPrice2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceComparisonModel
     */
    'difference'?: number | null;
}
/**
 * 
 * @export
 * @interface MarketPriceModel
 */
export interface MarketPriceModel {
    /**
     * 
     * @type {string}
     * @memberof MarketPriceModel
     */
    'reutersInstrumentCode': string;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'openPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'closePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'settlementPrice': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'highPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'lowPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'volume'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceModel
     */
    'contractYear': number;
    /**
     * 
     * @type {string}
     * @memberof MarketPriceModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface MarketPricePositionModel
 */
export interface MarketPricePositionModel {
    /**
     * 
     * @type {string}
     * @memberof MarketPricePositionModel
     */
    'reutersInstrumentCodePrefix': string;
    /**
     * 
     * @type {number}
     * @memberof MarketPricePositionModel
     */
    'openInterest': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPricePositionModel
     */
    'longPosition': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPricePositionModel
     */
    'shortPosition': number;
    /**
     * 
     * @type {string}
     * @memberof MarketPricePositionModel
     */
    'asOfDate': string;
}
/**
 * 
 * @export
 * @interface MarketPriceTradingViewModel
 */
export interface MarketPriceTradingViewModel {
    /**
     * 
     * @type {string}
     * @memberof MarketPriceTradingViewModel
     */
    'asOfDate': string;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceTradingViewModel
     */
    'time': number;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceTradingViewModel
     */
    'open'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceTradingViewModel
     */
    'high'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceTradingViewModel
     */
    'low'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceTradingViewModel
     */
    'close'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceTradingViewModel
     */
    'volume'?: number | null;
}
/**
 * 
 * @export
 * @interface MarketPriceVolatilityModel
 */
export interface MarketPriceVolatilityModel {
    /**
     * 
     * @type {MarketPricesTimeSpan}
     * @memberof MarketPriceVolatilityModel
     */
    'timeSpan': MarketPricesTimeSpan;
    /**
     * 
     * @type {number}
     * @memberof MarketPriceVolatilityModel
     */
    'volatilityPercent': number;
    /**
     * 
     * @type {boolean}
     * @memberof MarketPriceVolatilityModel
     */
    'isImpliedVolatility': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketPricesTimeSpan = {
    OneDay: 'OneDay',
    FiveDays: 'FiveDays',
    OneMonth: 'OneMonth',
    ThirtyDays: 'ThirtyDays',
    ThreeMonths: 'ThreeMonths',
    SixMonths: 'SixMonths',
    NineMonths: 'NineMonths',
    OneYear: 'OneYear',
    TwoYears: 'TwoYears',
    ThreeYears: 'ThreeYears',
    FourYears: 'FourYears',
    FiveYears: 'FiveYears',
    EightYears: 'EightYears',
    TenYears: 'TenYears',
    FifteenYears: 'FifteenYears',
    TwentyYears: 'TwentyYears',
    All: 'All'
} as const;

export type MarketPricesTimeSpan = typeof MarketPricesTimeSpan[keyof typeof MarketPricesTimeSpan];


/**
 * 
 * @export
 * @enum {string}
 */

export const Meridian = {
    FirstPrincipal: 'FirstPrincipal',
    SecondPrincipal: 'SecondPrincipal',
    ThirdPrincipal: 'ThirdPrincipal',
    FourthPrincipal: 'FourthPrincipal',
    FifthPrincipal: 'FifthPrincipal',
    SixthPrincipal: 'SixthPrincipal',
    BlackHills: 'BlackHills',
    Boise: 'Boise',
    Chickasaw: 'Chickasaw',
    Choctaw: 'Choctaw',
    Cimarron: 'Cimarron',
    GilaSaltRiver: 'GilaSaltRiver',
    Humboldt: 'Humboldt',
    Huntsville: 'Huntsville',
    Indian: 'Indian',
    Louisiana: 'Louisiana',
    Michigan: 'Michigan',
    PrincipalMt: 'PrincipalMt',
    MountDiablo: 'MountDiablo',
    NewMexico: 'NewMexico',
    StHelena: 'StHelena',
    StStephens: 'StStephens',
    SaltLake: 'SaltLake',
    SanBernardino: 'SanBernardino',
    Tallahassee: 'Tallahassee',
    UintahSpec: 'UintahSpec',
    Ute: 'Ute',
    Washington: 'Washington',
    Willamette: 'Willamette',
    WindRiver: 'WindRiver',
    OhioRiverSurvey: 'OhioRiverSurvey',
    BetweenTheMiamis: 'BetweenTheMiamis',
    FirstSciotoRiverBase: 'FirstSciotoRiverBase',
    SecondSciotoRiverBase: 'SecondSciotoRiverBase',
    FourthPrincipleMnWi: 'FourthPrincipleMnWi',
    WestOfGreatMiami: 'WestOfGreatMiami',
    UsMilitarySurvey: 'UsMilitarySurvey',
    CtWesternResOh: 'CtWesternResOh',
    Delaware: 'Delaware',
    Georgia: 'Georgia',
    Kentucky: 'Kentucky',
    Maryland: 'Maryland',
    Maine: 'Maine',
    NorthCarolina: 'NorthCarolina',
    NewHampshire: 'NewHampshire',
    NewJersey: 'NewJersey',
    NewYork: 'NewYork',
    Ohio: 'Ohio',
    Pennsylvania: 'Pennsylvania',
    SouthCarolina: 'SouthCarolina',
    Tennessee: 'Tennessee',
    Texas: 'Texas',
    Virginia: 'Virginia',
    Vermont: 'Vermont',
    WestVirginia: 'WestVirginia'
} as const;

export type Meridian = typeof Meridian[keyof typeof Meridian];


/**
 * 
 * @export
 * @enum {string}
 */

export const MobileApplicationCountry = {
    Brazil: 'Brazil',
    Canada: 'Canada',
    Europe: 'Europe',
    UnitedKingdom: 'UnitedKingdom',
    UnitedStates: 'UnitedStates',
    Paraguay: 'Paraguay'
} as const;

export type MobileApplicationCountry = typeof MobileApplicationCountry[keyof typeof MobileApplicationCountry];


/**
 * 
 * @export
 * @interface OptionChainModel
 */
export interface OptionChainModel {
    /**
     * 
     * @type {string}
     * @memberof OptionChainModel
     */
    'gainFuturesCode': string;
    /**
     * 
     * @type {string}
     * @memberof OptionChainModel
     */
    'underlyingGainFuturesCode': string;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'contractYear': number;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'contractMonth': number;
    /**
     * 
     * @type {OptionType}
     * @memberof OptionChainModel
     */
    'optionType': OptionType;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'strikePrice': number;
    /**
     * 
     * @type {string}
     * @memberof OptionChainModel
     */
    'expirationDate': string;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'latestPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'bidPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'askPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'openPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'highPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'lowPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'netChange'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'percentChange'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OptionChainModel
     */
    'asOfDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'impliedVolatility'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'vega'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'theta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'gamma'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'delta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionChainModel
     */
    'theoreticalPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface OptionExpirationsModel
 */
export interface OptionExpirationsModel {
    /**
     * 
     * @type {string}
     * @memberof OptionExpirationsModel
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof OptionExpirationsModel
     */
    'gainFuturesCodePrefix': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OptionType = {
    All: 'All',
    Call: 'Call',
    Put: 'Put'
} as const;

export type OptionType = typeof OptionType[keyof typeof OptionType];


/**
 * 
 * @export
 * @interface PositionDetailsModel
 */
export interface PositionDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'accountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'product': string;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'productDisplayName'?: string | null;
    /**
     * 
     * @type {SecurityType}
     * @memberof PositionDetailsModel
     */
    'instrumentType'?: SecurityType;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'contactYear': number;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'contactMonth': number;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'contactDay'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'tradeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'globalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'tradeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'quantityLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'quantityShort'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'totalPriceLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'totalPriceShort'?: number | null;
    /**
     * 
     * @type {PutOrCall}
     * @memberof PositionDetailsModel
     */
    'putOrCall'?: PutOrCall;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'strikePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'triggerBarrier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'tradePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'settlementPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'settlementPriceCurrency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'signedDelta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'baseCurrency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'tradeCurrency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'profitAndLossAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'masterInstrumentExchangeSymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'masterMic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'futuresLastTradeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PositionDetailsModel
     */
    'contractSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PositionDetailsModel
     */
    'tickerSearchCompositeKey'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof PositionDetailsModel
     */
    'sourceSystem': SourceSystem;
}
/**
 * 
 * @export
 * @interface PositionsModel
 */
export interface PositionsModel {
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'id': string;
    /**
     * 
     * @type {SecurityType}
     * @memberof PositionsModel
     */
    'securityType'?: SecurityType;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'product': string;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'productDisplayName'?: string | null;
    /**
     * 
     * @type {SecurityType}
     * @memberof PositionsModel
     */
    'instrumentType'?: SecurityType;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'exchangeName': string;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'exchangeNameShort': string;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'contractYearMonthDay'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'contactYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'contactMonth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'contactDay'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'quantityLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'quantityShort'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'quantityNet'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'averagePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'averageLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'averageShort'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'totalPriceLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'totalPriceShort'?: number | null;
    /**
     * 
     * @type {PutOrCall}
     * @memberof PositionsModel
     */
    'putOrCall'?: PutOrCall;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'strikePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'triggerBarrier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'signedDelta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'combinedOptionDelta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'settlementPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'settlementPriceCurrency'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PositionsModel
     */
    'hasMarketPrice'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'tradeCurrency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'profitAndLossAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'profitAndLossAmountCurrency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'rowCount': number;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'masterInstrumentExchangeSymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'masterMic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'futuresLastTradeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PositionsModel
     */
    'contractSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'tickerSearchCompositeKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'reutersInstrumentCode'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof PositionsModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof PositionsModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface PremiumArticleModel
 */
export interface PremiumArticleModel {
    /**
     * 
     * @type {string}
     * @memberof PremiumArticleModel
     */
    'premiumArticleGuid': string;
    /**
     * 
     * @type {string}
     * @memberof PremiumArticleModel
     */
    'premiumArticleContentId': string;
}
/**
 * 
 * @export
 * @interface PriorDaysActivityDetailsModel
 */
export interface PriorDaysActivityDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'accountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'product': string;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'productDisplayName'?: string | null;
    /**
     * 
     * @type {SecurityType}
     * @memberof PriorDaysActivityDetailsModel
     */
    'securityType'?: SecurityType;
    /**
     * 
     * @type {SecurityType}
     * @memberof PriorDaysActivityDetailsModel
     */
    'instrumentType'?: SecurityType;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'symbolContractYearMonthDay': string;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'contactYear': number;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'contactMonth': number;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'contactDay'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'tradeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'globalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'tradeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'quantityLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'quantityShort'?: number | null;
    /**
     * 
     * @type {PutOrCall}
     * @memberof PriorDaysActivityDetailsModel
     */
    'putOrCall'?: PutOrCall;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'strikePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'triggerBarrier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'tradePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'settlementPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'signedDelta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'baseCurrency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'tradeCurrency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'commissions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityDetailsModel
     */
    'commissionCurrency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityDetailsModel
     */
    'profitAndLossAmount'?: number | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof PriorDaysActivityDetailsModel
     */
    'sourceSystem': SourceSystem;
}
/**
 * 
 * @export
 * @interface PriorDaysActivityModel
 */
export interface PriorDaysActivityModel {
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityModel
     */
    'id': string;
    /**
     * 
     * @type {SecurityType}
     * @memberof PriorDaysActivityModel
     */
    'securityType'?: SecurityType;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityModel
     */
    'product': string;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityModel
     */
    'productDisplayName'?: string | null;
    /**
     * 
     * @type {SecurityType}
     * @memberof PriorDaysActivityModel
     */
    'instrumentType'?: SecurityType;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityModel
     */
    'exchangeName': string;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityModel
     */
    'exchangeNameShort': string;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityModel
     */
    'contractYearMonthDay'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'contactYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'contactMonth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'contactDay'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'quantityLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'quantityShort'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'quantityNet'?: number | null;
    /**
     * 
     * @type {PutOrCall}
     * @memberof PriorDaysActivityModel
     */
    'putOrCall'?: PutOrCall;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'strikePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'triggerBarrier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'combinedOptionDelta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'averageLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'averageShort'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'settlementPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityModel
     */
    'settlementPriceCurrency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityModel
     */
    'tradeCurrency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'profitAndLossAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityModel
     */
    'profitAndLossAmountCurrency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'averagePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriorDaysActivityModel
     */
    'signedDelta'?: number | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof PriorDaysActivityModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof PriorDaysActivityModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'product'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof ProductModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PutOrCall = {
    Put: 'PUT',
    Call: 'CALL'
} as const;

export type PutOrCall = typeof PutOrCall[keyof typeof PutOrCall];


/**
 * 
 * @export
 * @interface RadarTimeModel
 */
export interface RadarTimeModel {
    /**
     * 
     * @type {string}
     * @memberof RadarTimeModel
     */
    'radarTime': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RangeCardinalDirection = {
    East: 'East',
    West: 'West'
} as const;

export type RangeCardinalDirection = typeof RangeCardinalDirection[keyof typeof RangeCardinalDirection];


/**
 * 
 * @export
 * @interface RealizedGainsAndLossesDetailsModel
 */
export interface RealizedGainsAndLossesDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'accountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'tradeDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'quantityLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'quantityShort'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'exchangeName': string;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'exchangeNameShort': string;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'strikePrice'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'tradePrice'?: number | null;
    /**
     * 
     * @type {PutOrCall}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'putOrCall'?: PutOrCall;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'debitOrCredit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'charges'?: number | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof RealizedGainsAndLossesDetailsModel
     */
    'sourceSystem': SourceSystem;
}
/**
 * 
 * @export
 * @interface RealizedGainsAndLossesModel
 */
export interface RealizedGainsAndLossesModel {
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesModel
     */
    'masterAccountName': string;
    /**
     * 
     * @type {SecurityType}
     * @memberof RealizedGainsAndLossesModel
     */
    'instrumentType'?: SecurityType;
    /**
     * 
     * @type {SecurityType}
     * @memberof RealizedGainsAndLossesModel
     */
    'securityType'?: SecurityType;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'quantityLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'quantityShort'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'quantityNet'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesModel
     */
    'exchangeName': string;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesModel
     */
    'exchangeNameShort': string;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesModel
     */
    'product': string;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesModel
     */
    'contractYearMonthDay'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'contactYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'contactMonth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'contactDay'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'strikePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'averageLong'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'averageShort'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'averagePrice'?: number | null;
    /**
     * 
     * @type {PutOrCall}
     * @memberof RealizedGainsAndLossesModel
     */
    'putOrCall'?: PutOrCall;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesModel
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'debitOrCredit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedGainsAndLossesModel
     */
    'charges'?: number | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof RealizedGainsAndLossesModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof RealizedGainsAndLossesModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface RegionModel
 */
export interface RegionModel {
    /**
     * 
     * @type {Array<RegionModel>}
     * @memberof RegionModel
     */
    'regions': Array<RegionModel>;
    /**
     * 
     * @type {number}
     * @memberof RegionModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RegionModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface RegisterUserForZohoRequest
 */
export interface RegisterUserForZohoRequest {
    /**
     * 
     * @type {Application}
     * @memberof RegisterUserForZohoRequest
     */
    'application': Application;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserForZohoRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserForZohoRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserForZohoRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserForZohoRequest
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserForZohoRequest
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserForZohoRequest
     */
    'country': string;
    /**
     * 
     * @type {number}
     * @memberof RegisterUserForZohoRequest
     */
    'trialDays': number;
}
/**
 * 
 * @export
 * @interface RegisterUserForZohoResponse
 */
export interface RegisterUserForZohoResponse {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserForZohoResponse
     */
    'redirectUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterUserRequest
 */
export interface RegisterUserRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'city'?: string | null;
    /**
     * 
     * @type {State}
     * @memberof RegisterUserRequest
     */
    'state'?: State;
    /**
     * 
     * @type {Country}
     * @memberof RegisterUserRequest
     */
    'country'?: Country;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'promotionalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'password'?: string | null;
    /**
     * 
     * @type {Application}
     * @memberof RegisterUserRequest
     */
    'application'?: Application;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'subApplication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    'buildNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterUserResponse
 */
export interface RegisterUserResponse {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserResponse
     */
    'contactId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserResponse
     */
    'userGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RegisterUserResponse
     */
    'productId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserResponse
     */
    'requestedDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RegisterUserResponse
     */
    'registrationStatusId'?: number | null;
    /**
     * 
     * @type {RegistrationStatus}
     * @memberof RegisterUserResponse
     */
    'registrationStatus'?: RegistrationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterUserResponse
     */
    'isPreviouslyRegistered'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RegistrationStatus = {
    InProcess: 'InProcess',
    Invited: 'Invited',
    Denied: 'Denied',
    Activated: 'Activated',
    Removed: 'Removed',
    PendingDeactivate: 'PendingDeactivate'
} as const;

export type RegistrationStatus = typeof RegistrationStatus[keyof typeof RegistrationStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const RegistrationType = {
    Deactivated: 'Deactivated',
    Premium: 'Premium',
    PremiumCanceled: 'PremiumCanceled',
    Trial: 'Trial',
    TrialExpired: 'TrialExpired'
} as const;

export type RegistrationType = typeof RegistrationType[keyof typeof RegistrationType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ReportType = {
    CashActivity: 'CashActivity',
    Positions: 'Positions',
    PriorDaysActivity: 'PriorDaysActivity',
    RealizedGainsAndLosses: 'RealizedGainsAndLosses'
} as const;

export type ReportType = typeof ReportType[keyof typeof ReportType];


/**
 * 
 * @export
 * @interface RetireCropFieldRequest
 */
export interface RetireCropFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof RetireCropFieldRequest
     */
    'cropFieldGuid': string;
    /**
     * 
     * @type {string}
     * @memberof RetireCropFieldRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {boolean}
     * @memberof RetireCropFieldRequest
     */
    'isRetired': boolean;
    /**
     * 
     * @type {number}
     * @memberof RetireCropFieldRequest
     */
    'endYear'?: number | null;
}
/**
 * 
 * @export
 * @interface ReutersInstrumentCodeForAccountModel
 */
export interface ReutersInstrumentCodeForAccountModel {
    /**
     * 
     * @type {SecurityType}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'securityType'?: SecurityType;
    /**
     * 
     * @type {SecurityType}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'instrumentType'?: SecurityType;
    /**
     * 
     * @type {PutOrCall}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'putOrCall'?: PutOrCall;
    /**
     * 
     * @type {number}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'strikePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'settlementPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'masterInstrumentExchangeSymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'masterMic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'futuresLastTradeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'contractSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'reutersInstrumentCode': string;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'tickerSearchCompositeKey'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'sourceSystem': SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeForAccountModel
     */
    'accountNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface ReutersInstrumentCodeModel
 */
export interface ReutersInstrumentCodeModel {
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'contractSegment': string;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'contractName': string;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'contractMonthYear'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'tickerSearchCompositeKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'reutersInstrumentCode': string;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'reutersInstrumentCodePrefix': string;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'exchangeCode': string;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'exchangePrefix': string;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'firstNoticeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'firstTradeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReutersInstrumentCodeModel
     */
    'expirationDate'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchAccountModel
 */
export interface SearchAccountModel {
    /**
     * 
     * @type {Array<SearchAccountModel>}
     * @memberof SearchAccountModel
     */
    'SubAccounts': Array<SearchAccountModel>;
    /**
     * 
     * @type {number}
     * @memberof SearchAccountModel
     */
    'AccountBalance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAccountModel
     */
    'IntradayNetLiquidationValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAccountModel
     */
    'netLiquidationValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAccountModel
     */
    'MarginCall'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAccountModel
     */
    'MarginCallAge'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAccountModel
     */
    'accountName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAccountModel
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SearchAccountModel
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAccountModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {SourceSystem}
     * @memberof SearchAccountModel
     */
    'backOfficeSystem'?: SourceSystem;
    /**
     * 
     * @type {SourceSystem}
     * @memberof SearchAccountModel
     */
    'sourceSystem'?: SourceSystem;
    /**
     * 
     * @type {string}
     * @memberof SearchAccountModel
     */
    'brokerCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAccountModel
     */
    'firm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAccountModel
     */
    'groupAccountNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAccountModel
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAccountModel
     */
    'isErrorAccount': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAccountModel
     */
    'isGroupAccount': boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchAccountModel
     */
    'office'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAccountModel
     */
    'isQueryMatch': boolean;
    /**
     * 
     * @type {Array<CodexAccountModel>}
     * @memberof SearchAccountModel
     */
    'subAccounts': Array<CodexAccountModel>;
}
/**
 * 
 * @export
 * @interface SecondaryMarketModel
 */
export interface SecondaryMarketModel {
    /**
     * 
     * @type {string}
     * @memberof SecondaryMarketModel
     */
    'marketId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SecondaryMarketModel
     */
    'marketName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SecondaryMarketModel
     */
    'marketIconUrl'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SecurityType = {
    Futures: 'Futures',
    Option: 'Option',
    Swap: 'Swap',
    Exotic: 'Exotic'
} as const;

export type SecurityType = typeof SecurityType[keyof typeof SecurityType];


/**
 * 
 * @export
 * @interface SendSupportEmailRequest
 */
export interface SendSupportEmailRequest {
    /**
     * 
     * @type {SupportType}
     * @memberof SendSupportEmailRequest
     */
    'type'?: SupportType;
    /**
     * 
     * @type {SupportType}
     * @memberof SendSupportEmailRequest
     */
    'supportType'?: SupportType;
    /**
     * 
     * @type {string}
     * @memberof SendSupportEmailRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendSupportEmailRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendSupportEmailRequest
     * @deprecated
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendSupportEmailRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendSupportEmailRequest
     */
    'description': string;
    /**
     * 
     * @type {Application}
     * @memberof SendSupportEmailRequest
     */
    'application'?: Application;
    /**
     * 
     * @type {MobileApplicationCountry}
     * @memberof SendSupportEmailRequest
     */
    'mobileApplicationCountry'?: MobileApplicationCountry;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SortDirection = {
    Asc: 'Asc',
    Desc: 'Desc',
    None: 'None'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];


/**
 * 
 * @export
 * @enum {string}
 */

export const SourceSystem = {
    Gmi: 'GMI',
    Xtp: 'XTP',
    Murex: 'Murex',
    Janus: 'JANUS',
    Mdhub: 'MDHUB',
    Eclipse: 'Eclipse'
} as const;

export type SourceSystem = typeof SourceSystem[keyof typeof SourceSystem];


/**
 * 
 * @export
 * @enum {string}
 */

export const State = {
    Al: 'AL',
    Ak: 'AK',
    Ar: 'AR',
    Az: 'AZ',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    Dc: 'DC',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Ia: 'IA',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Ma: 'MA',
    Md: 'MD',
    Me: 'ME',
    Mi: 'MI',
    Mn: 'MN',
    Mo: 'MO',
    Ms: 'MS',
    Mt: 'MT',
    Nc: 'NC',
    Nd: 'ND',
    Ne: 'NE',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Nv: 'NV',
    Ny: 'NY',
    Ok: 'OK',
    Oh: 'OH',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Va: 'VA',
    Vt: 'VT',
    Wa: 'WA',
    Wi: 'WI',
    Wv: 'WV',
    Wy: 'WY',
    Pr: 'PR',
    As: 'AS',
    Gu: 'GU',
    Mp: 'MP',
    Vi: 'VI',
    Ab: 'AB',
    Bc: 'BC',
    Mb: 'MB',
    Nb: 'NB',
    Nl: 'NL',
    Nt: 'NT',
    Ns: 'NS',
    Nu: 'NU',
    On: 'ON',
    Pe: 'PE',
    Qc: 'QC',
    Sk: 'SK',
    Yt: 'YT'
} as const;

export type State = typeof State[keyof typeof State];


/**
 * 
 * @export
 * @enum {string}
 */

export const StatementType = {
    Daily: 'Daily',
    Monthly: 'Monthly'
} as const;

export type StatementType = typeof StatementType[keyof typeof StatementType];


/**
 * 
 * @export
 * @interface StoneXCommoditySolutionsContractModel
 */
export interface StoneXCommoditySolutionsContractModel {
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'contractVersionId': string;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'contractId': string;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'created': string;
    /**
     * 
     * @type {StoneXCommoditySolutionsContractStatus}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'status'?: StoneXCommoditySolutionsContractStatus;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'grainWorxId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'grainWorxVersionId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'contractDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'underlier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'unitsTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'purchasePriceTypeCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'basis'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'futuresPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'grainWorxContractPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'incoTermName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'customerContractNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'volume': number;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'unPriced': number;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractModel
     */
    'unApplied': number;
}
/**
 * 
 * @export
 * @interface StoneXCommoditySolutionsContractPricingsModel
 */
export interface StoneXCommoditySolutionsContractPricingsModel {
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractPricingsModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractPricingsModel
     */
    'volume': number;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsContractPricingsModel
     */
    'pricingDate': string;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractPricingsModel
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractPricingsModel
     */
    'basis': number;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractPricingsModel
     */
    'futuresPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractPricingsModel
     */
    'unapplied': number;
    /**
     * 
     * @type {number}
     * @memberof StoneXCommoditySolutionsContractPricingsModel
     */
    'appliedVolume': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StoneXCommoditySolutionsContractStatus = {
    Active: 'Active',
    Completed: 'Completed',
    Deleted: 'Deleted'
} as const;

export type StoneXCommoditySolutionsContractStatus = typeof StoneXCommoditySolutionsContractStatus[keyof typeof StoneXCommoditySolutionsContractStatus];


/**
 * 
 * @export
 * @interface StoneXCommoditySolutionsCustomerModel
 */
export interface StoneXCommoditySolutionsCustomerModel {
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsCustomerModel
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsCustomerModel
     */
    'customerName': string;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsCustomerModel
     */
    'customerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsCustomerModel
     */
    'grainWorxCustomerId': string;
    /**
     * 
     * @type {boolean}
     * @memberof StoneXCommoditySolutionsCustomerModel
     */
    'isVendor'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StoneXCommoditySolutionsCustomerModel
     */
    'isActive'?: boolean | null;
}
/**
 * 
 * @export
 * @interface StoneXCommoditySolutionsUnderlierModel
 */
export interface StoneXCommoditySolutionsUnderlierModel {
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsUnderlierModel
     */
    'underlierId': string;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsUnderlierModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsUnderlierModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof StoneXCommoditySolutionsUnderlierModel
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof StoneXCommoditySolutionsUnderlierModel
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface SubscriptionModel
 */
export interface SubscriptionModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    'subscriptionId': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    'planId'?: string | null;
    /**
     * 
     * @type {MarketIntelligenceUserModel}
     * @memberof SubscriptionModel
     */
    'user': MarketIntelligenceUserModel;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    'planName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    'planDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    'start'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    'end'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    'trialStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    'trialEnd'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SupportType = {
    General: 'General',
    Insurance: 'Insurance',
    MarketExpert: 'MarketExpert',
    MarketIntelligenceSubscription: 'MarketIntelligenceSubscription',
    Technical: 'Technical',
    TradingAccountAccess: 'TradingAccountAccess',
    CanadaCashBids: 'CanadaCashBids'
} as const;

export type SupportType = typeof SupportType[keyof typeof SupportType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SymbolCategory = {
    Currency: 'Currency',
    Dairy: 'Dairy',
    Energy: 'Energy',
    Fertilizer: 'Fertilizer',
    Grains: 'Grains',
    Indices: 'Indices',
    Livestock: 'Livestock',
    Softs: 'Softs'
} as const;

export type SymbolCategory = typeof SymbolCategory[keyof typeof SymbolCategory];


/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'ticketId': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'ticketVersionNumber': number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'ticketNumber': string;
    /**
     * 
     * @type {TicketType}
     * @memberof Ticket
     */
    'ticketType'?: TicketType;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'ticketUnloadDate': string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'commodityId': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'unitId': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'originCustomerId': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'destinationCustomerId': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'elevatorDomainId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'officeId': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'grossPlusTarePounds'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'ticketDate': string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'netDollars': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'grossUnits': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'netUnits': number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'settleDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'firstSettledDate'?: string | null;
    /**
     * 
     * @type {Array<TicketApplication>}
     * @memberof Ticket
     */
    'ticketApplications': Array<TicketApplication>;
    /**
     * 
     * @type {Array<TicketGrade>}
     * @memberof Ticket
     */
    'ticketGrades': Array<TicketGrade>;
}
/**
 * 
 * @export
 * @interface TicketApplication
 */
export interface TicketApplication {
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'ticketApplicationId': number;
    /**
     * 
     * @type {TicketApplicationType}
     * @memberof TicketApplication
     */
    'ticketApplicationType': TicketApplicationType;
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'netUnits'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'contractId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'contractPriceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'ticketApplicationPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {AchWireSetting}
     * @memberof TicketApplication
     */
    'achWireSetting'?: AchWireSetting;
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'grossDollars'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'netDollars'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'advancedDollars'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'storage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketApplication
     */
    'miscellaneousCharge'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TicketApplication
     */
    'settleDate'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TicketApplicationType = {
    Contract: 'Contract',
    Spot: 'Spot',
    Storage: 'Storage',
    Held: 'Held',
    NotPriced: 'NotPriced',
    OutOfSpotStorage: 'OutOfSpotStorage',
    OutOfContractStorage: 'OutOfContractStorage',
    WithHeldReceipt: 'WithHeldReceipt',
    OffSite: 'OffSite',
    Pending: 'Pending',
    SpotPending: 'SpotPending',
    Rental: 'Rental'
} as const;

export type TicketApplicationType = typeof TicketApplicationType[keyof typeof TicketApplicationType];


/**
 * 
 * @export
 * @interface TicketGrade
 */
export interface TicketGrade {
    /**
     * 
     * @type {number}
     * @memberof TicketGrade
     */
    'ticketGradeId': number;
    /**
     * 
     * @type {number}
     * @memberof TicketGrade
     */
    'discountId': number;
    /**
     * 
     * @type {string}
     * @memberof TicketGrade
     */
    'discountName': string;
    /**
     * 
     * @type {string}
     * @memberof TicketGrade
     */
    'discountDescription': string;
    /**
     * 
     * @type {number}
     * @memberof TicketGrade
     */
    'discountGrade'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TicketGrade
     */
    'dollarsOff'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TicketType = {
    Sale: 'Sale',
    Purchase: 'Purchase'
} as const;

export type TicketType = typeof TicketType[keyof typeof TicketType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TotalNetWorth = {
    LessThanOneHundredThousand: 'LessThanOneHundredThousand',
    OneHundredThousandToFiveHundredThousand: 'OneHundredThousandToFiveHundredThousand',
    FiveHundredThousandToOneMillion: 'FiveHundredThousandToOneMillion',
    OneMillionToFiveMillion: 'OneMillionToFiveMillion',
    GreaterThanFiveMillion: 'GreaterThanFiveMillion'
} as const;

export type TotalNetWorth = typeof TotalNetWorth[keyof typeof TotalNetWorth];


/**
 * 
 * @export
 * @enum {string}
 */

export const TownshipCardinalDirection = {
    North: 'North',
    South: 'South'
} as const;

export type TownshipCardinalDirection = typeof TownshipCardinalDirection[keyof typeof TownshipCardinalDirection];


/**
 * 
 * @export
 * @enum {string}
 */

export const UnitOfMeasure = {
    Bag: 'Bag',
    Barrel: 'Barrel',
    BillionCubicFeet: 'BillionCubicFeet',
    Bushel: 'Bushel',
    Contracts: 'Contracts',
    Currency: 'Currency',
    Days: 'Days',
    DegreesFahrenheit: 'DegreesFahrenheit',
    EuropeanUnionAllowance: 'EuropeanUnionAllowance',
    Gallon: 'Gallon',
    HundredKilogram: 'HundredKilogram',
    HundredWeight: 'HundredWeight',
    Imperial: 'Imperial',
    Index: 'Index',
    Kilogram: 'Kilogram',
    KilogramMilkSolids: 'KilogramMilkSolids',
    Liter: 'Liter',
    MegaWatt: 'MegaWatt',
    MegaWattHour: 'MegaWattHour',
    Metric: 'Metric',
    MetricTon: 'MetricTon',
    MillionBritishThermalUnits: 'MillionBritishThermalUnits',
    MillionBushels: 'MillionBushels',
    MillionCubicFeet: 'MillionCubicFeet',
    MillionGallons: 'MillionGallons',
    MillionLiters: 'MillionLiters',
    MillionMetricTons: 'MillionMetricTons',
    MillionPounds: 'MillionPounds',
    Percent: 'Percent',
    Pound: 'Pound',
    PoundSingle: 'PoundSingle',
    ShortTon: 'ShortTon',
    ThousandBarrels: 'ThousandBarrels',
    ThousandBarrelsPerDay: 'ThousandBarrelsPerDay',
    ThousandHeads: 'ThousandHeads',
    ThousandMetricTons: 'ThousandMetricTons',
    ThousandPounds: 'ThousandPounds',
    ThousandShortTons: 'ThousandShortTons',
    Unknown: 'Unknown'
} as const;

export type UnitOfMeasure = typeof UnitOfMeasure[keyof typeof UnitOfMeasure];


/**
 * 
 * @export
 * @interface UpdateArticleViewCountRequest
 */
export interface UpdateArticleViewCountRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateArticleViewCountRequest
     */
    'articleContentId': string;
}
/**
 * 
 * @export
 * @interface UpdateArticleViewCountResponse
 */
export interface UpdateArticleViewCountResponse {
    /**
     * 
     * @type {ArticleViewCountModel}
     * @memberof UpdateArticleViewCountResponse
     */
    'articleViewCount'?: ArticleViewCountModel;
}
/**
 * 
 * @export
 * @interface UpdateCropBudgetExpenseRequest
 */
export interface UpdateCropBudgetExpenseRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCropBudgetExpenseRequest
     */
    'cropBudgetExpenseGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropBudgetExpenseRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropBudgetExpenseRequest
     */
    'cropBudgetGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropBudgetExpenseRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropBudgetExpenseRequest
     */
    'pricePerAcre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropBudgetExpenseRequest
     */
    'totalPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateCropBudgetRequest
 */
export interface UpdateCropBudgetRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCropBudgetRequest
     */
    'cropBudgetGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropBudgetRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {CropType}
     * @memberof UpdateCropBudgetRequest
     */
    'cropType': CropType;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropBudgetRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropBudgetRequest
     */
    'marketingYield'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropBudgetRequest
     */
    'projectedProduct'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropBudgetRequest
     */
    'projectedPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropBudgetRequest
     */
    'cropPriceGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCropFieldRequest
 */
export interface UpdateCropFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCropFieldRequest
     */
    'cropFieldGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropFieldRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropFieldRequest
     */
    'name': string;
    /**
     * 
     * @type {State}
     * @memberof UpdateCropFieldRequest
     */
    'state': State;
    /**
     * 
     * @type {Division}
     * @memberof UpdateCropFieldRequest
     */
    'division'?: Division;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropFieldRequest
     */
    'section'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropFieldRequest
     */
    'township'?: string | null;
    /**
     * 
     * @type {TownshipCardinalDirection}
     * @memberof UpdateCropFieldRequest
     */
    'townshipCardinalDirection'?: TownshipCardinalDirection;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropFieldRequest
     */
    'range'?: string | null;
    /**
     * 
     * @type {RangeCardinalDirection}
     * @memberof UpdateCropFieldRequest
     */
    'rangeCardinalDirection'?: RangeCardinalDirection;
    /**
     * 
     * @type {Meridian}
     * @memberof UpdateCropFieldRequest
     */
    'meridian'?: Meridian;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropFieldRequest
     */
    'farmableAcres'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropFieldRequest
     */
    'seededAcres'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateCropPlanRequest
 */
export interface UpdateCropPlanRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCropPlanRequest
     */
    'cropPlanGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropPlanRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {CropType}
     * @memberof UpdateCropPlanRequest
     */
    'cropType': CropType;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropPlanRequest
     */
    'variety'?: string | null;
    /**
     * 
     * @type {CropTolerance}
     * @memberof UpdateCropPlanRequest
     */
    'tolerance'?: CropTolerance;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropPlanRequest
     */
    'farmableAcres'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropPlanRequest
     */
    'seededAcres'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropPlanRequest
     */
    'seededDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCropPlanRequest
     */
    'agronomicYield'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCropPlanRequest
     */
    'cropBudgetGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateFixedExpenseRequest
 */
export interface UpdateFixedExpenseRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFixedExpenseRequest
     */
    'fixedExpenseGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFixedExpenseRequest
     */
    'knowRiskAccountGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFixedExpenseRequest
     */
    'fixedExpenseTypeGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFixedExpenseRequest
     */
    'customName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFixedExpenseRequest
     */
    'budgetPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFixedExpenseRequest
     */
    'budgetPricePerAcre'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateFuturesOnboardingAffiliationDetailsRequest
 */
export interface UpdateFuturesOnboardingAffiliationDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingAffiliationDetailsRequest
     */
    'futuresOnboardingApplicationGuid': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAffiliationDetailsRequest
     */
    'isPoliticallyExposed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAffiliationDetailsRequest
     */
    'hasEmployeeRelations': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAffiliationDetailsRequest
     */
    'hasRelatedAccounts': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingAffiliationDetailsRequest
     */
    'employeeName'?: string | null;
    /**
     * 
     * @type {EmployeeRelationshipType}
     * @memberof UpdateFuturesOnboardingAffiliationDetailsRequest
     */
    'employeeRelationshipType'?: EmployeeRelationshipType;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAffiliationDetailsRequest
     */
    'isRegisteredWithRegulator': boolean;
}
/**
 * 
 * @export
 * @interface UpdateFuturesOnboardingAgreementsRequest
 */
export interface UpdateFuturesOnboardingAgreementsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'futuresOnboardingApplicationGuid': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'agreesToStoneXAcknowledgementOfAdditionalDisclosuresAgreement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'agreesToStoneXArbitrationAgreement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'agreesToStoneXCommodityFuturesTradingCommissionRiskDisclosureAgreement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'agreesToStoneXFuturesAndExchangeTradedOptionsCustomerAgreement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'agreesToStoneXFuturesMarketDataSubscriptionTermsAgreement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'agreesToStoneXGroupPlatformUserAgreement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'agreesToStoneXMobileApplicationTermsAndConditionsAgreement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'agreesToStoneXOptionsDisclosureStatement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'agreesToStoneXPrivacyPolicyAgreement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingAgreementsRequest
     */
    'agreesToStoneXUserAgreement': boolean;
}
/**
 * 
 * @export
 * @interface UpdateFuturesOnboardingContactRequest
 */
export interface UpdateFuturesOnboardingContactRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingContactRequest
     */
    'futuresOnboardingApplicationGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingContactRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingContactRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingContactRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingContactRequest
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface UpdateFuturesOnboardingEmploymentDetailsRequest
 */
export interface UpdateFuturesOnboardingEmploymentDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingEmploymentDetailsRequest
     */
    'futuresOnboardingApplicationGuid': string;
    /**
     * 
     * @type {EmploymentStatus}
     * @memberof UpdateFuturesOnboardingEmploymentDetailsRequest
     */
    'employmentStatus': EmploymentStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingEmploymentDetailsRequest
     */
    'employerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingEmploymentDetailsRequest
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFuturesOnboardingEmploymentDetailsRequest
     */
    'yearsOfEmployment'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateFuturesOnboardingPersonalDetailsRequest
 */
export interface UpdateFuturesOnboardingPersonalDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingPersonalDetailsRequest
     */
    'futuresOnboardingApplicationGuid': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingPersonalDetailsRequest
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingPersonalDetailsRequest
     */
    'countryOfCitizenship': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingPersonalDetailsRequest
     */
    'socialSecurityNumber': string;
}
/**
 * 
 * @export
 * @interface UpdateFuturesOnboardingRegulatoryDetailsRequest
 */
export interface UpdateFuturesOnboardingRegulatoryDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingRegulatoryDetailsRequest
     */
    'futuresOnboardingApplicationGuid': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingRegulatoryDetailsRequest
     */
    'hasCommodityExchangeActExemption': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingRegulatoryDetailsRequest
     */
    'beenThroughBankruptcy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingRegulatoryDetailsRequest
     */
    'hadLegalDisputes': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFuturesOnboardingRegulatoryDetailsRequest
     */
    'hasClosedWithUnsatisfiedDebitBalance': boolean;
}
/**
 * 
 * @export
 * @interface UpdateFuturesOnboardingTradingExperienceDetailsRequest
 */
export interface UpdateFuturesOnboardingTradingExperienceDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFuturesOnboardingTradingExperienceDetailsRequest
     */
    'futuresOnboardingApplicationGuid': string;
    /**
     * 
     * @type {YearsOfTradingExperience}
     * @memberof UpdateFuturesOnboardingTradingExperienceDetailsRequest
     */
    'yearsOfExperienceTradingSecurites': YearsOfTradingExperience;
    /**
     * 
     * @type {YearsOfTradingExperience}
     * @memberof UpdateFuturesOnboardingTradingExperienceDetailsRequest
     */
    'yearsOfExperienceTradingCommodities': YearsOfTradingExperience;
    /**
     * 
     * @type {YearsOfTradingExperience}
     * @memberof UpdateFuturesOnboardingTradingExperienceDetailsRequest
     */
    'yearsOfExperienceTradingOtcContracts': YearsOfTradingExperience;
}
/**
 * 
 * @export
 * @interface UpdateIncomeCalculatorRequest
 */
export interface UpdateIncomeCalculatorRequest {
    /**
     * 
     * @type {CropType}
     * @memberof UpdateIncomeCalculatorRequest
     */
    'cropType': CropType;
    /**
     * 
     * @type {number}
     * @memberof UpdateIncomeCalculatorRequest
     */
    'seededAcres': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateIncomeCalculatorRequest
     */
    'marketPrice': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateIncomeCalculatorRequest
     */
    'yield'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateIncomeCalculatorRequest
     */
    'agronomicYield'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateIncomeCalculatorRequest
     */
    'fixedCost': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateIncomeCalculatorRequest
     */
    'variableCost': number;
}
/**
 * 
 * @export
 * @interface UpdateKnowRiskFeatureRequest
 */
export interface UpdateKnowRiskFeatureRequest {
    /**
     * 
     * @type {FeatureType}
     * @memberof UpdateKnowRiskFeatureRequest
     */
    'featureType': FeatureType;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKnowRiskFeatureRequest
     */
    'isOn': boolean;
    /**
     * 
     * @type {FeatureApplicationLevel}
     * @memberof UpdateKnowRiskFeatureRequest
     */
    'featureApplicationLevel'?: FeatureApplicationLevel;
    /**
     * 
     * @type {string}
     * @memberof UpdateKnowRiskFeatureRequest
     */
    'knowRiskUserGuid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKnowRiskFeatureRequest
     */
    'minimumBuildNumber'?: number | null;
    /**
     * 
     * @type {Application}
     * @memberof UpdateKnowRiskFeatureRequest
     */
    'mobileApplication': Application;
    /**
     * 
     * @type {MobileApplicationCountry}
     * @memberof UpdateKnowRiskFeatureRequest
     */
    'mobileApplicationCountry'?: MobileApplicationCountry;
}
/**
 * 
 * @export
 * @interface UpdateKnowRiskSymbolRequest
 */
export interface UpdateKnowRiskSymbolRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateKnowRiskSymbolRequest
     */
    'reutersInstrumentCodePrefix': string;
    /**
     * 
     * @type {Array<Application>}
     * @memberof UpdateKnowRiskSymbolRequest
     */
    'mobileApplications': Array<Application>;
    /**
     * 
     * @type {Array<MobileApplicationCountry>}
     * @memberof UpdateKnowRiskSymbolRequest
     */
    'mobileApplicationCountries': Array<MobileApplicationCountry>;
}
/**
 * 
 * @export
 * @interface UpdateTermsAndConditionsRequest
 */
export interface UpdateTermsAndConditionsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTermsAndConditionsRequest
     */
    'termsAndConditionsGuid': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserAgreementType = {
    MobileApplicationTermsAndConditions: 'MobileApplicationTermsAndConditions',
    StoneXPlatformAgreement: 'StoneXPlatformAgreement'
} as const;

export type UserAgreementType = typeof UserAgreementType[keyof typeof UserAgreementType];


/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'userGuid': string;
    /**
     * 
     * @type {Array<UserPermission>}
     * @memberof UserModel
     */
    'userPermissions': Array<UserPermission>;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserPermission = {
    SystemAdministrator: 'SystemAdministrator',
    Regular: 'Regular'
} as const;

export type UserPermission = typeof UserPermission[keyof typeof UserPermission];


/**
 * 
 * @export
 * @interface ValidateUserTokenResponse
 */
export interface ValidateUserTokenResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateUserTokenResponse
     */
    'isValid'?: boolean;
}
/**
 * 
 * @export
 * @interface VerifyRegistrationRequest
 */
export interface VerifyRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyRegistrationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyRegistrationRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyRegistrationRequest
     */
    'activationCode': string;
}
/**
 * 
 * @export
 * @interface VerifyRegistrationResponse
 */
export interface VerifyRegistrationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof VerifyRegistrationResponse
     */
    'isVerified'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof VerifyRegistrationResponse
     */
    'error'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyRegistrationResponse
     */
    'contactId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyRegistrationResponse
     */
    'userGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyRegistrationResponse
     */
    'accessToken'?: string | null;
}
/**
 * 
 * @export
 * @interface VersionModel
 */
export interface VersionModel {
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    'versionId': number;
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    'mapSourceId': number;
    /**
     * 
     * @type {string}
     * @memberof VersionModel
     */
    'versionDate': string;
    /**
     * 
     * @type {string}
     * @memberof VersionModel
     */
    'versionGuid': string;
}
/**
 * 
 * @export
 * @interface WatchListModel
 */
export interface WatchListModel {
    /**
     * 
     * @type {string}
     * @memberof WatchListModel
     */
    'contentConfigurationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WatchListModel
     */
    'contentConfigurationName'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WeatherUnit = {
    Imperial: 'Imperial',
    Metric: 'Metric'
} as const;

export type WeatherUnit = typeof WeatherUnit[keyof typeof WeatherUnit];


/**
 * 
 * @export
 * @interface WeatherVideoModel
 */
export interface WeatherVideoModel {
    /**
     * 
     * @type {string}
     * @memberof WeatherVideoModel
     */
    'link'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WeatherVideoModel
     */
    'updatedDate': string;
    /**
     * 
     * @type {string}
     * @memberof WeatherVideoModel
     */
    'youTubeId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const YearsOfTradingExperience = {
    LessThanOneYear: 'LessThanOneYear',
    OneToTwoYears: 'OneToTwoYears',
    TwoToThreeYears: 'TwoToThreeYears',
    GreaterThanThreeYears: 'GreaterThanThreeYears'
} as const;

export type YearsOfTradingExperience = typeof YearsOfTradingExperience[keyof typeof YearsOfTradingExperience];


/**
 * 
 * @export
 * @interface ZohoSubscriptionModel
 */
export interface ZohoSubscriptionModel {
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscriptionModel
     */
    'subscriptionId': string;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscriptionModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscriptionModel
     */
    'planId': string;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscriptionModel
     */
    'planName': string;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscriptionModel
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscriptionModel
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscriptionModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscriptionModel
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZohoSubscriptionModel
     */
    'createdAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ZoomMetadataModel
 */
export interface ZoomMetadataModel {
    /**
     * 
     * @type {number}
     * @memberof ZoomMetadataModel
     */
    'maximumZoom'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ZoomMetadataModel
     */
    'minimumZoom'?: number | null;
}

/**
 * FuturesApi - axios parameter creator
 * @export
 */
export const FuturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddFuturesOnboardingApplicationRequest} [addFuturesOnboardingApplicationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFuturesOnboardingApplication: async (addFuturesOnboardingApplicationRequest?: AddFuturesOnboardingApplicationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFuturesOnboardingApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {AddFuturesOnboardingContactRequest} [addFuturesOnboardingContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFuturesOnboardingContact: async (futuresOnboardingApplicationGuid: string, addFuturesOnboardingContactRequest?: AddFuturesOnboardingContactRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('addFuturesOnboardingContact', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/contacts`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFuturesOnboardingContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {AddFuturesOnboardingContactAddressRequest} [addFuturesOnboardingContactAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFuturesOnboardingContactAddress: async (futuresOnboardingApplicationGuid: string, addFuturesOnboardingContactAddressRequest?: AddFuturesOnboardingContactAddressRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('addFuturesOnboardingContactAddress', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/contacts/address`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFuturesOnboardingContactAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {AddFuturesOnboardingFinancialsRequest} [addFuturesOnboardingFinancialsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFuturesOnboardingFinancials: async (futuresOnboardingApplicationGuid: string, addFuturesOnboardingFinancialsRequest?: AddFuturesOnboardingFinancialsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('addFuturesOnboardingFinancials', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/financial-details`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFuturesOnboardingFinancialsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCheckPdf: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadCheckPdf', 'id', id)
            const localVarPath = `/futures/physical-positions/checks/grain/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientServicesCenterOnboardingApplication: async (futuresOnboardingApplicationGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('getClientServicesCenterOnboardingApplication', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/client-services-center-application`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentFuturesOnboardingApplication: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFuturesOnboardingApplication: async (futuresOnboardingApplicationGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('getFuturesOnboardingApplication', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} timeSpan 
         * @param {number} contractNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketPriceVolatility: async (reutersInstrumentCodePrefix: string, timeSpan: string, contractNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('getMarketPriceVolatility', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('getMarketPriceVolatility', 'timeSpan', timeSpan)
            // verify required parameter 'contractNumber' is not null or undefined
            assertParamExists('getMarketPriceVolatility', 'contractNumber', contractNumber)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix}/volatility/{timeSpan}`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)))
                .replace(`{${"timeSpan"}}`, encodeURIComponent(String(timeSpan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractNumber !== undefined) {
                localVarQueryParameter['ContractNumber'] = contractNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicalPositionsTicket: async (ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getPhysicalPositionsTicket', 'ticketId', ticketId)
            const localVarPath = `/futures/physical-positions/tickets/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {StatementType} statementType 
         * @param {string} statementDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatement: async (sourceSystem: string, accountNumber: string, statementType: StatementType, statementDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('getStatement', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('getStatement', 'accountNumber', accountNumber)
            // verify required parameter 'statementType' is not null or undefined
            assertParamExists('getStatement', 'statementType', statementType)
            // verify required parameter 'statementDate' is not null or undefined
            assertParamExists('getStatement', 'statementDate', statementDate)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/statement`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (statementType !== undefined) {
                localVarQueryParameter['StatementType'] = statementType;
            }

            if (statementDate !== undefined) {
                localVarQueryParameter['StatementDate'] = (statementDate as any instanceof Date) ?
                    (statementDate as any).toISOString() :
                    statementDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountDetails: async (sourceSystem: string, accountNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listAccountDetails', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listAccountDetails', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/details`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountTypes: async (sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listAccountTypes', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listAccountTypes', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/account-types`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['ReportType'] = reportType;
            }

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {Array<string>} [accountNumbers] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts: async (searchTerm?: string, accountNumbers?: Array<string>, take?: number, skip?: number, sortColumn?: string, sortDirection?: SortDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (accountNumbers) {
                localVarQueryParameter['AccountNumbers'] = accountNumbers;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountsMercury: async (sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/accounts/codex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllMarketPricesComparisonChart: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/prices/comparison/chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {Array<string>} [description] 
         * @param {Array<string>} [accountTypes] 
         * @param {number} [cashRangeStart] 
         * @param {number} [cashRangeEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCashActivity: async (sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, description?: Array<string>, accountTypes?: Array<string>, cashRangeStart?: number, cashRangeEnd?: number, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listCashActivity', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listCashActivity', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/cash-activity`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }

            if (description) {
                localVarQueryParameter['Description'] = description;
            }

            if (accountTypes) {
                localVarQueryParameter['AccountTypes'] = accountTypes;
            }

            if (cashRangeStart !== undefined) {
                localVarQueryParameter['CashRange.Start'] = cashRangeStart;
            }

            if (cashRangeEnd !== undefined) {
                localVarQueryParameter['CashRange.End'] = cashRangeEnd;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CashBidCommodity} [commodity] 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [radius] 
         * @param {string} [refinitivInstrumentCodePrefix] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCashBids: async (commodity?: CashBidCommodity, latitude?: number, longitude?: number, radius?: number, refinitivInstrumentCodePrefix?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/cash-bids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commodity !== undefined) {
                localVarQueryParameter['Commodity'] = commodity;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }

            if (radius !== undefined) {
                localVarQueryParameter['Radius'] = radius;
            }

            if (refinitivInstrumentCodePrefix !== undefined) {
                localVarQueryParameter['RefinitivInstrumentCodePrefix'] = refinitivInstrumentCodePrefix;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContracts: async (sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listContracts', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listContracts', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/contracts`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['ReportType'] = reportType;
            }

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCurrencies: async (sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listCurrencies', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listCurrencies', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/currencies`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDescriptions: async (sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listDescriptions', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listDescriptions', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/descriptions`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['ReportType'] = reportType;
            }

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExchanges: async (sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listExchanges', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listExchanges', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/exchanges`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['ReportType'] = reportType;
            }

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFees: async (sourceSystem: string, accountNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listFees', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listFees', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/fees`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFuturesBuyingPowersAccounts: async (sortColumn?: string, sortDirection?: SortDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/gain/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gainFuturesCodePrefix 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFuturesContracts: async (gainFuturesCodePrefix: string, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gainFuturesCodePrefix' is not null or undefined
            assertParamExists('listFuturesContracts', 'gainFuturesCodePrefix', gainFuturesCodePrefix)
            const localVarPath = `/futures/gain/contracts/{gainFuturesCodePrefix}`
                .replace(`{${"gainFuturesCodePrefix"}}`, encodeURIComponent(String(gainFuturesCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketIndexes: async (reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCode' is not null or undefined
            assertParamExists('listMarketIndexes', 'reutersInstrumentCode', reutersInstrumentCode)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketIndexes', 'timeSpan', timeSpan)
            const localVarPath = `/futures/indexes/{reutersInstrumentCode}`
                .replace(`{${"reutersInstrumentCode"}}`, encodeURIComponent(String(reutersInstrumentCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricePositions: async (reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricePositions', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPricePositions', 'timeSpan', timeSpan)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix}/positions`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {number} contractNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPriceVolatilities: async (reutersInstrumentCodePrefix: string, contractNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPriceVolatilities', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'contractNumber' is not null or undefined
            assertParamExists('listMarketPriceVolatilities', 'contractNumber', contractNumber)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix}/volatility`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractNumber !== undefined) {
                localVarQueryParameter['ContractNumber'] = contractNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPrices: async (reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCode' is not null or undefined
            assertParamExists('listMarketPrices', 'reutersInstrumentCode', reutersInstrumentCode)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPrices', 'timeSpan', timeSpan)
            const localVarPath = `/futures/prices/{reutersInstrumentCode}`
                .replace(`{${"reutersInstrumentCode"}}`, encodeURIComponent(String(reutersInstrumentCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} contractYear 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesByContractYear: async (reutersInstrumentCodePrefix: string, contractYear: string, timeSpan: MarketPricesTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesByContractYear', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'contractYear' is not null or undefined
            assertParamExists('listMarketPricesByContractYear', 'contractYear', contractYear)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPricesByContractYear', 'timeSpan', timeSpan)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix}/contract-year/{contractYear}`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)))
                .replace(`{${"contractYear"}}`, encodeURIComponent(String(contractYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesByDay: async (reutersInstrumentCodePrefix: string, asOfDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesByDay', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'asOfDate' is not null or undefined
            assertParamExists('listMarketPricesByDay', 'asOfDate', asOfDate)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix}/date/{asOfDate}`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)))
                .replace(`{${"asOfDate"}}`, encodeURIComponent(String(asOfDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix1 
         * @param {string} reutersInstrumentCodePrefix2 
         * @param {string} [asOfDate] 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesComparison: async (reutersInstrumentCodePrefix1: string, reutersInstrumentCodePrefix2: string, asOfDate?: string, timeSpan?: MarketPricesTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix1' is not null or undefined
            assertParamExists('listMarketPricesComparison', 'reutersInstrumentCodePrefix1', reutersInstrumentCodePrefix1)
            // verify required parameter 'reutersInstrumentCodePrefix2' is not null or undefined
            assertParamExists('listMarketPricesComparison', 'reutersInstrumentCodePrefix2', reutersInstrumentCodePrefix2)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix1}/comparison/{reutersInstrumentCodePrefix2}`
                .replace(`{${"reutersInstrumentCodePrefix1"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix1)))
                .replace(`{${"reutersInstrumentCodePrefix2"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix2)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (asOfDate !== undefined) {
                localVarQueryParameter['AsOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString() :
                    asOfDate;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesComparisonChart: async (reutersInstrumentCodePrefix: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesComparisonChart', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix}/comparison/chart`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesForTradingView: async (reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCode' is not null or undefined
            assertParamExists('listMarketPricesForTradingView', 'reutersInstrumentCode', reutersInstrumentCode)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPricesForTradingView', 'timeSpan', timeSpan)
            const localVarPath = `/futures/prices/{reutersInstrumentCode}/trading-view`
                .replace(`{${"reutersInstrumentCode"}}`, encodeURIComponent(String(reutersInstrumentCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} asOfDate 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesForwardCurve: async (reutersInstrumentCodePrefix: string, asOfDate: string, timeSpan?: MarketPricesTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesForwardCurve', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'asOfDate' is not null or undefined
            assertParamExists('listMarketPricesForwardCurve', 'asOfDate', asOfDate)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix}/forward-curve/{asOfDate}`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)))
                .replace(`{${"asOfDate"}}`, encodeURIComponent(String(asOfDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {number} contractNumber 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesRolling: async (reutersInstrumentCodePrefix: string, contractNumber: number, timeSpan: MarketPricesTimeSpan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesRolling', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'contractNumber' is not null or undefined
            assertParamExists('listMarketPricesRolling', 'contractNumber', contractNumber)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPricesRolling', 'timeSpan', timeSpan)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix}/rolling`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractNumber !== undefined) {
                localVarQueryParameter['ContractNumber'] = contractNumber;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {number} [startingContractMonth] 
         * @param {Array<number>} [contractMonths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesRollingYear: async (reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, startingContractMonth?: number, contractMonths?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesRollingYear', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('listMarketPricesRollingYear', 'timeSpan', timeSpan)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix}/rolling/year`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startingContractMonth !== undefined) {
                localVarQueryParameter['StartingContractMonth'] = startingContractMonth;
            }

            if (contractMonths) {
                localVarQueryParameter['ContractMonths'] = contractMonths;
            }

            if (timeSpan !== undefined) {
                localVarQueryParameter['TimeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesToday: async (reutersInstrumentCodePrefix: string, asOfDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('listMarketPricesToday', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            const localVarPath = `/futures/prices/{reutersInstrumentCodePrefix}/date`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (asOfDate !== undefined) {
                localVarQueryParameter['AsOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString() :
                    asOfDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gainFuturesCode 
         * @param {string} [expirationDate] 
         * @param {number} [numberOfStrikes] 
         * @param {OptionType} [optionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOptionsChain: async (gainFuturesCode: string, expirationDate?: string, numberOfStrikes?: number, optionType?: OptionType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gainFuturesCode' is not null or undefined
            assertParamExists('listOptionsChain', 'gainFuturesCode', gainFuturesCode)
            const localVarPath = `/futures/gain/contracts/{gainFuturesCode}/options/chain`
                .replace(`{${"gainFuturesCode"}}`, encodeURIComponent(String(gainFuturesCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (expirationDate !== undefined) {
                localVarQueryParameter['ExpirationDate'] = (expirationDate as any instanceof Date) ?
                    (expirationDate as any).toISOString() :
                    expirationDate;
            }

            if (numberOfStrikes !== undefined) {
                localVarQueryParameter['NumberOfStrikes'] = numberOfStrikes;
            }

            if (optionType !== undefined) {
                localVarQueryParameter['OptionType'] = optionType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} gainFuturesCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOptionsExpirations: async (gainFuturesCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gainFuturesCode' is not null or undefined
            assertParamExists('listOptionsExpirations', 'gainFuturesCode', gainFuturesCode)
            const localVarPath = `/futures/gain/contracts/{gainFuturesCode}/options/expirations`
                .replace(`{${"gainFuturesCode"}}`, encodeURIComponent(String(gainFuturesCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [contractCodes] 
         * @param {Array<string>} [checkIds] 
         * @param {string} [fromCheckDate] 
         * @param {string} [toCheckDate] 
         * @param {string} [createdFromDate] 
         * @param {string} [createdToDate] 
         * @param {string} [updateSinceDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPhysicalPositionChecks: async (contractCodes?: Array<string>, checkIds?: Array<string>, fromCheckDate?: string, toCheckDate?: string, createdFromDate?: string, createdToDate?: string, updateSinceDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/physical-positions/checks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractCodes) {
                localVarQueryParameter['ContractCodes'] = contractCodes;
            }

            if (checkIds) {
                localVarQueryParameter['CheckIds'] = checkIds;
            }

            if (fromCheckDate !== undefined) {
                localVarQueryParameter['FromCheckDate'] = (fromCheckDate as any instanceof Date) ?
                    (fromCheckDate as any).toISOString() :
                    fromCheckDate;
            }

            if (toCheckDate !== undefined) {
                localVarQueryParameter['ToCheckDate'] = (toCheckDate as any instanceof Date) ?
                    (toCheckDate as any).toISOString() :
                    toCheckDate;
            }

            if (createdFromDate !== undefined) {
                localVarQueryParameter['CreatedFromDate'] = (createdFromDate as any instanceof Date) ?
                    (createdFromDate as any).toISOString() :
                    createdFromDate;
            }

            if (createdToDate !== undefined) {
                localVarQueryParameter['CreatedToDate'] = (createdToDate as any instanceof Date) ?
                    (createdToDate as any).toISOString() :
                    createdToDate;
            }

            if (updateSinceDate !== undefined) {
                localVarQueryParameter['UpdateSinceDate'] = (updateSinceDate as any instanceof Date) ?
                    (updateSinceDate as any).toISOString() :
                    updateSinceDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [commodityId] 
         * @param {Array<string>} [contractCodes] 
         * @param {Array<string>} [purchaseSaleTypes] 
         * @param {Array<string>} [ticketIds] 
         * @param {Array<string>} [ticketNumbers] 
         * @param {string} [createdFrom] 
         * @param {string} [createdTo] 
         * @param {number} [destinationCustomerId] 
         * @param {number} [originId] 
         * @param {string} [unloadDate] 
         * @param {string} [updatedSince] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPhysicalPositionTickets: async (commodityId?: number, contractCodes?: Array<string>, purchaseSaleTypes?: Array<string>, ticketIds?: Array<string>, ticketNumbers?: Array<string>, createdFrom?: string, createdTo?: string, destinationCustomerId?: number, originId?: number, unloadDate?: string, updatedSince?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/physical-positions/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commodityId !== undefined) {
                localVarQueryParameter['CommodityId'] = commodityId;
            }

            if (contractCodes) {
                localVarQueryParameter['ContractCodes'] = contractCodes;
            }

            if (purchaseSaleTypes) {
                localVarQueryParameter['PurchaseSaleTypes'] = purchaseSaleTypes;
            }

            if (ticketIds) {
                localVarQueryParameter['TicketIds'] = ticketIds;
            }

            if (ticketNumbers) {
                localVarQueryParameter['TicketNumbers'] = ticketNumbers;
            }

            if (createdFrom !== undefined) {
                localVarQueryParameter['CreatedFrom'] = (createdFrom as any instanceof Date) ?
                    (createdFrom as any).toISOString() :
                    createdFrom;
            }

            if (createdTo !== undefined) {
                localVarQueryParameter['CreatedTo'] = (createdTo as any instanceof Date) ?
                    (createdTo as any).toISOString() :
                    createdTo;
            }

            if (destinationCustomerId !== undefined) {
                localVarQueryParameter['DestinationCustomerId'] = destinationCustomerId;
            }

            if (originId !== undefined) {
                localVarQueryParameter['OriginId'] = originId;
            }

            if (unloadDate !== undefined) {
                localVarQueryParameter['UnloadDate'] = (unloadDate as any instanceof Date) ?
                    (unloadDate as any).toISOString() :
                    unloadDate;
            }

            if (updatedSince !== undefined) {
                localVarQueryParameter['UpdatedSince'] = (updatedSince as any instanceof Date) ?
                    (updatedSince as any).toISOString() :
                    updatedSince;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} id 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPositionDetails: async (sourceSystem: string, accountNumber: string, id: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listPositionDetails', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listPositionDetails', 'accountNumber', accountNumber)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listPositionDetails', 'id', id)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/positions/details`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {Array<string>} [products] 
         * @param {string} [tradeDateStart] 
         * @param {string} [tradeDateEnd] 
         * @param {Array<PutOrCall>} [putOrCall] 
         * @param {number} [strikePriceRangeStart] 
         * @param {number} [strikePriceRangeEnd] 
         * @param {Array<string>} [exchanges] 
         * @param {Array<string>} [contracts] 
         * @param {number} [triggerBarrierStart] 
         * @param {number} [triggerBarrierEnd] 
         * @param {number} [tradePriceRangeStart] 
         * @param {number} [tradePriceRangeEnd] 
         * @param {Array<string>} [ticketNumbers] 
         * @param {Array<string>} [tradeIds] 
         * @param {Array<string>} [globalIds] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPositions: async (sourceSystem: string, accountNumber: string, products?: Array<string>, tradeDateStart?: string, tradeDateEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, contracts?: Array<string>, triggerBarrierStart?: number, triggerBarrierEnd?: number, tradePriceRangeStart?: number, tradePriceRangeEnd?: number, ticketNumbers?: Array<string>, tradeIds?: Array<string>, globalIds?: Array<string>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listPositions', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listPositions', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/positions`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (products) {
                localVarQueryParameter['Products'] = products;
            }

            if (tradeDateStart !== undefined) {
                localVarQueryParameter['TradeDate.Start'] = (tradeDateStart as any instanceof Date) ?
                    (tradeDateStart as any).toISOString().substr(0,10) :
                    tradeDateStart;
            }

            if (tradeDateEnd !== undefined) {
                localVarQueryParameter['TradeDate.End'] = (tradeDateEnd as any instanceof Date) ?
                    (tradeDateEnd as any).toISOString().substr(0,10) :
                    tradeDateEnd;
            }

            if (putOrCall) {
                localVarQueryParameter['PutOrCall'] = putOrCall;
            }

            if (strikePriceRangeStart !== undefined) {
                localVarQueryParameter['StrikePriceRange.Start'] = strikePriceRangeStart;
            }

            if (strikePriceRangeEnd !== undefined) {
                localVarQueryParameter['StrikePriceRange.End'] = strikePriceRangeEnd;
            }

            if (exchanges) {
                localVarQueryParameter['Exchanges'] = exchanges;
            }

            if (contracts) {
                localVarQueryParameter['Contracts'] = contracts;
            }

            if (triggerBarrierStart !== undefined) {
                localVarQueryParameter['TriggerBarrier.Start'] = triggerBarrierStart;
            }

            if (triggerBarrierEnd !== undefined) {
                localVarQueryParameter['TriggerBarrier.End'] = triggerBarrierEnd;
            }

            if (tradePriceRangeStart !== undefined) {
                localVarQueryParameter['TradePriceRange.Start'] = tradePriceRangeStart;
            }

            if (tradePriceRangeEnd !== undefined) {
                localVarQueryParameter['TradePriceRange.End'] = tradePriceRangeEnd;
            }

            if (ticketNumbers) {
                localVarQueryParameter['TicketNumbers'] = ticketNumbers;
            }

            if (tradeIds) {
                localVarQueryParameter['TradeIds'] = tradeIds;
            }

            if (globalIds) {
                localVarQueryParameter['GlobalIds'] = globalIds;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {Array<string>} [products] 
         * @param {string} [tradeDateStart] 
         * @param {string} [tradeDateEnd] 
         * @param {Array<PutOrCall>} [putOrCall] 
         * @param {number} [strikePriceRangeStart] 
         * @param {number} [strikePriceRangeEnd] 
         * @param {Array<string>} [exchanges] 
         * @param {Array<string>} [contracts] 
         * @param {number} [triggerBarrierStart] 
         * @param {number} [triggerBarrierEnd] 
         * @param {number} [tradePriceRangeStart] 
         * @param {number} [tradePriceRangeEnd] 
         * @param {Array<string>} [ticketNumbers] 
         * @param {Array<string>} [tradeIds] 
         * @param {Array<string>} [globalIds] 
         * @param {string} [startDateStart] 
         * @param {string} [startDateEnd] 
         * @param {string} [endDateStart] 
         * @param {string} [endDateEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPriorDaysActivity: async (sourceSystem: string, accountNumber: string, products?: Array<string>, tradeDateStart?: string, tradeDateEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, contracts?: Array<string>, triggerBarrierStart?: number, triggerBarrierEnd?: number, tradePriceRangeStart?: number, tradePriceRangeEnd?: number, ticketNumbers?: Array<string>, tradeIds?: Array<string>, globalIds?: Array<string>, startDateStart?: string, startDateEnd?: string, endDateStart?: string, endDateEnd?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listPriorDaysActivity', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listPriorDaysActivity', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/prior-days-activity`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (products) {
                localVarQueryParameter['Products'] = products;
            }

            if (tradeDateStart !== undefined) {
                localVarQueryParameter['TradeDate.Start'] = (tradeDateStart as any instanceof Date) ?
                    (tradeDateStart as any).toISOString().substr(0,10) :
                    tradeDateStart;
            }

            if (tradeDateEnd !== undefined) {
                localVarQueryParameter['TradeDate.End'] = (tradeDateEnd as any instanceof Date) ?
                    (tradeDateEnd as any).toISOString().substr(0,10) :
                    tradeDateEnd;
            }

            if (putOrCall) {
                localVarQueryParameter['PutOrCall'] = putOrCall;
            }

            if (strikePriceRangeStart !== undefined) {
                localVarQueryParameter['StrikePriceRange.Start'] = strikePriceRangeStart;
            }

            if (strikePriceRangeEnd !== undefined) {
                localVarQueryParameter['StrikePriceRange.End'] = strikePriceRangeEnd;
            }

            if (exchanges) {
                localVarQueryParameter['Exchanges'] = exchanges;
            }

            if (contracts) {
                localVarQueryParameter['Contracts'] = contracts;
            }

            if (triggerBarrierStart !== undefined) {
                localVarQueryParameter['TriggerBarrier.Start'] = triggerBarrierStart;
            }

            if (triggerBarrierEnd !== undefined) {
                localVarQueryParameter['TriggerBarrier.End'] = triggerBarrierEnd;
            }

            if (tradePriceRangeStart !== undefined) {
                localVarQueryParameter['TradePriceRange.Start'] = tradePriceRangeStart;
            }

            if (tradePriceRangeEnd !== undefined) {
                localVarQueryParameter['TradePriceRange.End'] = tradePriceRangeEnd;
            }

            if (ticketNumbers) {
                localVarQueryParameter['TicketNumbers'] = ticketNumbers;
            }

            if (tradeIds) {
                localVarQueryParameter['TradeIds'] = tradeIds;
            }

            if (globalIds) {
                localVarQueryParameter['GlobalIds'] = globalIds;
            }

            if (startDateStart !== undefined) {
                localVarQueryParameter['StartDate.Start'] = (startDateStart as any instanceof Date) ?
                    (startDateStart as any).toISOString().substr(0,10) :
                    startDateStart;
            }

            if (startDateEnd !== undefined) {
                localVarQueryParameter['StartDate.End'] = (startDateEnd as any instanceof Date) ?
                    (startDateEnd as any).toISOString().substr(0,10) :
                    startDateEnd;
            }

            if (endDateStart !== undefined) {
                localVarQueryParameter['EndDate.Start'] = (endDateStart as any instanceof Date) ?
                    (endDateStart as any).toISOString().substr(0,10) :
                    endDateStart;
            }

            if (endDateEnd !== undefined) {
                localVarQueryParameter['EndDate.End'] = (endDateEnd as any instanceof Date) ?
                    (endDateEnd as any).toISOString().substr(0,10) :
                    endDateEnd;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} id 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPriorDaysActivityDetails: async (sourceSystem: string, accountNumber: string, id: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listPriorDaysActivityDetails', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listPriorDaysActivityDetails', 'accountNumber', accountNumber)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listPriorDaysActivityDetails', 'id', id)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/prior-days-activity/details`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts: async (sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listProducts', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listProducts', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/products`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['ReportType'] = reportType;
            }

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {Array<PutOrCall>} [putOrCall] 
         * @param {number} [strikePriceRangeStart] 
         * @param {number} [strikePriceRangeEnd] 
         * @param {Array<string>} [exchanges] 
         * @param {Array<string>} [products] 
         * @param {Array<string>} [contracts] 
         * @param {Array<string>} [currencies] 
         * @param {Array<string>} [accountTypes] 
         * @param {number} [debitOrCreditRangeStart] 
         * @param {number} [debitOrCreditRangeEnd] 
         * @param {number} [chargeRangeStart] 
         * @param {number} [chargeRangeEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRealizedGainsAndLosses: async (sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, products?: Array<string>, contracts?: Array<string>, currencies?: Array<string>, accountTypes?: Array<string>, debitOrCreditRangeStart?: number, debitOrCreditRangeEnd?: number, chargeRangeStart?: number, chargeRangeEnd?: number, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listRealizedGainsAndLosses', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listRealizedGainsAndLosses', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/realized-gains-and-losses`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }

            if (putOrCall) {
                localVarQueryParameter['PutOrCall'] = putOrCall;
            }

            if (strikePriceRangeStart !== undefined) {
                localVarQueryParameter['StrikePriceRange.Start'] = strikePriceRangeStart;
            }

            if (strikePriceRangeEnd !== undefined) {
                localVarQueryParameter['StrikePriceRange.End'] = strikePriceRangeEnd;
            }

            if (exchanges) {
                localVarQueryParameter['Exchanges'] = exchanges;
            }

            if (products) {
                localVarQueryParameter['Products'] = products;
            }

            if (contracts) {
                localVarQueryParameter['Contracts'] = contracts;
            }

            if (currencies) {
                localVarQueryParameter['Currencies'] = currencies;
            }

            if (accountTypes) {
                localVarQueryParameter['AccountTypes'] = accountTypes;
            }

            if (debitOrCreditRangeStart !== undefined) {
                localVarQueryParameter['DebitOrCreditRange.Start'] = debitOrCreditRangeStart;
            }

            if (debitOrCreditRangeEnd !== undefined) {
                localVarQueryParameter['DebitOrCreditRange.End'] = debitOrCreditRangeEnd;
            }

            if (chargeRangeStart !== undefined) {
                localVarQueryParameter['ChargeRange.Start'] = chargeRangeStart;
            }

            if (chargeRangeEnd !== undefined) {
                localVarQueryParameter['ChargeRange.End'] = chargeRangeEnd;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} id 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRealizedGainsAndLossesDetails: async (sourceSystem: string, accountNumber: string, id: string, dateRangeStart?: string, dateRangeEnd?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listRealizedGainsAndLossesDetails', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listRealizedGainsAndLossesDetails', 'accountNumber', accountNumber)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listRealizedGainsAndLossesDetails', 'id', id)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/realized-gains-and-losses/details`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [tickerSearchCompositeKeys] 
         * @param {Array<string>} [reutersInstrumentCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReutersInstrumentCodes: async (tickerSearchCompositeKeys?: Array<string>, reutersInstrumentCodes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/reuters-instrument-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tickerSearchCompositeKeys) {
                localVarQueryParameter['TickerSearchCompositeKeys'] = tickerSearchCompositeKeys;
            }

            if (reutersInstrumentCodes) {
                localVarQueryParameter['ReutersInstrumentCodes'] = reutersInstrumentCodes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReutersInstrumentCodesForAccount: async (sourceSystem: string, accountNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceSystem' is not null or undefined
            assertParamExists('listReutersInstrumentCodesForAccount', 'sourceSystem', sourceSystem)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('listReutersInstrumentCodesForAccount', 'accountNumber', accountNumber)
            const localVarPath = `/futures/{sourceSystem}/{accountNumber}/reuters-instrument-codes`
                .replace(`{${"sourceSystem"}}`, encodeURIComponent(String(sourceSystem)))
                .replace(`{${"accountNumber"}}`, encodeURIComponent(String(accountNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSearchAccountsMercury: async (searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/futures/accounts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingAffiliationDetailsRequest} [updateFuturesOnboardingAffiliationDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingAffiliationDetails: async (futuresOnboardingApplicationGuid: string, updateFuturesOnboardingAffiliationDetailsRequest?: UpdateFuturesOnboardingAffiliationDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('updateFuturesOnboardingAffiliationDetails', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/affiliation-details`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFuturesOnboardingAffiliationDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingAgreementsRequest} [updateFuturesOnboardingAgreementsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingAgreements: async (futuresOnboardingApplicationGuid: string, updateFuturesOnboardingAgreementsRequest?: UpdateFuturesOnboardingAgreementsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('updateFuturesOnboardingAgreements', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/agreements`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFuturesOnboardingAgreementsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingContactRequest} [updateFuturesOnboardingContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingContact: async (futuresOnboardingApplicationGuid: string, updateFuturesOnboardingContactRequest?: UpdateFuturesOnboardingContactRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('updateFuturesOnboardingContact', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/contacts`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFuturesOnboardingContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingEmploymentDetailsRequest} [updateFuturesOnboardingEmploymentDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingEmploymentDetails: async (futuresOnboardingApplicationGuid: string, updateFuturesOnboardingEmploymentDetailsRequest?: UpdateFuturesOnboardingEmploymentDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('updateFuturesOnboardingEmploymentDetails', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/employment-details`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFuturesOnboardingEmploymentDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingPersonalDetailsRequest} [updateFuturesOnboardingPersonalDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingPersonalDetails: async (futuresOnboardingApplicationGuid: string, updateFuturesOnboardingPersonalDetailsRequest?: UpdateFuturesOnboardingPersonalDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('updateFuturesOnboardingPersonalDetails', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/personal-details`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFuturesOnboardingPersonalDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingRegulatoryDetailsRequest} [updateFuturesOnboardingRegulatoryDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingRegulatoryDetails: async (futuresOnboardingApplicationGuid: string, updateFuturesOnboardingRegulatoryDetailsRequest?: UpdateFuturesOnboardingRegulatoryDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('updateFuturesOnboardingRegulatoryDetails', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/regulatory-details`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFuturesOnboardingRegulatoryDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingTradingExperienceDetailsRequest} [updateFuturesOnboardingTradingExperienceDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingTradingExperienceDetails: async (futuresOnboardingApplicationGuid: string, updateFuturesOnboardingTradingExperienceDetailsRequest?: UpdateFuturesOnboardingTradingExperienceDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'futuresOnboardingApplicationGuid' is not null or undefined
            assertParamExists('updateFuturesOnboardingTradingExperienceDetails', 'futuresOnboardingApplicationGuid', futuresOnboardingApplicationGuid)
            const localVarPath = `/futures/onboarding/{futuresOnboardingApplicationGuid}/trading-experience-details`
                .replace(`{${"futuresOnboardingApplicationGuid"}}`, encodeURIComponent(String(futuresOnboardingApplicationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFuturesOnboardingTradingExperienceDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FuturesApi - functional programming interface
 * @export
 */
export const FuturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FuturesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddFuturesOnboardingApplicationRequest} [addFuturesOnboardingApplicationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFuturesOnboardingApplication(addFuturesOnboardingApplicationRequest?: AddFuturesOnboardingApplicationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddFuturesOnboardingApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFuturesOnboardingApplication(addFuturesOnboardingApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {AddFuturesOnboardingContactRequest} [addFuturesOnboardingContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFuturesOnboardingContact(futuresOnboardingApplicationGuid: string, addFuturesOnboardingContactRequest?: AddFuturesOnboardingContactRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFuturesOnboardingContact(futuresOnboardingApplicationGuid, addFuturesOnboardingContactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {AddFuturesOnboardingContactAddressRequest} [addFuturesOnboardingContactAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFuturesOnboardingContactAddress(futuresOnboardingApplicationGuid: string, addFuturesOnboardingContactAddressRequest?: AddFuturesOnboardingContactAddressRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFuturesOnboardingContactAddress(futuresOnboardingApplicationGuid, addFuturesOnboardingContactAddressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {AddFuturesOnboardingFinancialsRequest} [addFuturesOnboardingFinancialsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFuturesOnboardingFinancials(futuresOnboardingApplicationGuid: string, addFuturesOnboardingFinancialsRequest?: AddFuturesOnboardingFinancialsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFuturesOnboardingFinancials(futuresOnboardingApplicationGuid, addFuturesOnboardingFinancialsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCheckPdf(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCheckPdf(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientServicesCenterOnboardingApplication(futuresOnboardingApplicationGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientServicesCenterOnboardingApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientServicesCenterOnboardingApplication(futuresOnboardingApplicationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentFuturesOnboardingApplication(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCurrentFuturesOnboardingApplicationGuidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentFuturesOnboardingApplication(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFuturesOnboardingApplication(futuresOnboardingApplicationGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFuturesOnboardingApplicationSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFuturesOnboardingApplication(futuresOnboardingApplicationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} timeSpan 
         * @param {number} contractNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketPriceVolatility(reutersInstrumentCodePrefix: string, timeSpan: string, contractNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketPriceVolatilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketPriceVolatility(reutersInstrumentCodePrefix, timeSpan, contractNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhysicalPositionsTicket(ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhysicalPositionsTicket(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {StatementType} statementType 
         * @param {string} statementDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatement(sourceSystem: string, accountNumber: string, statementType: StatementType, statementDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatement(sourceSystem, accountNumber, statementType, statementDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccountDetails(sourceSystem: string, accountNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAccountDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccountDetails(sourceSystem, accountNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccountTypes(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAccountTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccountTypes(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {Array<string>} [accountNumbers] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccounts(searchTerm?: string, accountNumbers?: Array<string>, take?: number, skip?: number, sortColumn?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccounts(searchTerm, accountNumbers, take, skip, sortColumn, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccountsMercury(sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCodexAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccountsMercury(sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllMarketPricesComparisonChart(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesComparisonChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllMarketPricesComparisonChart(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {Array<string>} [description] 
         * @param {Array<string>} [accountTypes] 
         * @param {number} [cashRangeStart] 
         * @param {number} [cashRangeEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCashActivity(sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, description?: Array<string>, accountTypes?: Array<string>, cashRangeStart?: number, cashRangeEnd?: number, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCashActivityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCashActivity(sourceSystem, accountNumber, dateRangeStart, dateRangeEnd, description, accountTypes, cashRangeStart, cashRangeEnd, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CashBidCommodity} [commodity] 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [radius] 
         * @param {string} [refinitivInstrumentCodePrefix] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCashBids(commodity?: CashBidCommodity, latitude?: number, longitude?: number, radius?: number, refinitivInstrumentCodePrefix?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCashBidsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCashBids(commodity, latitude, longitude, radius, refinitivInstrumentCodePrefix, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContracts(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListContractsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContracts(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCurrencies(sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCurrenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCurrencies(sourceSystem, accountNumber, dateRangeStart, dateRangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDescriptions(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDescriptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDescriptions(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listExchanges(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListExchangesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listExchanges(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFees(sourceSystem: string, accountNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommissionAndFeesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFees(sourceSystem, accountNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFuturesBuyingPowersAccounts(sortColumn?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFuturesAccountBuyingPowersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFuturesBuyingPowersAccounts(sortColumn, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} gainFuturesCodePrefix 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFuturesContracts(gainFuturesCodePrefix: string, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFuturesContractsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFuturesContracts(gainFuturesCodePrefix, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketIndexes(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketIndexValuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketIndexes(reutersInstrumentCode, timeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricePositions(reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricePositionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricePositions(reutersInstrumentCodePrefix, timeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {number} contractNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPriceVolatilities(reutersInstrumentCodePrefix: string, contractNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPriceVolatilitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPriceVolatilities(reutersInstrumentCodePrefix, contractNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPrices(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPrices(reutersInstrumentCode, timeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} contractYear 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesByContractYear(reutersInstrumentCodePrefix: string, contractYear: string, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesByContractYear(reutersInstrumentCodePrefix, contractYear, timeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesByDay(reutersInstrumentCodePrefix: string, asOfDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesByDay(reutersInstrumentCodePrefix, asOfDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix1 
         * @param {string} reutersInstrumentCodePrefix2 
         * @param {string} [asOfDate] 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesComparison(reutersInstrumentCodePrefix1: string, reutersInstrumentCodePrefix2: string, asOfDate?: string, timeSpan?: MarketPricesTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesComparisonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesComparison(reutersInstrumentCodePrefix1, reutersInstrumentCodePrefix2, asOfDate, timeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesComparisonChart(reutersInstrumentCodePrefix: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesComparisonChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesComparisonChart(reutersInstrumentCodePrefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesForTradingView(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesForTradingViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesForTradingView(reutersInstrumentCode, timeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} asOfDate 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesForwardCurve(reutersInstrumentCodePrefix: string, asOfDate: string, timeSpan?: MarketPricesTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesForwardCurveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesForwardCurve(reutersInstrumentCodePrefix, asOfDate, timeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {number} contractNumber 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesRolling(reutersInstrumentCodePrefix: string, contractNumber: number, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesRolling(reutersInstrumentCodePrefix, contractNumber, timeSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {number} [startingContractMonth] 
         * @param {Array<number>} [contractMonths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesRollingYear(reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, startingContractMonth?: number, contractMonths?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesRollingYear(reutersInstrumentCodePrefix, timeSpan, startingContractMonth, contractMonths, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketPricesToday(reutersInstrumentCodePrefix: string, asOfDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketPricesToday(reutersInstrumentCodePrefix, asOfDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} gainFuturesCode 
         * @param {string} [expirationDate] 
         * @param {number} [numberOfStrikes] 
         * @param {OptionType} [optionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOptionsChain(gainFuturesCode: string, expirationDate?: string, numberOfStrikes?: number, optionType?: OptionType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionsChainResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOptionsChain(gainFuturesCode, expirationDate, numberOfStrikes, optionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} gainFuturesCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOptionsExpirations(gainFuturesCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionsExpirationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOptionsExpirations(gainFuturesCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [contractCodes] 
         * @param {Array<string>} [checkIds] 
         * @param {string} [fromCheckDate] 
         * @param {string} [toCheckDate] 
         * @param {string} [createdFromDate] 
         * @param {string} [createdToDate] 
         * @param {string} [updateSinceDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPhysicalPositionChecks(contractCodes?: Array<string>, checkIds?: Array<string>, fromCheckDate?: string, toCheckDate?: string, createdFromDate?: string, createdToDate?: string, updateSinceDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListChecksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPhysicalPositionChecks(contractCodes, checkIds, fromCheckDate, toCheckDate, createdFromDate, createdToDate, updateSinceDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [commodityId] 
         * @param {Array<string>} [contractCodes] 
         * @param {Array<string>} [purchaseSaleTypes] 
         * @param {Array<string>} [ticketIds] 
         * @param {Array<string>} [ticketNumbers] 
         * @param {string} [createdFrom] 
         * @param {string} [createdTo] 
         * @param {number} [destinationCustomerId] 
         * @param {number} [originId] 
         * @param {string} [unloadDate] 
         * @param {string} [updatedSince] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPhysicalPositionTickets(commodityId?: number, contractCodes?: Array<string>, purchaseSaleTypes?: Array<string>, ticketIds?: Array<string>, ticketNumbers?: Array<string>, createdFrom?: string, createdTo?: string, destinationCustomerId?: number, originId?: number, unloadDate?: string, updatedSince?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTicketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPhysicalPositionTickets(commodityId, contractCodes, purchaseSaleTypes, ticketIds, ticketNumbers, createdFrom, createdTo, destinationCustomerId, originId, unloadDate, updatedSince, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} id 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPositionDetails(sourceSystem: string, accountNumber: string, id: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPositionDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPositionDetails(sourceSystem, accountNumber, id, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {Array<string>} [products] 
         * @param {string} [tradeDateStart] 
         * @param {string} [tradeDateEnd] 
         * @param {Array<PutOrCall>} [putOrCall] 
         * @param {number} [strikePriceRangeStart] 
         * @param {number} [strikePriceRangeEnd] 
         * @param {Array<string>} [exchanges] 
         * @param {Array<string>} [contracts] 
         * @param {number} [triggerBarrierStart] 
         * @param {number} [triggerBarrierEnd] 
         * @param {number} [tradePriceRangeStart] 
         * @param {number} [tradePriceRangeEnd] 
         * @param {Array<string>} [ticketNumbers] 
         * @param {Array<string>} [tradeIds] 
         * @param {Array<string>} [globalIds] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPositions(sourceSystem: string, accountNumber: string, products?: Array<string>, tradeDateStart?: string, tradeDateEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, contracts?: Array<string>, triggerBarrierStart?: number, triggerBarrierEnd?: number, tradePriceRangeStart?: number, tradePriceRangeEnd?: number, ticketNumbers?: Array<string>, tradeIds?: Array<string>, globalIds?: Array<string>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPositionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPositions(sourceSystem, accountNumber, products, tradeDateStart, tradeDateEnd, putOrCall, strikePriceRangeStart, strikePriceRangeEnd, exchanges, contracts, triggerBarrierStart, triggerBarrierEnd, tradePriceRangeStart, tradePriceRangeEnd, ticketNumbers, tradeIds, globalIds, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {Array<string>} [products] 
         * @param {string} [tradeDateStart] 
         * @param {string} [tradeDateEnd] 
         * @param {Array<PutOrCall>} [putOrCall] 
         * @param {number} [strikePriceRangeStart] 
         * @param {number} [strikePriceRangeEnd] 
         * @param {Array<string>} [exchanges] 
         * @param {Array<string>} [contracts] 
         * @param {number} [triggerBarrierStart] 
         * @param {number} [triggerBarrierEnd] 
         * @param {number} [tradePriceRangeStart] 
         * @param {number} [tradePriceRangeEnd] 
         * @param {Array<string>} [ticketNumbers] 
         * @param {Array<string>} [tradeIds] 
         * @param {Array<string>} [globalIds] 
         * @param {string} [startDateStart] 
         * @param {string} [startDateEnd] 
         * @param {string} [endDateStart] 
         * @param {string} [endDateEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPriorDaysActivity(sourceSystem: string, accountNumber: string, products?: Array<string>, tradeDateStart?: string, tradeDateEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, contracts?: Array<string>, triggerBarrierStart?: number, triggerBarrierEnd?: number, tradePriceRangeStart?: number, tradePriceRangeEnd?: number, ticketNumbers?: Array<string>, tradeIds?: Array<string>, globalIds?: Array<string>, startDateStart?: string, startDateEnd?: string, endDateStart?: string, endDateEnd?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPriorDaysActivityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPriorDaysActivity(sourceSystem, accountNumber, products, tradeDateStart, tradeDateEnd, putOrCall, strikePriceRangeStart, strikePriceRangeEnd, exchanges, contracts, triggerBarrierStart, triggerBarrierEnd, tradePriceRangeStart, tradePriceRangeEnd, ticketNumbers, tradeIds, globalIds, startDateStart, startDateEnd, endDateStart, endDateEnd, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} id 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPriorDaysActivityDetails(sourceSystem: string, accountNumber: string, id: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPriorDaysActivityDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPriorDaysActivityDetails(sourceSystem, accountNumber, id, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProducts(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProducts(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {Array<PutOrCall>} [putOrCall] 
         * @param {number} [strikePriceRangeStart] 
         * @param {number} [strikePriceRangeEnd] 
         * @param {Array<string>} [exchanges] 
         * @param {Array<string>} [products] 
         * @param {Array<string>} [contracts] 
         * @param {Array<string>} [currencies] 
         * @param {Array<string>} [accountTypes] 
         * @param {number} [debitOrCreditRangeStart] 
         * @param {number} [debitOrCreditRangeEnd] 
         * @param {number} [chargeRangeStart] 
         * @param {number} [chargeRangeEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRealizedGainsAndLosses(sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, products?: Array<string>, contracts?: Array<string>, currencies?: Array<string>, accountTypes?: Array<string>, debitOrCreditRangeStart?: number, debitOrCreditRangeEnd?: number, chargeRangeStart?: number, chargeRangeEnd?: number, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRealizedGainsAndLossesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRealizedGainsAndLosses(sourceSystem, accountNumber, dateRangeStart, dateRangeEnd, putOrCall, strikePriceRangeStart, strikePriceRangeEnd, exchanges, products, contracts, currencies, accountTypes, debitOrCreditRangeStart, debitOrCreditRangeEnd, chargeRangeStart, chargeRangeEnd, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} id 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRealizedGainsAndLossesDetails(sourceSystem: string, accountNumber: string, id: string, dateRangeStart?: string, dateRangeEnd?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRealizedGainsAndLossesDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRealizedGainsAndLossesDetails(sourceSystem, accountNumber, id, dateRangeStart, dateRangeEnd, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [tickerSearchCompositeKeys] 
         * @param {Array<string>} [reutersInstrumentCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReutersInstrumentCodes(tickerSearchCompositeKeys?: Array<string>, reutersInstrumentCodes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListReutersInstrumentCodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReutersInstrumentCodes(tickerSearchCompositeKeys, reutersInstrumentCodes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReutersInstrumentCodesForAccount(sourceSystem: string, accountNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListReutersInstrumentCodesForAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReutersInstrumentCodesForAccount(sourceSystem, accountNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSearchAccountsMercury(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSearchAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSearchAccountsMercury(searchTerm, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingAffiliationDetailsRequest} [updateFuturesOnboardingAffiliationDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFuturesOnboardingAffiliationDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingAffiliationDetailsRequest?: UpdateFuturesOnboardingAffiliationDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFuturesOnboardingAffiliationDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingAffiliationDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingAgreementsRequest} [updateFuturesOnboardingAgreementsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFuturesOnboardingAgreements(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingAgreementsRequest?: UpdateFuturesOnboardingAgreementsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFuturesOnboardingAgreements(futuresOnboardingApplicationGuid, updateFuturesOnboardingAgreementsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingContactRequest} [updateFuturesOnboardingContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFuturesOnboardingContact(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingContactRequest?: UpdateFuturesOnboardingContactRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFuturesOnboardingContact(futuresOnboardingApplicationGuid, updateFuturesOnboardingContactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingEmploymentDetailsRequest} [updateFuturesOnboardingEmploymentDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFuturesOnboardingEmploymentDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingEmploymentDetailsRequest?: UpdateFuturesOnboardingEmploymentDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFuturesOnboardingEmploymentDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingEmploymentDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingPersonalDetailsRequest} [updateFuturesOnboardingPersonalDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFuturesOnboardingPersonalDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingPersonalDetailsRequest?: UpdateFuturesOnboardingPersonalDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFuturesOnboardingPersonalDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingPersonalDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingRegulatoryDetailsRequest} [updateFuturesOnboardingRegulatoryDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFuturesOnboardingRegulatoryDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingRegulatoryDetailsRequest?: UpdateFuturesOnboardingRegulatoryDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFuturesOnboardingRegulatoryDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingRegulatoryDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingTradingExperienceDetailsRequest} [updateFuturesOnboardingTradingExperienceDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFuturesOnboardingTradingExperienceDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingTradingExperienceDetailsRequest?: UpdateFuturesOnboardingTradingExperienceDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFuturesOnboardingTradingExperienceDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingTradingExperienceDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FuturesApi - factory interface
 * @export
 */
export const FuturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FuturesApiFp(configuration)
    return {
        /**
         * 
         * @param {AddFuturesOnboardingApplicationRequest} [addFuturesOnboardingApplicationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFuturesOnboardingApplication(addFuturesOnboardingApplicationRequest?: AddFuturesOnboardingApplicationRequest, options?: any): AxiosPromise<AddFuturesOnboardingApplicationResponse> {
            return localVarFp.addFuturesOnboardingApplication(addFuturesOnboardingApplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {AddFuturesOnboardingContactRequest} [addFuturesOnboardingContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFuturesOnboardingContact(futuresOnboardingApplicationGuid: string, addFuturesOnboardingContactRequest?: AddFuturesOnboardingContactRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addFuturesOnboardingContact(futuresOnboardingApplicationGuid, addFuturesOnboardingContactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {AddFuturesOnboardingContactAddressRequest} [addFuturesOnboardingContactAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFuturesOnboardingContactAddress(futuresOnboardingApplicationGuid: string, addFuturesOnboardingContactAddressRequest?: AddFuturesOnboardingContactAddressRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addFuturesOnboardingContactAddress(futuresOnboardingApplicationGuid, addFuturesOnboardingContactAddressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {AddFuturesOnboardingFinancialsRequest} [addFuturesOnboardingFinancialsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFuturesOnboardingFinancials(futuresOnboardingApplicationGuid: string, addFuturesOnboardingFinancialsRequest?: AddFuturesOnboardingFinancialsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addFuturesOnboardingFinancials(futuresOnboardingApplicationGuid, addFuturesOnboardingFinancialsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCheckPdf(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadCheckPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientServicesCenterOnboardingApplication(futuresOnboardingApplicationGuid: string, options?: any): AxiosPromise<GetClientServicesCenterOnboardingApplicationResponse> {
            return localVarFp.getClientServicesCenterOnboardingApplication(futuresOnboardingApplicationGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentFuturesOnboardingApplication(options?: any): AxiosPromise<GetCurrentFuturesOnboardingApplicationGuidResponse> {
            return localVarFp.getCurrentFuturesOnboardingApplication(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFuturesOnboardingApplication(futuresOnboardingApplicationGuid: string, options?: any): AxiosPromise<GetFuturesOnboardingApplicationSummaryResponse> {
            return localVarFp.getFuturesOnboardingApplication(futuresOnboardingApplicationGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} timeSpan 
         * @param {number} contractNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketPriceVolatility(reutersInstrumentCodePrefix: string, timeSpan: string, contractNumber: number, options?: any): AxiosPromise<GetMarketPriceVolatilityResponse> {
            return localVarFp.getMarketPriceVolatility(reutersInstrumentCodePrefix, timeSpan, contractNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicalPositionsTicket(ticketId: string, options?: any): AxiosPromise<GetTicketResponse> {
            return localVarFp.getPhysicalPositionsTicket(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {StatementType} statementType 
         * @param {string} statementDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatement(sourceSystem: string, accountNumber: string, statementType: StatementType, statementDate: string, options?: any): AxiosPromise<any> {
            return localVarFp.getStatement(sourceSystem, accountNumber, statementType, statementDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountDetails(sourceSystem: string, accountNumber: string, options?: any): AxiosPromise<ListAccountDetailsResponse> {
            return localVarFp.listAccountDetails(sourceSystem, accountNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountTypes(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: any): AxiosPromise<ListAccountTypesResponse> {
            return localVarFp.listAccountTypes(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {Array<string>} [accountNumbers] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts(searchTerm?: string, accountNumbers?: Array<string>, take?: number, skip?: number, sortColumn?: string, sortDirection?: SortDirection, options?: any): AxiosPromise<ListAccountsResponse> {
            return localVarFp.listAccounts(searchTerm, accountNumbers, take, skip, sortColumn, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountsMercury(sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCodexAccountsResponse> {
            return localVarFp.listAccountsMercury(sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllMarketPricesComparisonChart(options?: any): AxiosPromise<ListMarketPricesComparisonChartResponse> {
            return localVarFp.listAllMarketPricesComparisonChart(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {Array<string>} [description] 
         * @param {Array<string>} [accountTypes] 
         * @param {number} [cashRangeStart] 
         * @param {number} [cashRangeEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCashActivity(sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, description?: Array<string>, accountTypes?: Array<string>, cashRangeStart?: number, cashRangeEnd?: number, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCashActivityResponse> {
            return localVarFp.listCashActivity(sourceSystem, accountNumber, dateRangeStart, dateRangeEnd, description, accountTypes, cashRangeStart, cashRangeEnd, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CashBidCommodity} [commodity] 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [radius] 
         * @param {string} [refinitivInstrumentCodePrefix] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCashBids(commodity?: CashBidCommodity, latitude?: number, longitude?: number, radius?: number, refinitivInstrumentCodePrefix?: string, take?: number, skip?: number, options?: any): AxiosPromise<ListCashBidsResponse> {
            return localVarFp.listCashBids(commodity, latitude, longitude, radius, refinitivInstrumentCodePrefix, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContracts(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: any): AxiosPromise<ListContractsResponse> {
            return localVarFp.listContracts(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCurrencies(sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, options?: any): AxiosPromise<ListCurrenciesResponse> {
            return localVarFp.listCurrencies(sourceSystem, accountNumber, dateRangeStart, dateRangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDescriptions(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: any): AxiosPromise<ListDescriptionsResponse> {
            return localVarFp.listDescriptions(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExchanges(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: any): AxiosPromise<ListExchangesResponse> {
            return localVarFp.listExchanges(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFees(sourceSystem: string, accountNumber: string, options?: any): AxiosPromise<ListCommissionAndFeesResponse> {
            return localVarFp.listFees(sourceSystem, accountNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFuturesBuyingPowersAccounts(sortColumn?: string, sortDirection?: SortDirection, options?: any): AxiosPromise<ListFuturesAccountBuyingPowersResponse> {
            return localVarFp.listFuturesBuyingPowersAccounts(sortColumn, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gainFuturesCodePrefix 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFuturesContracts(gainFuturesCodePrefix: string, take?: number, options?: any): AxiosPromise<ListFuturesContractsResponse> {
            return localVarFp.listFuturesContracts(gainFuturesCodePrefix, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketIndexes(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options?: any): AxiosPromise<ListMarketIndexValuesResponse> {
            return localVarFp.listMarketIndexes(reutersInstrumentCode, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricePositions(reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, options?: any): AxiosPromise<ListMarketPricePositionsResponse> {
            return localVarFp.listMarketPricePositions(reutersInstrumentCodePrefix, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {number} contractNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPriceVolatilities(reutersInstrumentCodePrefix: string, contractNumber: number, options?: any): AxiosPromise<ListMarketPriceVolatilitiesResponse> {
            return localVarFp.listMarketPriceVolatilities(reutersInstrumentCodePrefix, contractNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPrices(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options?: any): AxiosPromise<ListMarketPricesResponse> {
            return localVarFp.listMarketPrices(reutersInstrumentCode, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} contractYear 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesByContractYear(reutersInstrumentCodePrefix: string, contractYear: string, timeSpan: MarketPricesTimeSpan, options?: any): AxiosPromise<ListMarketPricesResponse> {
            return localVarFp.listMarketPricesByContractYear(reutersInstrumentCodePrefix, contractYear, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} asOfDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesByDay(reutersInstrumentCodePrefix: string, asOfDate: string, options?: any): AxiosPromise<ListMarketPricesResponse> {
            return localVarFp.listMarketPricesByDay(reutersInstrumentCodePrefix, asOfDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix1 
         * @param {string} reutersInstrumentCodePrefix2 
         * @param {string} [asOfDate] 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesComparison(reutersInstrumentCodePrefix1: string, reutersInstrumentCodePrefix2: string, asOfDate?: string, timeSpan?: MarketPricesTimeSpan, options?: any): AxiosPromise<ListMarketPricesComparisonResponse> {
            return localVarFp.listMarketPricesComparison(reutersInstrumentCodePrefix1, reutersInstrumentCodePrefix2, asOfDate, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesComparisonChart(reutersInstrumentCodePrefix: string, options?: any): AxiosPromise<ListMarketPricesComparisonChartResponse> {
            return localVarFp.listMarketPricesComparisonChart(reutersInstrumentCodePrefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesForTradingView(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options?: any): AxiosPromise<ListMarketPricesForTradingViewResponse> {
            return localVarFp.listMarketPricesForTradingView(reutersInstrumentCode, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} asOfDate 
         * @param {MarketPricesTimeSpan} [timeSpan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesForwardCurve(reutersInstrumentCodePrefix: string, asOfDate: string, timeSpan?: MarketPricesTimeSpan, options?: any): AxiosPromise<ListMarketPricesForwardCurveResponse> {
            return localVarFp.listMarketPricesForwardCurve(reutersInstrumentCodePrefix, asOfDate, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {number} contractNumber 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesRolling(reutersInstrumentCodePrefix: string, contractNumber: number, timeSpan: MarketPricesTimeSpan, options?: any): AxiosPromise<ListMarketPricesResponse> {
            return localVarFp.listMarketPricesRolling(reutersInstrumentCodePrefix, contractNumber, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {MarketPricesTimeSpan} timeSpan 
         * @param {number} [startingContractMonth] 
         * @param {Array<number>} [contractMonths] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesRollingYear(reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, startingContractMonth?: number, contractMonths?: Array<number>, options?: any): AxiosPromise<ListMarketPricesResponse> {
            return localVarFp.listMarketPricesRollingYear(reutersInstrumentCodePrefix, timeSpan, startingContractMonth, contractMonths, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketPricesToday(reutersInstrumentCodePrefix: string, asOfDate?: string, options?: any): AxiosPromise<ListMarketPricesResponse> {
            return localVarFp.listMarketPricesToday(reutersInstrumentCodePrefix, asOfDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gainFuturesCode 
         * @param {string} [expirationDate] 
         * @param {number} [numberOfStrikes] 
         * @param {OptionType} [optionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOptionsChain(gainFuturesCode: string, expirationDate?: string, numberOfStrikes?: number, optionType?: OptionType, options?: any): AxiosPromise<ListOptionsChainResponse> {
            return localVarFp.listOptionsChain(gainFuturesCode, expirationDate, numberOfStrikes, optionType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} gainFuturesCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOptionsExpirations(gainFuturesCode: string, options?: any): AxiosPromise<ListOptionsExpirationsResponse> {
            return localVarFp.listOptionsExpirations(gainFuturesCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [contractCodes] 
         * @param {Array<string>} [checkIds] 
         * @param {string} [fromCheckDate] 
         * @param {string} [toCheckDate] 
         * @param {string} [createdFromDate] 
         * @param {string} [createdToDate] 
         * @param {string} [updateSinceDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPhysicalPositionChecks(contractCodes?: Array<string>, checkIds?: Array<string>, fromCheckDate?: string, toCheckDate?: string, createdFromDate?: string, createdToDate?: string, updateSinceDate?: string, options?: any): AxiosPromise<ListChecksResponse> {
            return localVarFp.listPhysicalPositionChecks(contractCodes, checkIds, fromCheckDate, toCheckDate, createdFromDate, createdToDate, updateSinceDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [commodityId] 
         * @param {Array<string>} [contractCodes] 
         * @param {Array<string>} [purchaseSaleTypes] 
         * @param {Array<string>} [ticketIds] 
         * @param {Array<string>} [ticketNumbers] 
         * @param {string} [createdFrom] 
         * @param {string} [createdTo] 
         * @param {number} [destinationCustomerId] 
         * @param {number} [originId] 
         * @param {string} [unloadDate] 
         * @param {string} [updatedSince] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPhysicalPositionTickets(commodityId?: number, contractCodes?: Array<string>, purchaseSaleTypes?: Array<string>, ticketIds?: Array<string>, ticketNumbers?: Array<string>, createdFrom?: string, createdTo?: string, destinationCustomerId?: number, originId?: number, unloadDate?: string, updatedSince?: string, options?: any): AxiosPromise<ListTicketsResponse> {
            return localVarFp.listPhysicalPositionTickets(commodityId, contractCodes, purchaseSaleTypes, ticketIds, ticketNumbers, createdFrom, createdTo, destinationCustomerId, originId, unloadDate, updatedSince, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} id 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPositionDetails(sourceSystem: string, accountNumber: string, id: string, take?: number, skip?: number, options?: any): AxiosPromise<ListPositionDetailsResponse> {
            return localVarFp.listPositionDetails(sourceSystem, accountNumber, id, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {Array<string>} [products] 
         * @param {string} [tradeDateStart] 
         * @param {string} [tradeDateEnd] 
         * @param {Array<PutOrCall>} [putOrCall] 
         * @param {number} [strikePriceRangeStart] 
         * @param {number} [strikePriceRangeEnd] 
         * @param {Array<string>} [exchanges] 
         * @param {Array<string>} [contracts] 
         * @param {number} [triggerBarrierStart] 
         * @param {number} [triggerBarrierEnd] 
         * @param {number} [tradePriceRangeStart] 
         * @param {number} [tradePriceRangeEnd] 
         * @param {Array<string>} [ticketNumbers] 
         * @param {Array<string>} [tradeIds] 
         * @param {Array<string>} [globalIds] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPositions(sourceSystem: string, accountNumber: string, products?: Array<string>, tradeDateStart?: string, tradeDateEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, contracts?: Array<string>, triggerBarrierStart?: number, triggerBarrierEnd?: number, tradePriceRangeStart?: number, tradePriceRangeEnd?: number, ticketNumbers?: Array<string>, tradeIds?: Array<string>, globalIds?: Array<string>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListPositionsResponse> {
            return localVarFp.listPositions(sourceSystem, accountNumber, products, tradeDateStart, tradeDateEnd, putOrCall, strikePriceRangeStart, strikePriceRangeEnd, exchanges, contracts, triggerBarrierStart, triggerBarrierEnd, tradePriceRangeStart, tradePriceRangeEnd, ticketNumbers, tradeIds, globalIds, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {Array<string>} [products] 
         * @param {string} [tradeDateStart] 
         * @param {string} [tradeDateEnd] 
         * @param {Array<PutOrCall>} [putOrCall] 
         * @param {number} [strikePriceRangeStart] 
         * @param {number} [strikePriceRangeEnd] 
         * @param {Array<string>} [exchanges] 
         * @param {Array<string>} [contracts] 
         * @param {number} [triggerBarrierStart] 
         * @param {number} [triggerBarrierEnd] 
         * @param {number} [tradePriceRangeStart] 
         * @param {number} [tradePriceRangeEnd] 
         * @param {Array<string>} [ticketNumbers] 
         * @param {Array<string>} [tradeIds] 
         * @param {Array<string>} [globalIds] 
         * @param {string} [startDateStart] 
         * @param {string} [startDateEnd] 
         * @param {string} [endDateStart] 
         * @param {string} [endDateEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPriorDaysActivity(sourceSystem: string, accountNumber: string, products?: Array<string>, tradeDateStart?: string, tradeDateEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, contracts?: Array<string>, triggerBarrierStart?: number, triggerBarrierEnd?: number, tradePriceRangeStart?: number, tradePriceRangeEnd?: number, ticketNumbers?: Array<string>, tradeIds?: Array<string>, globalIds?: Array<string>, startDateStart?: string, startDateEnd?: string, endDateStart?: string, endDateEnd?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListPriorDaysActivityResponse> {
            return localVarFp.listPriorDaysActivity(sourceSystem, accountNumber, products, tradeDateStart, tradeDateEnd, putOrCall, strikePriceRangeStart, strikePriceRangeEnd, exchanges, contracts, triggerBarrierStart, triggerBarrierEnd, tradePriceRangeStart, tradePriceRangeEnd, ticketNumbers, tradeIds, globalIds, startDateStart, startDateEnd, endDateStart, endDateEnd, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} id 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPriorDaysActivityDetails(sourceSystem: string, accountNumber: string, id: string, take?: number, skip?: number, options?: any): AxiosPromise<ListPriorDaysActivityDetailsResponse> {
            return localVarFp.listPriorDaysActivityDetails(sourceSystem, accountNumber, id, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {ReportType} [reportType] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: any): AxiosPromise<ListProductsResponse> {
            return localVarFp.listProducts(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {Array<PutOrCall>} [putOrCall] 
         * @param {number} [strikePriceRangeStart] 
         * @param {number} [strikePriceRangeEnd] 
         * @param {Array<string>} [exchanges] 
         * @param {Array<string>} [products] 
         * @param {Array<string>} [contracts] 
         * @param {Array<string>} [currencies] 
         * @param {Array<string>} [accountTypes] 
         * @param {number} [debitOrCreditRangeStart] 
         * @param {number} [debitOrCreditRangeEnd] 
         * @param {number} [chargeRangeStart] 
         * @param {number} [chargeRangeEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRealizedGainsAndLosses(sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, products?: Array<string>, contracts?: Array<string>, currencies?: Array<string>, accountTypes?: Array<string>, debitOrCreditRangeStart?: number, debitOrCreditRangeEnd?: number, chargeRangeStart?: number, chargeRangeEnd?: number, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListRealizedGainsAndLossesResponse> {
            return localVarFp.listRealizedGainsAndLosses(sourceSystem, accountNumber, dateRangeStart, dateRangeEnd, putOrCall, strikePriceRangeStart, strikePriceRangeEnd, exchanges, products, contracts, currencies, accountTypes, debitOrCreditRangeStart, debitOrCreditRangeEnd, chargeRangeStart, chargeRangeEnd, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {string} id 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRealizedGainsAndLossesDetails(sourceSystem: string, accountNumber: string, id: string, dateRangeStart?: string, dateRangeEnd?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListRealizedGainsAndLossesDetailsResponse> {
            return localVarFp.listRealizedGainsAndLossesDetails(sourceSystem, accountNumber, id, dateRangeStart, dateRangeEnd, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [tickerSearchCompositeKeys] 
         * @param {Array<string>} [reutersInstrumentCodes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReutersInstrumentCodes(tickerSearchCompositeKeys?: Array<string>, reutersInstrumentCodes?: Array<string>, options?: any): AxiosPromise<ListReutersInstrumentCodesResponse> {
            return localVarFp.listReutersInstrumentCodes(tickerSearchCompositeKeys, reutersInstrumentCodes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceSystem 
         * @param {string} accountNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReutersInstrumentCodesForAccount(sourceSystem: string, accountNumber: string, options?: any): AxiosPromise<ListReutersInstrumentCodesForAccountResponse> {
            return localVarFp.listReutersInstrumentCodesForAccount(sourceSystem, accountNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSearchAccountsMercury(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListSearchAccountsResponse> {
            return localVarFp.listSearchAccountsMercury(searchTerm, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingAffiliationDetailsRequest} [updateFuturesOnboardingAffiliationDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingAffiliationDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingAffiliationDetailsRequest?: UpdateFuturesOnboardingAffiliationDetailsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateFuturesOnboardingAffiliationDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingAffiliationDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingAgreementsRequest} [updateFuturesOnboardingAgreementsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingAgreements(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingAgreementsRequest?: UpdateFuturesOnboardingAgreementsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateFuturesOnboardingAgreements(futuresOnboardingApplicationGuid, updateFuturesOnboardingAgreementsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingContactRequest} [updateFuturesOnboardingContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingContact(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingContactRequest?: UpdateFuturesOnboardingContactRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateFuturesOnboardingContact(futuresOnboardingApplicationGuid, updateFuturesOnboardingContactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingEmploymentDetailsRequest} [updateFuturesOnboardingEmploymentDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingEmploymentDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingEmploymentDetailsRequest?: UpdateFuturesOnboardingEmploymentDetailsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateFuturesOnboardingEmploymentDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingEmploymentDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingPersonalDetailsRequest} [updateFuturesOnboardingPersonalDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingPersonalDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingPersonalDetailsRequest?: UpdateFuturesOnboardingPersonalDetailsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateFuturesOnboardingPersonalDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingPersonalDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingRegulatoryDetailsRequest} [updateFuturesOnboardingRegulatoryDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingRegulatoryDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingRegulatoryDetailsRequest?: UpdateFuturesOnboardingRegulatoryDetailsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateFuturesOnboardingRegulatoryDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingRegulatoryDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} futuresOnboardingApplicationGuid 
         * @param {UpdateFuturesOnboardingTradingExperienceDetailsRequest} [updateFuturesOnboardingTradingExperienceDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFuturesOnboardingTradingExperienceDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingTradingExperienceDetailsRequest?: UpdateFuturesOnboardingTradingExperienceDetailsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateFuturesOnboardingTradingExperienceDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingTradingExperienceDetailsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FuturesApi - object-oriented interface
 * @export
 * @class FuturesApi
 * @extends {BaseAPI}
 */
export class FuturesApi extends BaseAPI {
    /**
     * 
     * @param {AddFuturesOnboardingApplicationRequest} [addFuturesOnboardingApplicationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public addFuturesOnboardingApplication(addFuturesOnboardingApplicationRequest?: AddFuturesOnboardingApplicationRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).addFuturesOnboardingApplication(addFuturesOnboardingApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {AddFuturesOnboardingContactRequest} [addFuturesOnboardingContactRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public addFuturesOnboardingContact(futuresOnboardingApplicationGuid: string, addFuturesOnboardingContactRequest?: AddFuturesOnboardingContactRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).addFuturesOnboardingContact(futuresOnboardingApplicationGuid, addFuturesOnboardingContactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {AddFuturesOnboardingContactAddressRequest} [addFuturesOnboardingContactAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public addFuturesOnboardingContactAddress(futuresOnboardingApplicationGuid: string, addFuturesOnboardingContactAddressRequest?: AddFuturesOnboardingContactAddressRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).addFuturesOnboardingContactAddress(futuresOnboardingApplicationGuid, addFuturesOnboardingContactAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {AddFuturesOnboardingFinancialsRequest} [addFuturesOnboardingFinancialsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public addFuturesOnboardingFinancials(futuresOnboardingApplicationGuid: string, addFuturesOnboardingFinancialsRequest?: AddFuturesOnboardingFinancialsRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).addFuturesOnboardingFinancials(futuresOnboardingApplicationGuid, addFuturesOnboardingFinancialsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public downloadCheckPdf(id: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).downloadCheckPdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public getClientServicesCenterOnboardingApplication(futuresOnboardingApplicationGuid: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).getClientServicesCenterOnboardingApplication(futuresOnboardingApplicationGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public getCurrentFuturesOnboardingApplication(options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).getCurrentFuturesOnboardingApplication(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public getFuturesOnboardingApplication(futuresOnboardingApplicationGuid: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).getFuturesOnboardingApplication(futuresOnboardingApplicationGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {string} timeSpan 
     * @param {number} contractNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public getMarketPriceVolatility(reutersInstrumentCodePrefix: string, timeSpan: string, contractNumber: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).getMarketPriceVolatility(reutersInstrumentCodePrefix, timeSpan, contractNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public getPhysicalPositionsTicket(ticketId: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).getPhysicalPositionsTicket(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {StatementType} statementType 
     * @param {string} statementDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public getStatement(sourceSystem: string, accountNumber: string, statementType: StatementType, statementDate: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).getStatement(sourceSystem, accountNumber, statementType, statementDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listAccountDetails(sourceSystem: string, accountNumber: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listAccountDetails(sourceSystem, accountNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {ReportType} [reportType] 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listAccountTypes(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listAccountTypes(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {Array<string>} [accountNumbers] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listAccounts(searchTerm?: string, accountNumbers?: Array<string>, take?: number, skip?: number, sortColumn?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listAccounts(searchTerm, accountNumbers, take, skip, sortColumn, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listAccountsMercury(sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listAccountsMercury(sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listAllMarketPricesComparisonChart(options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listAllMarketPricesComparisonChart(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {Array<string>} [description] 
     * @param {Array<string>} [accountTypes] 
     * @param {number} [cashRangeStart] 
     * @param {number} [cashRangeEnd] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listCashActivity(sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, description?: Array<string>, accountTypes?: Array<string>, cashRangeStart?: number, cashRangeEnd?: number, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listCashActivity(sourceSystem, accountNumber, dateRangeStart, dateRangeEnd, description, accountTypes, cashRangeStart, cashRangeEnd, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashBidCommodity} [commodity] 
     * @param {number} [latitude] 
     * @param {number} [longitude] 
     * @param {number} [radius] 
     * @param {string} [refinitivInstrumentCodePrefix] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listCashBids(commodity?: CashBidCommodity, latitude?: number, longitude?: number, radius?: number, refinitivInstrumentCodePrefix?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listCashBids(commodity, latitude, longitude, radius, refinitivInstrumentCodePrefix, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {ReportType} [reportType] 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listContracts(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listContracts(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listCurrencies(sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listCurrencies(sourceSystem, accountNumber, dateRangeStart, dateRangeEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {ReportType} [reportType] 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listDescriptions(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listDescriptions(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {ReportType} [reportType] 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listExchanges(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listExchanges(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listFees(sourceSystem: string, accountNumber: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listFees(sourceSystem, accountNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listFuturesBuyingPowersAccounts(sortColumn?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listFuturesBuyingPowersAccounts(sortColumn, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gainFuturesCodePrefix 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listFuturesContracts(gainFuturesCodePrefix: string, take?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listFuturesContracts(gainFuturesCodePrefix, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCode 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketIndexes(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketIndexes(reutersInstrumentCode, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPricePositions(reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPricePositions(reutersInstrumentCodePrefix, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {number} contractNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPriceVolatilities(reutersInstrumentCodePrefix: string, contractNumber: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPriceVolatilities(reutersInstrumentCodePrefix, contractNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCode 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPrices(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPrices(reutersInstrumentCode, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {string} contractYear 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPricesByContractYear(reutersInstrumentCodePrefix: string, contractYear: string, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPricesByContractYear(reutersInstrumentCodePrefix, contractYear, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {string} asOfDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPricesByDay(reutersInstrumentCodePrefix: string, asOfDate: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPricesByDay(reutersInstrumentCodePrefix, asOfDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix1 
     * @param {string} reutersInstrumentCodePrefix2 
     * @param {string} [asOfDate] 
     * @param {MarketPricesTimeSpan} [timeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPricesComparison(reutersInstrumentCodePrefix1: string, reutersInstrumentCodePrefix2: string, asOfDate?: string, timeSpan?: MarketPricesTimeSpan, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPricesComparison(reutersInstrumentCodePrefix1, reutersInstrumentCodePrefix2, asOfDate, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPricesComparisonChart(reutersInstrumentCodePrefix: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPricesComparisonChart(reutersInstrumentCodePrefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCode 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPricesForTradingView(reutersInstrumentCode: string, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPricesForTradingView(reutersInstrumentCode, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {string} asOfDate 
     * @param {MarketPricesTimeSpan} [timeSpan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPricesForwardCurve(reutersInstrumentCodePrefix: string, asOfDate: string, timeSpan?: MarketPricesTimeSpan, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPricesForwardCurve(reutersInstrumentCodePrefix, asOfDate, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {number} contractNumber 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPricesRolling(reutersInstrumentCodePrefix: string, contractNumber: number, timeSpan: MarketPricesTimeSpan, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPricesRolling(reutersInstrumentCodePrefix, contractNumber, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {MarketPricesTimeSpan} timeSpan 
     * @param {number} [startingContractMonth] 
     * @param {Array<number>} [contractMonths] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPricesRollingYear(reutersInstrumentCodePrefix: string, timeSpan: MarketPricesTimeSpan, startingContractMonth?: number, contractMonths?: Array<number>, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPricesRollingYear(reutersInstrumentCodePrefix, timeSpan, startingContractMonth, contractMonths, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {string} [asOfDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listMarketPricesToday(reutersInstrumentCodePrefix: string, asOfDate?: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listMarketPricesToday(reutersInstrumentCodePrefix, asOfDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gainFuturesCode 
     * @param {string} [expirationDate] 
     * @param {number} [numberOfStrikes] 
     * @param {OptionType} [optionType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listOptionsChain(gainFuturesCode: string, expirationDate?: string, numberOfStrikes?: number, optionType?: OptionType, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listOptionsChain(gainFuturesCode, expirationDate, numberOfStrikes, optionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} gainFuturesCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listOptionsExpirations(gainFuturesCode: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listOptionsExpirations(gainFuturesCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [contractCodes] 
     * @param {Array<string>} [checkIds] 
     * @param {string} [fromCheckDate] 
     * @param {string} [toCheckDate] 
     * @param {string} [createdFromDate] 
     * @param {string} [createdToDate] 
     * @param {string} [updateSinceDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listPhysicalPositionChecks(contractCodes?: Array<string>, checkIds?: Array<string>, fromCheckDate?: string, toCheckDate?: string, createdFromDate?: string, createdToDate?: string, updateSinceDate?: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listPhysicalPositionChecks(contractCodes, checkIds, fromCheckDate, toCheckDate, createdFromDate, createdToDate, updateSinceDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [commodityId] 
     * @param {Array<string>} [contractCodes] 
     * @param {Array<string>} [purchaseSaleTypes] 
     * @param {Array<string>} [ticketIds] 
     * @param {Array<string>} [ticketNumbers] 
     * @param {string} [createdFrom] 
     * @param {string} [createdTo] 
     * @param {number} [destinationCustomerId] 
     * @param {number} [originId] 
     * @param {string} [unloadDate] 
     * @param {string} [updatedSince] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listPhysicalPositionTickets(commodityId?: number, contractCodes?: Array<string>, purchaseSaleTypes?: Array<string>, ticketIds?: Array<string>, ticketNumbers?: Array<string>, createdFrom?: string, createdTo?: string, destinationCustomerId?: number, originId?: number, unloadDate?: string, updatedSince?: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listPhysicalPositionTickets(commodityId, contractCodes, purchaseSaleTypes, ticketIds, ticketNumbers, createdFrom, createdTo, destinationCustomerId, originId, unloadDate, updatedSince, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {string} id 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listPositionDetails(sourceSystem: string, accountNumber: string, id: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listPositionDetails(sourceSystem, accountNumber, id, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {Array<string>} [products] 
     * @param {string} [tradeDateStart] 
     * @param {string} [tradeDateEnd] 
     * @param {Array<PutOrCall>} [putOrCall] 
     * @param {number} [strikePriceRangeStart] 
     * @param {number} [strikePriceRangeEnd] 
     * @param {Array<string>} [exchanges] 
     * @param {Array<string>} [contracts] 
     * @param {number} [triggerBarrierStart] 
     * @param {number} [triggerBarrierEnd] 
     * @param {number} [tradePriceRangeStart] 
     * @param {number} [tradePriceRangeEnd] 
     * @param {Array<string>} [ticketNumbers] 
     * @param {Array<string>} [tradeIds] 
     * @param {Array<string>} [globalIds] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listPositions(sourceSystem: string, accountNumber: string, products?: Array<string>, tradeDateStart?: string, tradeDateEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, contracts?: Array<string>, triggerBarrierStart?: number, triggerBarrierEnd?: number, tradePriceRangeStart?: number, tradePriceRangeEnd?: number, ticketNumbers?: Array<string>, tradeIds?: Array<string>, globalIds?: Array<string>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listPositions(sourceSystem, accountNumber, products, tradeDateStart, tradeDateEnd, putOrCall, strikePriceRangeStart, strikePriceRangeEnd, exchanges, contracts, triggerBarrierStart, triggerBarrierEnd, tradePriceRangeStart, tradePriceRangeEnd, ticketNumbers, tradeIds, globalIds, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {Array<string>} [products] 
     * @param {string} [tradeDateStart] 
     * @param {string} [tradeDateEnd] 
     * @param {Array<PutOrCall>} [putOrCall] 
     * @param {number} [strikePriceRangeStart] 
     * @param {number} [strikePriceRangeEnd] 
     * @param {Array<string>} [exchanges] 
     * @param {Array<string>} [contracts] 
     * @param {number} [triggerBarrierStart] 
     * @param {number} [triggerBarrierEnd] 
     * @param {number} [tradePriceRangeStart] 
     * @param {number} [tradePriceRangeEnd] 
     * @param {Array<string>} [ticketNumbers] 
     * @param {Array<string>} [tradeIds] 
     * @param {Array<string>} [globalIds] 
     * @param {string} [startDateStart] 
     * @param {string} [startDateEnd] 
     * @param {string} [endDateStart] 
     * @param {string} [endDateEnd] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listPriorDaysActivity(sourceSystem: string, accountNumber: string, products?: Array<string>, tradeDateStart?: string, tradeDateEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, contracts?: Array<string>, triggerBarrierStart?: number, triggerBarrierEnd?: number, tradePriceRangeStart?: number, tradePriceRangeEnd?: number, ticketNumbers?: Array<string>, tradeIds?: Array<string>, globalIds?: Array<string>, startDateStart?: string, startDateEnd?: string, endDateStart?: string, endDateEnd?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listPriorDaysActivity(sourceSystem, accountNumber, products, tradeDateStart, tradeDateEnd, putOrCall, strikePriceRangeStart, strikePriceRangeEnd, exchanges, contracts, triggerBarrierStart, triggerBarrierEnd, tradePriceRangeStart, tradePriceRangeEnd, ticketNumbers, tradeIds, globalIds, startDateStart, startDateEnd, endDateStart, endDateEnd, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {string} id 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listPriorDaysActivityDetails(sourceSystem: string, accountNumber: string, id: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listPriorDaysActivityDetails(sourceSystem, accountNumber, id, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {ReportType} [reportType] 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listProducts(sourceSystem: string, accountNumber: string, reportType?: ReportType, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listProducts(sourceSystem, accountNumber, reportType, dateRangeStart, dateRangeEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {Array<PutOrCall>} [putOrCall] 
     * @param {number} [strikePriceRangeStart] 
     * @param {number} [strikePriceRangeEnd] 
     * @param {Array<string>} [exchanges] 
     * @param {Array<string>} [products] 
     * @param {Array<string>} [contracts] 
     * @param {Array<string>} [currencies] 
     * @param {Array<string>} [accountTypes] 
     * @param {number} [debitOrCreditRangeStart] 
     * @param {number} [debitOrCreditRangeEnd] 
     * @param {number} [chargeRangeStart] 
     * @param {number} [chargeRangeEnd] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listRealizedGainsAndLosses(sourceSystem: string, accountNumber: string, dateRangeStart?: string, dateRangeEnd?: string, putOrCall?: Array<PutOrCall>, strikePriceRangeStart?: number, strikePriceRangeEnd?: number, exchanges?: Array<string>, products?: Array<string>, contracts?: Array<string>, currencies?: Array<string>, accountTypes?: Array<string>, debitOrCreditRangeStart?: number, debitOrCreditRangeEnd?: number, chargeRangeStart?: number, chargeRangeEnd?: number, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listRealizedGainsAndLosses(sourceSystem, accountNumber, dateRangeStart, dateRangeEnd, putOrCall, strikePriceRangeStart, strikePriceRangeEnd, exchanges, products, contracts, currencies, accountTypes, debitOrCreditRangeStart, debitOrCreditRangeEnd, chargeRangeStart, chargeRangeEnd, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {string} id 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listRealizedGainsAndLossesDetails(sourceSystem: string, accountNumber: string, id: string, dateRangeStart?: string, dateRangeEnd?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listRealizedGainsAndLossesDetails(sourceSystem, accountNumber, id, dateRangeStart, dateRangeEnd, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [tickerSearchCompositeKeys] 
     * @param {Array<string>} [reutersInstrumentCodes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listReutersInstrumentCodes(tickerSearchCompositeKeys?: Array<string>, reutersInstrumentCodes?: Array<string>, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listReutersInstrumentCodes(tickerSearchCompositeKeys, reutersInstrumentCodes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceSystem 
     * @param {string} accountNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listReutersInstrumentCodesForAccount(sourceSystem: string, accountNumber: string, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listReutersInstrumentCodesForAccount(sourceSystem, accountNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public listSearchAccountsMercury(searchTerm?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).listSearchAccountsMercury(searchTerm, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {UpdateFuturesOnboardingAffiliationDetailsRequest} [updateFuturesOnboardingAffiliationDetailsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public updateFuturesOnboardingAffiliationDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingAffiliationDetailsRequest?: UpdateFuturesOnboardingAffiliationDetailsRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).updateFuturesOnboardingAffiliationDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingAffiliationDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {UpdateFuturesOnboardingAgreementsRequest} [updateFuturesOnboardingAgreementsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public updateFuturesOnboardingAgreements(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingAgreementsRequest?: UpdateFuturesOnboardingAgreementsRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).updateFuturesOnboardingAgreements(futuresOnboardingApplicationGuid, updateFuturesOnboardingAgreementsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {UpdateFuturesOnboardingContactRequest} [updateFuturesOnboardingContactRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public updateFuturesOnboardingContact(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingContactRequest?: UpdateFuturesOnboardingContactRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).updateFuturesOnboardingContact(futuresOnboardingApplicationGuid, updateFuturesOnboardingContactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {UpdateFuturesOnboardingEmploymentDetailsRequest} [updateFuturesOnboardingEmploymentDetailsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public updateFuturesOnboardingEmploymentDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingEmploymentDetailsRequest?: UpdateFuturesOnboardingEmploymentDetailsRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).updateFuturesOnboardingEmploymentDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingEmploymentDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {UpdateFuturesOnboardingPersonalDetailsRequest} [updateFuturesOnboardingPersonalDetailsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public updateFuturesOnboardingPersonalDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingPersonalDetailsRequest?: UpdateFuturesOnboardingPersonalDetailsRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).updateFuturesOnboardingPersonalDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingPersonalDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {UpdateFuturesOnboardingRegulatoryDetailsRequest} [updateFuturesOnboardingRegulatoryDetailsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public updateFuturesOnboardingRegulatoryDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingRegulatoryDetailsRequest?: UpdateFuturesOnboardingRegulatoryDetailsRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).updateFuturesOnboardingRegulatoryDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingRegulatoryDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} futuresOnboardingApplicationGuid 
     * @param {UpdateFuturesOnboardingTradingExperienceDetailsRequest} [updateFuturesOnboardingTradingExperienceDetailsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuturesApi
     */
    public updateFuturesOnboardingTradingExperienceDetails(futuresOnboardingApplicationGuid: string, updateFuturesOnboardingTradingExperienceDetailsRequest?: UpdateFuturesOnboardingTradingExperienceDetailsRequest, options?: AxiosRequestConfig) {
        return FuturesApiFp(this.configuration).updateFuturesOnboardingTradingExperienceDetails(futuresOnboardingApplicationGuid, updateFuturesOnboardingTradingExperienceDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalsApi - axios parameter creator
 * @export
 */
export const GlobalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConversionRate: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/conversion-rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEquitiesPricesEndOfDay: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/equities/prices/eod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEquitiesSearch: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/equities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFuturesPrices: async (reutersInstrumentCode: string, dateRangeStart?: string, dateRangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCode' is not null or undefined
            assertParamExists('listFuturesPrices', 'reutersInstrumentCode', reutersInstrumentCode)
            const localVarPath = `/globals/prices/futures/{reutersInstrumentCode}`
                .replace(`{${"reutersInstrumentCode"}}`, encodeURIComponent(String(reutersInstrumentCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalApplications: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/custody/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalContacts: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/custody/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalFatca: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/custody/fatca`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalFinancials: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/custody/financials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalLimits: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/custody/limits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalRegulatory: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/custody/regulatory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalSalesforceContacts: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/cdhub/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalSalesforceCountries: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/cdhub/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalSalesforceRegions: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/cdhub/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalSalesforceServices: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/globals/cdhub/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalsApi - functional programming interface
 * @export
 */
export const GlobalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConversionRate(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConversionRate(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEquitiesPricesEndOfDay(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEquitiesPricesEndOfDay(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEquitiesSearch(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEquitiesSearch(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFuturesPrices(reutersInstrumentCode: string, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFuturesHistoricalPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFuturesPrices(reutersInstrumentCode, dateRangeStart, dateRangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalApplications(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalApplications(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalContacts(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalContacts(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalFatca(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalFatca(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalFinancials(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalFinancials(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalLimits(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalLimits(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalRegulatory(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalRegulatory(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalSalesforceContacts(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalSalesforceContacts(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalSalesforceCountries(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalSalesforceCountries(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalSalesforceRegions(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalSalesforceRegions(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGlobalSalesforceServices(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGlobalSalesforceServices(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalsApi - factory interface
 * @export
 */
export const GlobalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConversionRate(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listConversionRate(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEquitiesPricesEndOfDay(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listEquitiesPricesEndOfDay(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEquitiesSearch(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listEquitiesSearch(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCode 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFuturesPrices(reutersInstrumentCode: string, dateRangeStart?: string, dateRangeEnd?: string, options?: any): AxiosPromise<ListFuturesHistoricalPricesResponse> {
            return localVarFp.listFuturesPrices(reutersInstrumentCode, dateRangeStart, dateRangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalApplications(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listGlobalApplications(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalContacts(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listGlobalContacts(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalFatca(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listGlobalFatca(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalFinancials(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listGlobalFinancials(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalLimits(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listGlobalLimits(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalRegulatory(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listGlobalRegulatory(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalSalesforceContacts(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listGlobalSalesforceContacts(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalSalesforceCountries(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listGlobalSalesforceCountries(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalSalesforceRegions(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listGlobalSalesforceRegions(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGlobalSalesforceServices(skip?: number, take?: number, options?: any): AxiosPromise<ListEntitiesResponse> {
            return localVarFp.listGlobalSalesforceServices(skip, take, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalsApi - object-oriented interface
 * @export
 * @class GlobalsApi
 * @extends {BaseAPI}
 */
export class GlobalsApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listConversionRate(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listConversionRate(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listEquitiesPricesEndOfDay(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listEquitiesPricesEndOfDay(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listEquitiesSearch(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listEquitiesSearch(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCode 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listFuturesPrices(reutersInstrumentCode: string, dateRangeStart?: string, dateRangeEnd?: string, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listFuturesPrices(reutersInstrumentCode, dateRangeStart, dateRangeEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listGlobalApplications(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listGlobalApplications(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listGlobalContacts(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listGlobalContacts(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listGlobalFatca(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listGlobalFatca(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listGlobalFinancials(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listGlobalFinancials(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listGlobalLimits(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listGlobalLimits(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listGlobalRegulatory(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listGlobalRegulatory(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listGlobalSalesforceContacts(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listGlobalSalesforceContacts(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listGlobalSalesforceCountries(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listGlobalSalesforceCountries(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listGlobalSalesforceRegions(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listGlobalSalesforceRegions(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsApi
     */
    public listGlobalSalesforceServices(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return GlobalsApiFp(this.configuration).listGlobalSalesforceServices(skip, take, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationHealth(options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.getApplicationHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public getApplicationHealth(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).getApplicationHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KnowRiskApi - axios parameter creator
 * @export
 */
export const KnowRiskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add an account.
         * @param {AddKnowRiskAccountRequest} [addKnowRiskAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccount: async (addKnowRiskAccountRequest?: AddKnowRiskAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addKnowRiskAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add income calculator.
         * @param {AddIncomeCalculatorRequest} [addIncomeCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIncomeCalculator: async (addIncomeCalculatorRequest?: AddIncomeCalculatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/income-calculator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addIncomeCalculatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddKnowRiskFavoriteRequest} [addKnowRiskFavoriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKnowRiskUserFavorites: async (addKnowRiskFavoriteRequest?: AddKnowRiskFavoriteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addKnowRiskFavoriteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddSupportTicketRequest} [addSupportTicketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSupportTicket: async (addSupportTicketRequest?: AddSupportTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/support/ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addSupportTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} featureType 
         * @param {string} knowRiskUserGuid 
         * @param {Application} mobileApplication 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature: async (featureType: string, knowRiskUserGuid: string, mobileApplication: Application, mobileApplicationCountry?: MobileApplicationCountry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureType' is not null or undefined
            assertParamExists('deleteFeature', 'featureType', featureType)
            // verify required parameter 'knowRiskUserGuid' is not null or undefined
            assertParamExists('deleteFeature', 'knowRiskUserGuid', knowRiskUserGuid)
            // verify required parameter 'mobileApplication' is not null or undefined
            assertParamExists('deleteFeature', 'mobileApplication', mobileApplication)
            const localVarPath = `/know-risk/features/{featureType}/user/{knowRiskUserGuid}`
                .replace(`{${"featureType"}}`, encodeURIComponent(String(featureType)))
                .replace(`{${"knowRiskUserGuid"}}`, encodeURIComponent(String(knowRiskUserGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mobileApplication !== undefined) {
                localVarQueryParameter['MobileApplication'] = mobileApplication;
            }

            if (mobileApplicationCountry !== undefined) {
                localVarQueryParameter['MobileApplicationCountry'] = mobileApplicationCountry;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} favoriteType 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKnowRiskUserFavorites: async (favoriteType: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteType' is not null or undefined
            assertParamExists('deleteKnowRiskUserFavorites', 'favoriteType', favoriteType)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('deleteKnowRiskUserFavorites', 'value', value)
            const localVarPath = `/know-risk/favorites/{favoriteType}/{value}`
                .replace(`{${"favoriteType"}}`, encodeURIComponent(String(favoriteType)))
                .replace(`{${"value"}}`, encodeURIComponent(String(value)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableKnowRiskUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/constants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllKnowRiskAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/accounts/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEndOfDayFertilizerPricesService: async (mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/prices/fertilizer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mobileApplication !== undefined) {
                localVarQueryParameter['MobileApplication'] = mobileApplication;
            }

            if (mobileApplicationCountry !== undefined) {
                localVarQueryParameter['MobileApplicationCountry'] = mobileApplicationCountry;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeatures: async (mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mobileApplication !== undefined) {
                localVarQueryParameter['MobileApplication'] = mobileApplication;
            }

            if (mobileApplicationCountry !== undefined) {
                localVarQueryParameter['MobileApplicationCountry'] = mobileApplicationCountry;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIncomeCalculatorDefinitions: async (mobileApplicationCountry?: MobileApplicationCountry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/income-calculator/definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mobileApplicationCountry !== undefined) {
                localVarQueryParameter['MobileApplicationCountry'] = mobileApplicationCountry;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List income calculators.
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIncomeCalculators: async (sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/income-calculator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List your accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CashBidCommodity} [commodity] 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [radius] 
         * @param {string} [refinitivInstrumentCodePrefix] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskCashBids: async (commodity?: CashBidCommodity, latitude?: number, longitude?: number, radius?: number, refinitivInstrumentCodePrefix?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/cash-bids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commodity !== undefined) {
                localVarQueryParameter['Commodity'] = commodity;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }

            if (radius !== undefined) {
                localVarQueryParameter['Radius'] = radius;
            }

            if (refinitivInstrumentCodePrefix !== undefined) {
                localVarQueryParameter['RefinitivInstrumentCodePrefix'] = refinitivInstrumentCodePrefix;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskCashBidsByFavorite: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/cash-bids/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cropType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskCashBidsCanada: async (cropType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cropType' is not null or undefined
            assertParamExists('listKnowRiskCashBidsCanada', 'cropType', cropType)
            const localVarPath = `/know-risk/cash-bids/canada/{cropType}`
                .replace(`{${"cropType"}}`, encodeURIComponent(String(cropType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskCashBidsCanadaByFavorites: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/cash-bids/canada/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} favoriteType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskFavorites: async (favoriteType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteType' is not null or undefined
            assertParamExists('listKnowRiskFavorites', 'favoriteType', favoriteType)
            const localVarPath = `/know-risk/favorites/{favoriteType}`
                .replace(`{${"favoriteType"}}`, encodeURIComponent(String(favoriteType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSymbols: async (mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/features/symbols`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mobileApplication !== undefined) {
                localVarQueryParameter['MobileApplication'] = mobileApplication;
            }

            if (mobileApplicationCountry !== undefined) {
                localVarQueryParameter['MobileApplicationCountry'] = mobileApplicationCountry;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSymbolsByFavorites: async (mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/features/symbols/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mobileApplication !== undefined) {
                localVarQueryParameter['MobileApplication'] = mobileApplication;
            }

            if (mobileApplicationCountry !== undefined) {
                localVarQueryParameter['MobileApplicationCountry'] = mobileApplicationCountry;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendSupportEmailRequest} [sendSupportEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSupportEmail: async (sendSupportEmailRequest?: SendSupportEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/support/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendSupportEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} featureType 
         * @param {UpdateKnowRiskFeatureRequest} [updateKnowRiskFeatureRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature: async (featureType: string, updateKnowRiskFeatureRequest?: UpdateKnowRiskFeatureRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureType' is not null or undefined
            assertParamExists('updateFeature', 'featureType', featureType)
            const localVarPath = `/know-risk/features/{featureType}`
                .replace(`{${"featureType"}}`, encodeURIComponent(String(featureType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKnowRiskFeatureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update income calculator.
         * @param {string} cropType 
         * @param {UpdateIncomeCalculatorRequest} [updateIncomeCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncomeCalculator: async (cropType: string, updateIncomeCalculatorRequest?: UpdateIncomeCalculatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cropType' is not null or undefined
            assertParamExists('updateIncomeCalculator', 'cropType', cropType)
            const localVarPath = `/know-risk/income-calculator/{cropType}`
                .replace(`{${"cropType"}}`, encodeURIComponent(String(cropType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIncomeCalculatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {UpdateKnowRiskSymbolRequest} [updateKnowRiskSymbolRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSymbol: async (reutersInstrumentCodePrefix: string, updateKnowRiskSymbolRequest?: UpdateKnowRiskSymbolRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reutersInstrumentCodePrefix' is not null or undefined
            assertParamExists('updateSymbol', 'reutersInstrumentCodePrefix', reutersInstrumentCodePrefix)
            const localVarPath = `/know-risk/features/symbols/{reutersInstrumentCodePrefix}`
                .replace(`{${"reutersInstrumentCodePrefix"}}`, encodeURIComponent(String(reutersInstrumentCodePrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKnowRiskSymbolRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnowRiskApi - functional programming interface
 * @export
 */
export const KnowRiskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KnowRiskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add an account.
         * @param {AddKnowRiskAccountRequest} [addKnowRiskAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAccount(addKnowRiskAccountRequest?: AddKnowRiskAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddKnowRiskAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAccount(addKnowRiskAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add income calculator.
         * @param {AddIncomeCalculatorRequest} [addIncomeCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addIncomeCalculator(addIncomeCalculatorRequest?: AddIncomeCalculatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddIncomeCalculatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addIncomeCalculator(addIncomeCalculatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddKnowRiskFavoriteRequest} [addKnowRiskFavoriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addKnowRiskUserFavorites(addKnowRiskFavoriteRequest?: AddKnowRiskFavoriteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addKnowRiskUserFavorites(addKnowRiskFavoriteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddSupportTicketRequest} [addSupportTicketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSupportTicket(addSupportTicketRequest?: AddSupportTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSupportTicket(addSupportTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} featureType 
         * @param {string} knowRiskUserGuid 
         * @param {Application} mobileApplication 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeature(featureType: string, knowRiskUserGuid: string, mobileApplication: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeature(featureType, knowRiskUserGuid, mobileApplication, mobileApplicationCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} favoriteType 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteKnowRiskUserFavorites(favoriteType: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteKnowRiskUserFavorites(favoriteType, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableKnowRiskUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableKnowRiskUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<EnumAndDescriptionModel>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllKnowRiskAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListKnowRiskAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllKnowRiskAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEndOfDayFertilizerPricesService(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEndOfDayFertilizerPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEndOfDayFertilizerPricesService(mobileApplication, mobileApplicationCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFeatures(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListKnowRiskFeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFeatures(mobileApplication, mobileApplicationCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIncomeCalculatorDefinitions(mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListIncomeCalculatorDefinitionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIncomeCalculatorDefinitions(mobileApplicationCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List income calculators.
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIncomeCalculators(sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListIncomeCalculatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIncomeCalculators(sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List your accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listKnowRiskAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListKnowRiskAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listKnowRiskAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CashBidCommodity} [commodity] 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [radius] 
         * @param {string} [refinitivInstrumentCodePrefix] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listKnowRiskCashBids(commodity?: CashBidCommodity, latitude?: number, longitude?: number, radius?: number, refinitivInstrumentCodePrefix?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCashBidsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listKnowRiskCashBids(commodity, latitude, longitude, radius, refinitivInstrumentCodePrefix, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listKnowRiskCashBidsByFavorite(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCashBidsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listKnowRiskCashBidsByFavorite(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} cropType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listKnowRiskCashBidsCanada(cropType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCashBidsCanadaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listKnowRiskCashBidsCanada(cropType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listKnowRiskCashBidsCanadaByFavorites(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCashBidsCanadaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listKnowRiskCashBidsCanadaByFavorites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} favoriteType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listKnowRiskFavorites(favoriteType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListKnowRiskFavoritesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listKnowRiskFavorites(favoriteType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSymbols(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListKnowRiskSymbolsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSymbols(mobileApplication, mobileApplicationCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSymbolsByFavorites(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListKnowRiskSymbolsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSymbolsByFavorites(mobileApplication, mobileApplicationCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendSupportEmailRequest} [sendSupportEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSupportEmail(sendSupportEmailRequest?: SendSupportEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSupportEmail(sendSupportEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} featureType 
         * @param {UpdateKnowRiskFeatureRequest} [updateKnowRiskFeatureRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeature(featureType: string, updateKnowRiskFeatureRequest?: UpdateKnowRiskFeatureRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeature(featureType, updateKnowRiskFeatureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update income calculator.
         * @param {string} cropType 
         * @param {UpdateIncomeCalculatorRequest} [updateIncomeCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIncomeCalculator(cropType: string, updateIncomeCalculatorRequest?: UpdateIncomeCalculatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIncomeCalculator(cropType, updateIncomeCalculatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {UpdateKnowRiskSymbolRequest} [updateKnowRiskSymbolRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSymbol(reutersInstrumentCodePrefix: string, updateKnowRiskSymbolRequest?: UpdateKnowRiskSymbolRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSymbol(reutersInstrumentCodePrefix, updateKnowRiskSymbolRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KnowRiskApi - factory interface
 * @export
 */
export const KnowRiskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KnowRiskApiFp(configuration)
    return {
        /**
         * 
         * @summary Add an account.
         * @param {AddKnowRiskAccountRequest} [addKnowRiskAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccount(addKnowRiskAccountRequest?: AddKnowRiskAccountRequest, options?: any): AxiosPromise<AddKnowRiskAccountResponse> {
            return localVarFp.addAccount(addKnowRiskAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add income calculator.
         * @param {AddIncomeCalculatorRequest} [addIncomeCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIncomeCalculator(addIncomeCalculatorRequest?: AddIncomeCalculatorRequest, options?: any): AxiosPromise<AddIncomeCalculatorResponse> {
            return localVarFp.addIncomeCalculator(addIncomeCalculatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddKnowRiskFavoriteRequest} [addKnowRiskFavoriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKnowRiskUserFavorites(addKnowRiskFavoriteRequest?: AddKnowRiskFavoriteRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addKnowRiskUserFavorites(addKnowRiskFavoriteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddSupportTicketRequest} [addSupportTicketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSupportTicket(addSupportTicketRequest?: AddSupportTicketRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addSupportTicket(addSupportTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} featureType 
         * @param {string} knowRiskUserGuid 
         * @param {Application} mobileApplication 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature(featureType: string, knowRiskUserGuid: string, mobileApplication: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: any): AxiosPromise<object> {
            return localVarFp.deleteFeature(featureType, knowRiskUserGuid, mobileApplication, mobileApplicationCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} favoriteType 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKnowRiskUserFavorites(favoriteType: string, value: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteKnowRiskUserFavorites(favoriteType, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableKnowRiskUser(options?: any): AxiosPromise<object> {
            return localVarFp.disableKnowRiskUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll(options?: any): AxiosPromise<{ [key: string]: Array<EnumAndDescriptionModel>; }> {
            return localVarFp.listAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllKnowRiskAccounts(options?: any): AxiosPromise<ListKnowRiskAccountsResponse> {
            return localVarFp.listAllKnowRiskAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEndOfDayFertilizerPricesService(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: any): AxiosPromise<ListEndOfDayFertilizerPricesResponse> {
            return localVarFp.listEndOfDayFertilizerPricesService(mobileApplication, mobileApplicationCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeatures(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: any): AxiosPromise<ListKnowRiskFeaturesResponse> {
            return localVarFp.listFeatures(mobileApplication, mobileApplicationCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIncomeCalculatorDefinitions(mobileApplicationCountry?: MobileApplicationCountry, options?: any): AxiosPromise<ListIncomeCalculatorDefinitionsResponse> {
            return localVarFp.listIncomeCalculatorDefinitions(mobileApplicationCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List income calculators.
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIncomeCalculators(sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListIncomeCalculatorsResponse> {
            return localVarFp.listIncomeCalculators(sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List your accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskAccounts(options?: any): AxiosPromise<ListKnowRiskAccountsResponse> {
            return localVarFp.listKnowRiskAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CashBidCommodity} [commodity] 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {number} [radius] 
         * @param {string} [refinitivInstrumentCodePrefix] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskCashBids(commodity?: CashBidCommodity, latitude?: number, longitude?: number, radius?: number, refinitivInstrumentCodePrefix?: string, take?: number, skip?: number, options?: any): AxiosPromise<ListCashBidsResponse> {
            return localVarFp.listKnowRiskCashBids(commodity, latitude, longitude, radius, refinitivInstrumentCodePrefix, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskCashBidsByFavorite(options?: any): AxiosPromise<ListCashBidsResponse> {
            return localVarFp.listKnowRiskCashBidsByFavorite(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cropType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskCashBidsCanada(cropType: string, options?: any): AxiosPromise<ListCashBidsCanadaResponse> {
            return localVarFp.listKnowRiskCashBidsCanada(cropType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskCashBidsCanadaByFavorites(options?: any): AxiosPromise<ListCashBidsCanadaResponse> {
            return localVarFp.listKnowRiskCashBidsCanadaByFavorites(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} favoriteType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listKnowRiskFavorites(favoriteType: string, options?: any): AxiosPromise<ListKnowRiskFavoritesResponse> {
            return localVarFp.listKnowRiskFavorites(favoriteType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSymbols(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: any): AxiosPromise<ListKnowRiskSymbolsResponse> {
            return localVarFp.listSymbols(mobileApplication, mobileApplicationCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Application} [mobileApplication] 
         * @param {MobileApplicationCountry} [mobileApplicationCountry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSymbolsByFavorites(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: any): AxiosPromise<ListKnowRiskSymbolsResponse> {
            return localVarFp.listSymbolsByFavorites(mobileApplication, mobileApplicationCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendSupportEmailRequest} [sendSupportEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSupportEmail(sendSupportEmailRequest?: SendSupportEmailRequest, options?: any): AxiosPromise<object> {
            return localVarFp.sendSupportEmail(sendSupportEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} featureType 
         * @param {UpdateKnowRiskFeatureRequest} [updateKnowRiskFeatureRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature(featureType: string, updateKnowRiskFeatureRequest?: UpdateKnowRiskFeatureRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateFeature(featureType, updateKnowRiskFeatureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update income calculator.
         * @param {string} cropType 
         * @param {UpdateIncomeCalculatorRequest} [updateIncomeCalculatorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncomeCalculator(cropType: string, updateIncomeCalculatorRequest?: UpdateIncomeCalculatorRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateIncomeCalculator(cropType, updateIncomeCalculatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reutersInstrumentCodePrefix 
         * @param {UpdateKnowRiskSymbolRequest} [updateKnowRiskSymbolRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSymbol(reutersInstrumentCodePrefix: string, updateKnowRiskSymbolRequest?: UpdateKnowRiskSymbolRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateSymbol(reutersInstrumentCodePrefix, updateKnowRiskSymbolRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnowRiskApi - object-oriented interface
 * @export
 * @class KnowRiskApi
 * @extends {BaseAPI}
 */
export class KnowRiskApi extends BaseAPI {
    /**
     * 
     * @summary Add an account.
     * @param {AddKnowRiskAccountRequest} [addKnowRiskAccountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public addAccount(addKnowRiskAccountRequest?: AddKnowRiskAccountRequest, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).addAccount(addKnowRiskAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add income calculator.
     * @param {AddIncomeCalculatorRequest} [addIncomeCalculatorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public addIncomeCalculator(addIncomeCalculatorRequest?: AddIncomeCalculatorRequest, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).addIncomeCalculator(addIncomeCalculatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddKnowRiskFavoriteRequest} [addKnowRiskFavoriteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public addKnowRiskUserFavorites(addKnowRiskFavoriteRequest?: AddKnowRiskFavoriteRequest, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).addKnowRiskUserFavorites(addKnowRiskFavoriteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddSupportTicketRequest} [addSupportTicketRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public addSupportTicket(addSupportTicketRequest?: AddSupportTicketRequest, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).addSupportTicket(addSupportTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} featureType 
     * @param {string} knowRiskUserGuid 
     * @param {Application} mobileApplication 
     * @param {MobileApplicationCountry} [mobileApplicationCountry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public deleteFeature(featureType: string, knowRiskUserGuid: string, mobileApplication: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).deleteFeature(featureType, knowRiskUserGuid, mobileApplication, mobileApplicationCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} favoriteType 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public deleteKnowRiskUserFavorites(favoriteType: string, value: string, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).deleteKnowRiskUserFavorites(favoriteType, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public disableKnowRiskUser(options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).disableKnowRiskUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listAll(options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all accounts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listAllKnowRiskAccounts(options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listAllKnowRiskAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Application} [mobileApplication] 
     * @param {MobileApplicationCountry} [mobileApplicationCountry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listEndOfDayFertilizerPricesService(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listEndOfDayFertilizerPricesService(mobileApplication, mobileApplicationCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Application} [mobileApplication] 
     * @param {MobileApplicationCountry} [mobileApplicationCountry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listFeatures(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listFeatures(mobileApplication, mobileApplicationCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileApplicationCountry} [mobileApplicationCountry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listIncomeCalculatorDefinitions(mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listIncomeCalculatorDefinitions(mobileApplicationCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List income calculators.
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listIncomeCalculators(sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listIncomeCalculators(sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List your accounts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listKnowRiskAccounts(options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listKnowRiskAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashBidCommodity} [commodity] 
     * @param {number} [latitude] 
     * @param {number} [longitude] 
     * @param {number} [radius] 
     * @param {string} [refinitivInstrumentCodePrefix] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listKnowRiskCashBids(commodity?: CashBidCommodity, latitude?: number, longitude?: number, radius?: number, refinitivInstrumentCodePrefix?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listKnowRiskCashBids(commodity, latitude, longitude, radius, refinitivInstrumentCodePrefix, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listKnowRiskCashBidsByFavorite(options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listKnowRiskCashBidsByFavorite(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cropType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listKnowRiskCashBidsCanada(cropType: string, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listKnowRiskCashBidsCanada(cropType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listKnowRiskCashBidsCanadaByFavorites(options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listKnowRiskCashBidsCanadaByFavorites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} favoriteType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listKnowRiskFavorites(favoriteType: string, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listKnowRiskFavorites(favoriteType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Application} [mobileApplication] 
     * @param {MobileApplicationCountry} [mobileApplicationCountry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listSymbols(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listSymbols(mobileApplication, mobileApplicationCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Application} [mobileApplication] 
     * @param {MobileApplicationCountry} [mobileApplicationCountry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public listSymbolsByFavorites(mobileApplication?: Application, mobileApplicationCountry?: MobileApplicationCountry, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).listSymbolsByFavorites(mobileApplication, mobileApplicationCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendSupportEmailRequest} [sendSupportEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public sendSupportEmail(sendSupportEmailRequest?: SendSupportEmailRequest, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).sendSupportEmail(sendSupportEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} featureType 
     * @param {UpdateKnowRiskFeatureRequest} [updateKnowRiskFeatureRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public updateFeature(featureType: string, updateKnowRiskFeatureRequest?: UpdateKnowRiskFeatureRequest, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).updateFeature(featureType, updateKnowRiskFeatureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update income calculator.
     * @param {string} cropType 
     * @param {UpdateIncomeCalculatorRequest} [updateIncomeCalculatorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public updateIncomeCalculator(cropType: string, updateIncomeCalculatorRequest?: UpdateIncomeCalculatorRequest, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).updateIncomeCalculator(cropType, updateIncomeCalculatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reutersInstrumentCodePrefix 
     * @param {UpdateKnowRiskSymbolRequest} [updateKnowRiskSymbolRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskApi
     */
    public updateSymbol(reutersInstrumentCodePrefix: string, updateKnowRiskSymbolRequest?: UpdateKnowRiskSymbolRequest, options?: AxiosRequestConfig) {
        return KnowRiskApiFp(this.configuration).updateSymbol(reutersInstrumentCodePrefix, updateKnowRiskSymbolRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KnowRiskCommoditySolutionsApi - axios parameter creator
 * @export
 */
export const KnowRiskCommoditySolutionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} contractVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoneXCommoditySolutionsContractPricings: async (contractVersionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractVersionId' is not null or undefined
            assertParamExists('listStoneXCommoditySolutionsContractPricings', 'contractVersionId', contractVersionId)
            const localVarPath = `/know-risk/commmodity-solutions/contracts/{contractVersionId}/pricings`
                .replace(`{${"contractVersionId"}}`, encodeURIComponent(String(contractVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {Array<string>} [codes] 
         * @param {string} [contractId] 
         * @param {string} [status] 
         * @param {string} [customerContractNo] 
         * @param {string} [underlier] 
         * @param {string} [tradeDateFrom] 
         * @param {string} [tradeDateTo] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {boolean} [refresh] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoneXCommoditySolutionsContracts: async (customerId: string, codes?: Array<string>, contractId?: string, status?: string, customerContractNo?: string, underlier?: string, tradeDateFrom?: string, tradeDateTo?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, refresh?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('listStoneXCommoditySolutionsContracts', 'customerId', customerId)
            const localVarPath = `/know-risk/commmodity-solutions/customers/{customerId}/contracts`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codes) {
                localVarQueryParameter['Codes'] = codes;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['ContractId'] = contractId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (customerContractNo !== undefined) {
                localVarQueryParameter['CustomerContractNo'] = customerContractNo;
            }

            if (underlier !== undefined) {
                localVarQueryParameter['Underlier'] = underlier;
            }

            if (tradeDateFrom !== undefined) {
                localVarQueryParameter['TradeDateFrom'] = (tradeDateFrom as any instanceof Date) ?
                    (tradeDateFrom as any).toISOString() :
                    tradeDateFrom;
            }

            if (tradeDateTo !== undefined) {
                localVarQueryParameter['TradeDateTo'] = (tradeDateTo as any instanceof Date) ?
                    (tradeDateTo as any).toISOString() :
                    tradeDateTo;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (refresh !== undefined) {
                localVarQueryParameter['Refresh'] = refresh;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoneXCommoditySolutionsCustomers: async (email: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('listStoneXCommoditySolutionsCustomers', 'email', email)
            const localVarPath = `/know-risk/commmodity-solutions/customers/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {Array<string>} [contractCodes] 
         * @param {string} [search] 
         * @param {Array<string>} [codes] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoneXCommoditySolutionsUnderliers: async (customerId: string, contractCodes?: Array<string>, search?: string, codes?: Array<string>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('listStoneXCommoditySolutionsUnderliers', 'customerId', customerId)
            const localVarPath = `/know-risk/commmodity-solutions/customers/{customerId}/underliers`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractCodes) {
                localVarQueryParameter['ContractCodes'] = contractCodes;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (codes) {
                localVarQueryParameter['Codes'] = codes;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnowRiskCommoditySolutionsApi - functional programming interface
 * @export
 */
export const KnowRiskCommoditySolutionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KnowRiskCommoditySolutionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} contractVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoneXCommoditySolutionsContractPricings(contractVersionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStoneXCommoditySolutionsContractPricingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoneXCommoditySolutionsContractPricings(contractVersionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {Array<string>} [codes] 
         * @param {string} [contractId] 
         * @param {string} [status] 
         * @param {string} [customerContractNo] 
         * @param {string} [underlier] 
         * @param {string} [tradeDateFrom] 
         * @param {string} [tradeDateTo] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {boolean} [refresh] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoneXCommoditySolutionsContracts(customerId: string, codes?: Array<string>, contractId?: string, status?: string, customerContractNo?: string, underlier?: string, tradeDateFrom?: string, tradeDateTo?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, refresh?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStoneXCommoditySolutionsContractsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoneXCommoditySolutionsContracts(customerId, codes, contractId, status, customerContractNo, underlier, tradeDateFrom, tradeDateTo, sortColumn, sortDirection, take, skip, refresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoneXCommoditySolutionsCustomers(email: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStoneXCommoditySolutionsCustomersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoneXCommoditySolutionsCustomers(email, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {Array<string>} [contractCodes] 
         * @param {string} [search] 
         * @param {Array<string>} [codes] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStoneXCommoditySolutionsUnderliers(customerId: string, contractCodes?: Array<string>, search?: string, codes?: Array<string>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStoneXCommoditySolutionsUnderliersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStoneXCommoditySolutionsUnderliers(customerId, contractCodes, search, codes, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KnowRiskCommoditySolutionsApi - factory interface
 * @export
 */
export const KnowRiskCommoditySolutionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KnowRiskCommoditySolutionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} contractVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoneXCommoditySolutionsContractPricings(contractVersionId: string, options?: any): AxiosPromise<ListStoneXCommoditySolutionsContractPricingsResponse> {
            return localVarFp.listStoneXCommoditySolutionsContractPricings(contractVersionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {Array<string>} [codes] 
         * @param {string} [contractId] 
         * @param {string} [status] 
         * @param {string} [customerContractNo] 
         * @param {string} [underlier] 
         * @param {string} [tradeDateFrom] 
         * @param {string} [tradeDateTo] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {boolean} [refresh] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoneXCommoditySolutionsContracts(customerId: string, codes?: Array<string>, contractId?: string, status?: string, customerContractNo?: string, underlier?: string, tradeDateFrom?: string, tradeDateTo?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, refresh?: boolean, options?: any): AxiosPromise<ListStoneXCommoditySolutionsContractsResponse> {
            return localVarFp.listStoneXCommoditySolutionsContracts(customerId, codes, contractId, status, customerContractNo, underlier, tradeDateFrom, tradeDateTo, sortColumn, sortDirection, take, skip, refresh, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoneXCommoditySolutionsCustomers(email: string, name?: string, options?: any): AxiosPromise<ListStoneXCommoditySolutionsCustomersResponse> {
            return localVarFp.listStoneXCommoditySolutionsCustomers(email, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {Array<string>} [contractCodes] 
         * @param {string} [search] 
         * @param {Array<string>} [codes] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStoneXCommoditySolutionsUnderliers(customerId: string, contractCodes?: Array<string>, search?: string, codes?: Array<string>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListStoneXCommoditySolutionsUnderliersResponse> {
            return localVarFp.listStoneXCommoditySolutionsUnderliers(customerId, contractCodes, search, codes, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnowRiskCommoditySolutionsApi - object-oriented interface
 * @export
 * @class KnowRiskCommoditySolutionsApi
 * @extends {BaseAPI}
 */
export class KnowRiskCommoditySolutionsApi extends BaseAPI {
    /**
     * 
     * @param {string} contractVersionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCommoditySolutionsApi
     */
    public listStoneXCommoditySolutionsContractPricings(contractVersionId: string, options?: AxiosRequestConfig) {
        return KnowRiskCommoditySolutionsApiFp(this.configuration).listStoneXCommoditySolutionsContractPricings(contractVersionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {Array<string>} [codes] 
     * @param {string} [contractId] 
     * @param {string} [status] 
     * @param {string} [customerContractNo] 
     * @param {string} [underlier] 
     * @param {string} [tradeDateFrom] 
     * @param {string} [tradeDateTo] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {boolean} [refresh] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCommoditySolutionsApi
     */
    public listStoneXCommoditySolutionsContracts(customerId: string, codes?: Array<string>, contractId?: string, status?: string, customerContractNo?: string, underlier?: string, tradeDateFrom?: string, tradeDateTo?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, refresh?: boolean, options?: AxiosRequestConfig) {
        return KnowRiskCommoditySolutionsApiFp(this.configuration).listStoneXCommoditySolutionsContracts(customerId, codes, contractId, status, customerContractNo, underlier, tradeDateFrom, tradeDateTo, sortColumn, sortDirection, take, skip, refresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCommoditySolutionsApi
     */
    public listStoneXCommoditySolutionsCustomers(email: string, name?: string, options?: AxiosRequestConfig) {
        return KnowRiskCommoditySolutionsApiFp(this.configuration).listStoneXCommoditySolutionsCustomers(email, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {Array<string>} [contractCodes] 
     * @param {string} [search] 
     * @param {Array<string>} [codes] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCommoditySolutionsApi
     */
    public listStoneXCommoditySolutionsUnderliers(customerId: string, contractCodes?: Array<string>, search?: string, codes?: Array<string>, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskCommoditySolutionsApiFp(this.configuration).listStoneXCommoditySolutionsUnderliers(customerId, contractCodes, search, codes, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KnowRiskCropsApi - axios parameter creator
 * @export
 */
export const KnowRiskCropsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {AddCropBudgetRequest} [addCropBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCropBudget: async (knowRiskAccountGuid: string, addCropBudgetRequest?: AddCropBudgetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('addCropBudget', 'knowRiskAccountGuid', knowRiskAccountGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-budgets`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCropBudgetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {AddCropBudgetExpenseRequest} [addCropBudgetExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCropBudgetExpense: async (knowRiskAccountGuid: string, cropBudgetGuid: string, addCropBudgetExpenseRequest?: AddCropBudgetExpenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('addCropBudgetExpense', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropBudgetGuid' is not null or undefined
            assertParamExists('addCropBudgetExpense', 'cropBudgetGuid', cropBudgetGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-budgets/{cropBudgetGuid}/crop-budget-expenses`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropBudgetGuid"}}`, encodeURIComponent(String(cropBudgetGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCropBudgetExpenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {AddCropFieldRequest} [addCropFieldRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCropField: async (knowRiskAccountGuid: string, addCropFieldRequest?: AddCropFieldRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('addCropField', 'knowRiskAccountGuid', knowRiskAccountGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-fields`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCropFieldRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {AddCropPlanRequest} [addCropPlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCropPlan: async (knowRiskAccountGuid: string, addCropPlanRequest?: AddCropPlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('addCropPlan', 'knowRiskAccountGuid', knowRiskAccountGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-plans`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCropPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add fixed expense.
         * @param {string} knowRiskAccountGuid 
         * @param {AddFixedExpenseRequest} [addFixedExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFixedExpense: async (knowRiskAccountGuid: string, addFixedExpenseRequest?: AddFixedExpenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('addFixedExpense', 'knowRiskAccountGuid', knowRiskAccountGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/fixed-expenses`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFixedExpenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {DeleteCropBudgetRequest} [deleteCropBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCropBudget: async (knowRiskAccountGuid: string, cropBudgetGuid: string, deleteCropBudgetRequest?: DeleteCropBudgetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('deleteCropBudget', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropBudgetGuid' is not null or undefined
            assertParamExists('deleteCropBudget', 'cropBudgetGuid', cropBudgetGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-budgets/{cropBudgetGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropBudgetGuid"}}`, encodeURIComponent(String(cropBudgetGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCropBudgetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} cropBudgetExpenseGuid 
         * @param {DeleteCropBudgetExpenseRequest} [deleteCropBudgetExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCropBudgetExpense: async (knowRiskAccountGuid: string, cropBudgetGuid: string, cropBudgetExpenseGuid: string, deleteCropBudgetExpenseRequest?: DeleteCropBudgetExpenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('deleteCropBudgetExpense', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropBudgetGuid' is not null or undefined
            assertParamExists('deleteCropBudgetExpense', 'cropBudgetGuid', cropBudgetGuid)
            // verify required parameter 'cropBudgetExpenseGuid' is not null or undefined
            assertParamExists('deleteCropBudgetExpense', 'cropBudgetExpenseGuid', cropBudgetExpenseGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-budgets/{cropBudgetGuid}/crop-budget-expenses/{cropBudgetExpenseGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropBudgetGuid"}}`, encodeURIComponent(String(cropBudgetGuid)))
                .replace(`{${"cropBudgetExpenseGuid"}}`, encodeURIComponent(String(cropBudgetExpenseGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCropBudgetExpenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update fixed expense.
         * @param {string} knowRiskAccountGuid 
         * @param {string} fixedExpenseGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFixedExpense: async (knowRiskAccountGuid: string, fixedExpenseGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('deleteFixedExpense', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'fixedExpenseGuid' is not null or undefined
            assertParamExists('deleteFixedExpense', 'fixedExpenseGuid', fixedExpenseGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/fixed-expenses/{fixedExpenseGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"fixedExpenseGuid"}}`, encodeURIComponent(String(fixedExpenseGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCropBudget: async (knowRiskAccountGuid: string, cropBudgetGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('getCropBudget', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropBudgetGuid' is not null or undefined
            assertParamExists('getCropBudget', 'cropBudgetGuid', cropBudgetGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-budgets/{cropBudgetGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropBudgetGuid"}}`, encodeURIComponent(String(cropBudgetGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCropFields: async (knowRiskAccountGuid: string, cropFieldGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('getCropFields', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropFieldGuid' is not null or undefined
            assertParamExists('getCropFields', 'cropFieldGuid', cropFieldGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-fields/{cropFieldGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropFieldGuid"}}`, encodeURIComponent(String(cropFieldGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropPlanGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCropPlan: async (knowRiskAccountGuid: string, cropPlanGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('getCropPlan', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropPlanGuid' is not null or undefined
            assertParamExists('getCropPlan', 'cropPlanGuid', cropPlanGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-plans/{cropPlanGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropPlanGuid"}}`, encodeURIComponent(String(cropPlanGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropBudgetExpenses: async (knowRiskAccountGuid: string, cropBudgetGuid: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('listCropBudgetExpenses', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropBudgetGuid' is not null or undefined
            assertParamExists('listCropBudgetExpenses', 'cropBudgetGuid', cropBudgetGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-budgets/{cropBudgetGuid}/crop-budget-expenses`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropBudgetGuid"}}`, encodeURIComponent(String(cropBudgetGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} year 
         * @param {CropType} [cropType] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropBudgets: async (knowRiskAccountGuid: string, year: string, cropType?: CropType, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('listCropBudgets', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('listCropBudgets', 'year', year)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-budgets/year/{year}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cropType !== undefined) {
                localVarQueryParameter['CropType'] = cropType;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {boolean} [isRetired] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropFields: async (knowRiskAccountGuid: string, isRetired?: boolean, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('listCropFields', 'knowRiskAccountGuid', knowRiskAccountGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-fields`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isRetired !== undefined) {
                localVarQueryParameter['IsRetired'] = isRetired;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} year 
         * @param {string} [cropBudgetGuid] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropPlans: async (knowRiskAccountGuid: string, year: string, cropBudgetGuid?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('listCropPlans', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('listCropPlans', 'year', year)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-plans/year/{year}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cropBudgetGuid !== undefined) {
                localVarQueryParameter['CropBudgetGuid'] = cropBudgetGuid;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropPlansByCropBudget: async (knowRiskAccountGuid: string, cropBudgetGuid: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('listCropPlansByCropBudget', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropBudgetGuid' is not null or undefined
            assertParamExists('listCropPlansByCropBudget', 'cropBudgetGuid', cropBudgetGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-budgets/{cropBudgetGuid}/crop-plans`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropBudgetGuid"}}`, encodeURIComponent(String(cropBudgetGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropPlansByCropField: async (knowRiskAccountGuid: string, cropFieldGuid: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('listCropPlansByCropField', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropFieldGuid' is not null or undefined
            assertParamExists('listCropPlansByCropField', 'cropFieldGuid', cropFieldGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-fields/{cropFieldGuid}/crop-plans`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropFieldGuid"}}`, encodeURIComponent(String(cropFieldGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropType 
         * @param {string} [searchTerm] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropVarieties: async (knowRiskAccountGuid: string, cropType: string, searchTerm?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('listCropVarieties', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropType' is not null or undefined
            assertParamExists('listCropVarieties', 'cropType', cropType)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-plans/varieties/{cropType}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropType"}}`, encodeURIComponent(String(cropType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List fixed expenses for year.
         * @param {string} knowRiskAccountGuid 
         * @param {string} year 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFixedExpenses: async (knowRiskAccountGuid: string, year: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('listFixedExpenses', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('listFixedExpenses', 'year', year)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/fixed-expenses/year/{year}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortColumn !== undefined) {
                localVarQueryParameter['SortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {RetireCropFieldRequest} [retireCropFieldRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retireCropField: async (knowRiskAccountGuid: string, cropFieldGuid: string, retireCropFieldRequest?: RetireCropFieldRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('retireCropField', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropFieldGuid' is not null or undefined
            assertParamExists('retireCropField', 'cropFieldGuid', cropFieldGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-fields/{cropFieldGuid}/retire`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropFieldGuid"}}`, encodeURIComponent(String(cropFieldGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(retireCropFieldRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {UpdateCropBudgetRequest} [updateCropBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCropBudget: async (knowRiskAccountGuid: string, cropBudgetGuid: string, updateCropBudgetRequest?: UpdateCropBudgetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('updateCropBudget', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropBudgetGuid' is not null or undefined
            assertParamExists('updateCropBudget', 'cropBudgetGuid', cropBudgetGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-budgets/{cropBudgetGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropBudgetGuid"}}`, encodeURIComponent(String(cropBudgetGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCropBudgetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} cropBudgetExpenseGuid 
         * @param {UpdateCropBudgetExpenseRequest} [updateCropBudgetExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCropBudgetExpense: async (knowRiskAccountGuid: string, cropBudgetGuid: string, cropBudgetExpenseGuid: string, updateCropBudgetExpenseRequest?: UpdateCropBudgetExpenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('updateCropBudgetExpense', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropBudgetGuid' is not null or undefined
            assertParamExists('updateCropBudgetExpense', 'cropBudgetGuid', cropBudgetGuid)
            // verify required parameter 'cropBudgetExpenseGuid' is not null or undefined
            assertParamExists('updateCropBudgetExpense', 'cropBudgetExpenseGuid', cropBudgetExpenseGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-budgets/{cropBudgetGuid}/crop-budget-expenses/{cropBudgetExpenseGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropBudgetGuid"}}`, encodeURIComponent(String(cropBudgetGuid)))
                .replace(`{${"cropBudgetExpenseGuid"}}`, encodeURIComponent(String(cropBudgetExpenseGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCropBudgetExpenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {UpdateCropFieldRequest} [updateCropFieldRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCropField: async (knowRiskAccountGuid: string, cropFieldGuid: string, updateCropFieldRequest?: UpdateCropFieldRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('updateCropField', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropFieldGuid' is not null or undefined
            assertParamExists('updateCropField', 'cropFieldGuid', cropFieldGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-fields/{cropFieldGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropFieldGuid"}}`, encodeURIComponent(String(cropFieldGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCropFieldRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropPlanGuid 
         * @param {UpdateCropPlanRequest} [updateCropPlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCropPlan: async (knowRiskAccountGuid: string, cropPlanGuid: string, updateCropPlanRequest?: UpdateCropPlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('updateCropPlan', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'cropPlanGuid' is not null or undefined
            assertParamExists('updateCropPlan', 'cropPlanGuid', cropPlanGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/crop-plans/{cropPlanGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"cropPlanGuid"}}`, encodeURIComponent(String(cropPlanGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCropPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update fixed expense.
         * @param {string} knowRiskAccountGuid 
         * @param {string} fixedExpenseGuid 
         * @param {UpdateFixedExpenseRequest} [updateFixedExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFixedExpense: async (knowRiskAccountGuid: string, fixedExpenseGuid: string, updateFixedExpenseRequest?: UpdateFixedExpenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowRiskAccountGuid' is not null or undefined
            assertParamExists('updateFixedExpense', 'knowRiskAccountGuid', knowRiskAccountGuid)
            // verify required parameter 'fixedExpenseGuid' is not null or undefined
            assertParamExists('updateFixedExpense', 'fixedExpenseGuid', fixedExpenseGuid)
            const localVarPath = `/know-risk/{knowRiskAccountGuid}/fixed-expenses/{fixedExpenseGuid}`
                .replace(`{${"knowRiskAccountGuid"}}`, encodeURIComponent(String(knowRiskAccountGuid)))
                .replace(`{${"fixedExpenseGuid"}}`, encodeURIComponent(String(fixedExpenseGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFixedExpenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnowRiskCropsApi - functional programming interface
 * @export
 */
export const KnowRiskCropsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KnowRiskCropsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {AddCropBudgetRequest} [addCropBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCropBudget(knowRiskAccountGuid: string, addCropBudgetRequest?: AddCropBudgetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCropBudgetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCropBudget(knowRiskAccountGuid, addCropBudgetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {AddCropBudgetExpenseRequest} [addCropBudgetExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCropBudgetExpense(knowRiskAccountGuid: string, cropBudgetGuid: string, addCropBudgetExpenseRequest?: AddCropBudgetExpenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCropBudgetExpenseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCropBudgetExpense(knowRiskAccountGuid, cropBudgetGuid, addCropBudgetExpenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {AddCropFieldRequest} [addCropFieldRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCropField(knowRiskAccountGuid: string, addCropFieldRequest?: AddCropFieldRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCropFieldResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCropField(knowRiskAccountGuid, addCropFieldRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {AddCropPlanRequest} [addCropPlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCropPlan(knowRiskAccountGuid: string, addCropPlanRequest?: AddCropPlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCropPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCropPlan(knowRiskAccountGuid, addCropPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add fixed expense.
         * @param {string} knowRiskAccountGuid 
         * @param {AddFixedExpenseRequest} [addFixedExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFixedExpense(knowRiskAccountGuid: string, addFixedExpenseRequest?: AddFixedExpenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddFixedExpenseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFixedExpense(knowRiskAccountGuid, addFixedExpenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {DeleteCropBudgetRequest} [deleteCropBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, deleteCropBudgetRequest?: DeleteCropBudgetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCropBudget(knowRiskAccountGuid, cropBudgetGuid, deleteCropBudgetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} cropBudgetExpenseGuid 
         * @param {DeleteCropBudgetExpenseRequest} [deleteCropBudgetExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCropBudgetExpense(knowRiskAccountGuid: string, cropBudgetGuid: string, cropBudgetExpenseGuid: string, deleteCropBudgetExpenseRequest?: DeleteCropBudgetExpenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCropBudgetExpense(knowRiskAccountGuid, cropBudgetGuid, cropBudgetExpenseGuid, deleteCropBudgetExpenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update fixed expense.
         * @param {string} knowRiskAccountGuid 
         * @param {string} fixedExpenseGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFixedExpense(knowRiskAccountGuid: string, fixedExpenseGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFixedExpense(knowRiskAccountGuid, fixedExpenseGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCropBudgetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCropBudget(knowRiskAccountGuid, cropBudgetGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCropFields(knowRiskAccountGuid: string, cropFieldGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCropFieldResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCropFields(knowRiskAccountGuid, cropFieldGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropPlanGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCropPlan(knowRiskAccountGuid: string, cropPlanGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCropPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCropPlan(knowRiskAccountGuid, cropPlanGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCropBudgetExpenses(knowRiskAccountGuid: string, cropBudgetGuid: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCropBudgetExpensesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCropBudgetExpenses(knowRiskAccountGuid, cropBudgetGuid, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} year 
         * @param {CropType} [cropType] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCropBudgets(knowRiskAccountGuid: string, year: string, cropType?: CropType, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCropBudgetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCropBudgets(knowRiskAccountGuid, year, cropType, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {boolean} [isRetired] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCropFields(knowRiskAccountGuid: string, isRetired?: boolean, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCropFieldsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCropFields(knowRiskAccountGuid, isRetired, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} year 
         * @param {string} [cropBudgetGuid] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCropPlans(knowRiskAccountGuid: string, year: string, cropBudgetGuid?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCropPlansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCropPlans(knowRiskAccountGuid, year, cropBudgetGuid, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCropPlansByCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCropPlansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCropPlansByCropBudget(knowRiskAccountGuid, cropBudgetGuid, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCropPlansByCropField(knowRiskAccountGuid: string, cropFieldGuid: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCropPlansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCropPlansByCropField(knowRiskAccountGuid, cropFieldGuid, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropType 
         * @param {string} [searchTerm] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCropVarieties(knowRiskAccountGuid: string, cropType: string, searchTerm?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCropVarietiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCropVarieties(knowRiskAccountGuid, cropType, searchTerm, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List fixed expenses for year.
         * @param {string} knowRiskAccountGuid 
         * @param {string} year 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFixedExpenses(knowRiskAccountGuid: string, year: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFixedExpensesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFixedExpenses(knowRiskAccountGuid, year, sortColumn, sortDirection, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {RetireCropFieldRequest} [retireCropFieldRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retireCropField(knowRiskAccountGuid: string, cropFieldGuid: string, retireCropFieldRequest?: RetireCropFieldRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retireCropField(knowRiskAccountGuid, cropFieldGuid, retireCropFieldRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {UpdateCropBudgetRequest} [updateCropBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, updateCropBudgetRequest?: UpdateCropBudgetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCropBudget(knowRiskAccountGuid, cropBudgetGuid, updateCropBudgetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} cropBudgetExpenseGuid 
         * @param {UpdateCropBudgetExpenseRequest} [updateCropBudgetExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCropBudgetExpense(knowRiskAccountGuid: string, cropBudgetGuid: string, cropBudgetExpenseGuid: string, updateCropBudgetExpenseRequest?: UpdateCropBudgetExpenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCropBudgetExpense(knowRiskAccountGuid, cropBudgetGuid, cropBudgetExpenseGuid, updateCropBudgetExpenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {UpdateCropFieldRequest} [updateCropFieldRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCropField(knowRiskAccountGuid: string, cropFieldGuid: string, updateCropFieldRequest?: UpdateCropFieldRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCropField(knowRiskAccountGuid, cropFieldGuid, updateCropFieldRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropPlanGuid 
         * @param {UpdateCropPlanRequest} [updateCropPlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCropPlan(knowRiskAccountGuid: string, cropPlanGuid: string, updateCropPlanRequest?: UpdateCropPlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCropPlan(knowRiskAccountGuid, cropPlanGuid, updateCropPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update fixed expense.
         * @param {string} knowRiskAccountGuid 
         * @param {string} fixedExpenseGuid 
         * @param {UpdateFixedExpenseRequest} [updateFixedExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFixedExpense(knowRiskAccountGuid: string, fixedExpenseGuid: string, updateFixedExpenseRequest?: UpdateFixedExpenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFixedExpense(knowRiskAccountGuid, fixedExpenseGuid, updateFixedExpenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KnowRiskCropsApi - factory interface
 * @export
 */
export const KnowRiskCropsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KnowRiskCropsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {AddCropBudgetRequest} [addCropBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCropBudget(knowRiskAccountGuid: string, addCropBudgetRequest?: AddCropBudgetRequest, options?: any): AxiosPromise<AddCropBudgetResponse> {
            return localVarFp.addCropBudget(knowRiskAccountGuid, addCropBudgetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {AddCropBudgetExpenseRequest} [addCropBudgetExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCropBudgetExpense(knowRiskAccountGuid: string, cropBudgetGuid: string, addCropBudgetExpenseRequest?: AddCropBudgetExpenseRequest, options?: any): AxiosPromise<AddCropBudgetExpenseResponse> {
            return localVarFp.addCropBudgetExpense(knowRiskAccountGuid, cropBudgetGuid, addCropBudgetExpenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {AddCropFieldRequest} [addCropFieldRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCropField(knowRiskAccountGuid: string, addCropFieldRequest?: AddCropFieldRequest, options?: any): AxiosPromise<AddCropFieldResponse> {
            return localVarFp.addCropField(knowRiskAccountGuid, addCropFieldRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {AddCropPlanRequest} [addCropPlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCropPlan(knowRiskAccountGuid: string, addCropPlanRequest?: AddCropPlanRequest, options?: any): AxiosPromise<AddCropPlanResponse> {
            return localVarFp.addCropPlan(knowRiskAccountGuid, addCropPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add fixed expense.
         * @param {string} knowRiskAccountGuid 
         * @param {AddFixedExpenseRequest} [addFixedExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFixedExpense(knowRiskAccountGuid: string, addFixedExpenseRequest?: AddFixedExpenseRequest, options?: any): AxiosPromise<AddFixedExpenseResponse> {
            return localVarFp.addFixedExpense(knowRiskAccountGuid, addFixedExpenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {DeleteCropBudgetRequest} [deleteCropBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, deleteCropBudgetRequest?: DeleteCropBudgetRequest, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCropBudget(knowRiskAccountGuid, cropBudgetGuid, deleteCropBudgetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} cropBudgetExpenseGuid 
         * @param {DeleteCropBudgetExpenseRequest} [deleteCropBudgetExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCropBudgetExpense(knowRiskAccountGuid: string, cropBudgetGuid: string, cropBudgetExpenseGuid: string, deleteCropBudgetExpenseRequest?: DeleteCropBudgetExpenseRequest, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCropBudgetExpense(knowRiskAccountGuid, cropBudgetGuid, cropBudgetExpenseGuid, deleteCropBudgetExpenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update fixed expense.
         * @param {string} knowRiskAccountGuid 
         * @param {string} fixedExpenseGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFixedExpense(knowRiskAccountGuid: string, fixedExpenseGuid: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteFixedExpense(knowRiskAccountGuid, fixedExpenseGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, options?: any): AxiosPromise<GetCropBudgetResponse> {
            return localVarFp.getCropBudget(knowRiskAccountGuid, cropBudgetGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCropFields(knowRiskAccountGuid: string, cropFieldGuid: string, options?: any): AxiosPromise<GetCropFieldResponse> {
            return localVarFp.getCropFields(knowRiskAccountGuid, cropFieldGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropPlanGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCropPlan(knowRiskAccountGuid: string, cropPlanGuid: string, options?: any): AxiosPromise<GetCropPlanResponse> {
            return localVarFp.getCropPlan(knowRiskAccountGuid, cropPlanGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropBudgetExpenses(knowRiskAccountGuid: string, cropBudgetGuid: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCropBudgetExpensesResponse> {
            return localVarFp.listCropBudgetExpenses(knowRiskAccountGuid, cropBudgetGuid, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} year 
         * @param {CropType} [cropType] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropBudgets(knowRiskAccountGuid: string, year: string, cropType?: CropType, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCropBudgetsResponse> {
            return localVarFp.listCropBudgets(knowRiskAccountGuid, year, cropType, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {boolean} [isRetired] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropFields(knowRiskAccountGuid: string, isRetired?: boolean, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCropFieldsResponse> {
            return localVarFp.listCropFields(knowRiskAccountGuid, isRetired, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} year 
         * @param {string} [cropBudgetGuid] 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropPlans(knowRiskAccountGuid: string, year: string, cropBudgetGuid?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCropPlansResponse> {
            return localVarFp.listCropPlans(knowRiskAccountGuid, year, cropBudgetGuid, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropPlansByCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCropPlansResponse> {
            return localVarFp.listCropPlansByCropBudget(knowRiskAccountGuid, cropBudgetGuid, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropPlansByCropField(knowRiskAccountGuid: string, cropFieldGuid: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCropPlansResponse> {
            return localVarFp.listCropPlansByCropField(knowRiskAccountGuid, cropFieldGuid, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropType 
         * @param {string} [searchTerm] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCropVarieties(knowRiskAccountGuid: string, cropType: string, searchTerm?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListCropVarietiesResponse> {
            return localVarFp.listCropVarieties(knowRiskAccountGuid, cropType, searchTerm, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List fixed expenses for year.
         * @param {string} knowRiskAccountGuid 
         * @param {string} year 
         * @param {string} [sortColumn] 
         * @param {SortDirection} [sortDirection] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFixedExpenses(knowRiskAccountGuid: string, year: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: any): AxiosPromise<ListFixedExpensesResponse> {
            return localVarFp.listFixedExpenses(knowRiskAccountGuid, year, sortColumn, sortDirection, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {RetireCropFieldRequest} [retireCropFieldRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retireCropField(knowRiskAccountGuid: string, cropFieldGuid: string, retireCropFieldRequest?: RetireCropFieldRequest, options?: any): AxiosPromise<object> {
            return localVarFp.retireCropField(knowRiskAccountGuid, cropFieldGuid, retireCropFieldRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {UpdateCropBudgetRequest} [updateCropBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, updateCropBudgetRequest?: UpdateCropBudgetRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateCropBudget(knowRiskAccountGuid, cropBudgetGuid, updateCropBudgetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropBudgetGuid 
         * @param {string} cropBudgetExpenseGuid 
         * @param {UpdateCropBudgetExpenseRequest} [updateCropBudgetExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCropBudgetExpense(knowRiskAccountGuid: string, cropBudgetGuid: string, cropBudgetExpenseGuid: string, updateCropBudgetExpenseRequest?: UpdateCropBudgetExpenseRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateCropBudgetExpense(knowRiskAccountGuid, cropBudgetGuid, cropBudgetExpenseGuid, updateCropBudgetExpenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropFieldGuid 
         * @param {UpdateCropFieldRequest} [updateCropFieldRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCropField(knowRiskAccountGuid: string, cropFieldGuid: string, updateCropFieldRequest?: UpdateCropFieldRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateCropField(knowRiskAccountGuid, cropFieldGuid, updateCropFieldRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} knowRiskAccountGuid 
         * @param {string} cropPlanGuid 
         * @param {UpdateCropPlanRequest} [updateCropPlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCropPlan(knowRiskAccountGuid: string, cropPlanGuid: string, updateCropPlanRequest?: UpdateCropPlanRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateCropPlan(knowRiskAccountGuid, cropPlanGuid, updateCropPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update fixed expense.
         * @param {string} knowRiskAccountGuid 
         * @param {string} fixedExpenseGuid 
         * @param {UpdateFixedExpenseRequest} [updateFixedExpenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFixedExpense(knowRiskAccountGuid: string, fixedExpenseGuid: string, updateFixedExpenseRequest?: UpdateFixedExpenseRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateFixedExpense(knowRiskAccountGuid, fixedExpenseGuid, updateFixedExpenseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnowRiskCropsApi - object-oriented interface
 * @export
 * @class KnowRiskCropsApi
 * @extends {BaseAPI}
 */
export class KnowRiskCropsApi extends BaseAPI {
    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {AddCropBudgetRequest} [addCropBudgetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public addCropBudget(knowRiskAccountGuid: string, addCropBudgetRequest?: AddCropBudgetRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).addCropBudget(knowRiskAccountGuid, addCropBudgetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropBudgetGuid 
     * @param {AddCropBudgetExpenseRequest} [addCropBudgetExpenseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public addCropBudgetExpense(knowRiskAccountGuid: string, cropBudgetGuid: string, addCropBudgetExpenseRequest?: AddCropBudgetExpenseRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).addCropBudgetExpense(knowRiskAccountGuid, cropBudgetGuid, addCropBudgetExpenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {AddCropFieldRequest} [addCropFieldRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public addCropField(knowRiskAccountGuid: string, addCropFieldRequest?: AddCropFieldRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).addCropField(knowRiskAccountGuid, addCropFieldRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {AddCropPlanRequest} [addCropPlanRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public addCropPlan(knowRiskAccountGuid: string, addCropPlanRequest?: AddCropPlanRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).addCropPlan(knowRiskAccountGuid, addCropPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add fixed expense.
     * @param {string} knowRiskAccountGuid 
     * @param {AddFixedExpenseRequest} [addFixedExpenseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public addFixedExpense(knowRiskAccountGuid: string, addFixedExpenseRequest?: AddFixedExpenseRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).addFixedExpense(knowRiskAccountGuid, addFixedExpenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropBudgetGuid 
     * @param {DeleteCropBudgetRequest} [deleteCropBudgetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public deleteCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, deleteCropBudgetRequest?: DeleteCropBudgetRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).deleteCropBudget(knowRiskAccountGuid, cropBudgetGuid, deleteCropBudgetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropBudgetGuid 
     * @param {string} cropBudgetExpenseGuid 
     * @param {DeleteCropBudgetExpenseRequest} [deleteCropBudgetExpenseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public deleteCropBudgetExpense(knowRiskAccountGuid: string, cropBudgetGuid: string, cropBudgetExpenseGuid: string, deleteCropBudgetExpenseRequest?: DeleteCropBudgetExpenseRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).deleteCropBudgetExpense(knowRiskAccountGuid, cropBudgetGuid, cropBudgetExpenseGuid, deleteCropBudgetExpenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update fixed expense.
     * @param {string} knowRiskAccountGuid 
     * @param {string} fixedExpenseGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public deleteFixedExpense(knowRiskAccountGuid: string, fixedExpenseGuid: string, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).deleteFixedExpense(knowRiskAccountGuid, fixedExpenseGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropBudgetGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public getCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).getCropBudget(knowRiskAccountGuid, cropBudgetGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropFieldGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public getCropFields(knowRiskAccountGuid: string, cropFieldGuid: string, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).getCropFields(knowRiskAccountGuid, cropFieldGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropPlanGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public getCropPlan(knowRiskAccountGuid: string, cropPlanGuid: string, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).getCropPlan(knowRiskAccountGuid, cropPlanGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropBudgetGuid 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public listCropBudgetExpenses(knowRiskAccountGuid: string, cropBudgetGuid: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).listCropBudgetExpenses(knowRiskAccountGuid, cropBudgetGuid, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} year 
     * @param {CropType} [cropType] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public listCropBudgets(knowRiskAccountGuid: string, year: string, cropType?: CropType, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).listCropBudgets(knowRiskAccountGuid, year, cropType, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {boolean} [isRetired] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public listCropFields(knowRiskAccountGuid: string, isRetired?: boolean, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).listCropFields(knowRiskAccountGuid, isRetired, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} year 
     * @param {string} [cropBudgetGuid] 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public listCropPlans(knowRiskAccountGuid: string, year: string, cropBudgetGuid?: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).listCropPlans(knowRiskAccountGuid, year, cropBudgetGuid, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropBudgetGuid 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public listCropPlansByCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).listCropPlansByCropBudget(knowRiskAccountGuid, cropBudgetGuid, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropFieldGuid 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public listCropPlansByCropField(knowRiskAccountGuid: string, cropFieldGuid: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).listCropPlansByCropField(knowRiskAccountGuid, cropFieldGuid, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropType 
     * @param {string} [searchTerm] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public listCropVarieties(knowRiskAccountGuid: string, cropType: string, searchTerm?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).listCropVarieties(knowRiskAccountGuid, cropType, searchTerm, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List fixed expenses for year.
     * @param {string} knowRiskAccountGuid 
     * @param {string} year 
     * @param {string} [sortColumn] 
     * @param {SortDirection} [sortDirection] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public listFixedExpenses(knowRiskAccountGuid: string, year: string, sortColumn?: string, sortDirection?: SortDirection, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).listFixedExpenses(knowRiskAccountGuid, year, sortColumn, sortDirection, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropFieldGuid 
     * @param {RetireCropFieldRequest} [retireCropFieldRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public retireCropField(knowRiskAccountGuid: string, cropFieldGuid: string, retireCropFieldRequest?: RetireCropFieldRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).retireCropField(knowRiskAccountGuid, cropFieldGuid, retireCropFieldRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropBudgetGuid 
     * @param {UpdateCropBudgetRequest} [updateCropBudgetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public updateCropBudget(knowRiskAccountGuid: string, cropBudgetGuid: string, updateCropBudgetRequest?: UpdateCropBudgetRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).updateCropBudget(knowRiskAccountGuid, cropBudgetGuid, updateCropBudgetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropBudgetGuid 
     * @param {string} cropBudgetExpenseGuid 
     * @param {UpdateCropBudgetExpenseRequest} [updateCropBudgetExpenseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public updateCropBudgetExpense(knowRiskAccountGuid: string, cropBudgetGuid: string, cropBudgetExpenseGuid: string, updateCropBudgetExpenseRequest?: UpdateCropBudgetExpenseRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).updateCropBudgetExpense(knowRiskAccountGuid, cropBudgetGuid, cropBudgetExpenseGuid, updateCropBudgetExpenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropFieldGuid 
     * @param {UpdateCropFieldRequest} [updateCropFieldRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public updateCropField(knowRiskAccountGuid: string, cropFieldGuid: string, updateCropFieldRequest?: UpdateCropFieldRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).updateCropField(knowRiskAccountGuid, cropFieldGuid, updateCropFieldRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} knowRiskAccountGuid 
     * @param {string} cropPlanGuid 
     * @param {UpdateCropPlanRequest} [updateCropPlanRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public updateCropPlan(knowRiskAccountGuid: string, cropPlanGuid: string, updateCropPlanRequest?: UpdateCropPlanRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).updateCropPlan(knowRiskAccountGuid, cropPlanGuid, updateCropPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update fixed expense.
     * @param {string} knowRiskAccountGuid 
     * @param {string} fixedExpenseGuid 
     * @param {UpdateFixedExpenseRequest} [updateFixedExpenseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskCropsApi
     */
    public updateFixedExpense(knowRiskAccountGuid: string, fixedExpenseGuid: string, updateFixedExpenseRequest?: UpdateFixedExpenseRequest, options?: AxiosRequestConfig) {
        return KnowRiskCropsApiFp(this.configuration).updateFixedExpense(knowRiskAccountGuid, fixedExpenseGuid, updateFixedExpenseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KnowRiskWeatherApi - axios parameter creator
 * @export
 */
export const KnowRiskWeatherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentConditions: async (latitude: number, longitude: number, unit?: WeatherUnit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('getCurrentConditions', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('getCurrentConditions', 'longitude', longitude)
            const localVarPath = `/know-risk/weather/v2/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }

            if (unit !== undefined) {
                localVarQueryParameter['Unit'] = unit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentWeatherConditions: async (latitude: number, longitude: number, unit?: WeatherUnit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('getCurrentWeatherConditions', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('getCurrentWeatherConditions', 'longitude', longitude)
            const localVarPath = `/know-risk/weather/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }

            if (unit !== undefined) {
                localVarQueryParameter['Unit'] = unit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalVideo: async (latitude: number, longitude: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('getLocalVideo', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('getLocalVideo', 'longitude', longitude)
            const localVarPath = `/know-risk/weather/video/local`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLongRangeVideo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/weather/video/long-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {number} width 
         * @param {number} height 
         * @param {string} bbox 
         * @param {string} srs 
         * @param {string} version 
         * @param {string} request 
         * @param {string} service 
         * @param {string} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadarTile: async (token: string, width: number, height: number, bbox: string, srs: string, version: string, request: string, service: string, time?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getRadarTile', 'token', token)
            // verify required parameter 'width' is not null or undefined
            assertParamExists('getRadarTile', 'width', width)
            // verify required parameter 'height' is not null or undefined
            assertParamExists('getRadarTile', 'height', height)
            // verify required parameter 'bbox' is not null or undefined
            assertParamExists('getRadarTile', 'bbox', bbox)
            // verify required parameter 'srs' is not null or undefined
            assertParamExists('getRadarTile', 'srs', srs)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getRadarTile', 'version', version)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('getRadarTile', 'request', request)
            // verify required parameter 'service' is not null or undefined
            assertParamExists('getRadarTile', 'service', service)
            const localVarPath = `/know-risk/weather/radar/tile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }

            if (width !== undefined) {
                localVarQueryParameter['Width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['Height'] = height;
            }

            if (bbox !== undefined) {
                localVarQueryParameter['Bbox'] = bbox;
            }

            if (srs !== undefined) {
                localVarQueryParameter['Srs'] = srs;
            }

            if (version !== undefined) {
                localVarQueryParameter['Version'] = version;
            }

            if (request !== undefined) {
                localVarQueryParameter['Request'] = request;
            }

            if (service !== undefined) {
                localVarQueryParameter['Service'] = service;
            }

            if (time !== undefined) {
                localVarQueryParameter['Time'] = time;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadarToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/weather/radar/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} versionGuid 
         * @param {number} zoomLevel 
         * @param {number} xCoordinate 
         * @param {number} yCoordinate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVectorRadarTile: async (versionGuid: string, zoomLevel: number, xCoordinate: number, yCoordinate: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionGuid' is not null or undefined
            assertParamExists('getVectorRadarTile', 'versionGuid', versionGuid)
            // verify required parameter 'zoomLevel' is not null or undefined
            assertParamExists('getVectorRadarTile', 'zoomLevel', zoomLevel)
            // verify required parameter 'xCoordinate' is not null or undefined
            assertParamExists('getVectorRadarTile', 'xCoordinate', xCoordinate)
            // verify required parameter 'yCoordinate' is not null or undefined
            assertParamExists('getVectorRadarTile', 'yCoordinate', yCoordinate)
            const localVarPath = `/know-risk/weather/radar/tiles/vector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (versionGuid !== undefined) {
                localVarQueryParameter['VersionGuid'] = versionGuid;
            }

            if (zoomLevel !== undefined) {
                localVarQueryParameter['ZoomLevel'] = zoomLevel;
            }

            if (xCoordinate !== undefined) {
                localVarQueryParameter['XCoordinate'] = xCoordinate;
            }

            if (yCoordinate !== undefined) {
                localVarQueryParameter['YCoordinate'] = yCoordinate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [formattedStartDate] 
         * @param {string} [formattedEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClarityWeatherHistoricalWeatherConditions: async (latitude: number, longitude: number, startDate: string, endDate: string, formattedStartDate?: string, formattedEndDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('listClarityWeatherHistoricalWeatherConditions', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('listClarityWeatherHistoricalWeatherConditions', 'longitude', longitude)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('listClarityWeatherHistoricalWeatherConditions', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('listClarityWeatherHistoricalWeatherConditions', 'endDate', endDate)
            const localVarPath = `/know-risk/weather/v2/historical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (formattedStartDate !== undefined) {
                localVarQueryParameter['FormattedStartDate'] = formattedStartDate;
            }

            if (formattedEndDate !== undefined) {
                localVarQueryParameter['FormattedEndDate'] = formattedEndDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDailyForecast: async (latitude: number, longitude: number, unit?: WeatherUnit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('listDailyForecast', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('listDailyForecast', 'longitude', longitude)
            const localVarPath = `/know-risk/weather/v2/daily`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }

            if (unit !== undefined) {
                localVarQueryParameter['Unit'] = unit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDailyWeatherForecast: async (latitude: number, longitude: number, unit?: WeatherUnit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('listDailyWeatherForecast', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('listDailyWeatherForecast', 'longitude', longitude)
            const localVarPath = `/know-risk/weather/daily`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }

            if (unit !== undefined) {
                localVarQueryParameter['Unit'] = unit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHistoricalWeather: async (latitude: number, longitude: number, dateRangeStart?: string, dateRangeEnd?: string, unit?: WeatherUnit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('listHistoricalWeather', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('listHistoricalWeather', 'longitude', longitude)
            const localVarPath = `/know-risk/weather/historical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }

            if (unit !== undefined) {
                localVarQueryParameter['Unit'] = unit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHourlyForecast: async (latitude: number, longitude: number, unit?: WeatherUnit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('listHourlyForecast', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('listHourlyForecast', 'longitude', longitude)
            const localVarPath = `/know-risk/weather/v2/hourly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }

            if (unit !== undefined) {
                localVarQueryParameter['Unit'] = unit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHourlyWeatherForecast: async (latitude: number, longitude: number, unit?: WeatherUnit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('listHourlyWeatherForecast', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('listHourlyWeatherForecast', 'longitude', longitude)
            const localVarPath = `/know-risk/weather/hourly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['Latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['Longitude'] = longitude;
            }

            if (unit !== undefined) {
                localVarQueryParameter['Unit'] = unit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRadarIntervalVersions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/weather/radar/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRadarTimes: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('listRadarTimes', 'token', token)
            const localVarPath = `/know-risk/weather/radar/times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVideoWeatherForecast: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/know-risk/weather/video`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnowRiskWeatherApi - functional programming interface
 * @export
 */
export const KnowRiskWeatherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KnowRiskWeatherApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentConditions(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClarityWeatherCurrentWeatherConditionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentConditions(latitude, longitude, unit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentWeatherConditions(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCurrentWeatherConditionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentWeatherConditions(latitude, longitude, unit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocalVideo(latitude: number, longitude: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClarityWeatherLocalVideoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalVideo(latitude, longitude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLongRangeVideo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClarityWeatherLongRangeVideoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLongRangeVideo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {number} width 
         * @param {number} height 
         * @param {string} bbox 
         * @param {string} srs 
         * @param {string} version 
         * @param {string} request 
         * @param {string} service 
         * @param {string} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRadarTile(token: string, width: number, height: number, bbox: string, srs: string, version: string, request: string, service: string, time?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRadarTile(token, width, height, bbox, srs, version, request, service, time, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRadarToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRadarTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRadarToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} versionGuid 
         * @param {number} zoomLevel 
         * @param {number} xCoordinate 
         * @param {number} yCoordinate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVectorRadarTile(versionGuid: string, zoomLevel: number, xCoordinate: number, yCoordinate: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVectorRadarTile(versionGuid, zoomLevel, xCoordinate, yCoordinate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [formattedStartDate] 
         * @param {string} [formattedEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClarityWeatherHistoricalWeatherConditions(latitude: number, longitude: number, startDate: string, endDate: string, formattedStartDate?: string, formattedEndDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListClarityWeatherHistoricalWeatherConditionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClarityWeatherHistoricalWeatherConditions(latitude, longitude, startDate, endDate, formattedStartDate, formattedEndDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDailyForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListClarityWeatherDailyWeatherForecastResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDailyForecast(latitude, longitude, unit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDailyWeatherForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDailyWeatherForecastResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDailyWeatherForecast(latitude, longitude, unit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHistoricalWeather(latitude: number, longitude: number, dateRangeStart?: string, dateRangeEnd?: string, unit?: WeatherUnit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListHistoricalWeatherResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHistoricalWeather(latitude, longitude, dateRangeStart, dateRangeEnd, unit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHourlyForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListClarityWeatherHourlyWeatherForecastResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHourlyForecast(latitude, longitude, unit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHourlyWeatherForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListHourlyWeatherForecastResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHourlyWeatherForecast(latitude, longitude, unit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRadarIntervalVersions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRadarIntervalVersionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRadarIntervalVersions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRadarTimes(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRadarTimesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRadarTimes(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVideoWeatherForecast(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWeatherVideoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVideoWeatherForecast(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KnowRiskWeatherApi - factory interface
 * @export
 */
export const KnowRiskWeatherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KnowRiskWeatherApiFp(configuration)
    return {
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentConditions(latitude: number, longitude: number, unit?: WeatherUnit, options?: any): AxiosPromise<GetClarityWeatherCurrentWeatherConditionsResponse> {
            return localVarFp.getCurrentConditions(latitude, longitude, unit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentWeatherConditions(latitude: number, longitude: number, unit?: WeatherUnit, options?: any): AxiosPromise<GetCurrentWeatherConditionsResponse> {
            return localVarFp.getCurrentWeatherConditions(latitude, longitude, unit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalVideo(latitude: number, longitude: number, options?: any): AxiosPromise<GetClarityWeatherLocalVideoResponse> {
            return localVarFp.getLocalVideo(latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLongRangeVideo(options?: any): AxiosPromise<GetClarityWeatherLongRangeVideoResponse> {
            return localVarFp.getLongRangeVideo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {number} width 
         * @param {number} height 
         * @param {string} bbox 
         * @param {string} srs 
         * @param {string} version 
         * @param {string} request 
         * @param {string} service 
         * @param {string} [time] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadarTile(token: string, width: number, height: number, bbox: string, srs: string, version: string, request: string, service: string, time?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getRadarTile(token, width, height, bbox, srs, version, request, service, time, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRadarToken(options?: any): AxiosPromise<GetRadarTokenResponse> {
            return localVarFp.getRadarToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} versionGuid 
         * @param {number} zoomLevel 
         * @param {number} xCoordinate 
         * @param {number} yCoordinate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVectorRadarTile(versionGuid: string, zoomLevel: number, xCoordinate: number, yCoordinate: number, options?: any): AxiosPromise<void> {
            return localVarFp.getVectorRadarTile(versionGuid, zoomLevel, xCoordinate, yCoordinate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [formattedStartDate] 
         * @param {string} [formattedEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClarityWeatherHistoricalWeatherConditions(latitude: number, longitude: number, startDate: string, endDate: string, formattedStartDate?: string, formattedEndDate?: string, options?: any): AxiosPromise<ListClarityWeatherHistoricalWeatherConditionsResponse> {
            return localVarFp.listClarityWeatherHistoricalWeatherConditions(latitude, longitude, startDate, endDate, formattedStartDate, formattedEndDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDailyForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: any): AxiosPromise<ListClarityWeatherDailyWeatherForecastResponse> {
            return localVarFp.listDailyForecast(latitude, longitude, unit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDailyWeatherForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: any): AxiosPromise<ListDailyWeatherForecastResponse> {
            return localVarFp.listDailyWeatherForecast(latitude, longitude, unit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHistoricalWeather(latitude: number, longitude: number, dateRangeStart?: string, dateRangeEnd?: string, unit?: WeatherUnit, options?: any): AxiosPromise<ListHistoricalWeatherResponse> {
            return localVarFp.listHistoricalWeather(latitude, longitude, dateRangeStart, dateRangeEnd, unit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHourlyForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: any): AxiosPromise<ListClarityWeatherHourlyWeatherForecastResponse> {
            return localVarFp.listHourlyForecast(latitude, longitude, unit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {WeatherUnit} [unit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHourlyWeatherForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: any): AxiosPromise<ListHourlyWeatherForecastResponse> {
            return localVarFp.listHourlyWeatherForecast(latitude, longitude, unit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRadarIntervalVersions(options?: any): AxiosPromise<ListRadarIntervalVersionsResponse> {
            return localVarFp.listRadarIntervalVersions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRadarTimes(token: string, options?: any): AxiosPromise<ListRadarTimesResponse> {
            return localVarFp.listRadarTimes(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVideoWeatherForecast(options?: any): AxiosPromise<ListWeatherVideoResponse> {
            return localVarFp.listVideoWeatherForecast(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnowRiskWeatherApi - object-oriented interface
 * @export
 * @class KnowRiskWeatherApi
 * @extends {BaseAPI}
 */
export class KnowRiskWeatherApi extends BaseAPI {
    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {WeatherUnit} [unit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public getCurrentConditions(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).getCurrentConditions(latitude, longitude, unit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {WeatherUnit} [unit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public getCurrentWeatherConditions(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).getCurrentWeatherConditions(latitude, longitude, unit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public getLocalVideo(latitude: number, longitude: number, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).getLocalVideo(latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public getLongRangeVideo(options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).getLongRangeVideo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {number} width 
     * @param {number} height 
     * @param {string} bbox 
     * @param {string} srs 
     * @param {string} version 
     * @param {string} request 
     * @param {string} service 
     * @param {string} [time] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public getRadarTile(token: string, width: number, height: number, bbox: string, srs: string, version: string, request: string, service: string, time?: string, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).getRadarTile(token, width, height, bbox, srs, version, request, service, time, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public getRadarToken(options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).getRadarToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} versionGuid 
     * @param {number} zoomLevel 
     * @param {number} xCoordinate 
     * @param {number} yCoordinate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public getVectorRadarTile(versionGuid: string, zoomLevel: number, xCoordinate: number, yCoordinate: number, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).getVectorRadarTile(versionGuid, zoomLevel, xCoordinate, yCoordinate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} [formattedStartDate] 
     * @param {string} [formattedEndDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public listClarityWeatherHistoricalWeatherConditions(latitude: number, longitude: number, startDate: string, endDate: string, formattedStartDate?: string, formattedEndDate?: string, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).listClarityWeatherHistoricalWeatherConditions(latitude, longitude, startDate, endDate, formattedStartDate, formattedEndDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {WeatherUnit} [unit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public listDailyForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).listDailyForecast(latitude, longitude, unit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {WeatherUnit} [unit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public listDailyWeatherForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).listDailyWeatherForecast(latitude, longitude, unit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {WeatherUnit} [unit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public listHistoricalWeather(latitude: number, longitude: number, dateRangeStart?: string, dateRangeEnd?: string, unit?: WeatherUnit, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).listHistoricalWeather(latitude, longitude, dateRangeStart, dateRangeEnd, unit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {WeatherUnit} [unit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public listHourlyForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).listHourlyForecast(latitude, longitude, unit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {WeatherUnit} [unit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public listHourlyWeatherForecast(latitude: number, longitude: number, unit?: WeatherUnit, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).listHourlyWeatherForecast(latitude, longitude, unit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public listRadarIntervalVersions(options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).listRadarIntervalVersions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public listRadarTimes(token: string, options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).listRadarTimes(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowRiskWeatherApi
     */
    public listVideoWeatherForecast(options?: AxiosRequestConfig) {
        return KnowRiskWeatherApiFp(this.configuration).listVideoWeatherForecast(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketIntelligenceApi - axios parameter creator
 * @export
 */
export const MarketIntelligenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddPushNotificationRequest} [addPushNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPushNotification: async (addPushNotificationRequest?: AddPushNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/push-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPushNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddSubscriptionRequest} [addSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubscription: async (addSubscriptionRequest?: AddSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddWatchListRequest} [addWatchListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWatchList: async (addWatchListRequest?: AddWatchListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/watch-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addWatchListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePushNotificationRequest} [deletePushNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePushNotification: async (deletePushNotificationRequest?: DeletePushNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/push-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePushNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteSubscriptionRequest} [deleteSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription: async (deleteSubscriptionRequest?: DeleteSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contentConfigurationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWatchList: async (contentConfigurationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentConfigurationId' is not null or undefined
            assertParamExists('deleteWatchList', 'contentConfigurationId', contentConfigurationId)
            const localVarPath = `/market-intelligence/watch-list/{contentConfigurationId}`
                .replace(`{${"contentConfigurationId"}}`, encodeURIComponent(String(contentConfigurationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} articleId 
         * @param {string} [languageCode] 
         * @param {Application} [mobileApplication] 
         * @param {boolean} [usePremiumTrial] 
         * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle: async (articleId: string, languageCode?: string, mobileApplication?: Application, usePremiumTrial?: boolean, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getArticle', 'articleId', articleId)
            const localVarPath = `/market-intelligence/articles/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }

            if (mobileApplication !== undefined) {
                localVarQueryParameter['MobileApplication'] = mobileApplication;
            }

            if (usePremiumTrial !== undefined) {
                localVarQueryParameter['UsePremiumTrial'] = usePremiumTrial;
            }

            if (subscriptionCountry !== undefined) {
                localVarQueryParameter['SubscriptionCountry'] = subscriptionCountry;
            }

            if (source !== undefined) {
                localVarQueryParameter['Source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIntelligenceUser: async (email: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getMarketIntelligenceUser', 'email', email)
            const localVarPath = `/market-intelligence/users/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (source !== undefined) {
                localVarQueryParameter['Source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/subscriptions/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticleViewCounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/article-views`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticleViews: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/articles/views`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [author] 
         * @param {boolean} [isExactSearch] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {number} [marketId] 
         * @param {string} [contentConfigurationId] 
         * @param {string} [languageCode] 
         * @param {boolean} [usePremiumTrial] 
         * @param {Application} [mobileApplication] 
         * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
         * @param {string} [source] 
         * @param {boolean} [showOnlySubscibedArticles] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticles: async (searchTerm?: string, author?: string, isExactSearch?: boolean, dateRangeStart?: string, dateRangeEnd?: string, marketId?: number, contentConfigurationId?: string, languageCode?: string, usePremiumTrial?: boolean, mobileApplication?: Application, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, showOnlySubscibedArticles?: boolean, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (author !== undefined) {
                localVarQueryParameter['Author'] = author;
            }

            if (isExactSearch !== undefined) {
                localVarQueryParameter['IsExactSearch'] = isExactSearch;
            }

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }

            if (marketId !== undefined) {
                localVarQueryParameter['MarketId'] = marketId;
            }

            if (contentConfigurationId !== undefined) {
                localVarQueryParameter['ContentConfigurationId'] = contentConfigurationId;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }

            if (usePremiumTrial !== undefined) {
                localVarQueryParameter['UsePremiumTrial'] = usePremiumTrial;
            }

            if (mobileApplication !== undefined) {
                localVarQueryParameter['MobileApplication'] = mobileApplication;
            }

            if (subscriptionCountry !== undefined) {
                localVarQueryParameter['SubscriptionCountry'] = subscriptionCountry;
            }

            if (source !== undefined) {
                localVarQueryParameter['Source'] = source;
            }

            if (showOnlySubscibedArticles !== undefined) {
                localVarQueryParameter['ShowOnlySubscibedArticles'] = showOnlySubscibedArticles;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketId 
         * @param {string} [searchTerm] 
         * @param {string} [author] 
         * @param {boolean} [isExactSearch] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {string} [contentConfigurationId] 
         * @param {string} [languageCode] 
         * @param {boolean} [usePremiumTrial] 
         * @param {Application} [mobileApplication] 
         * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
         * @param {string} [source] 
         * @param {boolean} [showOnlySubscibedArticles] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketArticles: async (marketId: string, searchTerm?: string, author?: string, isExactSearch?: boolean, dateRangeStart?: string, dateRangeEnd?: string, contentConfigurationId?: string, languageCode?: string, usePremiumTrial?: boolean, mobileApplication?: Application, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, showOnlySubscibedArticles?: boolean, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('listMarketArticles', 'marketId', marketId)
            const localVarPath = `/market-intelligence/markets/{marketId}/articles`
                .replace(`{${"marketId"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (author !== undefined) {
                localVarQueryParameter['Author'] = author;
            }

            if (isExactSearch !== undefined) {
                localVarQueryParameter['IsExactSearch'] = isExactSearch;
            }

            if (dateRangeStart !== undefined) {
                localVarQueryParameter['DateRange.Start'] = (dateRangeStart as any instanceof Date) ?
                    (dateRangeStart as any).toISOString().substr(0,10) :
                    dateRangeStart;
            }

            if (dateRangeEnd !== undefined) {
                localVarQueryParameter['DateRange.End'] = (dateRangeEnd as any instanceof Date) ?
                    (dateRangeEnd as any).toISOString().substr(0,10) :
                    dateRangeEnd;
            }

            if (contentConfigurationId !== undefined) {
                localVarQueryParameter['ContentConfigurationId'] = contentConfigurationId;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }

            if (usePremiumTrial !== undefined) {
                localVarQueryParameter['UsePremiumTrial'] = usePremiumTrial;
            }

            if (mobileApplication !== undefined) {
                localVarQueryParameter['MobileApplication'] = mobileApplication;
            }

            if (subscriptionCountry !== undefined) {
                localVarQueryParameter['SubscriptionCountry'] = subscriptionCountry;
            }

            if (source !== undefined) {
                localVarQueryParameter['Source'] = source;
            }

            if (showOnlySubscibedArticles !== undefined) {
                localVarQueryParameter['ShowOnlySubscibedArticles'] = showOnlySubscibedArticles;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarkets: async (languageCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/markets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPremiumArticle: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/premium-articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubscriptions: async (email: string, source?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('listSubscriptions', 'email', email)
            const localVarPath = `/market-intelligence/subscriptions/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (source !== undefined) {
                localVarQueryParameter['Source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWatchList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/watch-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWatchListArticles: async (languageCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/market-intelligence/watch-list/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} articleContentId 
         * @param {UpdateArticleViewCountRequest} [updateArticleViewCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticleViewCount: async (articleContentId: string, updateArticleViewCountRequest?: UpdateArticleViewCountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleContentId' is not null or undefined
            assertParamExists('updateArticleViewCount', 'articleContentId', articleContentId)
            const localVarPath = `/market-intelligence/article-views/{articleContentId}`
                .replace(`{${"articleContentId"}}`, encodeURIComponent(String(articleContentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateArticleViewCountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} articleContentId 
         * @param {UpdateArticleViewCountRequest} [updateArticleViewCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticleViews: async (articleContentId: string, updateArticleViewCountRequest?: UpdateArticleViewCountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleContentId' is not null or undefined
            assertParamExists('updateArticleViews', 'articleContentId', articleContentId)
            const localVarPath = `/market-intelligence/articles/{articleContentId}/views`
                .replace(`{${"articleContentId"}}`, encodeURIComponent(String(articleContentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateArticleViewCountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketIntelligenceApi - functional programming interface
 * @export
 */
export const MarketIntelligenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketIntelligenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddPushNotificationRequest} [addPushNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPushNotification(addPushNotificationRequest?: AddPushNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPushNotification(addPushNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddSubscriptionRequest} [addSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSubscription(addSubscriptionRequest?: AddSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSubscription(addSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddWatchListRequest} [addWatchListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWatchList(addWatchListRequest?: AddWatchListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWatchList(addWatchListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePushNotificationRequest} [deletePushNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePushNotification(deletePushNotificationRequest?: DeletePushNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePushNotification(deletePushNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteSubscriptionRequest} [deleteSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubscription(deleteSubscriptionRequest?: DeleteSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSubscription(deleteSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} contentConfigurationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWatchList(contentConfigurationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWatchList(contentConfigurationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} articleId 
         * @param {string} [languageCode] 
         * @param {Application} [mobileApplication] 
         * @param {boolean} [usePremiumTrial] 
         * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticle(articleId: string, languageCode?: string, mobileApplication?: Application, usePremiumTrial?: boolean, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetArticleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticle(articleId, languageCode, mobileApplication, usePremiumTrial, subscriptionCountry, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketIntelligenceUser(email: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketIntelligenceUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketIntelligenceUser(email, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubscriptionStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listArticleViewCounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListArticleViewCountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listArticleViewCounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listArticleViews(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListArticleViewCountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listArticleViews(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [author] 
         * @param {boolean} [isExactSearch] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {number} [marketId] 
         * @param {string} [contentConfigurationId] 
         * @param {string} [languageCode] 
         * @param {boolean} [usePremiumTrial] 
         * @param {Application} [mobileApplication] 
         * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
         * @param {string} [source] 
         * @param {boolean} [showOnlySubscibedArticles] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listArticles(searchTerm?: string, author?: string, isExactSearch?: boolean, dateRangeStart?: string, dateRangeEnd?: string, marketId?: number, contentConfigurationId?: string, languageCode?: string, usePremiumTrial?: boolean, mobileApplication?: Application, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, showOnlySubscibedArticles?: boolean, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListArticlesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listArticles(searchTerm, author, isExactSearch, dateRangeStart, dateRangeEnd, marketId, contentConfigurationId, languageCode, usePremiumTrial, mobileApplication, subscriptionCountry, source, showOnlySubscibedArticles, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} marketId 
         * @param {string} [searchTerm] 
         * @param {string} [author] 
         * @param {boolean} [isExactSearch] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {string} [contentConfigurationId] 
         * @param {string} [languageCode] 
         * @param {boolean} [usePremiumTrial] 
         * @param {Application} [mobileApplication] 
         * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
         * @param {string} [source] 
         * @param {boolean} [showOnlySubscibedArticles] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketArticles(marketId: string, searchTerm?: string, author?: string, isExactSearch?: boolean, dateRangeStart?: string, dateRangeEnd?: string, contentConfigurationId?: string, languageCode?: string, usePremiumTrial?: boolean, mobileApplication?: Application, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, showOnlySubscibedArticles?: boolean, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListArticlesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketArticles(marketId, searchTerm, author, isExactSearch, dateRangeStart, dateRangeEnd, contentConfigurationId, languageCode, usePremiumTrial, mobileApplication, subscriptionCountry, source, showOnlySubscibedArticles, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarkets(languageCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMarketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarkets(languageCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPremiumArticle(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPremiumArticlesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPremiumArticle(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubscriptions(email: string, source?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSubscriptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSubscriptions(email, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWatchList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWatchListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWatchList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWatchListArticles(languageCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListArticlesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWatchListArticles(languageCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} articleContentId 
         * @param {UpdateArticleViewCountRequest} [updateArticleViewCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArticleViewCount(articleContentId: string, updateArticleViewCountRequest?: UpdateArticleViewCountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateArticleViewCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArticleViewCount(articleContentId, updateArticleViewCountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} articleContentId 
         * @param {UpdateArticleViewCountRequest} [updateArticleViewCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArticleViews(articleContentId: string, updateArticleViewCountRequest?: UpdateArticleViewCountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateArticleViewCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArticleViews(articleContentId, updateArticleViewCountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketIntelligenceApi - factory interface
 * @export
 */
export const MarketIntelligenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketIntelligenceApiFp(configuration)
    return {
        /**
         * 
         * @param {AddPushNotificationRequest} [addPushNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPushNotification(addPushNotificationRequest?: AddPushNotificationRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addPushNotification(addPushNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddSubscriptionRequest} [addSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubscription(addSubscriptionRequest?: AddSubscriptionRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addSubscription(addSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddWatchListRequest} [addWatchListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWatchList(addWatchListRequest?: AddWatchListRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addWatchList(addWatchListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePushNotificationRequest} [deletePushNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePushNotification(deletePushNotificationRequest?: DeletePushNotificationRequest, options?: any): AxiosPromise<object> {
            return localVarFp.deletePushNotification(deletePushNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteSubscriptionRequest} [deleteSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription(deleteSubscriptionRequest?: DeleteSubscriptionRequest, options?: any): AxiosPromise<object> {
            return localVarFp.deleteSubscription(deleteSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} contentConfigurationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWatchList(contentConfigurationId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteWatchList(contentConfigurationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} articleId 
         * @param {string} [languageCode] 
         * @param {Application} [mobileApplication] 
         * @param {boolean} [usePremiumTrial] 
         * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle(articleId: string, languageCode?: string, mobileApplication?: Application, usePremiumTrial?: boolean, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, options?: any): AxiosPromise<GetArticleResponse> {
            return localVarFp.getArticle(articleId, languageCode, mobileApplication, usePremiumTrial, subscriptionCountry, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketIntelligenceUser(email: string, source?: string, options?: any): AxiosPromise<GetMarketIntelligenceUserResponse> {
            return localVarFp.getMarketIntelligenceUser(email, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionStatus(options?: any): AxiosPromise<GetSubscriptionStatusResponse> {
            return localVarFp.getSubscriptionStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticleViewCounts(options?: any): AxiosPromise<ListArticleViewCountsResponse> {
            return localVarFp.listArticleViewCounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticleViews(options?: any): AxiosPromise<ListArticleViewCountsResponse> {
            return localVarFp.listArticleViews(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {string} [author] 
         * @param {boolean} [isExactSearch] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {number} [marketId] 
         * @param {string} [contentConfigurationId] 
         * @param {string} [languageCode] 
         * @param {boolean} [usePremiumTrial] 
         * @param {Application} [mobileApplication] 
         * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
         * @param {string} [source] 
         * @param {boolean} [showOnlySubscibedArticles] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticles(searchTerm?: string, author?: string, isExactSearch?: boolean, dateRangeStart?: string, dateRangeEnd?: string, marketId?: number, contentConfigurationId?: string, languageCode?: string, usePremiumTrial?: boolean, mobileApplication?: Application, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, showOnlySubscibedArticles?: boolean, take?: number, skip?: number, options?: any): AxiosPromise<ListArticlesResponse> {
            return localVarFp.listArticles(searchTerm, author, isExactSearch, dateRangeStart, dateRangeEnd, marketId, contentConfigurationId, languageCode, usePremiumTrial, mobileApplication, subscriptionCountry, source, showOnlySubscibedArticles, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} marketId 
         * @param {string} [searchTerm] 
         * @param {string} [author] 
         * @param {boolean} [isExactSearch] 
         * @param {string} [dateRangeStart] 
         * @param {string} [dateRangeEnd] 
         * @param {string} [contentConfigurationId] 
         * @param {string} [languageCode] 
         * @param {boolean} [usePremiumTrial] 
         * @param {Application} [mobileApplication] 
         * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
         * @param {string} [source] 
         * @param {boolean} [showOnlySubscibedArticles] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketArticles(marketId: string, searchTerm?: string, author?: string, isExactSearch?: boolean, dateRangeStart?: string, dateRangeEnd?: string, contentConfigurationId?: string, languageCode?: string, usePremiumTrial?: boolean, mobileApplication?: Application, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, showOnlySubscibedArticles?: boolean, take?: number, skip?: number, options?: any): AxiosPromise<ListArticlesResponse> {
            return localVarFp.listMarketArticles(marketId, searchTerm, author, isExactSearch, dateRangeStart, dateRangeEnd, contentConfigurationId, languageCode, usePremiumTrial, mobileApplication, subscriptionCountry, source, showOnlySubscibedArticles, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarkets(languageCode?: string, options?: any): AxiosPromise<ListMarketsResponse> {
            return localVarFp.listMarkets(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPremiumArticle(options?: any): AxiosPromise<ListPremiumArticlesResponse> {
            return localVarFp.listPremiumArticle(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubscriptions(email: string, source?: string, options?: any): AxiosPromise<ListSubscriptionsResponse> {
            return localVarFp.listSubscriptions(email, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWatchList(options?: any): AxiosPromise<ListWatchListResponse> {
            return localVarFp.listWatchList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWatchListArticles(languageCode?: string, options?: any): AxiosPromise<ListArticlesResponse> {
            return localVarFp.listWatchListArticles(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} articleContentId 
         * @param {UpdateArticleViewCountRequest} [updateArticleViewCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticleViewCount(articleContentId: string, updateArticleViewCountRequest?: UpdateArticleViewCountRequest, options?: any): AxiosPromise<UpdateArticleViewCountResponse> {
            return localVarFp.updateArticleViewCount(articleContentId, updateArticleViewCountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} articleContentId 
         * @param {UpdateArticleViewCountRequest} [updateArticleViewCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticleViews(articleContentId: string, updateArticleViewCountRequest?: UpdateArticleViewCountRequest, options?: any): AxiosPromise<UpdateArticleViewCountResponse> {
            return localVarFp.updateArticleViews(articleContentId, updateArticleViewCountRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketIntelligenceApi - object-oriented interface
 * @export
 * @class MarketIntelligenceApi
 * @extends {BaseAPI}
 */
export class MarketIntelligenceApi extends BaseAPI {
    /**
     * 
     * @param {AddPushNotificationRequest} [addPushNotificationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public addPushNotification(addPushNotificationRequest?: AddPushNotificationRequest, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).addPushNotification(addPushNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddSubscriptionRequest} [addSubscriptionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public addSubscription(addSubscriptionRequest?: AddSubscriptionRequest, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).addSubscription(addSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddWatchListRequest} [addWatchListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public addWatchList(addWatchListRequest?: AddWatchListRequest, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).addWatchList(addWatchListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePushNotificationRequest} [deletePushNotificationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public deletePushNotification(deletePushNotificationRequest?: DeletePushNotificationRequest, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).deletePushNotification(deletePushNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteSubscriptionRequest} [deleteSubscriptionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public deleteSubscription(deleteSubscriptionRequest?: DeleteSubscriptionRequest, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).deleteSubscription(deleteSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} contentConfigurationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public deleteWatchList(contentConfigurationId: string, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).deleteWatchList(contentConfigurationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} articleId 
     * @param {string} [languageCode] 
     * @param {Application} [mobileApplication] 
     * @param {boolean} [usePremiumTrial] 
     * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public getArticle(articleId: string, languageCode?: string, mobileApplication?: Application, usePremiumTrial?: boolean, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).getArticle(articleId, languageCode, mobileApplication, usePremiumTrial, subscriptionCountry, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public getMarketIntelligenceUser(email: string, source?: string, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).getMarketIntelligenceUser(email, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public getSubscriptionStatus(options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).getSubscriptionStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public listArticleViewCounts(options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).listArticleViewCounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public listArticleViews(options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).listArticleViews(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {string} [author] 
     * @param {boolean} [isExactSearch] 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {number} [marketId] 
     * @param {string} [contentConfigurationId] 
     * @param {string} [languageCode] 
     * @param {boolean} [usePremiumTrial] 
     * @param {Application} [mobileApplication] 
     * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
     * @param {string} [source] 
     * @param {boolean} [showOnlySubscibedArticles] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public listArticles(searchTerm?: string, author?: string, isExactSearch?: boolean, dateRangeStart?: string, dateRangeEnd?: string, marketId?: number, contentConfigurationId?: string, languageCode?: string, usePremiumTrial?: boolean, mobileApplication?: Application, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, showOnlySubscibedArticles?: boolean, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).listArticles(searchTerm, author, isExactSearch, dateRangeStart, dateRangeEnd, marketId, contentConfigurationId, languageCode, usePremiumTrial, mobileApplication, subscriptionCountry, source, showOnlySubscibedArticles, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} marketId 
     * @param {string} [searchTerm] 
     * @param {string} [author] 
     * @param {boolean} [isExactSearch] 
     * @param {string} [dateRangeStart] 
     * @param {string} [dateRangeEnd] 
     * @param {string} [contentConfigurationId] 
     * @param {string} [languageCode] 
     * @param {boolean} [usePremiumTrial] 
     * @param {Application} [mobileApplication] 
     * @param {MarketIntelligenceSubscriptionCountry} [subscriptionCountry] 
     * @param {string} [source] 
     * @param {boolean} [showOnlySubscibedArticles] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public listMarketArticles(marketId: string, searchTerm?: string, author?: string, isExactSearch?: boolean, dateRangeStart?: string, dateRangeEnd?: string, contentConfigurationId?: string, languageCode?: string, usePremiumTrial?: boolean, mobileApplication?: Application, subscriptionCountry?: MarketIntelligenceSubscriptionCountry, source?: string, showOnlySubscibedArticles?: boolean, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).listMarketArticles(marketId, searchTerm, author, isExactSearch, dateRangeStart, dateRangeEnd, contentConfigurationId, languageCode, usePremiumTrial, mobileApplication, subscriptionCountry, source, showOnlySubscibedArticles, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public listMarkets(languageCode?: string, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).listMarkets(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public listPremiumArticle(options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).listPremiumArticle(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public listSubscriptions(email: string, source?: string, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).listSubscriptions(email, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public listWatchList(options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).listWatchList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public listWatchListArticles(languageCode?: string, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).listWatchListArticles(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} articleContentId 
     * @param {UpdateArticleViewCountRequest} [updateArticleViewCountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public updateArticleViewCount(articleContentId: string, updateArticleViewCountRequest?: UpdateArticleViewCountRequest, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).updateArticleViewCount(articleContentId, updateArticleViewCountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} articleContentId 
     * @param {UpdateArticleViewCountRequest} [updateArticleViewCountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketIntelligenceApi
     */
    public updateArticleViews(articleContentId: string, updateArticleViewCountRequest?: UpdateArticleViewCountRequest, options?: AxiosRequestConfig) {
        return MarketIntelligenceApiFp(this.configuration).updateArticleViews(articleContentId, updateArticleViewCountRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplication: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/registration/application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddTermsAndConditionsRequest} [addTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTermsAndConditions: async (addTermsAndConditionsRequest?: AddTermsAndConditionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/terms-and-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTermsAndConditionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddUserAuthentiationAuditRequest} [addUserAuthentiationAuditRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserAuthenticatedAudit: async (addUserAuthentiationAuditRequest?: AddUserAuthentiationAuditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/authentication/audit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserAuthentiationAuditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AgreeToTermsAndConditionsRequest} [agreeToTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToTermsAndConditions: async (agreeToTermsAndConditionsRequest?: AgreeToTermsAndConditionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/terms-and-conditions/agree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreeToTermsAndConditionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userAgreementType 
         * @param {AgreeToUserAgreementRequest} [agreeToUserAgreementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToUserAgreement: async (userAgreementType: string, agreeToUserAgreementRequest?: AgreeToUserAgreementRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAgreementType' is not null or undefined
            assertParamExists('agreeToUserAgreement', 'userAgreementType', userAgreementType)
            const localVarPath = `/users/agreements/{userAgreementType}/agree`
                .replace(`{${"userAgreementType"}}`, encodeURIComponent(String(userAgreementType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreeToUserAgreementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthenticateUserRequest} [authenticateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser: async (authenticateUserRequest?: AuthenticateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {DeleteZohoSubscriptionRequest} [deleteZohoSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscriptionFromZoho: async (subscriptionId: string, deleteZohoSubscriptionRequest?: DeleteZohoSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('deleteSubscriptionFromZoho', 'subscriptionId', subscriptionId)
            const localVarPath = `/users/registration/zoho/subscription/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteZohoSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} application 
         * @param {string} [subApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsAndConditions: async (application: string, subApplication?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'application' is not null or undefined
            assertParamExists('getTermsAndConditions', 'application', application)
            const localVarPath = `/users/terms-and-conditions/{application}`
                .replace(`{${"application"}}`, encodeURIComponent(String(application)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subApplication !== undefined) {
                localVarQueryParameter['SubApplication'] = subApplication;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userAgreementType 
         * @param {string} application 
         * @param {string} [subApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAgreement: async (userAgreementType: string, application: string, subApplication?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAgreementType' is not null or undefined
            assertParamExists('getUserAgreement', 'userAgreementType', userAgreementType)
            // verify required parameter 'application' is not null or undefined
            assertParamExists('getUserAgreement', 'application', application)
            const localVarPath = `/users/agreements/{userAgreementType}/{application}`
                .replace(`{${"userAgreementType"}}`, encodeURIComponent(String(userAgreementType)))
                .replace(`{${"application"}}`, encodeURIComponent(String(application)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subApplication !== undefined) {
                localVarQueryParameter['SubApplication'] = subApplication;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getUserByEmail', 'email', email)
            const localVarPath = `/users/email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterUserRequest} [registerUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (registerUserRequest?: RegisterUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterUserForZohoRequest} [registerUserForZohoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserForZoho: async (registerUserForZohoRequest?: RegisterUserForZohoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/registration/zoho`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerUserForZohoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} termsAndConditionsGuid 
         * @param {UpdateTermsAndConditionsRequest} [updateTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermsAndConditions: async (termsAndConditionsGuid: string, updateTermsAndConditionsRequest?: UpdateTermsAndConditionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'termsAndConditionsGuid' is not null or undefined
            assertParamExists('updateTermsAndConditions', 'termsAndConditionsGuid', termsAndConditionsGuid)
            const localVarPath = `/users/terms-and-conditions/{termsAndConditionsGuid}`
                .replace(`{${"termsAndConditionsGuid"}}`, encodeURIComponent(String(termsAndConditionsGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTermsAndConditionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUserToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyRegistrationRequest} [verifyRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyRegistration: async (verifyRegistrationRequest?: VerifyRegistrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/registration/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApplication(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApplication(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddTermsAndConditionsRequest} [addTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTermsAndConditions(addTermsAndConditionsRequest?: AddTermsAndConditionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddTermsAndConditionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTermsAndConditions(addTermsAndConditionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddUserAuthentiationAuditRequest} [addUserAuthentiationAuditRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserAuthenticatedAudit(addUserAuthentiationAuditRequest?: AddUserAuthentiationAuditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserAuthenticatedAudit(addUserAuthentiationAuditRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AgreeToTermsAndConditionsRequest} [agreeToTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agreeToTermsAndConditions(agreeToTermsAndConditionsRequest?: AgreeToTermsAndConditionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agreeToTermsAndConditions(agreeToTermsAndConditionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userAgreementType 
         * @param {AgreeToUserAgreementRequest} [agreeToUserAgreementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agreeToUserAgreement(userAgreementType: string, agreeToUserAgreementRequest?: AgreeToUserAgreementRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agreeToUserAgreement(userAgreementType, agreeToUserAgreementRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthenticateUserRequest} [authenticateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUser(authenticateUserRequest?: AuthenticateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticateUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateUser(authenticateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {DeleteZohoSubscriptionRequest} [deleteZohoSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubscriptionFromZoho(subscriptionId: string, deleteZohoSubscriptionRequest?: DeleteZohoSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteZohoSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSubscriptionFromZoho(subscriptionId, deleteZohoSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} application 
         * @param {string} [subApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTermsAndConditions(application: string, subApplication?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTermsAndConditionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTermsAndConditions(application, subApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userAgreementType 
         * @param {string} application 
         * @param {string} [subApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAgreement(userAgreementType: string, application: string, subApplication?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserAgreementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAgreement(userAgreementType, application, subApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterUserRequest} [registerUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(registerUserRequest?: RegisterUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(registerUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterUserForZohoRequest} [registerUserForZohoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUserForZoho(registerUserForZohoRequest?: RegisterUserForZohoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterUserForZohoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUserForZoho(registerUserForZohoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} termsAndConditionsGuid 
         * @param {UpdateTermsAndConditionsRequest} [updateTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTermsAndConditions(termsAndConditionsGuid: string, updateTermsAndConditionsRequest?: UpdateTermsAndConditionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTermsAndConditions(termsAndConditionsGuid, updateTermsAndConditionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateUserToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateUserTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateUserToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyRegistrationRequest} [verifyRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyRegistration(verifyRegistrationRequest?: VerifyRegistrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyRegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyRegistration(verifyRegistrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplication(options?: any): AxiosPromise<object> {
            return localVarFp.addApplication(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddTermsAndConditionsRequest} [addTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTermsAndConditions(addTermsAndConditionsRequest?: AddTermsAndConditionsRequest, options?: any): AxiosPromise<AddTermsAndConditionsResponse> {
            return localVarFp.addTermsAndConditions(addTermsAndConditionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddUserAuthentiationAuditRequest} [addUserAuthentiationAuditRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserAuthenticatedAudit(addUserAuthentiationAuditRequest?: AddUserAuthentiationAuditRequest, options?: any): AxiosPromise<object> {
            return localVarFp.addUserAuthenticatedAudit(addUserAuthentiationAuditRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AgreeToTermsAndConditionsRequest} [agreeToTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToTermsAndConditions(agreeToTermsAndConditionsRequest?: AgreeToTermsAndConditionsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.agreeToTermsAndConditions(agreeToTermsAndConditionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userAgreementType 
         * @param {AgreeToUserAgreementRequest} [agreeToUserAgreementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agreeToUserAgreement(userAgreementType: string, agreeToUserAgreementRequest?: AgreeToUserAgreementRequest, options?: any): AxiosPromise<object> {
            return localVarFp.agreeToUserAgreement(userAgreementType, agreeToUserAgreementRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticateUserRequest} [authenticateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser(authenticateUserRequest?: AuthenticateUserRequest, options?: any): AxiosPromise<AuthenticateUserResponse> {
            return localVarFp.authenticateUser(authenticateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {DeleteZohoSubscriptionRequest} [deleteZohoSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscriptionFromZoho(subscriptionId: string, deleteZohoSubscriptionRequest?: DeleteZohoSubscriptionRequest, options?: any): AxiosPromise<DeleteZohoSubscriptionResponse> {
            return localVarFp.deleteSubscriptionFromZoho(subscriptionId, deleteZohoSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<GetUserResponse> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} application 
         * @param {string} [subApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsAndConditions(application: string, subApplication?: string, options?: any): AxiosPromise<GetTermsAndConditionsResponse> {
            return localVarFp.getTermsAndConditions(application, subApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userAgreementType 
         * @param {string} application 
         * @param {string} [subApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAgreement(userAgreementType: string, application: string, subApplication?: string, options?: any): AxiosPromise<GetUserAgreementResponse> {
            return localVarFp.getUserAgreement(userAgreementType, application, subApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByEmail(email: string, options?: any): AxiosPromise<GetUserResponse> {
            return localVarFp.getUserByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterUserRequest} [registerUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(registerUserRequest?: RegisterUserRequest, options?: any): AxiosPromise<RegisterUserResponse> {
            return localVarFp.registerUser(registerUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterUserForZohoRequest} [registerUserForZohoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserForZoho(registerUserForZohoRequest?: RegisterUserForZohoRequest, options?: any): AxiosPromise<RegisterUserForZohoResponse> {
            return localVarFp.registerUserForZoho(registerUserForZohoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} termsAndConditionsGuid 
         * @param {UpdateTermsAndConditionsRequest} [updateTermsAndConditionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermsAndConditions(termsAndConditionsGuid: string, updateTermsAndConditionsRequest?: UpdateTermsAndConditionsRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateTermsAndConditions(termsAndConditionsGuid, updateTermsAndConditionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUserToken(options?: any): AxiosPromise<ValidateUserTokenResponse> {
            return localVarFp.validateUserToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyRegistrationRequest} [verifyRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyRegistration(verifyRegistrationRequest?: VerifyRegistrationRequest, options?: any): AxiosPromise<VerifyRegistrationResponse> {
            return localVarFp.verifyRegistration(verifyRegistrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addApplication(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).addApplication(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddTermsAndConditionsRequest} [addTermsAndConditionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addTermsAndConditions(addTermsAndConditionsRequest?: AddTermsAndConditionsRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).addTermsAndConditions(addTermsAndConditionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddUserAuthentiationAuditRequest} [addUserAuthentiationAuditRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addUserAuthenticatedAudit(addUserAuthentiationAuditRequest?: AddUserAuthentiationAuditRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).addUserAuthenticatedAudit(addUserAuthentiationAuditRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AgreeToTermsAndConditionsRequest} [agreeToTermsAndConditionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public agreeToTermsAndConditions(agreeToTermsAndConditionsRequest?: AgreeToTermsAndConditionsRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).agreeToTermsAndConditions(agreeToTermsAndConditionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userAgreementType 
     * @param {AgreeToUserAgreementRequest} [agreeToUserAgreementRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public agreeToUserAgreement(userAgreementType: string, agreeToUserAgreementRequest?: AgreeToUserAgreementRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).agreeToUserAgreement(userAgreementType, agreeToUserAgreementRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticateUserRequest} [authenticateUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public authenticateUser(authenticateUserRequest?: AuthenticateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).authenticateUser(authenticateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subscriptionId 
     * @param {DeleteZohoSubscriptionRequest} [deleteZohoSubscriptionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteSubscriptionFromZoho(subscriptionId: string, deleteZohoSubscriptionRequest?: DeleteZohoSubscriptionRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteSubscriptionFromZoho(subscriptionId, deleteZohoSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getCurrentUser(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} application 
     * @param {string} [subApplication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getTermsAndConditions(application: string, subApplication?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getTermsAndConditions(application, subApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userAgreementType 
     * @param {string} application 
     * @param {string} [subApplication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserAgreement(userAgreementType: string, application: string, subApplication?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserAgreement(userAgreementType, application, subApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserByEmail(email: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterUserRequest} [registerUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public registerUser(registerUserRequest?: RegisterUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).registerUser(registerUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterUserForZohoRequest} [registerUserForZohoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public registerUserForZoho(registerUserForZohoRequest?: RegisterUserForZohoRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).registerUserForZoho(registerUserForZohoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} termsAndConditionsGuid 
     * @param {UpdateTermsAndConditionsRequest} [updateTermsAndConditionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateTermsAndConditions(termsAndConditionsGuid: string, updateTermsAndConditionsRequest?: UpdateTermsAndConditionsRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateTermsAndConditions(termsAndConditionsGuid, updateTermsAndConditionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public validateUserToken(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).validateUserToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyRegistrationRequest} [verifyRegistrationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public verifyRegistration(verifyRegistrationRequest?: VerifyRegistrationRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).verifyRegistration(verifyRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


