import React, { useEffect, useMemo, useState } from 'react';
import { demeterApi } from '../../../../Apis/Apis';
import { MarketPricesTimeSpan, UnitOfMeasure } from '../../../../Generated/Raven';
import {
    CommoditySpotPriceModel,
    Currency,
    GetMarketIndicatorFactorDataResponse,
    GetMarketIndicatorResponse,
    MarketIndicatorFactorDataModel,
    MarketIndicatorOutlook,
    MarketIndicatorOutlookResultModel,
    MarketIndicatorTemplateType,
    MarketPriceModel,
    RunMarketIndicatorFactorResponse,
    RunMarketIndicatorResponse,
} from '../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../Apis/Hooks/useApiWithoutAutoExecute';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import { yearsOfDataOptions } from '../../../Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { IChartAreaRangeData, IChartAreaRangeDataSeries, IChartData, IChartDataSeries } from '../ChartDefinitions';
import chartService from '../ChartService';
import ChartWrapper from '../ChartWrapper/ChartWrapper';
import MarketIndicatorChartRaw, { PlotBands } from './MarketIndicatorChartRaw';

export interface IMarketIndicatorChartProps {
    title?: string;
    runMarketIndicatorResponse?: RunMarketIndicatorResponse | undefined;
    runTestMarketIndicatorFactorResponse?: RunMarketIndicatorFactorResponse | GetMarketIndicatorFactorDataResponse;
    isLoading?: boolean;
    ignorePlotbands?: boolean;
    showOnlyAsPopout?: boolean;
    showPopout?: boolean;
    setShowPopout?: (showPopout: boolean) => void;
    useHedgeMonitorProRows?: boolean;
    useColorPalletteInLegend?: boolean;
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
}

const defaultContractNumber = 1;
const defaultMarketPricesTimeSpan = MarketPricesTimeSpan.FourYears;

const MarketIndicatorChart: React.FC<IMarketIndicatorChartProps> = (props: IMarketIndicatorChartProps) => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    // Data hooks.
    const getMarketIndicatorFactorResponse = props.runTestMarketIndicatorFactorResponse as GetMarketIndicatorFactorDataResponse;
    const additionalData = getMarketIndicatorFactorResponse?.additionalData;
    const symbols = useSymbolsApi();
    const symbolModel = useMemo(
        () =>
            symbols?.find(
                (x) =>
                    x.commodity === (getMarketIndicatorFactorResponse?.commodity ?? props.runMarketIndicatorResponse?.marketIndicator?.commodity) &&
                    x.region === (getMarketIndicatorFactorResponse?.region ?? props.runMarketIndicatorResponse?.marketIndicator?.region),
            ),
        [symbols, props.runMarketIndicatorResponse, props.runTestMarketIndicatorFactorResponse],
    );

    const [, refreshListMarketPrices, listMarketPricesResponse] = useApiWithoutAutoExecute(() =>
        demeterApi.listMarketPricesRollingWithConversions(
            symbolModel?.reutersInstrumentCodePrefix ?? '',
            props.runMarketIndicatorResponse?.marketIndicator?.contractNumber ?? defaultContractNumber,
            yearsOfDataOptions.find((x) => x.value === `${props.runMarketIndicatorResponse?.marketIndicator?.numberOfYears}`)?.marketPricesTimeSpan ??
                defaultMarketPricesTimeSpan,
        ),
    );

    const [, refreshListBasisPrices, listBasisPricesResponse] = useApiWithoutAutoExecute(() => {
        if (!props.runMarketIndicatorResponse?.marketIndicator?.usePrices) {
            return null;
        }

        return demeterApi.listCommodityBasisPrices(
            props.runMarketIndicatorResponse.marketIndicator.region,
            props.runMarketIndicatorResponse.marketIndicator.commodity,
            props.runMarketIndicatorResponse.marketIndicator.dataSource,
            undefined,
            props.runMarketIndicatorResponse.marketIndicator.subRegion,
            undefined,
            defaultMarketPricesTimeSpan,
        );
    });

    // Chart hooks.
    const [lineSeries, setLineSeries] = useState<IChartDataSeries[]>([]);
    const [areaRangeSeries, setAreaRangeSeries] = useState<IChartAreaRangeDataSeries[]>([]);

    const chartTitle = useMemo(() => {
        if (props.title) {
            return props.title;
        }

        if (!getMarketIndicatorFactorResponse && !props.runMarketIndicatorResponse) {
            return '';
        }

        return getMarketIndicatorFactorResponse?.factorDisplayName ?? props.runMarketIndicatorResponse?.marketIndicator?.displayName ?? '????';
    }, [symbolModel, props.runMarketIndicatorResponse, props.runTestMarketIndicatorFactorResponse]);

    const dataSourceTag = useMemo(() => {
        const sourceTags = [];

        const runTestResponse = props.runTestMarketIndicatorFactorResponse as GetMarketIndicatorFactorDataResponse;

        if (runTestResponse && runTestResponse.dataSourceTags && runTestResponse.dataSourceTags.length > 0) {
            sourceTags.push(runTestResponse.dataSourceTags[0]);
        }

        if (listBasisPricesResponse?.dataSourceTag) {
            sourceTags.push(listBasisPricesResponse?.dataSourceTag);
        }

        if (!props.runMarketIndicatorResponse?.marketIndicator?.usePrices) {
            sourceTags.push(symbolModel?.exchange ?? '', translations.dataSource.StoneXCalculations);
        }

        return sourceTags;
    }, [symbolModel, listBasisPricesResponse]);

    useEffect(() => {
        if (!props.runMarketIndicatorResponse || !symbols) {
            return;
        }

        if (props.runMarketIndicatorResponse.marketIndicator?.usePrices) {
            refreshListBasisPrices();
        } else {
            refreshListMarketPrices();
        }
    }, [props.runMarketIndicatorResponse, symbols, props.runTestMarketIndicatorFactorResponse]);

    useEffect(() => {
        const usePrices = props.runMarketIndicatorResponse?.marketIndicator?.usePrices;
        const isRunTestMarketIndicatorFactorData = props.ignorePlotbands && props.runTestMarketIndicatorFactorResponse;

        if (
            ((!usePrices && (!listMarketPricesResponse?.rows || listMarketPricesResponse?.rows.length === 0)) ||
                (usePrices && (!listBasisPricesResponse?.rows || listBasisPricesResponse?.rows.length === 0))) &&
            !isRunTestMarketIndicatorFactorData
        ) {
            setLineSeries([]);
            setAreaRangeSeries([]);
            return;
        }

        let lineSeriesData: IChartData[] = [];

        if (isRunTestMarketIndicatorFactorData) {
            lineSeriesData = getLineSeries((props.runTestMarketIndicatorFactorResponse?.rows as MarketIndicatorFactorDataModel[]) ?? []);
            // TODO - These two below have slightly different patterns. When we create the functions for the chartService,
            // we can update these functions in there.
        } else if (usePrices) {
            listBasisPricesResponse!.rows!.forEach((row: CommoditySpotPriceModel) => {
                lineSeriesData.push({
                    value: row.value ?? 0,
                    asOfDate: new Date(row.asOfDate),
                    isActualValue: true,
                });
            });
        } else {
            listMarketPricesResponse!.rows!.forEach((row: MarketPriceModel) => {
                lineSeriesData.push({
                    value: row.settlementPrice,
                    asOfDate: new Date(row.asOfDate),
                    isActualValue: true,
                });
            });
        }

        let lineSeriesTitle = chartTitle;
        if (
            getMarketIndicatorFactorResponse?.templateType === MarketIndicatorTemplateType.ForwardCurve ||
            getMarketIndicatorFactorResponse?.templateType === MarketIndicatorTemplateType.Speculative
        ) {
            lineSeriesTitle = getMarketIndicatorFactorResponse?.commodityDisplayName ?? chartTitle;
        }

        const newLinesSeries = [
            {
                label: lineSeriesTitle,
                data: lineSeriesData,
            },
        ];

        const newAreasSeries = [];

        if (additionalData) {
            const primaryData = additionalData?.find((x) => x.name === 'PrimaryData') ?? additionalData?.find((x) => x.name === 'ShortTermValue');
            const longTermValue = additionalData?.find((x) => x.name === 'LongTermValue');
            const secondaryData = additionalData?.find((x) => x.name === 'SecondaryData') ?? longTermValue;
            const longPosition = additionalData?.find((x) => x.name === 'LongPosition');
            const shortPosition = additionalData?.find((x) => x.name === 'ShortPosition');
            const averageForecast = additionalData?.find((x) => x.name === 'AverageForecast');

            const primaryLineDataSeries = getLineSeries(primaryData?.rows ?? []);
            const secondaryLineDataSeries = getLineSeries(secondaryData?.rows ?? []);
            const averageForecastDataSeries = getLineSeries(averageForecast?.rows ?? []);
            const longPositionDataSeries = getAreaRangeSeries(longPosition?.rows ?? []);
            const shortPositionDataSeries = getAreaRangeSeries(shortPosition?.rows ?? [], true);

            const netPosition =
                longPosition?.rows?.map((longItem, index) => {
                    const shortItem = shortPosition && shortPosition!.rows ? shortPosition!.rows[index] : null;
                    return {
                        value: longItem.value - (shortItem?.value ?? 0),
                        asOfDate: new Date(longItem.asOfDate),
                        isActualValue: longItem.isActualValue,
                    } as IChartData;
                }) ?? [];

            if (primaryLineDataSeries.length > 0) {
                newLinesSeries.push(
                    {
                        label: `${translate(primaryData?.displayName ?? '')}`,
                        data: primaryLineDataSeries,
                    },
                    {
                        label: `${translate(secondaryData?.displayName ?? '')}`,
                        data: secondaryLineDataSeries,
                        yAxis: longTermValue ? 0 : 1,
                    } as IChartDataSeries,
                );
            }

            if (longPositionDataSeries.length > 0) {
                newAreasSeries.push(
                    {
                        label: `${translate(longPosition?.displayName ?? '')}`,
                        data: longPositionDataSeries,
                    },
                    {
                        label: `${translate(shortPosition?.displayName ?? '')}`,
                        data: shortPositionDataSeries,
                    },
                );

                newLinesSeries.push({
                    label: translations.text.netPosition,
                    data: netPosition,
                    yAxis: 1,
                } as IChartDataSeries);
            }

            if (averageForecastDataSeries.length > 0) {
                newLinesSeries.push({
                    label: `${translate(averageForecast?.displayName ?? '')}`,
                    data: averageForecastDataSeries,
                });
            }
        }

        setLineSeries(newLinesSeries);

        if (newAreasSeries.length > 0) {
            setAreaRangeSeries(newAreasSeries);
        } else {
            setAreaRangeSeries([]);
        }
    }, [props.ignorePlotbands, chartTitle, listMarketPricesResponse, listBasisPricesResponse, props.runTestMarketIndicatorFactorResponse]);

    const getLineSeries = (rows: MarketIndicatorFactorDataModel[]) => {
        const currentLineValues: IChartData[] = [];

        rows.forEach((row) => {
            if (!row.value) {
                return;
            }

            currentLineValues.push({
                value: row.value,
                asOfDate: new Date(row.asOfDate),
                isActualValue: row.isActualValue,
            });
        });

        return currentLineValues;
    };

    const getAreaRangeSeries = (rows: MarketIndicatorFactorDataModel[], invertMinimumValue?: boolean) => {
        const currentAreaRangeValues: IChartAreaRangeData[] = [];

        rows.forEach((row) => {
            if (!row.value) {
                return;
            }

            currentAreaRangeValues.push({
                value: 0,
                maximumValue: invertMinimumValue ? 0 : row.value,
                minimumValue: invertMinimumValue ? -row.value : 0,
                asOfDate: new Date(row.asOfDate),
                isActualValue: true,
            });
        });

        return currentAreaRangeValues;
    };

    const getCurrencyOrUnitOfMeasureFromResponseData = (key: 'currency' | 'unitOfMeasure') => {
        if (getMarketIndicatorFactorResponse && getMarketIndicatorFactorResponse[key]) {
            return getMarketIndicatorFactorResponse[key];
        }

        if (listMarketPricesResponse && listMarketPricesResponse[key]) {
            return listMarketPricesResponse[key];
        }

        if (listBasisPricesResponse && listBasisPricesResponse[key]) {
            return listBasisPricesResponse[key];
        }

        if (additionalData && additionalData[0][key]) {
            return additionalData[0][key];
        }

        return '';
    };

    const plotBands = useMemo(() => {
        if (!props.runTestMarketIndicatorFactorResponse?.rows && !props.runMarketIndicatorResponse?.rows) {
            return [];
        }

        const newBands: PlotBands[] = [];
        const marketDataByDates = props.useHedgeMonitorProRows
            ? (props.runMarketIndicatorResponse as GetMarketIndicatorResponse)?.hedgeMonitorProRows
            : props.runTestMarketIndicatorFactorResponse?.rows ?? props.runMarketIndicatorResponse?.rows;
        const filteredDataByDates = (marketDataByDates as MarketIndicatorOutlookResultModel[])?.filter((x) => x.outlook !== MarketIndicatorOutlook.Unavailable);
        // TODO: I'm not sure if it's right, need to check logic here.
        filteredDataByDates?.forEach((x, index) => {
            const toDate = new Date((filteredDataByDates[index + 1] ?? x).asOfDate).getTime();
            if (index === 0 || filteredDataByDates[index - 1].outlook !== x.outlook) {
                newBands.push({
                    fromDate: new Date(x.asOfDate).getTime(),
                    toDate,
                    color: chartService.getOutlookColor(x.outlook, true),
                });
            } else {
                newBands[newBands.length - 1].toDate = toDate;
            }
        });

        return newBands;
    }, [props.runTestMarketIndicatorFactorResponse, props.runMarketIndicatorResponse]);

    const isLoading =
        props.isLoading ||
        (!props.runMarketIndicatorResponse && !props.runTestMarketIndicatorFactorResponse) ||
        (!props.runTestMarketIndicatorFactorResponse && props.ignorePlotbands) ||
        lineSeries.length === 0;

    const yAxisLabelSecondary = useMemo(() => {
        if (!additionalData || (!additionalData[1].currency && !additionalData[1].unitOfMeasure) || additionalData.find((x) => x.name === 'ShortTermValue')) {
            return '';
        }

        return chartService.getCurrencyAndUnitOfMeasureText(additionalData[1].unitOfMeasure, additionalData[1].currency);
    }, [props.runTestMarketIndicatorFactorResponse, translations]);

    const currency = useMemo(
        () => getCurrencyOrUnitOfMeasureFromResponseData('currency') as Currency,
        [listMarketPricesResponse, listBasisPricesResponse, getMarketIndicatorFactorResponse],
    );

    const unitOfMeasure = useMemo(
        () => getCurrencyOrUnitOfMeasureFromResponseData('unitOfMeasure') as UnitOfMeasure,
        [listMarketPricesResponse, listBasisPricesResponse, getMarketIndicatorFactorResponse],
    );

    // If in popout only mode, ignore the spinner.
    return (
        <ChartWrapper
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
            name="MarketIndicatorChart"
            title={chartTitle}
            dataSourceTag={dataSourceTag}
            isLoading={isLoading}
            testId="MarketIndicatorChart"
        >
            <MarketIndicatorChartRaw
                lineSeries={lineSeries}
                areaRangeSeries={areaRangeSeries}
                plotBands={props.ignorePlotbands || !plotBands ? [] : plotBands}
                currencies={[currency]}
                unitOfMeasures={[unitOfMeasure]}
                yAxisLabelSecondary={yAxisLabelSecondary}
                useColorPalletteInLegend={props.useColorPalletteInLegend}
                displayDecimalPlacesMinimum={props.displayDecimalPlacesMinimum}
                displayDecimalPlacesMaximum={props.displayDecimalPlacesMaximum}
            />
        </ChartWrapper>
    );
};

export default MarketIndicatorChart;
