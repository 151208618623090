import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { demeterMarketIndicatorsApi } from '../../../Apis/Apis';
import {
    DeleteMarketIndicatorFactorRequest,
    GetMarketIndicatorFactorResponse,
    GetMarketIndicatorResponse,
    MarketIndicatorFactorSummaryModel,
    MarketIndicatorTemplateType,
} from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import useMultipleApis from '../../Apis/Hooks/useMultipleApisHook';
import MarketIndicatorChart from '../../Components/Charts/MarketIndicator/MarketIndicatorChart';
import MarketIndicatorAverageSeasonalChart from '../../Components/Charts/MarketIndicators/MarketIndicatorAverageSeasonal/MarketIndicatorAverageSeasonalChart';
import MarketIndicatorCombinationChart from '../../Components/Charts/MarketIndicators/MarketIndicatorCombination/MarketIndicatorCombinationChart';
import MarketIndicatorDecileChart from '../../Components/Charts/MarketIndicators/MarketIndicatorDecile/MarketIndicatorDecileChart';
import MarketIndicatorForecastChart from '../../Components/Charts/MarketIndicators/MarketIndicatorForecast/MarketIndicatorForecastChart';
import MarketIndicatorHistoricalChart from '../../Components/Charts/MarketIndicators/MarketIndicatorHistorical/MarketIndicatorHistoricalChart';
import MarketIndicatorSeasonalChart from '../../Components/Charts/MarketIndicators/MarketIndicatorSeasonal/MarketIndicatorSeasonalChart';
import MarketIndicatorTechnicalChart from '../../Components/Charts/MarketIndicators/MarketIndicatorTechnical/MarketIndicatorTechnicalChart';
import IconButton from '../../Components/Form/Buttons/IconButton';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import {
    AverageSeasonalParameters,
    DecileParameters,
    HistoricalParameters,
    indicatorColorByOutlookDefinitions,
    SeasonalParameters,
    TechnicalParameters,
} from '../Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';
import styles from './MarketIndicatorFactorsPage.module.scss';

interface IMarketIndicatorFactorSummaryProps {
    factor: MarketIndicatorFactorSummaryModel;
    getMarketIndicatorResponse?: GetMarketIndicatorResponse;
    groupDisplayName?: string;
    handleIndicatorDelete: (request: DeleteMarketIndicatorFactorRequest) => void;
    isInAdministrationMode?: boolean;
}

// TODO - Permission service instead of "currentUserTypeIsCompanyAdministrator".
const MarketIndicatorFactorSummary: React.FC<IMarketIndicatorFactorSummaryProps> = (props: IMarketIndicatorFactorSummaryProps) => {
    // Application hooks and application constants.
    const [translations] = useLanguage();
    const { marketIndicatorGuid } = useParams();
    const [searchParameters] = useSearchParameters();
    const [showPopout, setShowPopout] = useState<boolean>(false);
    const templateTypeIsCombination = props.factor.templateType === MarketIndicatorTemplateType.Combination;

    const [, refreshGetMarketIndicatorFactorDataResponse, getMarketIndicatorFactorDataResponse] = useApiWithoutAutoExecute(
        () => {
            if (!searchParameters.marketIndicatorGuid || templateTypeIsCombination) {
                return null;
            }

            return demeterMarketIndicatorsApi.getMarketIndicatorFactorData(searchParameters.marketIndicatorGuid!, props.factor.marketIndicatorFactorGuid);
        },
        { errorMessage: translations.marketIndicators.singleFactorErrorMessage },
    );

    // Need to reduce the data set size to the number of years in the market indicator.
    const filteredGetMarketIndicatorFactorDataResponse = useMemo(() => {
        if (!props.getMarketIndicatorResponse?.marketIndicator?.numberOfYears || !getMarketIndicatorFactorDataResponse) {
            return undefined;
        }

        const startDate = moment().subtract(props.getMarketIndicatorResponse.marketIndicator.numberOfYears, 'year').toDate();

        return {
            ...getMarketIndicatorFactorDataResponse,
            rows: getMarketIndicatorFactorDataResponse?.rows?.filter((x) => new Date(x.asOfDate) >= startDate) ?? [],
            additionalData:
                getMarketIndicatorFactorDataResponse?.additionalData?.map((dataItem) => ({
                    ...dataItem,
                    rows: dataItem.rows?.filter((x) => new Date(x.asOfDate) >= startDate) ?? [],
                })) ?? [],
        };
    }, [getMarketIndicatorFactorDataResponse, props.getMarketIndicatorResponse]);

    const [, refreshGetMarketIndicatorSubFactorResponses, getMarketIndicatorSubFactorResponses] = useMultipleApis(
        () => {
            if (!searchParameters.marketIndicatorGuid || !templateTypeIsCombination) {
                return null;
            }

            return props.factor.subFactorGuids?.map((x) => demeterMarketIndicatorsApi.getMarketIndicatorFactorData(searchParameters.marketIndicatorGuid!, x!));
        },
        { errorMessage: translations.marketIndicators.multipleFactorsErrorMessage },
    );

    const factorSummary = useMemo(
        () => (
            <div
                style={{
                    backgroundColor: indicatorColorByOutlookDefinitions.find((x) => x.marketIndicatorOutlook === props.factor.outlook)?.indicatorColor,
                    color: indicatorColorByOutlookDefinitions.find((x) => x.marketIndicatorOutlook === props.factor.outlook)?.textColor,
                }}
                className={styles.indicator_outlook}
            >
                {translations.marketIndicatorOutlook[props.factor.outlook]}
            </div>
        ),
        [translations, props.factor.outlook],
    );

    const handleOpenMarketFactorChart = () => {
        if (props.isInAdministrationMode) {
            return;
        }

        if (!showPopout) {
            refreshGetMarketIndicatorFactorDataResponse();
            refreshGetMarketIndicatorSubFactorResponses();
        }

        setShowPopout(true);
    };

    return (
        <>
            <button
                onClick={handleOpenMarketFactorChart}
                type="button"
                className={props.isInAdministrationMode ? styles.indicator_administration : styles.indicator_user}
                data-testid={`MarketIndicatorFactor${props.factor.order}`}
            >
                <div className={styles.indicator_title}>{props.factor.displayName}</div>
                {props.isInAdministrationMode && (
                    <CloseIcon
                        onClick={() =>
                            props.handleIndicatorDelete({
                                marketIndicatorGuid: marketIndicatorGuid ?? '',
                                marketIndicatorFactorGuid: props.factor.marketIndicatorFactorGuid,
                            })
                        }
                    />
                )}
                {props.isInAdministrationMode ? (
                    <Link
                        // eslint-disable-next-line max-len
                        to={`${NavigationRoutes.AdministrationMarketIndicators}/${marketIndicatorGuid}/factorGroups/${props.groupDisplayName}/factors/${props.factor.marketIndicatorFactorGuid}/edit`}
                    >
                        {factorSummary}
                    </Link>
                ) : (
                    <>
                        {factorSummary}
                        {props.factor.templateType === MarketIndicatorTemplateType.AverageSeasonal && (
                            <MarketIndicatorAverageSeasonalChart
                                title={props.factor.displayName}
                                runTestMarketIndicatorFactorResponse={filteredGetMarketIndicatorFactorDataResponse!}
                                parameters={{ yearsOfData: props.factor.yearsOfData } as AverageSeasonalParameters}
                                showPopout={showPopout}
                                setShowPopout={setShowPopout}
                                showOnlyAsPopout
                            />
                        )}
                        {props.factor.templateType === MarketIndicatorTemplateType.Decile && (
                            <MarketIndicatorDecileChart
                                title={props.factor.displayName}
                                runTestMarketIndicatorFactorResponse={filteredGetMarketIndicatorFactorDataResponse!}
                                parameters={{ yearsOfData: props.factor.yearsOfData } as DecileParameters}
                                showPopout={showPopout}
                                setShowPopout={setShowPopout}
                                showOnlyAsPopout
                            />
                        )}

                        {props.factor.templateType === MarketIndicatorTemplateType.Forecast && (
                            <MarketIndicatorForecastChart
                                title={props.factor.displayName}
                                runTestMarketIndicatorFactorResponse={filteredGetMarketIndicatorFactorDataResponse!}
                                showPopout={showPopout}
                                setShowPopout={setShowPopout}
                                showOnlyAsPopout
                            />
                        )}
                        {props.factor.templateType === MarketIndicatorTemplateType.Historical && (
                            <MarketIndicatorHistoricalChart
                                title={props.factor.displayName}
                                runTestMarketIndicatorFactorResponse={filteredGetMarketIndicatorFactorDataResponse!}
                                parameters={{ yearsOfData: props.factor.yearsOfData } as HistoricalParameters}
                                showPopout={showPopout}
                                setShowPopout={setShowPopout}
                                showOnlyAsPopout
                            />
                        )}
                        {props.factor.templateType === MarketIndicatorTemplateType.Seasonal && (
                            <MarketIndicatorSeasonalChart
                                title={props.factor.displayName}
                                runTestMarketIndicatorFactorResponse={filteredGetMarketIndicatorFactorDataResponse!}
                                parameters={{ yearsOfData: props.factor.yearsOfData } as SeasonalParameters}
                                showPopout={showPopout}
                                setShowPopout={setShowPopout}
                                showOnlyAsPopout
                            />
                        )}
                        {props.factor.templateType === MarketIndicatorTemplateType.Technical && (
                            <MarketIndicatorTechnicalChart
                                title={props.factor.displayName}
                                runTestMarketIndicatorFactorResponse={filteredGetMarketIndicatorFactorDataResponse!}
                                parameters={
                                    {
                                        daysToAverage1: props.factor.daysToAverage1,
                                        daysToAverage2: props.factor.daysToAverage2,
                                        daysToAverage3: props.factor.daysToAverage3,
                                    } as TechnicalParameters
                                }
                                showPopout={showPopout}
                                setShowPopout={setShowPopout}
                                showOnlyAsPopout
                            />
                        )}
                        {templateTypeIsCombination && (
                            <MarketIndicatorCombinationChart
                                title={props.factor.displayName}
                                runMarketIndicatorResponse={props.getMarketIndicatorResponse}
                                getMarketIndicatorSubFactorResponses={getMarketIndicatorSubFactorResponses as GetMarketIndicatorFactorResponse[]}
                                showPopout={showPopout}
                                setShowPopout={setShowPopout}
                                showOnlyAsPopout
                            />
                        )}
                        {(props.factor.templateType === MarketIndicatorTemplateType.ForwardCurve ||
                            props.factor.templateType === MarketIndicatorTemplateType.Speculative) && (
                            <MarketIndicatorChart
                                title={props.factor.displayName}
                                runMarketIndicatorResponse={props.getMarketIndicatorResponse}
                                runTestMarketIndicatorFactorResponse={filteredGetMarketIndicatorFactorDataResponse!}
                                showPopout={showPopout}
                                setShowPopout={setShowPopout}
                                useColorPalletteInLegend
                                ignorePlotbands
                                showOnlyAsPopout
                            />
                        )}
                    </>
                )}
            </button>
            {(props.factor.templateType === MarketIndicatorTemplateType.ManualEntry ||
                props.factor.templateType === MarketIndicatorTemplateType.SlidingWindowSeasonal) &&
                !!props.factor.imageUrl && (
                    <Modal open={showPopout} onClose={() => setShowPopout(false)}>
                        <div className={styles.indicator_modal}>
                            <div className={styles.indicator_modal_close}>
                                <IconButton handleClick={() => setShowPopout(false)}>
                                    <CloseIcon className={styles.indicator_modal_close_icon} />
                                </IconButton>
                            </div>
                            <img className={styles.indicator_modal_image} src={props.factor.imageUrl} alt={props.factor.displayName} />
                        </div>
                    </Modal>
                )}
        </>
    );
};

export default MarketIndicatorFactorSummary;
