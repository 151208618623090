import { useMemo } from 'react';
import { demeterMarketIndicatorsApi } from '../../../Apis/Apis';
import { MarketIndicatorOutlook } from '../../../Generated/Raven-Demeter';
import useCacheThenApi from '../../Apis/Hooks/useCacheThenApiHook';
import ComponentHeader from '../../Components/Headers/ComponentHeader';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useLanguage from '../../Services/Language/useLanguageHook';
import HedgeMonitorProSummary from '../HedgeMonitorPro/HedgeMonitorProSummary';
import styles from './Dashboard.module.scss';

const DashboardHedgeMonitorProIndicators: React.FC = () => {
    // Application hooks.
    const [translations] = useLanguage();

    // Text hooks.
    const marketIndicatorTitleString = useMemo(() => translations.dashboard.headings.marketIndicatorsHeader, [translations]);

    // Api hooks.
    const [isLoading, , listMarketIndicatorsResponse] = useCacheThenApi(CacheKeys.ListMarketIndicatorsCurrent, () =>
        demeterMarketIndicatorsApi.listMarketIndicatorsCurrent(),
    );

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <div className={styles.dashboard_gray_background}>
            <div className={styles.dashboard_header}>
                <ComponentHeader title={marketIndicatorTitleString} />
            </div>
            <div className={styles.dashboard_outlook_indicators}>
                {listMarketIndicatorsResponse?.rows
                    ?.filter((x) => x.outlook !== MarketIndicatorOutlook.Unavailable && x.hedgeMonitorProOutlook && x.precipiceOutlook)
                    .map((x) => (
                        <div className={styles.dashboard_outlook_indicator}>
                            <HedgeMonitorProSummary useNavigationCard marketIndicator={x} />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default DashboardHedgeMonitorProIndicators;
