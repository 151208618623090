import { CellClassParams, ColGroupDef, ValueFormatterParams } from 'ag-grid-community';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import { DemeterRegion } from '../../../../Generated/Raven-Demeter';
import { CommodityMonthlyOriginsOrDestinationsModel } from '../../../Apis/Hooks/useOriginsAndDestinationsApiHook';
import formattingService from '../../../Services/Formatting/FormattingService';
import languageService from '../../../Services/Language/LanguageService';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';
import styleService from '../../../Services/Styles/StyleService';
import { chartColors } from '../../Charts/ChartDefinitions';
import styles from './OriginsAndDestinationsTable.module.scss';

const cellClassSetter = (parameters: CellClassParams) => {
    const cellStyles = [];
    if (parameters.colDef.field?.includes('Change')) {
        cellStyles.push(...styleService.positiveOrNegativeCellClassSetter(parameters));
    }
    if (parameters.node.footer) {
        cellStyles.push(styles.master_bold);
    }

    return cellStyles;
};

type RendererParameters = {
    data: CommodityMonthlyOriginsOrDestinationsModel;
};

const regionRenderer = (parameters: RendererParameters) => {
    const customTableDataWithColor = { rowStripColor: chartColors.donutChartColors[parameters.data.order], ...parameters.data };
    if (parameters.data.originOrDestinationRegion === DemeterRegion.All) {
        return languageService.translate(translationKeys.words.total);
    }
    return (
        <div className={styles.region_renderer_parent_div}>
            <div style={{ backgroundColor: customTableDataWithColor.rowStripColor }} className={styles.region_renderer_color} />
            <div className={styles.region_renderer_name}>
                <span>{languageService.translate(parameters.data.originOrDestinationRegion ?? '')}</span>
            </div>
        </div>
    );
};

const numberFormatter = (parameters: ValueFormatterParams) =>
    formattingService.toFormattedNumber(+parameters.value) ?? applicationConstants.TablePlaceholderZeroOrEmpty;

const percentFormatter = (parameters: ValueFormatterParams) => {
    if (!parameters.value) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }
    return formattingService.toPercent(+parameters.value, 0, 0);
};

export const originsAndDestinationsColumnDefinitions: ColGroupDef[] = [
    {
        headerName: '',
        headerClass: styles.origins_destinations_top_left_corner,
        children: [
            {
                headerValueGetter: () => languageService.translate(translationKeys.destinationsOrOrigins.fields.country),
                field: 'originOrDestinationRegion',
                cellClass: cellClassSetter,
                cellRenderer: regionRenderer,
            },
        ],
    },
    {
        headerName: '',
        children: [
            {
                headerValueGetter: () => languageService.translate(translationKeys.destinationsOrOrigins.fields.volume),
                field: 'value',
                cellClass: cellClassSetter,
                valueFormatter: numberFormatter,
                type: 'rightAligned',
            },
            {
                headerValueGetter: () => languageService.translate(translationKeys.destinationsOrOrigins.fields.yearOverYearChange),
                field: 'monthOverMonthChangePercent',
                cellClass: cellClassSetter,
                valueFormatter: percentFormatter,
                type: 'rightAligned',
            },
        ],
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.destinationsOrOrigins.fields.pastTwelveMonths),
        children: [
            {
                hide: false,
                headerValueGetter: () => languageService.translate(translationKeys.destinationsOrOrigins.fields.volume),
                field: 'rollingYearValue',
                cellClass: cellClassSetter,
                valueFormatter: numberFormatter,
                type: 'rightAligned',
            },
            {
                hide: false,
                headerValueGetter: () => languageService.translate(translationKeys.destinationsOrOrigins.fields.yearOverYearChange),
                field: 'rollingYearChangePercent',
                cellClass: cellClassSetter,
                valueFormatter: percentFormatter,
                type: 'rightAligned',
            },
        ],
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.destinationsOrOrigins.fields.yearToDate),
        children: [
            {
                hide: true,
                headerValueGetter: () => languageService.translate(translationKeys.destinationsOrOrigins.fields.volume),
                field: 'yearToDateValue',
                cellClass: cellClassSetter,
                valueFormatter: numberFormatter,
                type: 'rightAligned',
            },
            {
                hide: true,
                headerValueGetter: () => languageService.translate(translationKeys.destinationsOrOrigins.fields.yearToDateChange),
                field: 'yearToDateChangePercent',
                cellClass: cellClassSetter,
                valueFormatter: percentFormatter,
                type: 'rightAligned',
            },
        ],
    },
];

export const originsAndDestinationsColumnOptions = {
    resizable: true,
    suppressMenu: true,
    rowDrag: false,
    rowDragManaged: true,
    animateRows: true,
    enableCellChangeFlash: true,
    suppressMovable: true,
};
