import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import AgGridBuilder from '../../../../Components/AgGridBuilder/AgGridBuilder';
import { GetCommodityMonthlySeasonalResponse, SeasonalMonthlyRowModel } from '../../../Apis/Hooks/useSeasonalApiHook';
import useLanguage from '../../../Services/Language/useLanguageHook';
import {
    AverageOrTotalsType,
    SeasonalColumnDefinition,
    SeasonalModel,
    seasonalTableBase,
    seasonalTableDefaultColumnOptions,
    yearlyAverageOrTotalColumnDefinitions,
} from './SeasonalTableDefinitions';
import styles from './SeasonalTables.module.scss';

export interface ISeasonalTableRawProps {
    seasonalData: GetCommodityMonthlySeasonalResponse;
    isPercent: boolean;
    displayQuarterlyAndYearlyValues?: boolean;
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
}

const SeasonalTableRaw: React.FC<ISeasonalTableRawProps> = (props: ISeasonalTableRawProps) => {
    const [rowData, setRowData] = useState<SeasonalModel[]>([]);
    const [translations] = useLanguage();
    const tableReference = useRef<AgGridReact>(null);

    const seasonalMonthlyData = useMemo(
        () => props.seasonalData.rows?.map((row) => row as SeasonalMonthlyRowModel).filter((row) => row) ?? [],
        [props.seasonalData],
    );

    const getBaseSeasonalTable = () =>
        seasonalTableBase.map((element) => {
            const newChildren = element.children.map((child, index) => {
                if (index === 0) {
                    return { ...child, headerName: props.isPercent ? translations.words.shortYearOverYear : translations.words.year };
                }

                return child;
            });

            return { ...element, children: newChildren };
        });

    const headerValueGetter = (isAverageOrTotal: AverageOrTotalsType, prefix?: string) => {
        let header = translations.words.change;
        if (!props.isPercent) {
            if (isAverageOrTotal === 'total') {
                header = translations.words.total;
            } else {
                header = translations.text.averageShort;
            }
        }
        return `${prefix ?? ''} ${header}`;
    };

    const getYearlyAverageOrTotalColumnDefinitions = (isAverageOrTotal: AverageOrTotalsType) =>
        yearlyAverageOrTotalColumnDefinitions.map((element) => {
            const newChildren = element.children.map((child, index) => {
                const quarterlyPrefix = `${child.field![0]}${index + 1}`.toUpperCase();
                return {
                    ...child,
                    headerValueGetter: () => headerValueGetter(isAverageOrTotal, element.isYearly ? undefined : quarterlyPrefix),
                };
            });

            return { ...element, children: newChildren };
        });

    const [columnDefinitions, setColumnDefinitions] = useState<SeasonalColumnDefinition[]>(getBaseSeasonalTable());

    useEffect(() => {
        if (seasonalMonthlyData.length === 0) {
            return;
        }

        const isAverage = seasonalMonthlyData[0].monthlyValues![0].alwaysShowActualValue;
        const additionalColumnDefinitions = getYearlyAverageOrTotalColumnDefinitions(isAverage ? 'average' : 'total');

        additionalColumnDefinitions.map((x) =>
            x.children.forEach((child) => {
                if (props.displayQuarterlyAndYearlyValues) {
                    child.hide = false;
                } else {
                    child.hide = true;
                }
            }),
        );
        tableReference?.current?.api?.setColumnDefs([...getBaseSeasonalTable(), ...additionalColumnDefinitions]);

        const newSeasonalRowData = seasonalMonthlyData.map((row) => {
            const seasonalModel: SeasonalModel = {
                id: `${row.year}`,
                year: row.year!,
                isPercent: props.isPercent,
                displayDecimalPlacesMinimum: props.displayDecimalPlacesMinimum,
                displayDecimalPlacesMaximum: props.displayDecimalPlacesMaximum,
            };

            row.monthlyValues?.forEach((monthData) => {
                seasonalModel[`month${monthData.month}` as keyof SeasonalModel] = (
                    props.isPercent ? monthData.monthOverMonthChangePercent : monthData.value
                ) as never;
                seasonalModel[`month${monthData.month}IsActualValue` as keyof SeasonalModel] = monthData.isActualValue as never;
            });

            row.quarterlyValues?.forEach((quarterData) => {
                if (quarterData?.monthCount !== 3) {
                    return;
                }

                const totalOrAverageValueQuarter = isAverage ? quarterData.valueAverage : quarterData.valueTotal;

                seasonalModel[`quarter${quarterData.quarter}` as keyof SeasonalModel] = (
                    props.isPercent ? quarterData.quarterOverQuarterChangePercent : totalOrAverageValueQuarter
                ) as never;
                seasonalModel[`quarter${quarterData.quarter}IsActualValue` as keyof SeasonalModel] = quarterData.isActualValue as never;
            });

            if (row.yearlyValues?.monthCount === 12) {
                const totalOrAverageValueYearly = isAverage ? row.yearlyValues.valueAverage : row.yearlyValues.valueTotal;

                seasonalModel.yearlyAverageOrTotal = props.isPercent
                    ? row.monthlyValues![11].rollingYearChangePercent ?? undefined
                    : totalOrAverageValueYearly!;
                seasonalModel.yearlyAverageOrTotalIsActualValue = row.yearlyValues?.isActualValue;
            }

            return seasonalModel;
        });

        setRowData(newSeasonalRowData);
    }, [props.seasonalData, props.displayQuarterlyAndYearlyValues, props.isPercent]);

    return (
        <div className={styles.seasonal_table_container}>
            <AgGridBuilder
                gridRef={tableReference}
                rowData={rowData}
                hasSaveColumnsState
                setColumnDefinitions={setColumnDefinitions}
                columnDefinitions={columnDefinitions}
                defaultColumnDefinition={seasonalTableDefaultColumnOptions}
                gridHeightFull
                disableExport
                suppressMovableColumns
            />
            <div className={styles.seasonal_table_legend_container}>
                {seasonalMonthlyData.some((x) => x.monthlyValues?.some((y) => !y.isActualValue)) && (
                    <div className={styles.seasonal_table_forecast_container}>
                        {props.isPercent && <p className={styles.seasonal_table_year_over_year_label}>{translations.text.shortYearOverYearChange}</p>}
                        <div className={styles.seasonal_table_forecast_square} />
                        <p>{translations.words.forecast}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SeasonalTableRaw;
