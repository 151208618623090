/* eslint-disable no-debugger */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { demeterCompanyApi, demeterUsersApi } from '../../../../Apis/Apis';
import applicationSettings from '../../../../Core/Settings/ApplicationSettings';
import { DemeterMarket, DemeterMarketLicenseModel, DemeterUserModel, DemeterUserType } from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectCompanyGuid, selectUserType } from '../../../../Redux/Slices/UserSlice';
import useApi from '../../../Apis/Hooks/useApiHook';
import useApiWithForce from '../../../Apis/Hooks/useApiWithForce';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './LicensesPage.module.scss';
import LicensesTableRow from './LicensesTableRow';

const timeoutValue = 2000;

const LicensesPage: React.FC = () => {
    const [translations] = useLanguage();
    const routeParameters = useParams();
    const hasUserUpdateLicensesReference = useRef(0);
    const currentUserType = useApplicationSelector(selectUserType);
    const companyGuidFromRedux = useApplicationSelector(selectCompanyGuid);
    const activeUsersListReference = useRef<DemeterUserModel[]>([]);
    const [, setRender] = useState(false);
    const selectedCompanyGuid = useMemo(() => {
        if (routeParameters.demeterCompanyGuid) {
            return routeParameters.demeterCompanyGuid;
        }
        if (companyGuidFromRedux) {
            return companyGuidFromRedux;
        }

        return null;
    }, [companyGuidFromRedux, routeParameters.demeterCompanyGuid]);

    const [, , getCompanyApiResponse] = useApi(() => {
        if (!selectedCompanyGuid) {
            return null;
        }

        return demeterCompanyApi.getDemeterCompany(selectedCompanyGuid);
    });

    const [isLoadingUsersResponse, , getUsersApiResponse] = useApi(() => {
        if (!selectedCompanyGuid) {
            return null;
        }

        return demeterUsersApi.listDemeterUsers(undefined, undefined, undefined, selectedCompanyGuid, undefined, undefined, -1, 0);
    });

    const [, refreshUpdateLicensesApi] = useApiWithForce(
        () => {
            if (!selectedCompanyGuid || !activeUsersListReference.current.length) {
                return null;
            }

            return demeterCompanyApi.updateDemeterCompanyLicenses(selectedCompanyGuid, {
                userLicenses: activeUsersListReference.current.map((user) => ({
                    demeterUserTrialGuid: user.demeterUserTrialGuid,
                    markets: Array.from(new Set(user.markets)),
                })),
            });
        },
        { stopAutoExecute: true },
    );

    const availableMarketLicenses: DemeterMarketLicenseModel[] = useMemo(() => {
        if (!getCompanyApiResponse?.demeterCompany?.marketLicenses.length) {
            return [];
        }

        if (currentUserType === DemeterUserType.Premium) {
            return getCompanyApiResponse.demeterCompany?.marketLicenses
                .filter((x) => x.numberOfLicenses > 0 || x.numberOfLicensesUsed > 0)
                .map((marketLicense) => {
                    const numberOfLicensesUsed = activeUsersListReference?.current?.filter((x) => x.markets.some((s) => s === marketLicense.market)).length;
                    return {
                        market: marketLicense.market,
                        numberOfLicenses: marketLicense.numberOfLicenses,
                        numberOfLicensesUsed,
                    };
                });
        }

        return applicationSettings.markets.map((market) => {
            const numberOfLicensesUsed = activeUsersListReference?.current?.filter((x) => x.markets.some((s) => s === market)).length;
            const findCompanyMarketMatch = getCompanyApiResponse.demeterCompany?.marketLicenses.find((x) => x.market === market);
            return {
                market,
                numberOfLicenses: findCompanyMarketMatch ? findCompanyMarketMatch.numberOfLicenses : 0,
                numberOfLicensesUsed: numberOfLicensesUsed || 0,
            } as DemeterMarketLicenseModel;
        });
    }, [getCompanyApiResponse, activeUsersListReference.current]);

    const hasUnlimitedLicenses = useMemo(
        () => currentUserType === DemeterUserType.BusinessOwner || currentUserType === DemeterUserType.Administrator,
        [currentUserType],
    );

    const handleLicenseChange = (user: DemeterUserModel, market: DemeterMarket, isLicensed: boolean) => {
        if (!selectedCompanyGuid) {
            return null;
        }

        const avalibleLicenses = availableMarketLicenses.find((x) => x.market === market);
        const licenseIsAvalibleForUser = avalibleLicenses && avalibleLicenses.numberOfLicensesUsed < avalibleLicenses.numberOfLicenses;
        if (!licenseIsAvalibleForUser && !hasUnlimitedLicenses && !isLicensed) {
            return null;
        }

        const newActiveUsersList = [...activeUsersListReference.current];
        const userLicenseMatch = newActiveUsersList.find((x) => x.demeterUserTrialGuid === user.demeterUserTrialGuid)!;
        if (isLicensed) {
            userLicenseMatch.markets = userLicenseMatch.markets.filter((x) => x !== market);
        } else {
            userLicenseMatch.markets.push(market);
        }

        activeUsersListReference.current = newActiveUsersList;
        hasUserUpdateLicensesReference.current += 1;
        setRender((value) => !value);
        return user;
    };

    useEffect(() => {
        if (!getUsersApiResponse?.rows?.length) {
            return;
        }

        activeUsersListReference.current = getUsersApiResponse.rows
            .filter((x) => x.userType !== DemeterUserType.Regular)
            .sort((a, b) => {
                const aFirstName = a.firstName || '';
                const bFirstName = b.firstName || '';
                return aFirstName.localeCompare(bFirstName);
            });

        setRender((value) => !value);
    }, [getUsersApiResponse]);

    useEffect(() => {
        if (!hasUserUpdateLicensesReference.current) {
            return () => {};
        }

        const debounceLicensePut = setTimeout(() => {
            refreshUpdateLicensesApi();
            hasUserUpdateLicensesReference.current = 0;
        }, timeoutValue);

        return () => {
            clearTimeout(debounceLicensePut);
        };
    }, [hasUserUpdateLicensesReference.current]);

    useEffect(
        () => () => {
            if (hasUserUpdateLicensesReference.current) {
                refreshUpdateLicensesApi();
            }
        },
        [],
    );

    return (
        <div className={styles.licenses_page_container}>
            <ComponentHeader title={translations.licenses.title} />
            <div className={styles.licenses_page_table}>
                {!isLoadingUsersResponse && !activeUsersListReference.current.length && (
                    <div className={styles.licenses_page_row_container}>{translations.licenses.error.noUsersMessage}</div>
                )}
                {isLoadingUsersResponse || !activeUsersListReference.current.length ? (
                    <div className={styles.licenses_page_loading_container}>
                        <LoadingSpinner />
                    </div>
                ) : (
                    <div>
                        {availableMarketLicenses && (
                            <div className={styles.licenses_page_row_container}>
                                <div className={styles.licenses_page_cell_container} />
                                {availableMarketLicenses.map((marketLicense) => (
                                    <div key={marketLicense.market} className={styles.licenses_page_header_cell}>
                                        {marketLicense.market}
                                    </div>
                                ))}
                            </div>
                        )}
                        {activeUsersListReference.current.length &&
                            activeUsersListReference.current.map((userRow) => (
                                <LicensesTableRow
                                    key={userRow.demeterUserTrialGuid}
                                    user={userRow}
                                    hasUnlimitedLicenses={hasUnlimitedLicenses}
                                    availableMarkets={availableMarketLicenses}
                                    handleLicenseChange={handleLicenseChange}
                                />
                            ))}
                        {availableMarketLicenses && (
                            <div className={styles.licenses_page_row_container}>
                                <div className={styles.licenses_page_totals_name_cell}>{translations.words.licenses}</div>
                                {availableMarketLicenses.map((marketLicense) => (
                                    <div key={marketLicense.market} className={styles.licenses_page_column_sum_cell}>
                                        {marketLicense.numberOfLicensesUsed}/{marketLicense.numberOfLicenses}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {getCompanyApiResponse?.demeterCompany?.name && !!activeUsersListReference.current.length && (
                <div className={styles.licenses_page_admin_asterisk}>
                    * {translations.licenses.text.licenseTableFooter} {getCompanyApiResponse.demeterCompany?.name}
                </div>
            )}
        </div>
    );
};

export default LicensesPage;
