import { useMemo, useState } from 'react';
import { DemeterFilterTimeSpan } from '../../../../Generated/Raven-Demeter';
import {
    CommodityMonthlyOriginsOrDestinationsModel,
    ListCommodityMonthlyOriginsOrDestinationsResponse,
} from '../../../Apis/Hooks/useOriginsAndDestinationsApiHook';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import Switch from '../../Form/Buttons/Switch';
import { IChartProps } from '../ChartDefinitions';
import ChartWrapper from '../ChartWrapper/ChartWrapper';
import DonutChartRaw from '../Donut/DonutChartRaw';
import styles from './OriginsAndDestinationsChart.module.scss';

export interface IOriginsAndDestinationsChartProps extends IChartProps {
    title: string;
    originsOrDestinationsResponse: ListCommodityMonthlyOriginsOrDestinationsResponse;
    filterTimeSpan: DemeterFilterTimeSpan;
}

const OriginsAndDestinationsChart: React.FC<IOriginsAndDestinationsChartProps> = (props) => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    // Chart hooks.
    const [showPopout, setShowPopout] = useState<boolean>(false);
    const [originsAndDestinationsMonthLocked, setOriginsAndDestinationsMonthLocked] = useState<boolean>(false);
    const timeSpanIsYearToDate = props.filterTimeSpan === DemeterFilterTimeSpan.YearToDate;

    const chartTitle = useMemo(() => {
        if (originsAndDestinationsMonthLocked) {
            return `${translations.charts.text.monthly} ${props.title}`;
        }

        if (timeSpanIsYearToDate) {
            return `${translations.charts.text.yearToDate} ${props.title}`;
        }

        return `${translations.charts.text.pastTwelveMonths} ${props.title}`;
    }, [translations, originsAndDestinationsMonthLocked, props.title, props.filterTimeSpan]);

    const getValuesByTimespanAndMonthLocked = (model: CommodityMonthlyOriginsOrDestinationsModel) => {
        if (originsAndDestinationsMonthLocked) {
            return model.value;
        }

        if (timeSpanIsYearToDate) {
            return model.yearToDateValue;
        }

        return model.rollingYearValue;
    };

    const donutChartInnerText = useMemo(() => {
        if (!props.originsOrDestinationsResponse.rows) {
            return '';
        }

        const totalValue = formattingService.toFormattedNumber(getValuesByTimespanAndMonthLocked(props.originsOrDestinationsResponse.rows[0]));
        const timeSpanText = originsAndDestinationsMonthLocked
            ? translations.destinationsOrOrigins.text.monthly
            : translations.filterTimeSpan[props.filterTimeSpan!];

        return `<p class=${styles.origins_and_destination_chart_inner_text}>${timeSpanText}</p>
                <p class=${styles.origins_and_destination_chart_inner_text}>${translations.words.total}</p>
                <p class=${styles.origins_and_destination_chart_inner_text}><strong>${totalValue}</strong></p>`;
    }, [translations, originsAndDestinationsMonthLocked, props.originsOrDestinationsResponse, props.filterTimeSpan]);

    const DonutSeries = useMemo(() => {
        if (!props.originsOrDestinationsResponse.rows) {
            return null;
        }

        return props.originsOrDestinationsResponse.rows.map((row) => ({
            label: translate(row.originOrDestinationRegion!),
            data: [
                {
                    value: getValuesByTimespanAndMonthLocked(row)!,
                    asOfDate: new Date(row.asOfDate),
                    isActualValue: true,
                },
            ],
        }));
    }, [translations, originsAndDestinationsMonthLocked, props.originsOrDestinationsResponse, props.filterTimeSpan]);

    const isLoading = !DonutSeries;

    return (
        <div className={styles.origins_and_destination_chart}>
            <ChartWrapper
                name="OriginsAndDestinationsChart"
                title={chartTitle}
                showPopout={showPopout}
                setShowPopout={setShowPopout}
                dataSourceTag={props.originsOrDestinationsResponse.dataSourceTag ?? ''}
                isLoading={isLoading}
                testId={props.testId}
            >
                <DonutChartRaw
                    title={props.title}
                    innerText={donutChartInnerText}
                    donutSeries={DonutSeries!}
                    filterTimeSpan={props.filterTimeSpan}
                    showPopout={showPopout}
                    unitOfMeasure={props.originsOrDestinationsResponse.unitOfMeasure}
                    testId={props.testId}
                />
            </ChartWrapper>
            <p className={styles.origins_and_destination_chart_time_override}>
                {translations.destinationsOrOrigins.text.monthly}
                <Switch handleChange={() => setOriginsAndDestinationsMonthLocked(!originsAndDestinationsMonthLocked)} />
            </p>
        </div>
    );
};

export default OriginsAndDestinationsChart;
