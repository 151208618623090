import React, { useEffect, useMemo, useState } from 'react';
import { DemeterFilterTimeSpan } from '../../../../Generated/Raven-Demeter';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventActionsEnum, EventCategoriesEnum } from '../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../Services/Logging/LoggingService';
import styles from './FilterTimeSpans.module.scss';

export interface IFilterTimeSpansProps {
    name: string;
    filterTimeSpanOptions: DemeterFilterTimeSpan[];
    filterTimeSpan?: DemeterFilterTimeSpan;
    oldestAsOfDate?: Date;
    handleTimeSpanSelected: (filterTimeSpan: DemeterFilterTimeSpan) => void;
}

export const getTimestampMap = (date: Date): { [key in DemeterFilterTimeSpan]: number } => ({
    [DemeterFilterTimeSpan.OneDay]: new Date(date).setDate(date.getDate() - 1),
    [DemeterFilterTimeSpan.ThirtyDays]: new Date(date).setDate(date.getDate() - 30),
    [DemeterFilterTimeSpan.OneMonth]: new Date(date).setMonth(date.getMonth() - 1),
    [DemeterFilterTimeSpan.ThreeMonths]: new Date(date).setMonth(date.getMonth() - 3),
    [DemeterFilterTimeSpan.SixMonths]: new Date(date).setMonth(date.getMonth() - 6),
    [DemeterFilterTimeSpan.NineMonths]: new Date(date).setMonth(date.getMonth() - 9),
    [DemeterFilterTimeSpan.OneYear]: new Date(date).setFullYear(date.getFullYear() - 1),
    [DemeterFilterTimeSpan.TwoYears]: new Date(date).setFullYear(date.getFullYear() - 2),
    [DemeterFilterTimeSpan.ThreeYears]: new Date(date).setFullYear(date.getFullYear() - 3),
    [DemeterFilterTimeSpan.FourYears]: new Date(date).setFullYear(date.getFullYear() - 4),
    [DemeterFilterTimeSpan.FiveYears]: new Date(date).setFullYear(date.getFullYear() - 5),
    [DemeterFilterTimeSpan.TenYears]: new Date(date).setFullYear(date.getFullYear() - 10),
    [DemeterFilterTimeSpan.FifteenYears]: new Date(date).setFullYear(date.getFullYear() - 15),
    [DemeterFilterTimeSpan.TwentyYears]: new Date(date).setFullYear(date.getFullYear() - 20),
    // No date expectations on these types when they exists.
    [DemeterFilterTimeSpan.All]: 0,
    [DemeterFilterTimeSpan.LastTwoActuals]: 0,
    [DemeterFilterTimeSpan.YearToDate]: 0,
});

const FilterTimeSpans: React.FC<IFilterTimeSpansProps> = (props: IFilterTimeSpansProps) => {
    const [translations] = useLanguage();
    const [timeSpan, setTimeSpan] = useState<DemeterFilterTimeSpan>(props.filterTimeSpan ?? props.filterTimeSpanOptions[0]);

    const getAvailableTimeSpans = (): DemeterFilterTimeSpan[] => {
        if (!props.oldestAsOfDate) {
            return props.filterTimeSpanOptions;
        }

        const newTimeSpanOptions: DemeterFilterTimeSpan[] = [];
        const now = new Date();
        const timeSpanToTimestampMap = getTimestampMap(now);

        let stopMarker = false;
        props.filterTimeSpanOptions.forEach((element) => {
            if (props.oldestAsOfDate!.getTime() < timeSpanToTimestampMap[element] && !stopMarker) {
                newTimeSpanOptions.push(element);
            } else {
                // Push the last element in before stopping.
                newTimeSpanOptions.push(element);
                stopMarker = true;
            }
        });

        return newTimeSpanOptions;
    };

    const availableTimeSpans = useMemo<DemeterFilterTimeSpan[]>(() => {
        if (!props.oldestAsOfDate) {
            return [...props.filterTimeSpanOptions];
        }

        return [...getAvailableTimeSpans()];
    }, [props.filterTimeSpanOptions, props.oldestAsOfDate]);

    useEffect(() => {
        if (props.filterTimeSpan && props.filterTimeSpan !== timeSpan) {
            setTimeSpan(props.filterTimeSpan);
        }
    }, [props.filterTimeSpan]);

    const handleClick = (e: Event | React.MouseEvent) => {
        const value = (e.target as HTMLInputElement).value! as DemeterFilterTimeSpan;
        loggingService.trackEventWithAnalytics(EventActionsEnum.ButtonClick, EventCategoriesEnum.FilterTimeSpanSelected, `${props.name} ${value}`, props.name);

        setTimeSpan(value);
        props.handleTimeSpanSelected(value);
    };

    return (
        <div>
            {availableTimeSpans.map((x) => (
                <button
                    key={x}
                    className={timeSpan === x ? styles.filter_time_span_selected : styles.filter_time_span_button}
                    type="button"
                    onClick={(e) => handleClick(e)}
                    value={x}
                >
                    {translations.filterTimeSpan[x]}
                </button>
            ))}
        </div>
    );
};

export default FilterTimeSpans;
