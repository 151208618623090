import { useEffect, useMemo, useState } from 'react';
import { demeterApi, demeterUserDataApi, demeterUsersApi } from '../../../../Apis/Apis';
import {
    Currency,
    DemeterCommodity,
    DemeterDataSource,
    DemeterFilterTimeSpan,
    DemeterRegion,
    DemeterSubRegion,
    DemeterSymbolModel,
    DemeterTableDefinitionType,
    DemeterUserDataValue,
    DemeterUserStoreType,
    ExchangeType,
    MarketPriceModel,
    MarketPricesTimeSpan,
    SymbolCategory,
    UnitOfMeasure,
} from '../../../../Generated/Raven-Demeter';
import useApi from '../../../Apis/Hooks/useApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import useTableDefinitionsApi from '../../../Apis/Hooks/useTableDefinitionsApiHook';
import useUserStoreApi from '../../../Apis/Hooks/useUserStoreApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './BasisCalculator.module.scss';
import BasisCalculatorChartCarousel from './BasisCalculatorChartCarousel';
import {
    BasisAdjustment,
    BasisCalculatorChartText,
    BasisCalculatorProduct,
    BasisCalculatorProductPair,
    basisCurrencies,
    BasisLagPeriod,
    BasisPeriod,
    basisUnitOfMeasures,
    BasisValueModel,
    defaultStartDate,
} from './BasisCalculatorDefinitions';
import BasisCalculatorForm from './BasisCalculatorForm';
import BasisCalculatorGrid from './BasisCalculatorGrid';
import BasisCalculatorProductSelector from './BasisCalculatorProductSelector';
import BasisCalculatorService, { IBasisCalculatorCalculationResult } from './BasisCalculatorService';
import BasisCalculatorStartup from './BasisCalculatorStartup';

const defaultBasisCurrency = Currency.Eur;
const defaultBasisUnitOfMeasure = UnitOfMeasure.MetricTon;

const BasisCalculator = () => {
    const [translations, translate] = useLanguage();
    const [productSelectorOpen, setProductSelectorOpen] = useState(false);
    const [productSelectorActiveTab, setProductSelectorActiveTab] = useState(0);
    const [products, setProducts] = useState<BasisCalculatorProduct[] | undefined>();
    const [userProducts, setUserProducts] = useState<BasisCalculatorProduct[] | undefined>();
    const [productPrices1, setProductPrices1] = useState<MarketPriceModel[] | BasisValueModel[] | DemeterUserDataValue[] | undefined>();
    const [productPrices2, setProductPrices2] = useState<MarketPriceModel[] | BasisValueModel[] | DemeterUserDataValue[] | undefined>();
    const [product1ChartText, setProduct1ChartText] = useState<BasisCalculatorChartText>({ productName: '', dataSourceTags: [] });
    const [product2ChartText, setProduct2ChartText] = useState<BasisCalculatorChartText>({ productName: '', dataSourceTags: [] });
    const [calculationResult, setCalculationResult] = useState<IBasisCalculatorCalculationResult | undefined>();
    const [savedProductPairs, setSavedProductPairs] = useState<BasisCalculatorProductPair[]>();
    const [productPair, setProductPair] = useState<BasisCalculatorProductPair>({
        product1: undefined,
        product2: undefined,
    });

    const symbols = useSymbolsApi();
    const tableDefinitions = useTableDefinitionsApi(DemeterTableDefinitionType.CommodityPricesTable);
    const [userStoreValueResponse, updateUserStoreValue] = useUserStoreApi(DemeterUserStoreType.BasisCalculator);
    const [, listDemeterUserData, listDemeterUserDataResponse] = useApi(() => demeterUserDataApi.listDemeterUserData());

    const [, getDemeterUserDataProduct1, getDemeterUserDataProduct1Response] = useApi(() => {
        if (!productPair?.product1) {
            return null;
        }

        return demeterUserDataApi.getDemeterUserData(productPair.product1.id, productPair.currency!, productPair.unitOfMeasure!);
    });

    const [, getDemeterUserDataProduct2, getDemeterUserDataProduct2Response] = useApi(() => {
        if (!productPair?.product2) {
            return null;
        }

        return demeterUserDataApi.getDemeterUserData(productPair.product2.id, productPair.currency!, productPair.unitOfMeasure!);
    });

    useEffect(() => {
        if (!getDemeterUserDataProduct1Response) {
            return;
        }

        setProductPrices1(getDemeterUserDataProduct1Response.demeterUserData?.rows as DemeterUserDataValue[]);
        setProduct1ChartText({
            productName: getDemeterUserDataProduct1Response.demeterUserData?.name ?? '',
            dataSourceTags: [translations.region.UserData],
        });
    }, [getDemeterUserDataProduct1Response]);

    useEffect(() => {
        if (!getDemeterUserDataProduct2Response) {
            return;
        }

        setProductPrices2(getDemeterUserDataProduct2Response.demeterUserData?.rows as DemeterUserDataValue[]);
        setProduct2ChartText({
            productName: getDemeterUserDataProduct2Response.demeterUserData?.name ?? '',
            dataSourceTags: [translations.region.UserData],
        });
    }, [getDemeterUserDataProduct2Response]);

    const sortSymbols = (symbolsArray: DemeterSymbolModel[]): DemeterSymbolModel[] => {
        const categoryOrder = [
            SymbolCategory.Dairy,
            SymbolCategory.Energy,
            SymbolCategory.Grains,
            SymbolCategory.Livestock,
            SymbolCategory.Softs,
            SymbolCategory.Currency,
            SymbolCategory.Indices,
            SymbolCategory.Fertilizer,
        ];
        const exchangeOrder = [
            ExchangeType.Cme,
            ExchangeType.Eex,
            ExchangeType.Euronext,
            ExchangeType.Ice,
            ExchangeType.Sgx,
            ExchangeType.Mdx,
            ExchangeType.B3,
            ExchangeType.Mwe,
            ExchangeType.Otc,
            ExchangeType.Dce,
            ExchangeType.Safex,
        ];

        return symbolsArray.sort((a, b) => {
            const categoryIndexA = categoryOrder.indexOf(a.symbolCategory as SymbolCategory);
            const categoryIndexB = categoryOrder.indexOf(b.symbolCategory as SymbolCategory);

            if (categoryIndexA !== categoryIndexB) {
                if (categoryIndexA === -1) {
                    return 1;
                }
                if (categoryIndexB === -1) {
                    return -1;
                }
                return categoryIndexA - categoryIndexB;
            }
            const exchangeIndexA = exchangeOrder.indexOf(a.exchange as ExchangeType);
            const exchangeIndexB = exchangeOrder.indexOf(b.exchange as ExchangeType);
            if (exchangeIndexA !== exchangeIndexB) {
                if (exchangeIndexA === -1) {
                    return 1;
                }
                if (exchangeIndexB === -1) {
                    return -1;
                }
                return exchangeIndexA - exchangeIndexB;
            }
            return 0;
        });
    };

    useEffect(() => {
        listDemeterUserData();
    }, []);

    useEffect(() => {
        if (!listDemeterUserDataResponse) {
            return;
        }
        const responseData = listDemeterUserDataResponse.rows?.map(
            (x) =>
                ({
                    id: x.demeterUserDataGuid,
                    name: x.name,
                    currency: x.currency,
                    unitOfMeasure: x.unitOfMeasure,
                    category: 'userData',
                } as BasisCalculatorProduct),
        );
        setUserProducts(responseData);
    }, [listDemeterUserDataResponse]);

    const futureProducts = useMemo(() => {
        if (!symbols || !tableDefinitions) {
            return [];
        }
        return (
            sortSymbols(symbols)
                .filter((symbol) => symbol.symbolCategory !== SymbolCategory.Currency)
                // removed as per #128639 for conference
                .filter((symbol) => symbol.symbolCategory !== SymbolCategory.Energy)
                .filter((symbol) => symbol.symbolCategory !== SymbolCategory.Grains)
                .filter((symbol) => symbol.symbolCategory !== SymbolCategory.Softs)
                .map((symbol) => {
                    const product: BasisCalculatorProduct = {
                        id: `futures-${symbol.reutersInstrumentCodePrefix}`,
                        name: symbol.displayName,
                        category: 'futures',
                        market: symbol.symbolCategory,
                        reutersInstrumentCodePrefix: symbol.reutersInstrumentCodePrefix,
                        exchange: symbol.exchange,
                        region: symbol.pricesRegion ?? symbol.region,
                        commodity: symbol.pricesCommodity ?? symbol.commodity,
                        group: symbol.exchange,
                        contractNumber: symbol.rollingContractNumber,
                        extraParameters: symbol.pricesDataSource,
                        currency: symbol.currency,
                        unitOfMeasure: symbol.unitOfMeasure,
                        dataSourceTag: [symbol.exchange],
                        futuresSymbol: symbol,
                    };

                    if (symbol.pricesRegion) {
                        const regionDefinition = tableDefinitions.find((x) => x.region === symbol.pricesRegion);
                        product.commodityTableDefinition = regionDefinition?.demeterTableDefinitionGroups
                            .flatMap((x) => x.demeterTableDefinitions)
                            .find(
                                (x) =>
                                    x.commodity === symbol.pricesCommodity &&
                                    (x.extraParameters === symbol.pricesDataSource ||
                                        (!x.extraParameters && symbol.pricesDataSource === DemeterDataSource.All)),
                            );
                    }

                    return product;
                })
        );
    }, [symbols, tableDefinitions]);

    const physicalProducts = useMemo(() => {
        const productList: BasisCalculatorProduct[] = [];
        tableDefinitions
            ?.filter((regionGroup) => regionGroup.region !== DemeterRegion.All)
            .forEach((regionGroup) => {
                const market = regionGroup.market!;
                const region = regionGroup.region!;
                regionGroup.demeterTableDefinitionGroups
                    .filter((definitionGroup) => definitionGroup.commodity !== DemeterCommodity.All)
                    .filter((definitionGroup) => definitionGroup.commodity !== DemeterCommodity.Feed)
                    .forEach((definitionGroup) => {
                        definitionGroup.demeterTableDefinitions.forEach((definition) => {
                            const { displayName, groupDisplayName, commodity, extraParameters, subRegions } = definition;

                            const dataSource = extraParameters as DemeterDataSource;
                            const symbol = symbols?.find(
                                (x) =>
                                    x.pricesRegion &&
                                    x.pricesRegion === region &&
                                    x.pricesCommodity === commodity &&
                                    ((!x.pricesDataSource && !dataSource) || x.pricesDataSource === dataSource),
                            );

                            productList.push({
                                id: `physicalPrices-${market}-${region}-${groupDisplayName}-${commodity}-${extraParameters}-${displayName}`,
                                name: displayName,
                                category: 'physicalPrices',
                                market,
                                commodity,
                                group: region,
                                region: DemeterRegion[region],
                                subRegion: subRegions && subRegions.length > 0 ? subRegions[0] : null,
                                subGroup: definitionGroup.displayName,
                                extraParameters,
                                futuresSymbol: symbol,
                                commodityTableDefinition: definition,
                            });
                        });
                    });
            });

        return productList;
    }, [tableDefinitions]);

    useEffect(() => {
        if (isProductPairDraftStored()) {
            setProductPairDraft();
        } else {
            setLastSavedPair();
        }
    }, [savedProductPairs]);

    useEffect(() => {
        setProducts([...futureProducts, ...physicalProducts]);
    }, [futureProducts, physicalProducts]);

    useEffect(() => {
        updateProductPrices1();
    }, [productPair.product1, productPair.currency, productPair.unitOfMeasure]);

    useEffect(() => {
        updateProductPrices2();
    }, [productPair.product2, productPair.currency, productPair.unitOfMeasure]);

    useEffect(() => {
        if (productPrices1 && productPrices2 && productPair.startDate && productPair.basisLagPeriod) {
            calculate();
        }
    }, [
        productPrices1,
        productPrices2,
        productPair.startDate,
        productPair.basisLagPeriod,
        productPair.basisPeriod,
        productPair.basisAdjustment,
        productPair.useRegression,
    ]);

    const isProductPairDraftStored = () => {
        const storedProductPairDraft = sessionStorage.getItem('productPairDraft');
        return storedProductPairDraft !== null;
    };

    const saveProductPairDraft = (productPairDraft: BasisCalculatorProductPair) => {
        sessionStorage.setItem('productPairDraft', JSON.stringify(productPairDraft));
    };

    const setProductPairDraft = () => {
        const storedProductPairDraft = sessionStorage.getItem('productPairDraft');
        if (storedProductPairDraft) {
            const productPairDraft = JSON.parse(storedProductPairDraft) as BasisCalculatorProductPair;
            if (productPairDraft) {
                setProductPair(productPairDraft);
            }
        }
    };

    useEffect(() => {
        if (userStoreValueResponse) {
            try {
                setSavedProductPairs(userStoreValueResponse.userStore?.value?.savedProductPairs ?? []);
            } catch {
                setSavedProductPairs([]);
            }
        }
    }, [userStoreValueResponse]);

    const deleteSavedProductPair = (deleteProductPair: BasisCalculatorProductPair) => {
        try {
            const remainingSavedProductPairs = savedProductPairs?.filter((p) => p !== deleteProductPair);
            const request = {
                userStoreType: DemeterUserStoreType.BasisCalculator,
                value: { savedProductPairs: remainingSavedProductPairs },
            };

            demeterUsersApi.updateUserStore(DemeterUserStoreType.BasisCalculator, request).then(() => {
                setSavedProductPairs(remainingSavedProductPairs);
            });
        } catch {
            /* empty */
        }
    };

    const setLastSavedPair = () => {
        if (savedProductPairs && savedProductPairs.length > 0) {
            const pair = savedProductPairs[savedProductPairs.length - 1];
            setProductPair(pair);
        }
    };

    const selectProductPair = () => {
        setProductSelectorActiveTab(0);
        setProductSelectorOpen(true);
    };

    const selectSavedProductPair = () => {
        setProductSelectorActiveTab(1);
        setProductSelectorOpen(true);
    };

    const saveProductPair = () => {
        if (!productPair) {
            return;
        }

        try {
            if (productPair) {
                productPair.saved = new Date();
            }

            const savedPairs = savedProductPairs ?? [];
            const remainingSavedProductPairs = savedPairs?.filter(
                (p) => !(p.product1?.id === productPair.product1?.id && p.product2?.id === productPair.product2?.id),
            );

            remainingSavedProductPairs.push(productPair);
            updateUserStoreValue({ savedProductPairs: remainingSavedProductPairs });
            setSavedProductPairs(remainingSavedProductPairs);
        } catch {
            /* empty */
        }
    };

    const updateProductPrices1 = () => {
        if (!productPair || !productPair.product1) {
            return;
        }

        if (productPair.product1.category === 'futures' && !productPair.product1.futuresSymbol?.pricesRegion) {
            demeterApi
                .listMarketPricesRollingWithConversions(
                    productPair.product1.reutersInstrumentCodePrefix!,
                    productPair.product1.contractNumber!,
                    MarketPricesTimeSpan.TenYears,
                    productPair.currency,
                    productPair.unitOfMeasure,
                )
                .then((response) => {
                    setProductPrices1(response.data.rows as MarketPriceModel[]);
                    setProduct1ChartText({
                        productName: formattingService.toDisplayName(productPair.product1?.futuresSymbol),
                        dataSourceTags: [translations.exchange[productPair.product1?.exchange!]],
                    });
                });
        } else if (productPair.product1.category === 'userData') {
            getDemeterUserDataProduct1();
        } else {
            demeterApi
                .listCommodityMonthlyPrices(
                    productPair.product1.region! as DemeterRegion,
                    productPair.product1.commodity!,
                    (productPair.product1.extraParameters as DemeterDataSource) ?? DemeterDataSource.All,
                    productPair.currency,
                    productPair.product1.subRegion ? (productPair.product1.subRegion as DemeterSubRegion) : undefined,
                    productPair.unitOfMeasure,
                    DemeterFilterTimeSpan.TenYears,
                )
                .then((response) => {
                    setProductPrices1(BasisCalculatorService.convertMonthlyPricesData(response.data));

                    const newProductCharText = {
                        productName: translate(productPair.product1?.commodityTableDefinition?.displayName ?? ''),
                        dataSourceTags: (response.data.dataSourceTag ?? '').split(', '),
                    } as BasisCalculatorChartText;

                    if (response.data.futuresMarketPricesForwardCurve && response.data.futuresMarketPricesForwardCurve) {
                        const symbol = symbols?.find((x) => x.reutersInstrumentCodePrefix === response.data.reutersInstrumentCodePrefix);
                        if (symbol) {
                            newProductCharText.productNameForwardCurve = formattingService.toDisplayName(symbol);
                            newProductCharText.dataSourceTags.unshift(translations.exchange[symbol.exchange as ExchangeType]);
                        }
                    }

                    setProduct1ChartText(newProductCharText);
                });
        }
    };

    const updateProductPrices2 = () => {
        if (!productPair || !productPair.product2) {
            return;
        }

        if (productPair.product2.category === 'futures' && !productPair.product2.futuresSymbol?.pricesRegion) {
            demeterApi
                .listMarketPricesRollingWithConversions(
                    productPair.product2?.reutersInstrumentCodePrefix!,
                    productPair.product2?.contractNumber!,
                    MarketPricesTimeSpan.TenYears,
                    productPair?.currency,
                    productPair?.unitOfMeasure,
                )
                .then((response) => {
                    setProductPrices2(response.data.rows as MarketPriceModel[]);
                    setProduct2ChartText({
                        productName: formattingService.toDisplayName(productPair.product2?.futuresSymbol),
                        dataSourceTags: [translations.exchange[productPair.product2?.exchange!]],
                    });
                });
        } else if (productPair.product2.category === 'userData') {
            getDemeterUserDataProduct2();
        } else {
            demeterApi
                .listCommodityMonthlyPrices(
                    productPair.product2.region! as DemeterRegion,
                    productPair.product2.commodity!,
                    (productPair.product2.extraParameters as DemeterDataSource) ?? DemeterDataSource.All,
                    productPair.currency,
                    productPair.product2.subRegion ? (productPair.product2.subRegion as DemeterSubRegion) : undefined,
                    productPair.unitOfMeasure,
                    DemeterFilterTimeSpan.TenYears,
                )
                .then((response) => {
                    setProductPrices2(BasisCalculatorService.convertMonthlyPricesData(response.data));

                    const newProductCharText = {
                        productName: translate(productPair.product2?.commodityTableDefinition?.displayName ?? ''),
                        dataSourceTags: (response.data.dataSourceTag ?? '').split(', '),
                    } as BasisCalculatorChartText;

                    if (response.data.futuresMarketPricesForwardCurve && response.data.futuresMarketPricesForwardCurve) {
                        const symbol = symbols?.find((x) => x.reutersInstrumentCodePrefix === response.data.reutersInstrumentCodePrefix);
                        if (symbol) {
                            newProductCharText.productNameForwardCurve = formattingService.toDisplayName(symbol);
                            newProductCharText.dataSourceTags.unshift(translations.exchange[symbol.exchange as ExchangeType]);
                        }
                    }

                    setProduct2ChartText(newProductCharText);
                });
        }
    };

    const calculate = () => {
        if (!productPrices1 || !productPrices2) {
            setCalculationResult(undefined);
            return;
        }

        const result = BasisCalculatorService.calculate({
            basisLagPeriod: productPair.basisLagPeriod,
            basisPeriod: productPair.basisPeriod,
            basisAdjustment: productPair.basisAdjustment,
            useOptimalLag: productPair.useOptimalLag,
            useRegression: productPair.useRegression,
            startDate: productPair.startDate,
            productPrices1,
            productPrices2,
        });
        setCalculationResult(result);
    };

    const getCurrency = (value: BasisCalculatorProductPair): Currency => {
        const product1HasChanged = productPair && productPair.product1 && value.product1 && productPair.product1.id !== value.product1.id;
        const product1HasValidCurrency = value.product1 && value.product1.currency && basisCurrencies.some((x) => (x as Currency) === value.product1?.currency);
        if (product1HasChanged && product1HasValidCurrency) {
            return value.product1?.currency!;
        }
        if (value.currency) {
            return value.currency;
        }
        if (product1HasValidCurrency) {
            return value.product1?.currency!;
        }
        return defaultBasisCurrency;
    };

    const getUnitOfMeasure = (value: BasisCalculatorProductPair): UnitOfMeasure => {
        const product1HasChanged = productPair && productPair.product1 && value.product1 && productPair.product1.id !== value.product1.id;
        const product1HasValidUnitOfMeasure =
            value.product1 && value.product1.unitOfMeasure && basisUnitOfMeasures.some((x) => (x as UnitOfMeasure) === value.product1?.unitOfMeasure);
        if (product1HasChanged && product1HasValidUnitOfMeasure) {
            return value.product1?.unitOfMeasure!;
        }
        if (value.unitOfMeasure) {
            return value.unitOfMeasure;
        }
        if (product1HasValidUnitOfMeasure) {
            return value.product1?.unitOfMeasure!;
        }
        return defaultBasisUnitOfMeasure;
    };

    const changeProductPair = (value: BasisCalculatorProductPair) => {
        value.currency = getCurrency(value);
        value.unitOfMeasure = getUnitOfMeasure(value);
        value.basisPeriod = value.basisPeriod ?? BasisPeriod.Basis;
        value.basisAdjustment = value.basisAdjustment ?? BasisAdjustment.Average;
        value.basisLagPeriod = value.basisLagPeriod ?? BasisLagPeriod.NoLag;
        value.startDate = value.startDate ?? defaultStartDate;
        value.useOptimalLag = value.useOptimalLag ?? true;
        value.showAdvancedSettings = value.showAdvancedSettings ?? false;
        setProductPair(value);
        saveProductPairDraft(value);
    };

    const showLoading = !products || !userProducts || !savedProductPairs;
    const showStartup = !showLoading && (!productPair.product1 || !productPair.product2);
    const showCalculator = !showLoading && !showStartup;

    return (
        <>
            {showLoading && <PageLoadingSpinner />}
            {showStartup && (
                <div className={styles.basis_calculator}>
                    <div className={styles.basis_calculator_header_row}>
                        <ComponentHeader title={translations.calculators.text.basis} />
                    </div>
                    <BasisCalculatorStartup productOptions={products} userProductOptions={userProducts} onChangeProductPair={changeProductPair} />
                </div>
            )}
            {showCalculator && (
                <div className={styles.basis_calculator}>
                    <div className={styles.basis_calculator_header_row}>
                        <ComponentHeader title={translations.calculators.text.basis} />
                        <div className={styles.basis_calculator_controls}>
                            <LinkButton
                                title={translations.calculators.basis.actions.selectSavedPair}
                                type={LinkButtonType.White}
                                onClick={selectSavedProductPair}
                            />
                            <LinkButton title={translations.calculators.basis.actions.saveProductPair} type={LinkButtonType.White} onClick={saveProductPair} />
                        </div>
                    </div>
                    <div className={styles.basis_calculator_content_row}>
                        <BasisCalculatorForm
                            productPair={productPair}
                            calculationResult={calculationResult}
                            onChange={changeProductPair}
                            openProductSelector={selectProductPair}
                        />
                        <div className={styles.basis_calculator_content_right}>
                            <BasisCalculatorChartCarousel
                                productPair={productPair}
                                calculationResult={calculationResult}
                                product1ChartText={product1ChartText}
                                product2ChartText={product2ChartText}
                            />
                            <BasisCalculatorGrid calculationResult={calculationResult} />
                        </div>
                    </div>
                    <BasisCalculatorProductSelector
                        open={productSelectorOpen}
                        activeTab={productSelectorActiveTab}
                        productPair={productPair}
                        savedProductPairs={savedProductPairs}
                        onChange={changeProductPair}
                        onDelete={deleteSavedProductPair}
                        onClose={() => setProductSelectorOpen(false)}
                        productOptions={products}
                        userProductOptions={userProducts}
                    />
                </div>
            )}
        </>
    );
};

export default BasisCalculator;
