import { Options, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import scssVariables from '../../../../Config.module.scss';
import { Currency, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import {
    chartColors,
    ChartContext,
    columnChartType,
    defaultChartOptions,
    HighchartsPlot,
    IChartBarDataSeries,
    IChartDataSeries,
    IChartProps,
    lineChartType,
} from '../ChartDefinitions';
import chartService from '../ChartService';

export interface IMultiBarAreaChartRawProps extends IChartProps {
    lineSeries: IChartDataSeries;
    barsSeries: IChartBarDataSeries[];
    areaSeries: IChartBarDataSeries;
    unitOfMeasure?: UnitOfMeasure;
    currency?: Currency;
}

const barLineTickAmount = 7;
const areaTickAmount = 4;
const barAndLineChartHeightPercent = 70;
const areaChartHeightPercent = 30;

const MultiBarAreaChartRaw: React.FC<IMultiBarAreaChartRawProps> = (props: IMultiBarAreaChartRawProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    // Chart hooks.
    const multiBarWithLinesChartDefaultOptions = useMemo<Options>(
        () => ({
            ...defaultChartOptions,
            yAxis: [
                {
                    title: {
                        text: props.currency,
                        style: {
                            color: scssVariables.chartYLabel,
                        },
                    },
                    opposite: true,
                    top: `${barAndLineChartHeightPercent}%`,
                    height: `${areaChartHeightPercent}%`,
                    labels: {
                        format: '{value:{point.y: , .0f}',
                    },
                    tickAmount: areaTickAmount,
                },
                {
                    title: {
                        text: `${translations.words.volume} (${translations.unitOfMeasure[`Short${props.unitOfMeasure}`]})`,
                        style: {
                            color: scssVariables.chartYLabel,
                        },
                    },
                    height: `${barAndLineChartHeightPercent}%`,
                    tickAmount: barLineTickAmount,
                },
            ],
            series: [],
        }),
        [],
    );

    const [highchartOptions, setHighchartOptions] = useState<Options>(multiBarWithLinesChartDefaultOptions);

    const getDataSeriesDefinitions = useCallback(() => {
        const barsDataSeries = props.barsSeries.flatMap((barSeries, index) => {
            const currentColor = chartColors.lineChartColors[index];

            return [
                {
                    name: barSeries.label,
                    yAxis: 1,
                    type: columnChartType,
                    data: [] as HighchartsPlot[],
                    marker: {
                        symbol: 'square',
                        radius: 12,
                        fillColor: currentColor,
                        lineWidth: 1,
                        lineColor: currentColor,
                    },
                    color: currentColor,
                    visible: true,
                    showInLegend: true,
                },
            ];
        });

        const lineDataSeries = [
            {
                name: props.lineSeries.label,
                yAxis: 1,
                marker: {
                    enabled: true,
                },
                data: [] as HighchartsPlot[],
                color: chartColors.lineChartColors[props.barsSeries.length],
                type: lineChartType,
                visible: true,
                showInLegend: true,
            },
        ];

        const areaDataSeries = [
            {
                name: props.areaSeries.label,
                yAxis: 0,
                marker: {
                    enabled: false,
                },
                data: [] as HighchartsPlot[],
                color: chartColors.lineChartColors[props.barsSeries.length + 1],
                type: 'area',
                visible: true,
                showInLegend: true,
            },
        ];

        return [...barsDataSeries, ...lineDataSeries, ...areaDataSeries];
    }, [props.lineSeries, props.barsSeries, props.areaSeries]);

    // Main useEffect to update chart when props or data changes.
    useEffect(() => {
        const dataSeries = getDataSeriesDefinitions();
        const allSeries = [...props.barsSeries, props.lineSeries, props.areaSeries];
        const downloadData = chartService.getDownloadData(allSeries);

        allSeries.forEach((series, index) => {
            const endIndex = series.data.length;
            dataSeries[index].data = series.data.slice(0, endIndex).map((item) => ({ x: item.asOfDate.getTime(), y: item.value }));
        });

        const newOptions = {
            ...multiBarWithLinesChartDefaultOptions,
            ...{ series: dataSeries },
            ...{
                tooltip: {
                    formatter() {
                        const context = this as unknown as ChartContext;
                        return chartService.getTooltipText(context, {
                            displayDecimalPlacesMinimum: props.displayDecimalPlacesMinimum ?? 0,
                            displayDecimalPlacesMaximum: props.displayDecimalPlacesMaximum ?? 0,
                        });
                    },
                },
            },
            downloadData,
        };

        const minimumValue = Math.min(...newOptions.series.flatMap((x) => (x.data ? x.data.map((y) => Math.abs(y.y ?? 0)) : [])).filter((x) => !!x));
        (newOptions.yAxis as YAxisOptions[])[0].labels!.format = `{value:{point.y: , .${formattingService.getDisplayDecimalPlacesMinimumForCharts(
            minimumValue,
        )}f}`;

        setHighchartOptions(newOptions as Options);
    }, [props.lineSeries, props.barsSeries, props.areaSeries]);

    return <HighchartsReact ref={props.chartReference} highcharts={HighStock} options={highchartOptions} containerProps={{ style: { height: '100%' } }} />;
};

export default memo(MultiBarAreaChartRaw);
