import scssVariables from '../../../../Config.module.scss';
import { GetMarketIndicatorFactorDataResponse } from '../../../../Generated/Raven-Demeter';

export interface IMarketIndicatorUserChartBaseProps {
    runTestMarketIndicatorFactorResponse?: GetMarketIndicatorFactorDataResponse;
    title?: string;
    showOnlyAsPopout?: boolean;
    showPopout?: boolean;
    setShowPopout?: (showPopout: boolean) => void;
    isLoading?: boolean;
}

export interface IMarketIndicatorChartColorPalette {
    lineColors: string[];
    areaRangeColors?: string[];
}

export const defaultChartPalette: IMarketIndicatorChartColorPalette = {
    areaRangeColors: [
        scssVariables.ninetyToOneHundredDecile,
        scssVariables.eightyToNinetyDecile,
        scssVariables.seventyToEightyDecile,
        scssVariables.sixtyToSeventyDecile,
        scssVariables.fiftyToSixtyDecile,
        scssVariables.fourtyToFiftyDecile,
        scssVariables.thirtyToFourtyDecile,
        scssVariables.twentyToThirtyDecile,
        scssVariables.tenToTwentyDecile,
        scssVariables.zeroToTenDecile,
    ],
    lineColors: [
        scssVariables.chartColor1,
        scssVariables.chartColor2,
        scssVariables.chartColor3,
        scssVariables.chartColor4,
        scssVariables.chartColor5,
        scssVariables.chartColor6,
        scssVariables.chartColor7,
        scssVariables.chartColor8,
        scssVariables.chartColor9,
        scssVariables.chartColor10,
        scssVariables.chartColor11,
        scssVariables.chartColor12,
        scssVariables.chartColor13,
        scssVariables.chartColor14,
        scssVariables.chartColor15,
    ],
};
