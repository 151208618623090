import { useMemo } from 'react';
import { demeterMarketIndicatorsApi } from '../../../Apis/Apis';
import useCacheThenApi from '../../Apis/Hooks/useCacheThenApiHook';
import ComponentHeader from '../../Components/Headers/ComponentHeader';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useLanguage from '../../Services/Language/useLanguageHook';
import MarketIndicatorGuageChart from '../MarketIndicators/MarketIndicatorGuageChart';
import styles from './Dashboard.module.scss';

const defaultChartDimensions = { chartSize: 140, dialRadius: 60 };

const DashboardMarketIndicators: React.FC = () => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    // Text hooks.
    const marketIndicatorTitleString = useMemo(() => translations.dashboard.headings.marketIndicatorsHeader, [translations]);

    // Api hooks.
    const [isLoading, , listMarketIndicatorsResponse] = useCacheThenApi(CacheKeys.ListMarketIndicatorsCurrent, () =>
        demeterMarketIndicatorsApi.listMarketIndicatorsCurrent(),
    );

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <div className={styles.dashboard_gray_background}>
            <div className={styles.dashboard_header}>
                <ComponentHeader title={marketIndicatorTitleString} />
            </div>
            <div className={styles.dashboard_guage_charts}>
                {listMarketIndicatorsResponse?.rows!.map((x) => (
                    <div className={styles.dashboard_guage_chart}>
                        <MarketIndicatorGuageChart
                            title={translate(x.displayName ?? '')}
                            chartDimensions={defaultChartDimensions}
                            marketIndicator={x}
                            isSmallGuage
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DashboardMarketIndicators;
