/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Modal } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../Refactor/Services/Logging/DataLayerDefinitions';
import loggingService from '../../Refactor/Services/Logging/LoggingService';
import IndicatorsManagementButton from '../../Refactor/Components/Form/Buttons/IndicatorsManagementButton/IndicatorsManagementButton';
import JoinedSelectionButtons from '../../Refactor/Components/Form/Buttons/JoinedSelectionButtons/JoinedSelectionButtons';
import LinkButton, { LinkButtonType } from '../../Refactor/Components/Form/Buttons/LinkButton';
import languageService from '../../Refactor/Services/Language/LanguageService';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import { CommodityReferenceKey, IManageModalProps } from './InterfaceManageModal';
import styles from './ManageModal.module.scss';

type SingleOrGroup = 'single' | 'group';

const ManageModal: React.FC<IManageModalProps> = ({
    originalAvailable,
    available,
    selected,
    setSelected,
    setAvailable,
    modalTitle,
    isFuturePage,
    simpleIndicators,
}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [translations] = useLanguage();

    // display only will refer to what is displayed to user
    const [leftSideDisplayOnly, setLeftSideDisplayOnly] = useState<CommodityReferenceKey[]>(available);
    const [rightSideDisplayOnly, setRightSideDisplayOnly] = useState<CommodityReferenceKey[]>(selected);
    const [leftSideDisplayGroup, setLeftSideDisplayGroup] = useState<CommodityReferenceKey[]>(available);
    const [groupedIndicatorKey, setGroupedIndicatorKey] = useState<string>('');

    // hidden values exists but not displayed to user (for searching)
    const [leftSideDisplayHidden, setLeftSideDisplayHidden] = useState<CommodityReferenceKey[]>(available);
    const [rightSideDisplayHidden, setRightSideDisplayHidden] = useState<CommodityReferenceKey[]>(selected);

    const [highlightedValueLeftSide, setHighlightedValueLeftSide] = useState<CommodityReferenceKey[]>([]);
    const [highlightedValueRightSide, setHighlightedValueRightSide] = useState<CommodityReferenceKey[]>([]);
    const [inputState, setInputState] = useState('');
    const [useJoinedButtonsSingle, setUseJoinedButtonsSingle] = useState(true);

    const selectionGroupOptions: { label: string; value: SingleOrGroup }[] = useMemo(
        () => [
            {
                label: translations.dashboard.indicators.group,
                value: 'group',
            },
            {
                label: translations.dashboard.indicators.single,
                value: 'single',
            },
        ],
        [],
    );

    const getGuids = (element: CommodityReferenceKey[]) => element.map((x: CommodityReferenceKey) => x.guid);
    const getDisplayNames = (element: CommodityReferenceKey[]) => element.map((x: CommodityReferenceKey) => x.displayName);

    const openModal = () => {
        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ModalInteraction,
            EventCategoriesEnum.ManageModalOpen,
            isFuturePage ? 'Manage Products' : 'Manage Indicators',
            EventDataTargetsEnum.MangeModal,
            setIsOpen,
            true,
        );
    };

    const closeModal = () => {
        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ModalInteraction,
            EventCategoriesEnum.ManageModalClose,
            isFuturePage ? 'Manage Products' : 'Manage Indicators',
            EventDataTargetsEnum.MangeModal,
            setIsOpen,
            false,
        );
    };

    const makeGroupedIndicators = (arr: any[]) => {
        const groupedIndicators: any = {};
        const filterAvailableIndicators = (arr1: any[], arr2: any[]) => arr1.filter((el) => getDisplayNames(arr2).indexOf(el.displayName) !== -1);

        filterAvailableIndicators(simpleIndicators ?? [], arr).map((item) => {
            if (groupedIndicators.hasOwnProperty(item.leadingIndicatorType)) {
                if (groupedIndicators[item.leadingIndicatorType] !== undefined) {
                    groupedIndicators[item.leadingIndicatorType].push({ guid: item.demeterLeadingIndicatorGuid, displayName: item.displayName });
                } else {
                    groupedIndicators[item.leadingIndicatorType] = [{ guid: item.demeterLeadingIndicatorGuid, displayName: item.displayName }];
                }
            } else {
                groupedIndicators[item.leadingIndicatorType] = [{ guid: item.demeterLeadingIndicatorGuid, displayName: item.displayName }];
            }
        });
        return groupedIndicators;
    };

    useEffect(() => {
        if (useJoinedButtonsSingle) {
            setLeftSideDisplayHidden(available);
            setLeftSideDisplayOnly(available);
            setRightSideDisplayHidden(selected);
            setRightSideDisplayOnly(selected);
        } else {
            setLeftSideDisplayGroup(makeGroupedIndicators(available));
        }
    }, [selected, available, useJoinedButtonsSingle]);

    useEffect(() => {
        const keyPress = (e: any) => {
            if (e.shiftKey && e.code === 'ArrowUp' && highlightedValueLeftSide.length <= 1) {
                e.preventDefault();
                const firstHighlightedVal = highlightedValueLeftSide[0];
                const searchType = firstHighlightedVal.displayName.split(' ')[0];
                const newHighlighted = leftSideDisplayOnly.filter((elem) => elem.displayName.split(' ')[0].includes(searchType));
                setHighlightedValueLeftSide(newHighlighted);
            }
            if (e.shiftKey && e.code === 'ArrowDown' && highlightedValueRightSide.length <= 1) {
                e.preventDefault();
                const firstHighlightedVal = highlightedValueRightSide[0];
                const searchType = firstHighlightedVal.displayName.split(' ')[0];
                const newHighlighted = rightSideDisplayOnly.filter((elem) => elem.displayName.split(' ')[0].includes(searchType));
                setHighlightedValueRightSide(newHighlighted);
            }
            if (e.shiftKey && e.code === 'ArrowRight' && highlightedValueLeftSide.length >= 1) {
                moveHighlightedToRightSide();
            }
            if (e.shiftKey && e.code === 'ArrowLeft' && highlightedValueRightSide.length >= 1) {
                moveHighlightedToLeftSide();
            }
        };
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    });

    // this will be when the set button is clicked...
    const handleSetSelectedAndAvailable = () => {
        setInputState('');
        setAvailable(leftSideDisplayHidden);
        setSelected(rightSideDisplayHidden);
        setLeftSideDisplayOnly(leftSideDisplayHidden);
        setRightSideDisplayOnly(rightSideDisplayHidden);
        closeModal();
    };

    useEffect(() => {
        if (available?.length == 0 && selected?.length == 0 && modalIsOpen) {
            setSelected(rightSideDisplayHidden);
        }
    }, [modalIsOpen]);

    // when we CLOSE modal WITHOUT 'set' (states default back to available and selected)
    const handleModalClose = () => {
        setHighlightedValueLeftSide([]);
        setHighlightedValueRightSide([]);
        setRightSideDisplayOnly(selected);
        setLeftSideDisplayOnly(available);
        setRightSideDisplayHidden(selected);
        setLeftSideDisplayHidden(available);
        closeModal();
    };

    // when the input changes in search box
    const inputChangeSearchHandler = (e: any) => {
        const { value } = e.target;
        setInputState(value);
        const tempArrayAvailable: CommodityReferenceKey[] = [...leftSideDisplayHidden];
        const leftTempDisplay: CommodityReferenceKey[] = [];
        tempArrayAvailable.forEach((element) => {
            if (element.displayName.toLowerCase().includes(value.toLowerCase()) && !rightSideDisplayOnly.includes(element)) {
                leftTempDisplay.push(element);
            }
        });
        if (useJoinedButtonsSingle) {
            setLeftSideDisplayOnly(leftTempDisplay);
        } else {
            setLeftSideDisplayGroup(makeGroupedIndicators(leftTempDisplay));
        }
    };

    // if the comparison array includes a value fromt the new Array, push it in and send it back
    const compareAndMerge = (comparisonArray: any[]) => {
        const newArray: any[] = [];
        originalAvailable.forEach((element: any) => {
            if (getGuids(comparisonArray).includes(element.guid)) {
                newArray.push(element);
            }
        });
        return newArray;
    };

    // moves highlighted values to last position on right side of screen
    const moveHighlightedToRightSide = () => {
        const leftSideDisplay = [...leftSideDisplayOnly];
        const leftSideHidden = [...leftSideDisplayHidden];
        leftSideDisplayHidden.forEach((element) => {
            if (getGuids(highlightedValueLeftSide).includes(element.guid)) {
                leftSideHidden.splice(getGuids(leftSideHidden).indexOf(element.guid), 1);
            }
        });
        highlightedValueLeftSide.forEach((element) => {
            leftSideDisplay.splice(getGuids(leftSideDisplay).indexOf(element.guid), 1);
        });
        setLeftSideDisplayOnly([...leftSideDisplay]);
        setRightSideDisplayOnly([...rightSideDisplayOnly, ...highlightedValueLeftSide]);
        setLeftSideDisplayGroup(makeGroupedIndicators([...leftSideDisplay]));
        setLeftSideDisplayHidden([...leftSideHidden]);
        setRightSideDisplayHidden([...rightSideDisplayHidden, ...highlightedValueLeftSide]);
        setHighlightedValueLeftSide([]);
    };

    // moves highlighted values to left side of screen and returns to original position
    const moveHighlightedToLeftSide = () => {
        const rightSideDisplay: CommodityReferenceKey[] = [...rightSideDisplayOnly];
        const rightSideHiddenTemp: CommodityReferenceKey[] = [];
        rightSideDisplayHidden.forEach((element) => {
            if (!getGuids(highlightedValueRightSide).includes(element.guid)) {
                rightSideHiddenTemp.push(rightSideDisplayHidden[getGuids(rightSideDisplayHidden).indexOf(element.guid)]);
            }
        });
        highlightedValueRightSide.forEach((element) => {
            rightSideDisplay.splice(getGuids(rightSideDisplay).indexOf(element.guid), 1);
        });
        // only required to get 'origional' available values
        const resetAvailable = compareAndMerge([...leftSideDisplayOnly, ...highlightedValueRightSide]);
        setRightSideDisplayOnly([...rightSideDisplay]);
        setLeftSideDisplayOnly(resetAvailable);
        setRightSideDisplayHidden([...rightSideHiddenTemp]);
        setLeftSideDisplayGroup(makeGroupedIndicators(resetAvailable));
        setLeftSideDisplayHidden(compareAndMerge([...leftSideDisplayHidden, ...highlightedValueRightSide]));
        setHighlightedValueRightSide([]);
    };

    // Object selector
    const groupSelector = (e: any) => {
        const { value } = e.target;
        if (value === groupedIndicatorKey) {
            setGroupedIndicatorKey('');
        } else {
            setGroupedIndicatorKey(value);
        }
    };

    // when an element is clicked, determine whether it was ctrl+clicked or not and highlight where applicable
    const setHighlightedValueHandler = (e: any) => {
        const { value, name } = e.target;
        const { ctrlKey } = e;
        const shiftKey = e.shiftKey;
        e.stopPropagation();

        const leftSideIncludesValue = getGuids(leftSideDisplayOnly).includes(value);
        const rightSideIncludesValue = getGuids(rightSideDisplayOnly).includes(value);
        const highlightedLeftSideIncludesValue = getGuids(highlightedValueLeftSide).includes(value);
        const highlightedRightSideIncludesValue = getGuids(highlightedValueRightSide).includes(value);

        if (shiftKey && leftSideIncludesValue && !highlightedLeftSideIncludesValue) {
            setHighlightedValueLeftSide((prev) => {
                const lastClickedGuid = getGuids(prev);
                const lastValuefromLastClickedGuid = lastClickedGuid[lastClickedGuid.length - 1];
                const allValsStartIndex = leftSideDisplayOnly.findIndex((e) => e.guid === lastValuefromLastClickedGuid);
                const allValsEndIndex = leftSideDisplayOnly.findIndex((e) => e.guid === value);
                let returnArray = [];
                if (allValsEndIndex > allValsStartIndex) {
                    returnArray = leftSideDisplayOnly.slice(allValsStartIndex, allValsEndIndex + 1);
                } else {
                    returnArray = leftSideDisplayOnly.slice(allValsEndIndex, allValsStartIndex + 1);
                }
                return returnArray;
            });
        } else if (shiftKey && rightSideIncludesValue && !highlightedRightSideIncludesValue) {
            setHighlightedValueRightSide((prev) => {
                const lastClickedGuid = getGuids(prev);
                const lastValuefromLastClickedGuid = lastClickedGuid[lastClickedGuid.length - 1];
                const allValsStartIndex = rightSideDisplayOnly.findIndex((e) => e.guid === lastValuefromLastClickedGuid);
                const allValsEndIndex = rightSideDisplayOnly.findIndex((e) => e.guid === value);
                let returnArray = [];
                if (allValsEndIndex > allValsStartIndex) {
                    returnArray = rightSideDisplayOnly.slice(allValsStartIndex, allValsEndIndex + 1);
                } else {
                    returnArray = rightSideDisplayOnly.slice(allValsEndIndex, allValsStartIndex + 1);
                }
                return returnArray;
            });
        } else if (leftSideIncludesValue && !ctrlKey) {
            setHighlightedValueLeftSide([{ displayName: name, guid: value }]);
        } else if (leftSideIncludesValue && !highlightedLeftSideIncludesValue && ctrlKey) {
            setHighlightedValueLeftSide([...highlightedValueLeftSide, { displayName: name, guid: value }]);
            // logic for keeping highlighted sections in order
            const holdingArray: any[] = [];
            leftSideDisplayOnly.forEach((element) => {
                if (getGuids(highlightedValueLeftSide).includes(element.guid) || element.guid === value) {
                    holdingArray.push(element);
                }
            });
            setHighlightedValueLeftSide([...holdingArray]);
        } else if (rightSideIncludesValue && !highlightedRightSideIncludesValue && !ctrlKey) {
            setHighlightedValueRightSide([{ displayName: name, guid: value }]);
        } else if (rightSideIncludesValue && !highlightedRightSideIncludesValue && ctrlKey) {
            // logic for keeping highlighted sections in order
            const holdingArray: any[] = [];
            rightSideDisplayOnly.forEach((element) => {
                if (getGuids(highlightedValueRightSide).includes(element.guid) || element.guid === value) {
                    holdingArray.push(element);
                }
            });
            setHighlightedValueRightSide([...holdingArray]);
        }
    };

    // moves highlighted value 'up' within selected array
    const moveHighlightedUp = () => {
        const rightSide = [...rightSideDisplayOnly];
        const highlighted = [...highlightedValueRightSide];
        for (let i = 0; i < highlighted.length; i += 1) {
            const indexOfElement = getGuids(rightSide).indexOf(highlighted[i].guid);
            if (indexOfElement !== 0) {
                const tempValue = rightSide[indexOfElement - 1];
                rightSide[indexOfElement - 1] = highlighted[i];
                rightSide[indexOfElement] = tempValue;
            }
        }
        setRightSideDisplayHidden([...rightSide]);
        setRightSideDisplayOnly([...rightSide]);
    };

    // moves highlighted value 'down' within selected array
    const moveHighlightedDown = () => {
        const rightSide = [...rightSideDisplayOnly];
        const highlighted = [...highlightedValueRightSide];
        for (let i = highlighted.length - 1; i >= 0; i -= 1) {
            const indexOfElement = getGuids(rightSide).indexOf(highlighted[i].guid);
            if (indexOfElement !== rightSide.length - 1) {
                const tempValue = rightSide[indexOfElement + 1];
                rightSide[indexOfElement + 1] = highlighted[i];
                rightSide[indexOfElement] = tempValue;
            }
        }
        setRightSideDisplayHidden([...rightSide]);
        setRightSideDisplayOnly([...rightSide]);
    };

    const updateJoinedSelection = (selectionValue: SingleOrGroup) => {
        setUseJoinedButtonsSingle(selectionValue === 'single');
    };

    return (
        <>
            {modalTitle && isFuturePage ? (
                <div
                    onClick={openModal}
                    className={
                        isFuturePage
                            ? `${styles.manageModal_clickable_title_future} ${styles.ag_grid_selection_row_2}`
                            : `${styles.manageModal_clickable_title} ${styles.ag_grid_selection_row_2}`
                    }
                >
                    {`${translations.dashboard.indicators.manage} ${modalTitle}`}
                </div>
            ) : (
                <div className={styles.manageModal_management_button}>
                    <IndicatorsManagementButton handleClick={openModal} tooltipText={translations.dashboard.indicators.addOrRemoveLeadingIndicators} />
                </div>
            )}
            <Modal onClose={closeModal} open={modalIsOpen} disableEnforceFocus>
                <div className={styles.manageModal_modal}>
                    <div className={styles.manageModal_modal_container}>
                        <div className={styles.manageModal_closeContainer}>
                            <Close onClick={closeModal} color="inherit" />
                        </div>
                        <h3 className={styles.manageModal_title}>{`${translations.dashboard.indicators.manage} ${modalTitle}`}</h3>
                        <h4 className={styles.manageModal_sub_heading}>{translations.dashboard.indicators.manageModalSubHeading}</h4>
                        <div className={styles.master_flex} style={{ marginBottom: 25, width: '100%' }}>
                            <div className={styles.manageModal_joined_buttons}>
                                <JoinedSelectionButtons
                                    options={selectionGroupOptions}
                                    handleSelectionChange={updateJoinedSelection}
                                    selection={useJoinedButtonsSingle ? 'single' : 'group'}
                                />
                            </div>
                            <div className={styles.manageModal_input}>
                                <input
                                    value={inputState}
                                    onChange={inputChangeSearchHandler}
                                    type="text"
                                    className={styles.manageModal_input_search}
                                    placeholder={
                                        isFuturePage
                                            ? translations.futures.text.searchProductPlaceholder
                                            : translations.dashboard.indicators.searchIndicatorPlaceholder
                                    }
                                />
                            </div>
                        </div>
                        <div className={styles.master_flex}>
                            <div className={styles.manageModal_header_and_box}>
                                <div className={styles.manageModal_items_header}>
                                    {isFuturePage ? translations.dashboard.indicators.availableProducts : translations.dashboard.indicators.availableIndicators}
                                </div>
                                <div className={styles.manageModal_left_box}>
                                    {useJoinedButtonsSingle
                                        ? leftSideDisplayOnly?.map((choice: CommodityReferenceKey) => (
                                              <div key={choice.guid}>
                                                  <button
                                                      value={choice.guid}
                                                      name={choice.displayName}
                                                      type="button"
                                                      onClick={setHighlightedValueHandler}
                                                      className={
                                                          getGuids(highlightedValueLeftSide).indexOf(choice.guid) === -1
                                                              ? styles.manageModal_clear_button
                                                              : styles.manageModal_selected
                                                      }
                                                  >
                                                      {languageService.translate(choice.displayName)}
                                                  </button>
                                              </div>
                                          ))
                                        : Object.keys(leftSideDisplayGroup).map((choice: string, index: number) => (
                                              <div key={choice}>
                                                  <button
                                                      value={choice}
                                                      name={choice}
                                                      type="button"
                                                      onClick={groupSelector}
                                                      className={styles.manageModal_clear_button}
                                                  >
                                                      {groupedIndicatorKey !== '' && groupedIndicatorKey === choice ? (
                                                          <KeyboardArrowDownIcon className={styles.manageModal_dropdown_arrow_icons} />
                                                      ) : (
                                                          <ChevronRightIcon className={styles.manageModal_dropdown_arrow_icons} />
                                                      )}
                                                      {languageService.translate(choice)}
                                                  </button>
                                                  {groupedIndicatorKey !== '' &&
                                                      groupedIndicatorKey === choice &&
                                                      // @ts-ignore
                                                      leftSideDisplayGroup[Object.keys(leftSideDisplayGroup)[index]].map((item: SelectedAndAvailable) => (
                                                          <div className={styles.manageModal_choice_group_options}>
                                                              <button
                                                                  value={item.guid}
                                                                  name={item.displayName}
                                                                  type="button"
                                                                  onClick={setHighlightedValueHandler}
                                                                  className={
                                                                      getDisplayNames(highlightedValueLeftSide).indexOf(item.displayName) === -1
                                                                          ? styles.manageModal_clear_button
                                                                          : styles.manageModal_selected
                                                                  }
                                                              >
                                                                  {item.displayName}
                                                              </button>
                                                          </div>
                                                      ))}
                                              </div>
                                          ))}
                                </div>
                            </div>
                            <div className={styles.manageModal_movement_div}>
                                <button onClick={moveHighlightedToLeftSide} className={styles.manageModal_movement_button} type="button">
                                    <ChevronLeftIcon className={styles.manageModal_arrow_icons} />
                                </button>
                                <button onClick={moveHighlightedToRightSide} className={styles.manageModal_movement_button} type="button">
                                    <ChevronRightIcon className={styles.manageModal_arrow_icons} />
                                </button>
                            </div>
                            <div className={styles.manageModal_header_and_box}>
                                <div className={styles.manageModal_items_header}>
                                    {isFuturePage ? translations.dashboard.indicators.selectedProducts : translations.dashboard.indicators.selectedIndicators}
                                </div>
                                <div className={styles.manageModal_right_box}>
                                    {rightSideDisplayOnly.map((choice: CommodityReferenceKey) => (
                                        <div key={choice.guid}>
                                            <button
                                                value={choice.guid}
                                                name={choice.displayName}
                                                type="button"
                                                onClick={setHighlightedValueHandler}
                                                className={
                                                    getGuids(highlightedValueRightSide).indexOf(choice.guid) === -1
                                                        ? styles.manageModal_clear_button
                                                        : styles.manageModal_selected
                                                }
                                            >
                                                {languageService.multiWordTranslate(choice.displayName)}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.manageModal_movement_div}>
                                <button onClick={moveHighlightedUp} className={styles.manageModal_movement_button} type="button">
                                    <KeyboardArrowUpIcon className={styles.manageModal_arrow_icons} />
                                </button>
                                <button onClick={moveHighlightedDown} className={styles.manageModal_movement_button} type="button">
                                    <KeyboardArrowDownIcon className={styles.manageModal_arrow_icons} />
                                </button>
                            </div>
                        </div>

                        <div className={styles.manageModal_cancel_set}>
                            <LinkButton title={translations.actions.set} type={LinkButtonType.Blue} onClick={handleSetSelectedAndAvailable} />
                            <LinkButton title={translations.actions.cancel} type={LinkButtonType.White} onClick={handleModalClose} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ManageModal;
